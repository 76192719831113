import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    TableContainer, 
    TableHead,
    Button,
    TableRow, 
    TableBody,
    TableCell,
    Table,
    IconButton,
    TextField,
    CircularProgress,
    FormControl
} from '@mui/material'
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch,useSelector } from "react-redux";
import moment from 'moment'
import FileBase64 from 'react-file-base64';
import ImageIcon from '@mui/icons-material/Image';

import useStyles from "../styles";
import {form6Update,getFile,assignLead,getLead,addSignature,sendForm6} from '../../../LeadState'
import NotificationContainer from "../../../../notifications/NotificationContainer";
import InvoicePDF from "../invoice/InvoicePDF";
import { fileupload } from '../../../../material_pickup_request/MprState';



const Form6  = ({form6Datas,form6File,list,close}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const dispatch = useDispatch()
    const [TableData,setTableData] = useState([])
    const loginData = useSelector(state => state.userdata)
    const state = useSelector(state => state.selectedLead)
    let external_id = (state.length > 0)? state[0].external_id : "";
    let loggedinID = (loginData && loginData.getData)?loginData.getData._id:""
    
    //data updation
    const [form6Data,setForm6Data] = useState(form6Datas)

    //show/ hide data
    const [showSenderAddress,setShowSenderAddress] = useState(false)
    const [showSenderAuth, setShowSenderAuth] = useState(false)
    const [showManifestDoc,setShowManifestDoc] = useState(false)
    const [showTransAddress,setShowTransAddress] = useState(false)
    const [showVehicle,setShowVehicle] = useState(false)
    const [showtransReg,setShowTransReg] = useState(false)
    const [showVehicleReg,setShowVehicleReg] = useState(false)
    const [showReceiverAuth,setShowReceiverAuth] = useState(false)
    const [showDescription,setShowDescription] = useState(false)
    const [showReceiverAdd,setShowReceiverAdd] = useState(false)
    const [showSign,setShowSign] = useState(false)

    //save data
    const [senderAuthNo,setSenderAuthNo] = useState((form6Data && form6Data.Content)?form6Data.Content.no2:"")
    const [manifest,setManifest] = useState((form6Data && form6Data.Content)?form6Data.Content.no3:"")
    const [transAdd,setTransAdd] = useState((form6Data && form6Data.Content)?form6Data.Content.no4:"")
    const [vehicle,setVehicle] = useState((form6Data && form6Data.Content)?form6Data.Content.no5:"")
    const [TransReg,setTransReg] = useState((form6Data && form6Data.Content)?form6Data.Content.no6:"")
    const [vehicleReg,setVehicleReg] = useState((form6Data && form6Data.Content)?form6Data.Content.no7:"")
    const [receiverAuth,setReceiverAuth] = useState((form6Data && form6Data.Content)?form6Data.Content.no9:"")
    const [desc,setDesc] = useState((form6Data && form6Data.Content)?form6Data.Content.no10:"")
    // eslint-disable-next-line
    const [senderAdd,setSenderAdd] = useState((form6Data && form6Data.Content)?form6Data.Content.no1:"")
    // eslint-disable-next-line
    const [receiverAdd,setReceiverAdd] = useState((form6Data && form6Data.Content)?form6Data.Content.no8:"")
    // eslint-disable-next-line
    const [stampData,setStampData] = useState((form6Data && form6Data.Content)?form6Data.thisStamp:"")
    // eslint-disable-next-line
    const [signData,setSignData] = useState((form6Data && form6Data.Content)?form6Data.thisSignature:"")
    // eslint-disable-next-line
    const [fileData,setFileData] = useState("")

    //message
    const [showloader, setShowLoader] = useState(false);
    const [message,setMessage] = useState("")
    const [notification,setNotification] = useState(false)
    const [type,setType] = useState("")
    const [showStampLoader,setShowStampLoader] = useState(false)
    const[sendButton,setSendButton]=useState(false)

    //pdf
    const [showPDF,setShowPDF] = useState(false)
    const [formUrl,setFormUrl] = useState("")

    const HandleIcon = ({onclick}) => {
        let icon = <IconButton className={classes.collapseIcon} onClick={onclick} size="large"><EditIcon /></IconButton>
        return icon
    }

    const HandleSaveIcon = ({func,disable}) => {
        let icon = <Button variant="outlined"  disabled={disable} size="small" onClick={() => handleSave(func)} className={classes.buttonOutlined}>{t("common_save")}</Button>
        return icon
    }
    
    const handleshowhidepdf = () => {
        setShowPDF(!showPDF)
    }

    const handleupdateImage= (e) => {
        setFileData(e)
    }

    const handleChange = (type) => {
       if(fileData.type === "image/png" || fileData.type === "image/jpeg" || fileData.type === "image/jpg"){
            setShowStampLoader(true)
            var filetype = fileData.type.split('/')
            var baseData = fileData.base64.split(',')
            let getTokenData = {
                "abbrevation" : loginData.getData.abbrevation,
                "fileType" : "assets",
                "folderName": list.external_id,
                "expireLimt" :1800,
                "extension" : (filetype.length >0) ? filetype[1] : ""
            }
            fileupload((baseData.length >0)?baseData[1]:"",getTokenData,fileData.type ).then((response) => {
                if(response.status === "200"){
                    let body = {
                        thisItem:type,
                        thisImage:{
                            fileName: response.fileName || response.filename,
                            folderName: response.folderName || response.folder || response.FolderName,
                        },
                        thisLead:list.external_id,
                        thisForm:'form6'
                    }
                    addSignature(body).then(resp =>{
                        if(resp.status === "200"){
                            setType("success")
                            setMessage(resp.message)
                            setNotification(true)
                            setShowStampLoader(false)
                            setShowSign(false)
                            getLead(external_id,dispatch).then(response => {
                                if(response && response.thisFormContent) setForm6Data(response.thisFormContent)
                            })
                        }else{
                            setType("error")
                            setMessage(resp.message)
                            setNotification(true)
                            setShowStampLoader(false)
                            setShowSign(false)
                        }
                    }) 
                }
              })
        } else{
            setType("error")
            setMessage("Please upload Image")
            setNotification(true)
        }
    }

    const handleSave = (func) => {
        setShowLoader(true)
        let BodyData = {
            "thisleadID":list.external_id,
            "thisDetails":{
                "no1":senderAdd,
                "no2":senderAuthNo,
                "no3":manifest,
                "no4":transAdd,
                "no5":vehicle,
                "no6":TransReg,
                "no7":vehicleReg,
                "no8":receiverAdd,
                "no9":receiverAuth,
                "no10":desc,
                "no11":(form6Data && form6Data.Content && form6Data.Content.no11 !== "Invalid date")?form6Data.Content.no11:"",
                "no12":(form6Data && form6Data.Content && form6Data.Content.no11 !== "Invalid date")?form6Data.Content.no12:"",
                "no13":(form6Data && form6Data.Content && form6Data.Content.no11 !== "Invalid date")?form6Data.Content.no13:""
            }
        }
        form6Update(BodyData).then(response => {
            
            if(response.status === "200"){
                func(false)
                setType("success")
                setMessage("Updated Successfully")
                setNotification(true)
                setShowLoader(false)
                getLead(external_id,dispatch).then(response => {
                    if(response && response.thisFormContent) setForm6Data(response.thisFormContent)
                })
                //setTimeout(()=>close(),2000)
            }else{
                func(false)
                setType("error")
                setMessage("Something went wrong")
                setNotification(true)
                setShowLoader(false)
            }
        })
    }

    const handleShowPreview = () => {
        setShowPDF(false)
    }

    const handlePreview = () => {
        let body  = {
            "fileName" : form6File.fileName,
            "folderName" : form6File.folderName,
            "expireLimt" : 1800
        }
        getFile(body).then(response => {
            setFormUrl(response.reference)
            setShowPDF(true)
        })
    }

    const handleSentForm6=()=>{
        setShowLoader(true)
        let data = {
            "form6":{
                "fileName" : form6File.fileName,
                "folderName" : form6File.folderName,
                "expireLimt" : 1800
            },
            "leadId":list.external_id
        }
        sendForm6(data,dispatch).then(response => {
            if(response.status === "200"){
                setType("success")
                setMessage("Form-6 Sent Successfully!")
                setNotification(true)
                setTimeout(()=>close(),2000)
                setShowLoader(false)
            }else{
                setType("error")
                setMessage("Something went wrong")
                setNotification(true)
                setShowLoader(false)
            }
        })
    }

    const handlePublish = () => {
        setShowLoader(true)
        let data = {
            "external_id":list.external_id,
            "leadData":{
                "status":"Form6 sent"
            },
            "mpr_id":list.Mpr_id
        }
        assignLead(data,dispatch).then(response => {
            if(response.status === "200"){
                setType("success")
                setMessage("Form-6 Sent Successfully!")
                setNotification(true)
                setTimeout(()=>close(),2000)
                setShowLoader(false)
            }else{
                setType("error")
                setMessage("Something went wrong")
                setNotification(true)
                setShowLoader(false)
            }
        })
    }

    const getFiles = (file) => {
        let bodyData = {
            fileName: file.fileName || file.filename,
            folderName: file.folderName || file.folder || file.FolderName,
            expireLimt : 1000
        }
        getFile(bodyData).then(response => {
            window.open(response.reference,"_blank");
        })
    }

    useEffect(()=>{
        if (localStorage.getItem('type') === "tenant") {
            setSendButton(true)
          }
         else{
            setSendButton(false)
         } 
    },[])

    useEffect(() => {
        let data = []
        if(Object.keys(form6Data).length >0){
            let formData = form6Data.Content
            let stamp = form6Data.thisStamp
            let sign = form6Data.thisSignature
            // let address = formData.no1.Address.address1 + ", "+
            //               formData.no1.Address.address2 + ", "+
            //               formData.no1.City + ", "+
            //               formData.no1.State + ", "+
            //               formData.no1.Pincode
            data.push(
                {
                    label : t("lead_stamp"),
                    value : (stamp ==="")?
                            (loginData.getData.roles.includes("Admin"))?
                                <><div className={classes.fileTypeInputTable}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={8} md={8} sm={12} xs={12}>
                                            
                                                <FormControl variant="standard" className={classes.margin}>
                                                <FileBase64
                                                    className={classes.input}
                                                    multiple={false}
                                                    onDone={(e)=>handleupdateImage(e)}
                                                />
                                                </FormControl>
                                                
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12} style={{textAlign:'right'}}>
                                           {(showStampLoader)?<CircularProgress /> : <Button size="small" variant="outlined" color="primary" onClick={()=>handleChange("Stamp")} className={classes.button}>{t("common_upload")}</Button>} 
                                        </Grid>
                                    </Grid>
                                </div>
                                <span>Please upload .png,.jpg, .jpeg file formats</span></>:<></>:
                                <IconButton
                                    onClick={() => getFiles(stamp)}
                                    target="_blank"
                                    className={classes.collapseIcon}
                                    size="large">
                           <ImageIcon />
                        </IconButton>,
                    action : ""
                },
                {
                    label : t("lead_signature"),
                    value :  (sign ==="" || showSign)?
                            (list.assigned_to ==="" || list.assigned_to === loggedinID)?
                                <><div className={classes.fileTypeInputTable}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={8} md={8} sm={12} xs={12}>
                                            <FormControl variant="standard" className={classes.margin}>
                                            <FileBase64
                                                className={classes.input}
                                                multiple={false}
                                                onDone={(e)=>handleupdateImage(e)}
                                            />
                                            </FormControl> 
                                        </Grid>
                                        <Grid item lg={4} md={4} sm={12} xs={12} style={{textAlign:'right'}}>
                                           {(showStampLoader)?<CircularProgress /> : <Button size="small" variant="outlined" color="primary" onClick={()=>handleChange("Signature")} className={classes.button}>{t("common_upload")}</Button>} 
                                        </Grid>
                                    </Grid>
                                </div>
                                <span>Please upload .png,.jpg, .jpeg file formats</span></>:<></>:
                                    <IconButton
                                        onClick={() => getFiles(sign)}
                                        target="_blank"
                                        className={classes.collapseIcon}
                                        size="large">
                                        <ImageIcon />
                                    </IconButton>,
                    action : (showSign)?(showloader)?<CircularProgress />:"":<HandleIcon onclick={()=>setShowSign(true)}/>
                },
                {
                    label : t("lead_form_label1"), 
                    value: (showSenderAddress)?<TextField
                        variant="standard"
                        autoFocus
                        label=""
                        fullWidth
                        value={senderAdd}
                        onChange={(e) => setSenderAdd(e.target.value)} />:formData.no1 ,
                    action :(showSenderAddress)?(showloader)?<CircularProgress />:<HandleSaveIcon disable = {(senderAdd === "")?true:false} func = {setShowSenderAddress}/>:<HandleIcon onclick={()=>setShowSenderAddress(true)}/>
                },
                {
                    label : t("lead_form_label2"),
                    value : (showSenderAuth)?<TextField
                        variant="standard"
                        autoFocus
                        label=""
                        value={senderAuthNo}
                        onChange={(e) => setSenderAuthNo(e.target.value)} />:formData.no2 ,
                    action :  (showSenderAuth)?(showloader)?<CircularProgress />:<HandleSaveIcon disable = {(senderAuthNo === "")?true:false} func = {setShowSenderAuth}/>:<HandleIcon onclick={()=>setShowSenderAuth(true)}/>
                },
                {
                    label : t("lead_form_label3"),
                    value:  (showManifestDoc)?<TextField
                        variant="standard"
                        autoFocus
                        label=""
                        value={manifest}
                        onChange={(e) => setManifest(e.target.value)} />:formData.no3 ,
                    action :  (showManifestDoc)?(showloader)?<CircularProgress />:<HandleSaveIcon disable = {(manifest === "")?true:false} func = {setShowManifestDoc} />:<HandleIcon onclick={()=>setShowManifestDoc(true)}/>
                },
                {
                    label : t("lead_form_label4"), 
                    value: (showTransAddress)?<TextField
                        variant="standard"
                        autoFocus
                        label=""
                        value={transAdd}
                        onChange={(e) => setTransAdd(e.target.value)} />:formData.no4 ,
                    action :  (showTransAddress)?(showloader)?<CircularProgress />:<HandleSaveIcon disable = {(transAdd === "")?true:false} func = {setShowTransAddress}/>:<HandleIcon onclick={()=>setShowTransAddress(true)}/>
                },
                {
                    label : t("lead_form_label5"), 
                    value : (showVehicle)?<TextField
                        variant="standard"
                        autoFocus
                        label=""
                        value={vehicle}
                        onChange={(e) => setVehicle(e.target.value)} />:formData.no5 ,
                    action :  (showVehicle)?(showloader)?<CircularProgress />:<HandleSaveIcon disable = {(vehicle === "")?true:false} func = {setShowVehicle} />:<HandleIcon onclick={()=>setShowVehicle(true)}/>
                },
                {
                    label : t("lead_form_label6"), 
                    value: (showtransReg)?<TextField
                        variant="standard"
                        autoFocus
                        label=""
                        value={TransReg}
                        onChange={(e) => setTransReg(e.target.value)} />:formData.no6 ,
                    action :  (showtransReg)?(showloader)?<CircularProgress />:<HandleSaveIcon disable = {(TransReg === "")?true:false} func = {setShowTransReg}/>:<HandleIcon onclick={()=>setShowTransReg(true)}/>
                },
                {
                    label : t("lead_form_label7"), 
                    value : (showVehicleReg)?<TextField
                        variant="standard"
                        autoFocus
                        label=""
                        value={vehicleReg}
                        onChange={(e) => setVehicleReg(e.target.value)} />:formData.no7 ,
                    action :  (showVehicleReg)?(showloader)?<CircularProgress />:<HandleSaveIcon disable = {(vehicleReg === "")?true:false} func = {setShowVehicleReg}/>:<HandleIcon onclick={()=>setShowVehicleReg(true)}/>
                },
                {
                    label : t("lead_form_label8"), 
                    value:(showReceiverAdd)?<TextField
                        variant="standard"
                        autoFocus
                        label=""
                        fullWidth
                        value={receiverAdd}
                        onChange={(e) => setReceiverAdd(e.target.value)} />:formData.no8 ,
                    action : (showReceiverAdd)?(showloader)?<CircularProgress />:<HandleSaveIcon disable = {(receiverAdd === "")?true:false} func = {setShowReceiverAdd}/>:<HandleIcon onclick={()=>setShowReceiverAdd(true)}/>
                },
                {
                    label :  t("lead_form_label9"), 
                    value : (showReceiverAuth)?<TextField
                        variant="standard"
                        autoFocus
                        label=""
                        value={receiverAuth}
                        onChange={(e) => setReceiverAuth(e.target.value)} />:formData.no9 ,
                    action :  (showReceiverAuth)?(showloader)?<CircularProgress />:<HandleSaveIcon disable = {(receiverAuth === "")?true:false} func = {setShowReceiverAuth} />:<HandleIcon onclick={()=>setShowReceiverAuth(true)}/>
                },
                {
                    label : t("lead_form_label10"), 
                    value: (showDescription)?<TextField
                        variant="standard"
                        autoFocus
                        label=""
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)} />:formData.no10 ,
                    action :  (showDescription)?(showloader)?<CircularProgress />:<HandleSaveIcon disable = {(desc === "")?true:false} func = {setShowDescription}/>:<HandleIcon onclick={()=>setShowDescription(true)}/>
                }
            )
        }
       
        setTableData(data)
        // eslint-disable-next-line
    },[showloader,showSenderAuth,senderAuthNo,
        manifest,showManifestDoc,
        showTransAddress,transAdd,
        showVehicle,vehicle,
        showtransReg,TransReg,
        showVehicleReg,vehicleReg,
        showReceiverAuth,receiverAuth,
        showDescription,desc,form6Data,
        fileData,showStampLoader,message,
        showSenderAddress,senderAdd,showSign,
        showReceiverAdd,receiverAdd
    ])
    
    return(
        <Paper className={classes.Datapaper}>
             {
                (notification)?<NotificationContainer message={message} notificationtype={type}/>:<></>
            }
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={5} lg={5} md={4}>
                        <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("lead_form_review")}</span>
                </Grid>
                <Grid item xs={12} sm={7} lg={6} md={8} className={classes.iconGridWithButton}>
                    {
                        (showPDF)?<Button variant="outlined" onClick={handleShowPreview} color="primary" size="small" className={classes.buttonOutlined} >{t("common_back")}</Button>
                        : <Grid style={{display:'flex'}}>
                           {sendButton &&  <Button variant="outlined" onClick={handleSentForm6} color="primary" size="small" className={classes.buttonOutlined} style={{marginRight:"12px"}} >{t("common_sendform6")}</Button>}
                            <Button variant="outlined" onClick={handlePreview} color="primary" size="small" className={classes.buttonOutlined} >{t("common_preview")}</Button>
                            <Button variant="outlined" onClick={()=>close()} color="primary" size="small" className={classes.buttonOutlined} >{t("common_close")}</Button>
                        </Grid>
                    }
                    
                </Grid>         
            </Grid>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={12} md={12} xs={12} sm={12}>
                 {
                     (showPDF)?<InvoicePDF  url={formUrl} close={handleshowhidepdf}/>: 
                     <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead >
                            <TableRow>
                                <TableCell className={classes.tableHeader}>{t("common_name")}</TableCell>
                                <TableCell align="left" className={classes.tableHeader}>{t("common_details")}</TableCell>
                                {
                                    ((form6Data.formstatus).toLowerCase() === ("approved").toLowerCase())?<></>:
                                    <TableCell align="left" className={classes.tableHeader}>{t("common_action")}</TableCell>
                                }
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {(TableData.length > 0)?
                           
                            TableData.map((item,key) => {
                                return(
                                    <TableRow key = {key}>
                                        <TableCell component="th" scope="row">{item.label}</TableCell>
                                        <TableCell align="left">{item.value}</TableCell>
                                        {
                                            ((form6Data.formstatus).toLowerCase() === ("approved").toLowerCase())?
                                            <></>:<TableCell align="left">{item.action}</TableCell>
                                        }   
                                    </TableRow>
                                )
                            }) : 
                            <TableRow>
                                <TableCell component="th" scope="row">{t("lead_form_no_data")}</TableCell>
                            </TableRow>
                        }
                        </TableBody>
                    </Table>
                </TableContainer> 
                }
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={10} className={classes.spanForm6}>
                    {
                        (form6Data.thisStamp ==="" || form6Data.thisSignature ==="")?
                        <span>Please Upload Your Signature [Either Admin's or Lead Owner's] and Stamp to publish your Form 6</span>:<></>
                    }
                </Grid>
                <Grid item lg={2} >
                 {
                     ((form6Data.formstatus).toLowerCase() === ("approved").toLowerCase())?<></>:
                     (showloader)?<CircularProgress />:
                     (loginData && loginData.getData && !loginData.getData.roles.includes('user'))?
                     (form6Data.thisStamp !=="" && form6Data.thisSignature !=="")?
                     <Button variant="outlined"  color="primary" onClick={handlePublish}  className={classes.buttonOutlined} >{t("common_publish")}</Button>:<></>:<></>
                 }   
                </Grid>

            </Grid>
        </Paper>
    )
}
export default Form6
import React, { useState } from "react";
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { useHistory } from "react-router-dom";
import { updateRequestFormNotification } from "../Header/HeaderState";
export const NotificationBell = ({ notifyData, sendNotifyToParent }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const sendNofticationUpdateToParent = () => {
    sendNotifyToParent(Date.now());
  };

  const updateAndGoToNotification = (notification) => {
    const notificationData = {
      notificationId: notification._id,
    };
    updateRequestFormNotification(notificationData)
      .then((response) => {
        if (response.status === 200) {
          sendNofticationUpdateToParent();
        }
        handleClose();
      })
      .catch((error) => {
        console.error("Error updating notification:", error);
      });

    history.push("/app/internalForms?tab=" + notification.type);
  };
  return (
    <div>
      <IconButton
        aria-haspopup="true"
        color="inherit"
        size="large"
        onClick={handleClick}
      >
        <Badge
          badgeContent={notifyData?.data?.length}
          style={{ marginRight: "12px" }}
        >
          <CircleNotificationsIcon style={{ color: "white" }} />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div
          style={{
            minWidth: "350px",
            maxHeight: "500px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              paddingLeft: "24px",
              paddingTop: "10px",
              fontWeight: "bold",
            }}
          >
            Notifications
          </Typography>
          <List>
            {notifyData?.data?.length == 0 ? (
              <ListItem>
                <Typography
                  variant="h6"
                  style={{ paddingLeft: "10px", fontSize: "18px" }}
                >
                  No new notifications
                </Typography>
              </ListItem>
            ) : (
              notifyData?.data?.map((notification, index) => (
                <React.Fragment key={notification?._id}>
                  <ListItem key={notification?._id}>
                    <Button
                      onClick={() => updateAndGoToNotification(notification)}
                      style={{
                        textTransform: "none",
                        textAlign: "left",
                        fontSize: "18px",
                      }}
                    >
                      <ListItemText primary={notification?.message} />
                    </Button>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))
            )}
          </List>
        </div>
      </Popover>
    </div>
  );
};

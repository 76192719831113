import {apiUrl } from '../../data/config'

export function addCustomer(data){  
    let url = apiUrl+'/v1/customer/onBoard'
    let mobileno = {"code" : data.countryCode,"number":data.mobile}
    let abbreviation = data.companyName.split(" ")
    let basicData = {
        "customer":{
            "name":data.companyName,
            "channel":data.createdBy,
            "createdAt":data.createdAt,
            "updatedAt":data.createdAt,
            "createdBy":data.createdBy,
            "updatedBy":data.createdBy,
            "isActive": true,
            "branch":[],
            //"employeeCount":data.companySize,
            "abbrevation": abbreviation[0],
            "tenant_id" : (localStorage.getItem('type') === "tenant")?localStorage.getItem('customerid'):"",
            "gst": data.taxNo,
            "notificationAllowed":!data.notificationAllowed,
            "pan": data.pan_no,
        }
    }
    let userData = {
        "user" : [{
            "userName": data.contactName,
            "branchId": "",
            "referenceId": "",
            "email": data.email,
            "mobile": mobileno,
            "createdAt":data.createdAt,
            "createdBy":data.createdBy,
            "updatedBy":data.createdBy,
            "isActive":data.isActive,
            "roles": ["Customer_Admin","Branch_Admin"],
            "isPrimary": data.isActive,
            "department": [],
            "password": "",
            "isTemperory": data.isActive,
            "type":"Customer"
        }]
    }
    let bodyData = {...basicData,...userData}
    const request = fetch(url,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token':localStorage.getItem('token')
          },

        body: JSON.stringify(bodyData)
    })
    .then( function(response) {
        if(response.ok){
            return response.json().then((data) => {
                return data
            })
        }else{
            return response
        }
    })
    return request
}


export function UpdateCustomerStatus(data){
    let url = apiUrl+'/v1/customer/saveCustomerUpdate'
    return fetch(url,{
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token':localStorage.getItem("token")
        },
        body : JSON.stringify(data)
    }).then(function (response) {
        if(response.ok){
            return response.json().then((data) => {
               return data
            })
        }else{
            return {
                "status" : "500",
                "message" : "Something went wrong"
            }
        }
    })
  }
  

export function getCutomerList(data,dispatch){
    let url = apiUrl+'/v1/tenant/uniqueCustomer'
    return fetch(url,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token':localStorage.getItem('token')
        },
        body : JSON.stringify(data)
    }).then( function(response) {
        if(response.ok){
            return response.json().then((data) => {
                dispatch({
                    type: "SET_TENANT_CUSTOMER_LIST",
                    payload: data
                })
                return data
            })
        }else{
            return response
        }
    })
}

export function sendCredential(data){  
    let url = apiUrl+'/v1/sendlogincredentials'
    return fetch(url,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token':localStorage.getItem('token')
          },

        body: JSON.stringify(data)
    })
    .then( function(response) {
        if(response.ok){
            return response.json().then((data) => {
                return data
            })
        }else{
            return {
                "status" : "500",
                "message": "Something went wrong"
              }
        }
    })
}
import React from "react";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Grid, Button, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import ViewLot from "./components/ViewLot";

const Lots = () => {
  const { t, i18n } = useTranslation();
  var classes = useStyles();

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("common_lot"), link: "" },
  ];

  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <div className={classes.HeaderContainer}>
                  <div className={classes.tenantHeader}>
                    <Typography variant="h3" size="sm">
                      {t("lot_span")}
                    </Typography>
                  </div>
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                   
                    <Button
                      variant="outlined"
                      href="/#/app/addLots"
                      // color="primary"
                      className={classes.buttonOutlined}
                      // fullWidth
                      style={{marginRight:"10px"}}
                    >
                      {t("lot_add_new")}
                    </Button>
                    <Button
                      variant="outlined"
                      href="/#/app/listTags"
                      className={classes.buttonOutlined}
                      // fullWidth
                      style={{marginRight:"10px"}}
                    >
                      {t("tags")}
                    </Button>
                    <Button
                      variant="outlined"
                      href="/#/app/mpUsers"
                      // color="primary"
                      className={classes.buttonOutlined}
                    >
                      {t("Buyers")}
                    </Button>
                    
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ViewLot />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Lots;

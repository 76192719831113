import React, { useState } from "react";
import { TextField, Grid, Paper } from "@mui/material";
import AsyncSelect from "react-select/async";
import classnames from "classnames";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";
import { searchTag } from "../MarketplaceState";
import moment from "moment";
// import { hoursList, amOrpmList, minutesList } from "../../../data/config";x
import { NumericFormat } from "react-number-format";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      // prefix = "₹"
    />
  );
}

const BasicDetails = ({ setForm, formData }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  var { errors, errorMessage, setErrors } = { formData };
  const [basicPrice, setBasicPrice] = useState(formData.basicPrice);
  const [incrementValue, setIncrementValue] = useState(formData.incrementValue);
  const [title, setTitle] = useState(formData.title);
  const [location, setLocation] = useState(formData.location);
  const [description, setDescription] = useState(formData.description);
  const [detaileDescription, setDetaileDescription] = useState(
    formData.detaileDescription
  );
  const [lastDate, setLastDate] = useState(formData.lastDate);
  const [inputValue, setValue] = useState();
  const [selectedValue, setSelectedValue] = useState(formData.tags);
  const [dateError, setDateError] = useState("");
  const [basicPriceError, setBasicPriceError] = useState("");
  const [incrementValueError, setIncrementValueError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [detailedDescriptionError, setdetailedDescriptionError] = useState("");
  var tagOptionSelected = [];

  const handleIncrementValue = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setIncrementValue(value);
    if (value === " ") {
      setIncrementValueError("Field is required");
    } else {
      setIncrementValue(value);
      formData.incrementValue = e.target.value;
      setIncrementValueError("");
    }
  };

  const handleBasicPrice = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setBasicPrice(value);
    if (value === "") {
      setBasicPriceError("Field is required");
    } else {
      setBasicPriceError("");
      formData.basicPrice = e.target.value;
    }
  };

  const handleTitle = (e) => {
    const value = e.target.value;
    setTitle(value);
    if (value.length <= 1) {
      setTitleError("Please enter atleast 2 characters");
    } else {
      setTitleError("");
      formData.title = e.target.value;
    }
  };

  const handleDescription = (e) => {
    const value = e.target.value;
    setDescription(value);
    if (value.length <= 1) {
      setDescriptionError("Please enter atleast 2 characters");
    } else {
      setDescriptionError("");
      setDescription(value);
      formData.description = e.target.value;
    }
  };

  const handleDetailedDescription = (e) => {
    const value = e.target.value;
    setDetaileDescription(value);
    if (value.length <= 1) {
      setdetailedDescriptionError("Please enter atleast 2 characters");
    } else {
      setdetailedDescriptionError("");
      formData.detaileDescription = e.target.value;
    }
  };

  const handleLocation = (e) => {
    const value = e.target.value;
    setLocation(value);
    if (value === "") {
      setLocationError("Field is required");
    } else if (value.length <= 1) {
      setLocationError("Please enter atleast 2 characters");
    } else {
      setLocationError("");
      formData.location = e.target.value;
    }
  };

  const handleLastDate = (date) => {
    const selected = date.target.value;
    const isAfter = moment(selected).isAfter(moment());
    if (isAfter) {
      setLastDate(selected);
      setDateError("");
      formData.lastDate = selected;
    } else {
      setLastDate(selected);
      setDateError("Please enter the future date");
    }
  };

  const handleInputChange = (value) => {
    setValue(value);
  };

  const handleChange = (tags) => {
    setSelectedValue(tags.value);
    tags.map((item) => {
      const newwObj = { label: item.label, value: item.value };
      tagOptionSelected.push(newwObj);
      setSelectedValue(tagOptionSelected);
    });
    formData.tags = tagOptionSelected;
  };

  const getAllTag = (e, callback) => {
    if (e.length > 0) {
      searchTag(e).then((response) => {
        const tempArray = [];
        if (response.data.tags) {
          response.data.tags.forEach((element) => {
            tempArray.push({
              label: `${element.item}`,
              value: element._id,
            });
          });
        } else {
          console.error("error");
        }
        callback(tempArray);
      });
    }
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      {
        <Grid container spacing={3}>
          <Grid lg={3} md={1} xs={0} sm={0} item></Grid>
          <Grid lg={6} md={10} xs={12} sm={12} item>
            <Paper
              className={classes.paper}
              classes={{
                root: classnames(classes.widgetRoot, {
                  [classes.noWidgetShadow]: null,
                }),
              }}
            >
              <div className={classes.form1}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} xs={12} sm={12}>
                    <div>
                      <TextField
                        id="title"
                        label={t("lot_title")}
                        fullWidth
                        name="title"
                        value={title}
                        onChange={handleTitle}
                        error={!!titleError}
                        helperText={title ? titleError : ""}
                        variant="outlined"
                        margin="dense"
                      />
                    </div>
                    <div>
                      <TextField
                        id="basic_price"
                        label={t("lot_basic_price")}
                        fullWidth
                        name="basicPrice"
                        value={basicPrice}
                        onChange={handleBasicPrice}
                        error={!!basicPriceError}
                        helperText={basicPrice ? basicPriceError : ""}
                        variant="outlined"
                        margin="dense"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        id="location"
                        label={t("lot_location")}
                        fullWidth
                        name="location"
                        value={location}
                        onChange={handleLocation}
                        error={!!locationError}
                        helperText={location ? locationError : ""}
                        variant="outlined"
                        margin="dense"
                      />
                    </div>
                    <div>
                      <TextField
                        id="incrementValue"
                        label={t("lot_increment_amount")}
                        fullWidth
                        name="incrementValue"
                        value={incrementValue}
                        onChange={handleIncrementValue}
                        error={!!incrementValueError}
                        helperText={incrementValue ? incrementValueError : ""}
                        variant="outlined"
                        margin="dense"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        name="date"
                        id="date"
                        label={t("close_date")}
                        type="date"
                        disable
                        InputLabelProps={{ shrink: true }}
                        value={lastDate}
                        onChange={handleLastDate}
                        error={!!dateError}
                        helperText={lastDate ? dateError : ""}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        inputProps={{ min: getCurrentDate() }}
                      />
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={1}
                        // maxRows={2}
                        variant="outlined"
                        id="description"
                        label={t("common_description")}
                        name="description"
                        value={description}
                        onChange={handleDescription}
                        error={!!descriptionError}
                        helperText={description ? descriptionError : ""}
                      />
                    </div>
                    <div style={{ marginTop: "1rem" }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={5}
                        // maxRows={10}
                        variant="outlined"
                        id="detaileDescription"
                        label={t("lot_detailed_description")}
                        name="detaileDescription"
                        value={detaileDescription}
                        onChange={handleDetailedDescription}
                        error={!!detailedDescriptionError}
                        helperText={
                          detaileDescription ? detailedDescriptionError : ""
                        }
                      />
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      <AsyncSelect
                        id="tags"
                        placeholder={t("lot_search_tag")}
                        isMulti
                        noOptionsMessage={() => "No tag found"}
                        cacheOptions
                        defaultOptions
                        name="tags"
                        value={selectedValue}
                        loadOptions={getAllTag}
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
          <Grid lg={3} md={1} xs={0} sm={0} item></Grid>
        </Grid>
      }
    </>
  );
};

export default BasicDetails;

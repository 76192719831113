import React,{useState,useEffect} from "react";
import { 
  Grid,
  Button,
  Card,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress, 
  IconButton} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch,useSelector } from "react-redux";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


//components
import useStyles from "../styles";
import { TealSwitch } from "../styles";
import Table from '../../../components/Table/Table'
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs'
import { Typography } from "../../../components/Wrappers/Wrappers";
import {getBranchData,getContactData,addRemoveExcelusive,deleteDetails,updateContactRole} from '../AdminState'
import NotificationContainer from "../../notifications/NotificationContainer";


const  CustomerBranch = () =>  {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  var classes = useStyles();
  const history = useHistory()
  const dispatch = useDispatch()
  const [branchData,setBranchData] = useState([])
  const [contactData,setContactData] = useState([])
  const [showContact,setShowContact] = useState(false)
  const [showLoader,setShowLoader] = useState(false)
  const [fetchLoader,setFetchLoader] = useState(false)
  const [type,setType] = useState("")
  const [message,setMessage] = useState("")
  const [notify,setNotify] = useState(false)
  const getdata = useSelector((state) => state.userdata.getData )
  const selectedBranch = useSelector((state) => state.selectedBranch)

  const [open, setOpen] = useState(false);
  const [modalTitle,setModalTitle] = useState("")
  const [modalContent,setModalContent] = useState("")
  const [condition,setCondition] = useState("")
  const [selectedItem,setSelectedItem] = useState()
  const [note,setNote] = useState("")
  const [deleteLoader,setDeleteLoader] = useState(false)
  const [branchId,setBranchId] = useState("")
  const [checked,setChecked] = useState(false)


  const handleRoleChange = (e,data) => {
        setChecked(e.target.checked)
        let roleArray = []
        if(e.target.checked){
            roleArray.push("Branch_Admin","Customer_Admin")
        }else{
            roleArray.push("Branch_Admin")
        }
        let bodyData = {
            "updateItem":"contact",
            "updateid":data._id,
            "updateData":{ 
                "roles": roleArray
            }
        }
        updateContactRole(bodyData).then(response => {
            if(response.status === "200"){
                setMessage("Contact Role Updated Successfully!")
                setType("success")
                setNotify(true)
                if(Object.keys(selectedBranch).length > 0){
                  handleView(selectedBranch)
                }
            }else{
                setMessage(response.message)
                setType("error")
                setNotify(true)
            }
        })
  }

  const handleClickOpen = (condition,data) => {
    setSelectedItem(data)
    setCondition(condition)
    if(condition === "customerBranch"){
      setModalTitle("Are you sure to delete the Branch?")
      setModalContent("Upon deleting this, all the contacts and MPRs in 'Open' status will be deleted too.")
    }else if(condition === "contact"){
      setModalContent("Are you sure to delete this Contact?")
      setModalTitle("")
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleView = (item) => {
    dispatch({
      type : 'SET_SELECTED_BRANCH',
      payload : item
    })
    let data = []
    setBranchId(item)
    getContactData(item._id,dispatch).then(response => {
        setShowContact(true)
        if(response.userDetail.length === 1){
          setNote("You are trying to delete the only contact of this branch. This might cause some gaps in MPR communication. Please confirm")
        }else{
          setNote("")
        }
        if(response.status === "200" && response.userDetail.length > 0 ){
          // eslint-disable-next-line
            response.userDetail.map(item => {
                data.push([
                    item.userName,
                    item.email,
                    item.mobile.code + "-"+ item.mobile.number,
                    item.roles.join(",").replaceAll('_',' '),
                    <FormControlLabel
                    control={
                        <TealSwitch
                        checked={item.roles.includes("Customer_Admin")}
                        onChange={(e) => handleRoleChange(e,item)}
                        name="checkedB"
                        color="primary"
                        />
                    }
                    />,
                    <IconButton  size="small"  className={classes.deleteicon} onClick={()=>handleClickOpen("contact",item)}>
                      <DeleteOutlineIcon />
                    </IconButton> 
                ])
            })
            setContactData(data)
        }else{
          setContactData([])
        }
    })
  }

  const handleDelete = () => {
    setDeleteLoader(true)
    let data = {
      condition : condition,
      id : selectedItem._id
    }
    deleteDetails(data).then(response => {
      if(response.status === "200"){
        setType("success")
        setMessage("Deleted Successfully")
        setNotify(true)
        setOpen(false)
        if(condition === "contact"){
          handleView(branchId)
        }
        setTimeout(() => {
          setType("")
          setMessage("")
          setNotify(false)
          setDeleteLoader(false)
        }, 6000);
      }else if(response.status ===  "500"){
        setType("error")
        setMessage(response.message)
        setNotify(true)
        setOpen(false)
        setDeleteLoader(false)
        if(condition === "contact"){
          handleView(branchId)
        }
      }
    })
  }

  const handleShowContactView = () => {

    setShowContact(!showContact)
    dispatch({
      type : 'SET_SELECTED_BRANCH',
      payload : {}
    })
  }
  const handleChange = (e,item) => {
    setShowLoader(true)
    let bodyData = {
      "checkField":e.target.checked,
      "thisCustomerBranch":item._id,
      "thisTenant":(e.target.checked)?getdata.tenant_id:""
    }
    addRemoveExcelusive(bodyData,dispatch).then(response => {
      if(response.status === "200"){
        setType("success")
        setMessage(response.message)
        setNotify(true)
        setShowLoader(false) 
        setOpen(false)
      }else{
        setType("error")
        setMessage(response.message)
        setNotify(true)
        setShowLoader(false)
        setOpen(false)
      }
    })
  }

  const handleAddContact = () => {
    history.push("/app/addContact")
  }

  useEffect(() => {
    if (Object.keys(selectedBranch).length > 0) {
      handleView(selectedBranch);
    }
  
    let data = [];
  
    const fetchData = async () => {
      try {
        setFetchLoader(true)
        const response = await getBranchData(dispatch);
        if (response.status === "200" && response.BranchDetail.length > 0) {
          response.BranchDetail.forEach(item => {
            let address = `${item.address.address1}${item.address.address2 ? ` and ${item.address.address2}` : ''}`;
            data.push([
              address,
              item.district +"-"+item.pincode,
              item.state,
              item.country,
              // (getdata?.tenant_id && getdata?.tenant_id !== "")?
              
              // <FormControlLabel
              //         control={<TealSwitch checked={(item.tenant_id === "")?false:true}  disabled={false} onChange={(e)=>handleChange(e,item)} name="exclusive"/>}
              //         label={""}
              //     /> :<></>,
              <div className={classes.buttonsList}>
              <Button variant="outlined" color="success" size="small"  className={classes.buttonOutlined} onClick={()=>handleView(item)}>
                  View Contacts
              </Button> 
                <Button variant="outlined" color="error" size="small" className={classes.buttonOutlinedDanger} onClick={()=>handleClickOpen("customerBranch",item)}>
                  Delete Branch
                </Button> 
            </div>
          ])
          });
          setBranchData(data);
          setFetchLoader(false)
        } else if (response.status === "401" || response.status === "500") {
          setType("error");
          setMessage(response.message);
          setNotify(true);
          setTimeout(() => {
            dispatch({ type: "LOG_OUT" });
            localStorage.clear();
          }, 2000);
          setFetchLoader(false)
        }
      } catch (error) {
        setType("error");
        setMessage("An Error Occured");
        setNotify(true);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
        setFetchLoader(false)
      }
      setFetchLoader(false)
    };
  
    fetchData();
  }, [showLoader,type,message]);
  

    const datatableHeader = [
       t("common_address"),
       t("common_city"),
       t("common_state"),
       t("common_country"),
      //  (getdata?.tenant_id && getdata?.tenant_id !== "") && "Exclusivity",
       t("common_action")
    ]

    const viewContactHeader = [
        t("common_full_name"),
        t("common_email"),
        t("common_mobile_no"),
        t("common_roles"),
        t("customer_admin"),
        t("common_action")
     ]
    
    const breadcrumb = [
      {label: t("common_admin") , link: "/#/app/customeradmin"},
      {label: t("common_branch" ), link: ""},
    ]
     
    return <>
      <BreadCrumbs data={breadcrumb} />
        <div className={classes.content} style={{marginTop:"62px"}}>
        {(notify)?<NotificationContainer message={message} notificationtype={type} />: <></>}
        <div className={classes.pageTitleContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12} >
              <Card>
                <div className={classes.HeaderContainer} >
                  <div className={classes.tenantHeader}>
                    <Typography variant="h3" size="sm">{t("common_branch_span")} </Typography> 
                  </div>
                  <div className={classes.tenantButtons} >
                    {
                        (showContact)?
                        <div >
                        <Button variant="outlined" onClick = {handleAddContact} color="primary" className={classes.buttonOutlined}>
                          {t("common_add_contact")}
                        </Button> &nbsp;
                        <Button variant="outlined" onClick={handleShowContactView}>
                        {t("common_back")}
                        </Button>
                        </div> : <Button variant="outlined" href="/#/app/addCustomerBranch" color="primary" className={classes.buttonOutlined}>
                            {t("common_add")+" "+t("common_branch")}
                         </Button>
                    }  
                  </div>
                </div>
              </Card> 
            </Grid>
          </Grid>
        </div>
      <Grid container spacing={4}>
          <Grid item xs={12}>
              {
                  (showContact)?<Table 
                  data={contactData} 
                  header={viewContactHeader} 
                  name={t("contact_list") }
                  options={{
                    filterType: 'checkbox',
                    print: false,
                    download: false,
                    filter:false,
                    selectableRows : 'none',
                    textLabels: {
                      body: {
                        noMatch: fetchLoader ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              style={{ color: "#0D6937", marginRight: "5px" }}
                            />{" "}
                            Loading...
                          </div>
                        ) : (
                          "No Contacts Saved"
                        ),
                      },
                    },
                   
                  }} /> : <Table 
                  data={branchData} 
                  header={datatableHeader} 
                  name={t("branch_list") }
                  options={{
                    filterType: 'checkbox',
                    print: false,
                    download: false,
                    filter:false,
                    selectableRows : 'none',
                    textLabels: {
                      body: {
                        noMatch: fetchLoader ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              style={{ color: "#0D6937", marginRight: "5px" }}
                            />{" "}
                            Loading...
                          </div>
                        ) : (
                          "No Branch data available"
                        ),
                      },
                    },
                  }} />
              }
            
          </Grid>
          <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {modalTitle}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {modalContent} <br />
       {(note !== "")? <span className={classes.noteBranch}>{note}</span> : ""}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={handleClose}>Cancel</Button>
      {
        deleteLoader ? <CircularProgress  color="secondary"/> :  <Button variant="outlined" className={classes.buttonOutlinedDanger2} onClick={handleDelete} autoFocus>
        Yes,Delete
      </Button>
      }
     
    </DialogActions>
  </Dialog>
      </Grid>
    </div>
  </>;
}

export default CustomerBranch;
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  typo: {
    color: theme.palette.text.hint,
  },
  content: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(8),
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(0), 
      paddingTop: theme.spacing(8),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0), 
      paddingTop: theme.spacing(8),
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonDiv: {
    float: "right",
    marginTop: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    marginTop: "2rem",
    marginLeft: "10rem",
    marginRight: "10rem",
    marginBottom: "1rem",
  },
  imageForm:{
    marginTop: "2rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    marginBottom: "1rem",
  },
  title: {
    font: "400 2.125rem / 1.17647059 Roboto,RobotoDraft,sans-serif",
    fontWeight: "300",
    margin: "16px 0 32px 0",
    textAlign: "left",
  },
  label: {
    textAlign: "left",
    marginTop: "2rem",
    marginBottom: "1rem",
  },
  stepper: {
    marginTop: theme.spacing(6),
  },
  paperButton: {
    marginTop: "2rem",
    textAlign: "right",
    padding: "1rem",
  },
  finish: {
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  HeaderContainer:{
    padding:theme.spacing(1.5),
    margin:theme.spacing(1.5),
    justifyContent: "space-between",
    display:'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  HeaderContainerEdit:{
    paddingLeft:theme.spacing(1),
    paddingRight:theme.spacing(1),
    margin:theme.spacing(1),
    justifyContent: "space-between",
    display:'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    display:'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  tenantHeader:{
    display:'flex',
    alignItems:'center'
  },
  tenantSubHeader:{
    paddingTop: "5.5px",
    marginLeft:"6px",
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap', 
      marginBottom:theme.spacing(2)
    },
  },
  buttonOutlined: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  tagButton: {
    // margin: "auto",
    // width:"85%",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  deleteButton: {
    color: "red",
    borderColor: "red",
    "&:hover": {
      backgroundColor: "red",
      borderColor: "red",
      color: "white",
    },
  },
  
  themeIcon: {
    color: theme.palette.primary.main,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  listItem: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  form: {
    marginTop: "2rem",
    marginLeft: "5rem",
    marginRight: "5rem",
    marginBottom: "1rem",
  },
  fileTypeInput: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    padding: "7px",
    borderRadius: "4px",
  },
  reviewCell: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
  },
  reviewCell2: {
    border: "1px solid",
    fontWeight: "bolder",
    borderColor: theme.palette.primary.main,
  },
  blueBg: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spanAlert: {
    color: "#ff0040",
  },
  finalizepaper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px",
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "50%",
    height: "80%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCell: {
    [theme.breakpoints.down('md')]: {
      display: "block",
    },
  },
  tableCell: {
    [theme.breakpoints.down('md')]: {
      display: "block",
    },
  },
}));

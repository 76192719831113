import React from "react";
import { Grid,Paper,IconButton,Tooltip,Link } from '@mui/material'
import {
    ExpandLess as DropUpIcon,
    AttachFile as AttachFileIcon,
}  from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


//components
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs'
import useStyles from "../../components/styles";

import Table from '../../../../components/Table/Table'
import Users from "../../components/User";

const ViewQuotationDetails = () => {
    var classes = useStyles();
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
   
    const breadcrumb_viewTenant = [
        {label: t("common_material_pickup") , link: "/#/app/MPR"},
        {label: "asd", link: ""},
    ]

    const userData = [
        ["Alex","alex@binbag.com","Active","10-05-2021 "],
        ["Ameer", "ameer@binbag.com","Active","10-05-2021 "],
        ["Jack","jack@binbag.com","InActive" , "14-05-2021"]
    ]
    const userHeader = [t("recycler"),t("recycler_mail_id"),t("common_status"),t("common_sent_on"),
    {
        name: "Attachments",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <IconButton size="small" variant="outlined" color="primary" href="http://www.africau.edu/images/default/sample.pdf" className={classes.collapseIcon} target="_blank">
                <AttachFileIcon />
              </IconButton>
            );
          }
        }
      }
    ]
    return <>
    <BreadCrumbs data={breadcrumb_viewTenant}/>
        <div className={classes.content}>
            <Grid container spacing={3}>
                    <Grid item lg={3} md={12} xs={12} sm={12} style={{paddingTop:'0px'}}>
                        <Users />
                    </Grid>
                    <Grid item lg={9} md={12} xs={12} sm={12} >
                        <Paper className={classes.Datapaper}>
                            <Grid container spacing={3} className={classes.grid}>
                            <Grid item xs={10}>
                            <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("common_quotations")}</span>
                            </Grid>
                            <Grid item xs={1} className={classes.backIconGrid}>
                            <Tooltip title="Back" aria-label="Back">
                            <Link
                                className={classes.backIcon}
                                href="/#/app/viewMpr"
                                color="primary"
                                underline="hover">{t("common_back")}</Link>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={1} className={classes.iconGrid}>
                                    <IconButton className={classes.collapseIcon} href="/#/app/viewMpr" size="large">
                                    <DropUpIcon /> 
                                    </IconButton>
                                </Grid>
                               
                                
                                
                                    <Grid item xs={12} className={classes.grid}>
                                    <Table 
                                        data={userData} 
                                        header={userHeader} 
                                        name="" 
                                        options={{
                                            filterType: 'checkbox',
                                            print: false,
                                            download: false,
                                            filter:false
                                        }} />
                                    </Grid>
                               
                            </Grid>
                        </Paper>
                        
                        
                    </Grid>
                </Grid>    
                
        </div>
    </>;
};

export default ViewQuotationDetails;
import React, { useState,useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import FileBase64 from 'react-file-base64';

import {
    FormControl,
    Input,
    InputLabel,
    Button,
    CircularProgress,
    Select,
    Grid,
    MenuItem,
    TextField
} from "@mui/material"
import { useDispatch,useSelector } from "react-redux";
import { NumericFormat } from 'react-number-format';

import useStyles from "../styles";

import NotificationContainer from '../../../../notifications/NotificationContainer'
import { fileupload } from '../../../../material_pickup_request/MprState';
import { assignLead } from '../../../LeadState'


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix = "₹"
      />
    );
  }

const QuotationForm = (props) => {
    // eslint-disable-next-line
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch()
    const [quotRefName,setQuotRefName] = useState("")
    const [quotdesc,setQuotDesc] = useState("") 
    const [quotevalue,setQuoteValue]  = useState("")
    const [fileData,setFileData] = useState("")
    const [region,setRegion] = useState("INR")
    const [message,setMessage] = useState("")
    const [notification,setNotification] = useState(false)
    const [type,setType] = useState("")
    const [showloader, setShowLoader] = useState(false);
    const [res,setRes] = useState("")
    const [submit,setSubmit] = useState(false)
    // eslint-disable-next-line
    const [draft,setDraft] = useState(false)
    const [genratedDate, setGeneratedDate] = useState(new Date());
    var loggedInDatas = useSelector(state => state.userdata)


    const handleupdateImage= (e) => {
        setFileData(e)
    }

    
    const  handleChange = () => { 
        if(fileData.type !== "application/pdf"){
            setType("error")
            setMessage("Please upload PDF File")
            setNotification(true)
        }else{
            setShowLoader(true)
            //var filename = fileData.name
            var filetype = fileData.type.split('/')
            var baseData = fileData.base64.split(',')
            let getTokenData = {
                "abbrevation" : loggedInDatas.getData.abbrevation,
                "fileType" : "quotation",
                "folderName": props.list.external_id,
                "expireLimt" :1800,
                "extension" : (filetype.length >0) ? filetype[1] : ""
            }
            fileupload((baseData.length >0)?baseData[1]:"",getTokenData,fileData.type ).then((response) => {
                if(response.status === "200"){
                    setRes(response)
                    setType("success")
                    setMessage(response.message)
                    setNotification(true)
                    setShowLoader(false)
                }
              })
        }
    }
    const handleClose = () => {
        props.close()
    }
    const handleSubmit = () => {
        setSubmit(true)
       let Data = {
           external_id : props.list.external_id,
           leadData:{
               quotation:{
                   folderName : "quotation/"+props.list.external_id,
                   fileName : res.fileName || res.filename,
                   quoteStatus : "sent",
                   totalAmount : {
                        region : region,
                        amount : quotevalue
                   },
                   description : quotdesc,
                   referenceName : quotRefName,
                   customerComments : "",
                   genratedDate:genratedDate
               },
               status: "Quotation sent"
           },
           mpr_id:props.list.Mpr_id
       }
       assignLead(Data,dispatch).then(response => {
            if(response.status === "200"){
                setType("success")
                setMessage("Quotation Sent Successfully")
                setNotification(true)
                setTimeout(()=>  props.close(),2000)
                setSubmit(false)
            }else{
                setType("error")
                setMessage("Something went wrong")
                setNotification(true)
                setSubmit(false)
            }
        })
    }

    // const handleDraft = () => {
    //     setDraft(true)
    //    let Data = {
    //        external_id : props.list.external_id,
    //        leadData:{
    //            quotation:{
    //                folder : "quote/"+props.list.external_id,
    //                filename : res.filename,
    //                quoteStatus : "draft",
    //                totalAmount : {
    //                     region : region,
    //                     amount : quotevalue
    //                },
    //                description : quotdesc,
    //                referenceName : quotRefName,
    //                customerComments : ""
    //            }
    //        }
    //    }
    //    quotationDraft(Data,dispatch).then(response => {
    //     if(response.status == "200"){
    //         setType("success")
    //         setMessage("Quotation Saved Successfully")
    //         setNotification(true)
    //         setTimeout(()=>  props.close(),2000)
    //         setDraft(false)
    //     }else{
    //         setType("error")
    //         setMessage("Something went wrong")
    //         setNotification(true)
    //         setDraft(false)
    //     }
    // })

    // } 
      useEffect(() => {
          // eslint-disable-next-line
      }, [fileData,notification])
    return <>
        {
            (notification)?<NotificationContainer message={message} notificationtype={type}/>:<></>
        }
    <div className={classes.formField}>
        <form  autoComplete="off" >
            <h3 style={{marginLeft:"4px"}}>{t("quotation_add_new")} </h3>
        <div>
            <FormControl variant="standard" fullWidth className={classes.margin} style={{margin:"4px"}}>
                <InputLabel htmlFor="address_line_1">{t("quotation")+" "+t("common_reference")+" "+t("common_name")}</InputLabel>
                <Input id="full_name" name="address_line_1" value={quotRefName} onChange={(e)=> setQuotRefName(e.target.value)}/>
            </FormControl>
        </div>
        <div>
            <FormControl variant="standard" fullWidth className={classes.margin} style={{margin:"4px"}}>
                <InputLabel htmlFor="address_line_2">{t("common_description")}</InputLabel>
                <Input id="full_name" name="address_line_2" value={quotdesc} onChange={(e)=> setQuotDesc(e.target.value)}/>
            </FormControl>
        </div>
        <div>
            <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                    <FormControl variant="standard" fullWidth className={classes.formControl}  style={{margin:"4px"}}>
                        <InputLabel id="state_drop">{t("common_reference")}</InputLabel>
                        <Select
                            variant="standard"
                            labelId="region"
                            id="region"
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}>
                            <MenuItem value={"INR"} >INR</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                <TextField
                    style={{margin:"4px",width:"100%"}}

                    variant="standard"
                    label={t("quotation_value")}
                    value={quotevalue}
                    onChange={(e) => setQuoteValue(e.target.value)}
                    name="numberformat"
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }} />
                </Grid>
            </Grid>
        </div>
        <div className={classes.fileTypeInput}>
            <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    
                        <FormControl variant="standard" className={classes.margin}>
                        <FileBase64
                            className={classes.input}
                            multiple={false}
                            onDone={(e)=>handleupdateImage(e)}
                        />
                        </FormControl>
                    
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign:'right'}}>
                   {(showloader)?<CircularProgress /> : <Button size="small" variant="outlined" color="primary" onClick={handleChange} className={classes.button}>{t("common_upload")}</Button>} 
                </Grid>
            </Grid>
            
        </div>
        <span style={{margin:"4px"}} >{t("quotation_upload_file")}</span>
        
            <div style={{paddingTop:'1rem',float:'right'}}>
            {/* <Button variant="outlined"  onClick={handleClose} className={classes.button}>{t("common_back")}</Button> */}
            {
                (res !== "")?<>
                {/*
                    (draft)?<CircularProgress />:<Button variant="outlined" color="primary" className={classes.buttonOutlined} onClick={handleDraft}>
                    {t("common_save_draft")}</Button>
                */}
                {
                    (submit)?<CircularProgress /> :<><Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                    {t("common_publish")}</Button></>
                }
                </>:<></>
            }
            </div>
        </form>
    </div>
    </>;
}

export default QuotationForm
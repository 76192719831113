import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";

const DescriptionComponent = ({ description }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const timeoutRef = useRef(null);

  const handleButtonClick = () => {
    clearTimeout(timeoutRef.current);
    setIsTooltipOpen(!isTooltipOpen);
    if (!isTooltipOpen) {
      timeoutRef.current = setTimeout(() => {
        setIsTooltipOpen(false);
      }, 80000);
    }
  };

  const handleButtonTouch = () => {
    clearTimeout(timeoutRef.current);
    setIsTooltipOpen(!isTooltipOpen);
    if (!isTooltipOpen) {
      timeoutRef.current = setTimeout(() => {
        setIsTooltipOpen(false);
      }, 80000);
    }
  };

  const handleTooltipClose = () => {
    clearTimeout(timeoutRef.current);
    setIsTooltipOpen(false);
  };

  return (
    <Tooltip
      open={isTooltipOpen}
      title={
        <h1 style={{ fontSize: "16px", lineHeight: "24px" }}>{description}</h1>
      }
      arrow
      onClick={handleButtonClick}
      onTouchStart={handleButtonTouch}
      onClose={handleTooltipClose}
      leaveTouchDelay={6000}
      enterTouchDelay={50}
      PopperProps={{
        sx: {
          width: "auto",
          maxWidth: "none",
          margin: "0",
        },
      }}
    >
      <Button
        sx={{
          marginLeft: {
            xs: "-21px",
            md: "6px",
          },
        }}
      >
        <DescriptionIcon color="success" />
      </Button>
    </Tooltip>
  );
};

export default DescriptionComponent;

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

const BankDetailsDialog = ({ open, onClose, onSave, existingBankDetails }) => {
  const [selectedBank, setSelectedBank] = useState(
    existingBankDetails[0] || {}
  );
  const [isNewBank, setIsNewBank] = useState(false);
  const handleSave = () => {
    onSave(selectedBank);
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h6" fontWeight={"bold"}>
          Select Bank Details
        </Typography>
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          aria-label="existingBankDetails"
          name="existingBankDetails"
          value={isNewBank ? "newBank" : selectedBank.bankName}
          onChange={(e) => {
            if (e.target.value === "newBank") {
              setIsNewBank(true);
            } else {
              setIsNewBank(false);
              const selected = existingBankDetails.find(
                (bank) => bank.bankName === e.target.value
              );
              setSelectedBank(selected || {});
            }
          }}
          sx={{marginBottom:"16px"}}
        >
          {existingBankDetails.map((existingBank, index) => (
            <FormControlLabel
              key={index}
              value={existingBank.bankName}
              control={<Radio />}
              label={existingBank.bankName}
            />
          ))}
        </RadioGroup>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Bank Name"
                sx={{ width: "100%" }}
                value={selectedBank.bankName || ""}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Account Number"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                sx={{ width: "100%" }}
                value={selectedBank.accountNumber || ""}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="IFSC Code"
                sx={{ width: "100%" }}
                value={selectedBank.ifscCode || ""}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                label="Address"
                sx={{ width: "100%" }}
                value={selectedBank.branchName || ""}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BankDetailsDialog;

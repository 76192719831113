import React,{useState} from "react";
import { 
    Grid,
    Paper,
    Collapse, 
    IconButton,
   
} from '@mui/material'
import {
    ExpandMore as DropDownIcon,
    ExpandLess as DropUpIcon
}  from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AttachmentIcon from '@mui/icons-material/Attachment';

import Table from '../../../../components/Table/Table'
import useStyles from "./styles";

import { getFile} from '../../../leads/LeadState'



const MaterialDetails = ({materialList,goodsList}) => {
    const { t } = useTranslation();
    var classes = useStyles();
    const[collapse,setCollapse] = useState(true)
    let material = []
    let goods = []

    const getUrl = (file) => {
        let bodyData = {
            fileName: file.fileName || file.filename,
            folderName: file.folderName || file.folder || file.FolderName,
            expireLimt : 1000
        }
        getFile(bodyData).then(response => {
            window.open(response.reference,"_blank");
        })
    }
// eslint-disable-next-line
    materialList.map(item => {
        material.push([
            item.fileName || item.filename,
            <IconButton onClick={()=> getUrl(item)} className={classes.collapseIcon} size="large">
                <AttachmentIcon />
            </IconButton>
        ])
    })
    // eslint-disable-next-line
    goodsList.map(item => {
        goods.push([
            item.fileName || item.filename,
            <IconButton onClick={()=> getUrl(item)} className={classes.collapseIcon} size="large">
                <AttachmentIcon />
            </IconButton>
        ])
    })

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    const material_header = [
        {
            label: t("common_file_name"),
            options: {
                filter: true,
            }
        },
        {
            label: t("common_reference"),
            options: {
                filter: true,
            }
        },  
    ]

    return (
        <Paper className={classes.Datapaper}>
            <Grid container spacing={3} className={classes.grid}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
                    <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("mpr_material_details")}</span>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} className={classes.iconGrid}>
                    <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
                        {(collapse)?<DropDownIcon /> :<DropUpIcon />}
                    </IconButton> 
                </Grid> 
            </Grid> 
            <Collapse in={collapse}>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid item lg={6} md={6} sm={12} xs={12} >
                        <Table 
                            data={material} 
                            header={material_header} 
                            name={t("common_mpr_list")} 
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                                pagination:false
                            }} />             
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} >
                        <Table 
                            data={goods} 
                            header={material_header} 
                            name={t("common_mpr_goods_list")} 
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                                pagination:false
                            }} />
                    </Grid>  
                </Grid>
            </Collapse>         
        </Paper>
    );
}
export default MaterialDetails
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText";
import { CircularProgress } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import UploadImages from "./UploadImages";
const QuestionsDialog = ({
  dialogOptionsRenderCondition,
  open,
  onClose,
  title,
  dateLabel,
  modeLabel,
  modeOptions,
  onConfirm,
  showLoader,
  mprID
}) => {
  const [selectedModes, setSelectedModes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);

  const handleModeChange = (event) => {
    setSelectedModes(event.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  const handleImagesUploaded = (imagesData) => {
    setUploadedImages(imagesData);
  };

  const handleConfirm = () => {
    onConfirm({ selectedModes, selectedDate, textFieldValue, uploadedImages });
    setSelectedModes([]);
    setSelectedDate();
    setTextFieldValue("");
  };
  // const isConfirmButtonDisabled = !selectedDate;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { width: "100%", maxWidth: "600px" },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* for awaiting response */}
        {dialogOptionsRenderCondition === "awaiting_response" && (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                sx={{ marginTop: "6px ", width: "100%" }}
                ampm={false}
                label={dateLabel}
                value={selectedDate}
                onChange={handleDateChange}
                disableFuture
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginTop: "14px" }}
            >
              <InputLabel>{modeLabel}</InputLabel>
              <Select
                label={modeLabel}
                multiple
                value={selectedModes}
                onChange={handleModeChange}
                renderValue={(selected) => selected.join(", ")}
              >
                {modeOptions.map((option) => (
                  <MenuItem key={option._id} value={option.mode}>
                  <Checkbox
                    checked={selectedModes.indexOf(option.mode) > -1}
                    style={{
                      color: "#377AA0",
                    }}
                  />
                  <ListItemText primary={option.mode} />
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          </>
        )}

        {/* for field visit planned */}
        {dialogOptionsRenderCondition === "field_visit_planned" && (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                sx={{ marginTop: "6px ", width: "100%" }}
                ampm={false}
                label={dateLabel}
                value={selectedDate}
                onChange={handleDateChange}
                disablePast
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </>
        )}

        {dialogOptionsRenderCondition === "followup_requested" && (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                sx={{ marginTop: "6px ", width: "100%" }}
                ampm={false}
                label={dateLabel}
                value={selectedDate}
                onChange={handleDateChange}
                disablePast
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </>
        )}

        {/* for field visit completed */}
        {dialogOptionsRenderCondition === "field_visit_completed" && (
          <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                sx={{ marginTop: "14px ", width: "100%" }}
                ampm={false}
                label={dateLabel}
                value={selectedDate}
                onChange={handleDateChange}
                disablePast
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
            <TextField
              fullWidth
              variant="outlined"
              label="Feedback "
              value={textFieldValue}
              onChange={handleTextFieldChange}
              style={{ margin: "14px 0" }}
              multiline
              rows={6}
            />
            {/* upload images  */}
            <UploadImages onImagesUploaded={handleImagesUploaded} />
          </>
        )}
       
        {(dialogOptionsRenderCondition != "field_visit_completed" && dialogOptionsRenderCondition != "quotation_sent" && dialogOptionsRenderCondition != "accepted")  && 
          <TextField
            fullWidth
            variant="outlined"
            label="Notes"
            value={textFieldValue}
            onChange={handleTextFieldChange}
            style={{ margin: "14px 0" }}
            multiline
            rows={6}
          />
        }
        
        { (dialogOptionsRenderCondition === "quotation_sent")  && 
          <TextField
            fullWidth
            variant="outlined"
            label="MPR ID"
            defaultValue={mprID}
            style={{ margin: "14px 0" }}
            InputProps={{
              readOnly: true,
            }}
          />
        }
        
        
      </DialogContent>
      <DialogActions style={{marginRight:'10px'}}>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        {showLoader? <CircularProgress style={{marginRight:"5px"}} size={30}/> : 
        <Button
        onClick={handleConfirm}
        color="primary"
        disabled={
          (dialogOptionsRenderCondition === "awaiting_response" && (selectedDate === null || selectedModes.length === 0)) ||
          (dialogOptionsRenderCondition === "field_visit_planned" && selectedDate === null) ||
          (dialogOptionsRenderCondition === "followup_requested" && selectedDate === null) ||
          (dialogOptionsRenderCondition === "field_visit_completed" && (selectedDate === null || textFieldValue.trim() === "")) ||
          (dialogOptionsRenderCondition === "quotation_sent" && (mprID === null || mprID === "")) ||
          (dialogOptionsRenderCondition === "rejected" && textFieldValue.trim() === "")
        }
      >
        Confirm
        </Button>}
      </DialogActions>
    </Dialog>
  );
};

export default QuestionsDialog;

import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    IconButton,
    Button} from '@mui/material'
import Table from '../../../../../components/Table/Table'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import {useDispatch,useSelector} from 'react-redux'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import useStyles from "../styles";
import AddInvoice from "./AddInvoice";
import {getFile,getLead} from '../../../LeadState'
import InvoicePDF from "./InvoicePDF";
import CreateInvoice from "./CreateInvoice";

function currencyFormat(num) {
    return parseInt(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const Invoice  = ({list,invoice,close}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const dispatch = useDispatch()
    const state = useSelector(state => state.selectedLead)
    let external_id = (state.length > 0)? state[0].external_id : "";

    const [invoiceData,setInvoiceData] = useState(invoice)
    const [showForm,setShowForm] = useState(false)
    const [showCreateForm,setShowCreateForm]= useState(false)
    const [showPDF,setShowPDF] = useState(false)
    const [invoiceUrl,setInvoiceUrl] = useState("")
    const [tableData,setTableData] = useState([])

    const header = [t("mpr_invoice")+" "+t("common_number"),t("mpr_invoice")+" "+t("mpr_invoice_amount"),t("lead_generated_on"),t("mpr_invoice")]
    //var loggedInDatas = useSelector(state => state.userdata)
    
    
    const handleShowForm = () => {
        setShowForm(!showForm)
        getLead(external_id,dispatch).then(response => {
            if(response && response.invoice) setInvoiceData(response.invoice)
        })
    }

    const handleShowCreateInvoiceForm=()=>{
        setShowCreateForm(!showCreateForm)
        getLead(external_id,dispatch).then(response => {
            if(response && response.invoice) setInvoiceData(response.invoice)
        })
    }

    const handleshowhidepdf = () => {
        setShowPDF(!showPDF)
    }

    const handlepdf = () => {
        let body  = {
            "fileName" : invoice.fileName || invoice.filename,
            "folderName" : invoice.folderName || invoice.folder || invoice.FolderName,
            "expireLimt" : 1800
        }
        getFile(body).then(response => {
            setInvoiceUrl(response.reference)
            setShowPDF(true)
        })
    }
    
    useEffect(() => {
        let data = []
        if(Object.keys(invoiceData).length !== 0){ 
            data.push([
                invoiceData.invoiceNumber,
                "INR " +  currencyFormat(invoiceData.amount),
                moment(invoiceData.generatedOn).format('DD-MM-YYYY'),
                <IconButton className={classes.collapseIcon} onClick={handlepdf} size="large">
                    <PictureAsPdfIcon />
                </IconButton>
            ]) 
        }
        setTableData(data)
        // eslint-disable-next-line
    },[showForm,showPDF,invoiceData])

    return(
        
        <Paper className={classes.Datapaper}>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={7}>
                        <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("mpr_invoice")}</span>
                </Grid>
                <Grid item xs={12} lg={5} className={classes.iconGridWithButton} style={{display:'flex',justifyContent:'end'}}>
                    {
                        (showPDF)?<Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} onClick={handleshowhidepdf}>{t("common_close")}</Button>:
                        (Object.keys(invoiceData).length === 0 )? 
                    
                        (! showCreateForm)?
                        <>
                        <Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} onClick={handleShowForm}>{t("common_upload")}</Button>
                        <Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} onClick={handleShowCreateInvoiceForm}>{t("common_create")}</Button>
                        </>
                        
                        :<>
                        <Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} onClick={handleShowForm}>{t("common_upload")}</Button>
                        <Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} onClick={handleShowCreateInvoiceForm}>{t("common_create")}</Button>
                        </>:
                     
                        <Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined}  onClick={()=>close()}>{t("common_close")}</Button>
                    }
                </Grid>         
            </Grid>               
            <Grid container spacing={3} className={classes.grid}>

                
            {(showForm)? 
                        <>
                            <Grid item lg={2} xs={0}>
                                
                            </Grid>
                            <Grid item lg={8} xs={12}> 
                                <AddInvoice  close={handleShowForm} list={list} />
                            </Grid> 
                            <Grid item lg={2} xs={0}></Grid>
                        </>: 
                            (showCreateForm)? 
                                            <>
                                                <Grid item lg={12} xs={12}> 
                                                    <CreateInvoice  close={handleShowCreateInvoiceForm} list={list} />
                                                </Grid>
                                            </>:
                            <Grid item lg={12} md={12} xs={12} sm={12}>
                                {
                                    (showPDF)? <InvoicePDF url={invoiceUrl} close={handleshowhidepdf} />:<Table 
                                    data={tableData} 
                                    header={header} 
                                    name="" 
                                    options={{
                                        filterType: 'checkbox',
                                        print: false,
                                        download: false,
                                        filter:false,
                                        selectableRows:'none',
                                    }} />
                                }
                                
                            </Grid>
                }
            </Grid>
        </Paper>
    )
}
export default Invoice
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Button,
  Box,
  Grid,
  CircularProgress,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import useStyles from "../styles";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCutomerList,
  sendCredential,
  UpdateCustomerStatus,
} from "../CustomerState";
import moment from "moment";
import NotificationContainer from "../../notifications/NotificationContainer";
import Switch from "@mui/material/Switch";
//component
import Table from "../../../components/Table/Table";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabPannel() {
  const classes = useStyles();
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  var roleDefine = useSelector((state) => state.userdata);
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [customerData, setCustomerData] = useState([]);
  const [fetchLoader,setFetchLoader] = useState(true)
  // eslint-disable-next-line
  const [selectedItem, setSelectedItem] = useState({});
  const [branchData, setBranchData] = useState([]);
  const [showBranch, setShowBranch] = useState(false);
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [notify, setNotification] = useState(false);
  const [sendButton, setSendButton] = useState(false);
  const [notificationChecked, setNotificationChecked] = useState();
  const [selectedCustomerId, setSelectedCustomerId] = useState();
  const [openSend, setOpenSend] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  //  const state = useSelector(state => state.customerList)

  const datatableHeader = [
    t("common_customer_name"),
    t("gst"),
    t("pan"),
    t("common_status"),
    t("common_createdAt"),
    t("common_action"),
  ];

  if (sendButton) {
    datatableHeader.push(t("common_notifications"), t("common_sendCredentials"));
  }

  const branchHeader = [
    t("common_address"),
    t("common_city"),
    t("common_state"),
    t("common_createdAt"),
    t("common_action"),
  ];

  const handleChangeNotification = (event, item) => {
    let data = {
      thisCustomer: item.Customer._id,
      updateValue: {
        notificationAllowed: event.target.checked,
      },
    };
    UpdateCustomerStatus(data).then((response) => {
      if (response.status === "200") {
        setType("success");
        setMessage(response.message);
        setNotification(true);
      }else if(response.status === "401"){
        setMessage(response.message)
        setType("error")
        setNotification(true)
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      } else {
        setType("error");
        setMessage(response.message);
        setNotification(true);
      }
    });
    setNotificationChecked((prev) => !prev);
  };

  const handleClose = () => {
    setOpenSend(false);
  };

  const handleSendCredentials = () => {
    setShowLoader(true);
    let data = {
      _id: selectedCustomerId,
    };
    sendCredential(data).then((response) => {
      if (response.status === "200") {
        setType("success");
        setMessage(response.message);
        setNotification(true);
        setShowLoader(false);
        setOpenSend(false);
      }else if(response.status === "401"){
        setMessage(response.message)
        setType("error")
        setNotification(true)
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      } else {
        setType("error");
        setMessage(response.message);
        setNotification(true);
        setShowLoader(false);
        setOpenSend(false);
      }
    });
  };

  const handleShowBranch = (item) => {
    let data = [];
    // eslint-disable-next-line
    item.Branch.map((element) => {
      let address = element.address.address1 + "," + element.address.address2;
      data.push([
        address,
        element.district,
        element.state + " - " + element.pincode,
        moment(element.createdAt).format("DD-MM-YYYY"),
        <Button
          variant="outlined"
          color="success"
          size="small"
          onClick={() => handleRowClick(element)}
          className={classes.buttonOutlined}
        >
          Add MPR
        </Button>,
      ]);
    });
    setBranchData(data);
    setShowBranch(!showBranch);
    setSelectedItem(item);
    dispatch({
      type: "SET_SELECTED_CUSTOMER_DATA",
      payload: item.Customer,
    });
  };

  const handleClickOpenSend = (_id) => {
    setSelectedCustomerId(_id);
    setOpenSend(true);
  };

  useEffect(() => {
    if (roleDefine.getData &&
      roleDefine.getData.type === "tenant" &&
      roleDefine.getData.roles &&
      Array.isArray(roleDefine.getData.roles) && 
      !roleDefine.getData.roles.includes("user")
    ) {
      setSendButton(true);
    } else {
      setSendButton(false);
    }
    let data = [];
    let bodyData = {
      tenant_id: localStorage.getItem("customerid"),
    };
    getCutomerList(bodyData, dispatch).then((response) => {
      if (response.status === "200") {
        // eslint-disable-next-line
        setFetchLoader(false)
        response.finalCustomer.map((item) => {
          data.push([
            item.Customer.name,
            item.Customer.gst,
            item.Customer.pan,
            item.Customer.isActive ? "Active" : "InActive",
            moment(item.Customer.createdAt).format("DD-MM-YYYY"),
            <Button
              variant="outlined"
              color="success"
              size="small"
              onClick={() => handleShowBranch(item)}
              className={classes.buttonOutlined}
            >
              View Branch
            </Button>,
            roleDefine.getData &&
            roleDefine.getData.type === "tenant" &&
            roleDefine.getData.roles &&
            Array.isArray(roleDefine.getData.roles) && 
            !roleDefine.getData.roles.includes("user") ? (
              <Switch
                checked={item.Customer.notificationAllowed}
                onChange={(event) => handleChangeNotification(event, item)}
                color="success"
                sx={{marginTop: {sm: '-6px',xs:'-6px' },marginLeft:{sm:'-10px',xs:'-10px'}}}
              />
          //     <PurpleSwitch
          //     defaultChecked={item.Customer.notificationAllowed}
          //     id={item.Customer._id}
          //     onChange={(event) => handleChangeNotification(event, item)}
          //     classes={{
          //         switchBase: classes.switchBase,
          //         track: classes.track,
          //         checked: classes.checked,
          //     }}
          // />
            ) : (
              ""
            ),
            roleDefine.getData &&
            roleDefine.getData.type === "tenant" &&
            roleDefine.getData.roles &&
            Array.isArray(roleDefine.getData.roles) && 
            !roleDefine.getData.roles.includes("user") ? (
              <Grid>
                <Button
                  variant="outlined"
                  color="success"
                  size="small"
                  onClick={() => handleClickOpenSend(item.Customer._id)}
                  disabled={item.Customer.notificationAllowed === false}
                  className={classes.buttonOutlined}
                >
                  Send
                </Button>
              </Grid>
            ) : (
              ""
            ),
          ]);
        });
      setCustomerData(data);
    }else {
      if (response.status === "401") {
        setType("error");
        setMessage(response.message);
        setNotification(true);
        setFetchLoader(false)
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      } else {
        setType("error");
        setMessage(response.message);
        setNotification(true);
        setFetchLoader(false)
      }
    }
  })
    // eslint-disable-next-line
  }, [notificationChecked, sendButton]);

  const handleRowClick = (item) => {
    dispatch({
      type: "SET_SELECTED_BRANCH",
      payload: item,
    });

    history.push("/app/tenant/addMPR");
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabData = [
    { label: t("customer_my_customer"), index: 0, name: "My Customer List" },
  ];

  return (
    <div className={classes.root}>
      {notify ? <NotificationContainer notificationtype={type} message={message} /> : <></>}
      <Card>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          {tabData.map((item, key) => {
            return (
              <Tab
                label={item.label}
                {...a11yProps(item.index)}
                classes={{ root: classes.tab }}
                key={key}
              />
            );
          })}
        </Tabs>
        {showBranch ? (
          <div style={{ textAlign: "right", marginRight: "2rem" }}>
            <Button
              variant="outlined"
              onClick={() => setShowBranch(false)}
              size="small"
              color="primary"
              className={classes.buttonOutlined}
            >
              {t("common_back")}
            </Button>
          </div>
        ) : (
          <></>
        )}

        <TabPanel value={value} index={0} key={1}>
          {showBranch ? (
            <Table
              data={branchData}
              header={branchHeader}
              name={t("branch_list")}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
                textLabels: {
                  body: {
                    noMatch: fetchLoader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#0D6937", marginRight: "5px" }}
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      "No Branches added"
                    ),
                  },
                },
              }}
            />
          ) : (
            <Table
              data={customerData}
              header={datatableHeader}
              name={t("customer_list")}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
                textLabels: {
                  body: {
                    noMatch: fetchLoader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#0D6937", marginRight: "5px" }}
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      "No Customers "
                    ),
                  },
                },
              }}
            />
          )}
        </TabPanel>
      </Card>
      <Dialog
        open={openSend}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to send login credentials?
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            Login credential will be sent to the customer.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          {showLoader ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleSendCredentials}
              variant="contained"
              className={classes.buttonOutlined}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { makeStyles } from "@mui/styles";
import withStyles from '@mui/styles/withStyles';
import { Badge,Switch} from '@mui/material';

export default makeStyles(theme => ({
    Datapaper:{
        marginBottom:theme.spacing(4),
        [theme.breakpoints.down("lg")]: {
          marginTop:theme.spacing(4)
        },
    },
    grid:{
        padding: theme.spacing(1)
    },
    collapseIcon:{
        cursor:'pointer',
        color: theme.palette.primary.main,
        "&:hover": {
            //color: theme.palette.secondary.main
        }
    },
    detailsGrid:{
      overflow : 'auto'
    },
    iconGridWithButton:{
      // textAlign : 'right',
      display:'flex',
      justifyContent:"end",
      height:theme.spacing(7)
    },
    iconGrid:{
        textAlign : 'right'
    },
    divTop:{
      marginTop:'1rem'
    },
    buttonOutlined:{
        color: theme.palette.primary.main,
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        marginLeft:'1rem',
        marginRight:'1rem',
        "&:hover" : {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.contrastText
        }
    },
    radioContactSelect: {
        border:'1px solid',
        borderColor:theme.palette.primary.main,
        padding:"10px"
    },
    radioContact: {
      padding:"10px"
  },
    cardPaper :{
        height:'5rem'
    },
    detailsDiv:{
        // width:'100%',
        margin:'1rem',
    },
    formControl: {
      margin: theme.spacing(1),
      marginLeft:"0",
      marginRight:"0",
      width: "100%"
    },
    pickupDiv:{
      width:'100%',
      padding:'1rem',
    },
    asignButton:{
        textAlign:'right',
        display:"flex",
        alignItems:'center',
        justifyContent:'flex-end'
    },
    formDiv:{
        margin:'2rem',
        border:'1px dashed',
        borderColor:theme.palette.primary.main,
        padding:'1rem'
    },
    choosedSlotSpan:{
        fontWeight:'bold',
        // marginLeft:'1rem',
        // marginRight:'1rem',
        color:theme.palette.primary.main,
        // padding:'8px',
        // borderRadius:'4px',
        // border: "1px solid",
        // borderColor: theme.palette.primary.main,
        // "&:hover" : {
        //     backgroundColor: theme.palette.primary.main,
        //     color: theme.palette.secondary.contrastText
        //   }
    },
    rejectedSlotSpan:{
        fontWeight:'bold',
        marginLeft:'1rem',
        color:theme.palette.secondary.main,
        padding:'8px',
        borderRadius:'4px',
        border: "1px solid",
        borderColor: theme.palette.secondary.main,
        "&:hover" : {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
          }
    },
    formField: {
        backgroundColor:'#fff',
        padding:'1rem 4rem',
        marginRight:'1rem',
        height:'30rem',
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        [theme.breakpoints.only("sm")]: {
          padding: theme.spacing(2), 
          marginRight:'0',
          height:'33rem',
        },
        [theme.breakpoints.only("xs")]: {
          padding: theme.spacing(2), 
          marginRight:'0',
          height:'36rem',
        },
    },
    invoiceForm:{
      backgroundColor:'#fff',
      padding:'1rem',
      // marginRight:'1rem',
      // height:'30rem',
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      [theme.breakpoints.only("sm")]: {
        padding: theme.spacing(2), 
        marginRight:'0',
        // height:'32rem',
      },
      [theme.breakpoints.only("xs")]: {
        padding: theme.spacing(2), 
        marginRight:'0',
        // height:'30rem',
      },
    },
    quotationForm:{
      backgroundColor:'#fff',
      padding:'1rem',
      // marginRight:'1rem',
      // height:'30rem',
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      [theme.breakpoints.only("sm")]: {
        padding: theme.spacing(2), 
        marginRight:'0',
        // height:'32rem',
      },
      [theme.breakpoints.only("xs")]: {
        padding: theme.spacing(2), 
        marginRight:'0',
        // height:'30rem',
      },
    },
    paymentformField: {
      backgroundColor:'#fff',
      padding:'4rem',
      marginRight:'1rem',
      height:'37rem',
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1), 
        height:'37rem',
        marginRight:'0',
      },
    },
    PickupformField:{
      backgroundColor:'#fff',
      padding:'4rem',
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2), 
        // paddingBottom : theme.spacing(1), 
      },
      marginRight:'1rem',
      height:'44rem',
      boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    alignButtom:{
      display:"flex",
      marginTop:'2rem',
      marginBottom:"2rem"
    },
    fileTypeInput:{
        marginTop:'1rem',
        borderBottom:'1px solid',
        borderColor:"rgba(0, 0, 0, 0.42)",
        padding:'7px',
      },
      fileTypeInputTable:{
        marginTop:'1rem',
        borderBottom:'1px solid',
        borderColor:"rgba(0, 0, 0, 0.42)",
        padding:'7px',
        width: '70%'
      },
      fileTypeInputTable1:{
        padding:'16px 0px',
      },
      pdfpaper:{
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        backgroundColor:'#eee',
        width:'620px',
        alignSelf:'center'
      },
      actiondiv:{
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        margin:'1rem',
        width:'97%',
        backgroundColor:'#eee'
      },
      pagenumberdiv:{
        width:'50px'
      },
      tableHeader:{
        fontWeight:'800'
      },
      closeLeadfield : {
        backgroundColor:'#fff',
        margin:'1rem',
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
      },
      spanForm6:{
        textAlign:'center',
        color: theme.palette.secondary.main
      },
      button:{
        marginRight:'1rem'
      },
      patternInput :{
        textTransform : 'uppercase',
        "&:invalid" : {
          border : '3px solid red'
        }
      }
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -10,
      padding: '0 4px',
    },
}))(Badge);
const TealSwitch = withStyles({
    switchBase: {
      color: '#2E7D32',
      '&$checked': {
        color: '#2E7D32',
      },
      '&$checked + $track': {
        backgroundColor:'#2E7D32',
      },
    },
    checked: {},
    track: {},
  })(Switch);
  
export {StyledBadge,TealSwitch}
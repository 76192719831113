import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  CircularProgress,
  Paper,
  Grid,
  InputAdornment,
  FormControlLabel,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  getMiscellaneousRequestOptions,
  updateMiscellaneousReqOption,
  addMiscellaneousRequestOption,
} from "../InternalFormState";
import NotificationContainer from "../../notifications/NotificationContainer";
import useStyles from "../styles";

const OptionsDialog = ({ onOptionsChanged }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [newOption, setNewOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });

  const fetchOptions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getMiscellaneousRequestOptions();
      setOptions(
        data.map(({ _id, option, isActive, isAdvance }) => ({
          _id,
          option,
          isActive,
          isAdvance,
        }))
      );
    } catch (error) {
      showNotification("Error fetching options. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (open) fetchOptions();
  }, [open, fetchOptions]);

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification({ message: "", type: "" }), 3000);
  };

  const handleOptionChange = (id, field, value) => {
    setOptions(
      options.map((option) =>
        option._id === id ? { ...option, [field]: value } : option
      )
    );
  };

  const handleSaveOption = async (id) => {
    try {
      setLoading(true);
      const option = options.find((opt) => opt._id === id);
      const { status } = await updateMiscellaneousReqOption({
        optionId: id,
        updateData: {
          option: option.option,
          isActive: option.isActive,
          isAdvance: option.isAdvance,
        },
      });
      if (status === "200") {
        showNotification("Option updated successfully.", "success");
        onOptionsChanged();
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      showNotification("Error saving option. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddOption = async () => {
    const trimmedOption = newOption.trim();
    if (!trimmedOption) {
      showNotification("Option cannot be empty.", "error");
      return;
    }
    if (
      options.some(
        (option) => option.option.toLowerCase() === trimmedOption.toLowerCase()
      )
    ) {
      showNotification("Option already exists.", "error");
      return;
    }
    try {
      setLoading(true);
      const { status } = await addMiscellaneousRequestOption({
        option: trimmedOption,
        isActive: true,
      });
      if (status === "200") {
        showNotification("New option added successfully.", "success");
        setNewOption("");
        fetchOptions();
        onOptionsChanged();
      } else {
        throw new Error("Add failed");
      }
    } catch (error) {
      showNotification("Error adding new option. Please try again.", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        sx={{ padding: "14px" }}
      >
        New
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Add new option or Edit existing ones</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" mb={3}>
            <TextField
              value={newOption}
              onChange={(e) => setNewOption(e.target.value)}
              label="Add new option"
              variant="outlined"
              fullWidth
              margin="dense"
            />
            <Button
              onClick={handleAddOption}
              disabled={loading}
              sx={{ ml: 1, minWidth: 100 }}
            >
              {loading ? <CircularProgress size={24} /> : "Add"}
            </Button>
          </Box>
          {options.map((item) => (
            <Paper
              key={item._id}
              elevation={1}
              sx={{ mb: 2, p: 2, backgroundColor: "#f5f5f5" }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Edit option"
                    value={item.option}
                    onChange={(e) =>
                      handleOptionChange(item._id, "option", e.target.value)
                    }
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={item.isActive}
                        onChange={() =>
                          handleOptionChange(
                            item._id,
                            "isActive",
                            !item.isActive
                          )
                        }
                        color="primary"
                        size="small"
                      />
                    }
                    label="Active"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={6} sm={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={item.isAdvance}
                        onChange={() =>
                          handleOptionChange(
                            item._id,
                            "isAdvance",
                            !item.isAdvance
                          )
                        }
                        color="primary"
                        size="small"
                      />
                    }
                    label="Advance"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveOption(item._id)}
                    disabled={loading}
                    fullWidth
                    startIcon={
                      loading ? (
                        <CircularProgress size={10} />
                      ) : (
                        <SaveIcon fontSize="10px" />
                      )
                    }
                    size="small"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      {notification.message && (
        <NotificationContainer
          message={notification.message}
          notificationtype={notification.type}
        />
      )}
    </>
  );
};

export default React.memo(OptionsDialog);

// eslint-disable-next-line
export default [
  {
    'title': 'All Day Event very long title',
    'allDay': true,
    'start': new Date(2021, 5, 5),
    'end': new Date(2021,5, 5)
  },
  {
    'title': 'Meeting with XYZ Company',
    'start': new Date(2021, 4, 6, 12, 30, 0),
    'end': new Date(2021, 4, 6, 16, 30, 0)
  },

  {
    'title': 'DTS STARTS',
    'start': new Date(2021, 4, 13, 0, 0, 0),
    'end': new Date(2021, 4, 20, 0, 0, 0)
  },

  {
    'title': 'DTS ENDS',
    'start': new Date(2021, 10, 6, 0, 0, 0),
    'end': new Date(2021, 10, 13, 0, 0, 0)
  },

  {
    'title': 'Some Event',
    'start': new Date(2021, 4, 6, 9, 30, 0),
    'end': new Date(2021, 4, 6, 10, 30, 0)
  },
  {
    'title': 'Conference',
    'start': new Date(2021, 4, 1),
    'end': new Date(2021, 4, 1),
    desc: 'Big conference for important people'
  },
  {
    'title': 'Meeting',
    'start': new Date(2021, 4, 12, 10, 30, 0, 0),
    'end': new Date(2021,4, 12, 12, 30, 0, 0),
    desc: 'Pre-meeting meeting, to prepare for the meeting'
  },
  {
    'title': 'Conference',
    'start': new Date(2021, 4, 23, 12, 0, 0, 0),
    'end': new Date(2021, 4, 23, 13, 0, 0, 0),
    desc: 'Power lunch'
  },
  {
    'title': 'Meeting',
    'start': new Date(2021, 4, 25, 14, 0, 0, 0),
    'end': new Date(2021, 4, 25, 15, 0, 0, 0)
  },
  {
    'title': 'Happy Hour',
    'start': new Date(2021, 4,26, 17, 0, 0, 0),
    'end': new Date(2021, 4, 26, 17, 30, 0, 0),
    desc: 'Most important meal of the day'
  },
  
]

import React,{useEffect,useState} from "react";
import { Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Link
} from '@mui/material';
import classnames from "classnames";
import moment from 'moment'
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import useStyles from "../styles";


const FormDivider = ({data}) =>{
   
  const classes = useStyles();

  return(
    <div className={classes.formDividerContainer}>
      <div className={classes.formDivider} />
      <Typography className={classes.formDividerWord}>{data}</Typography>
      <div className={classes.formDivider} />
    </div>
  )
}

const ReviewDetails = ({ setForm, formData }) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  var stateMpr = useSelector((state) => state.mprid);
  formData.MPRId = formData.MPRId ? formData.MPRId : stateMpr;
  var address = formData.contact_address
    ? JSON.parse(formData.contact_address)
    : "";
  var person = formData.contact_contact
    ? JSON.parse(formData.contact_contact)
    : "";
  const [finalserviceType, setFinalServiceType] = useState([]);
  const [UlField, setUlField] = useState(false);
  const [otherType, setOtherType] = useState([]);
  useEffect(() => {
    let getFinalServiceType =
      formData.service_type[formData.service_type.length - 1];
    setFinalServiceType(getFinalServiceType);
    setOtherType(formData.other_service_type);
    handleRole();
    // eslint-disable-next-line
  }, []);

  const handleRole = () => {
    if (localStorage.getItem("type") === "tenant") {
      setUlField(true);
    } else {
      setUlField(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={2} md={1} xs={0} sm={0}></Grid>
      <Grid item lg={8} md={10} xs={12} sm={12}>
        <Paper
          className={classes.paper}
          classes={{
            root: classnames(classes.widgetRoot, {
              [classes.noWidgetShadow]: null,
            }),
          }}
        >
          <div className={classes.form1}>
            <Grid container spacing={2}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ width: "width: calc(100% - 2*15px)" }}
              >
                <h3 className={classes.title}>{t("common_review_details")}</h3>
              </Grid>
            </Grid>

            <Grid container spacing={0}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.reviewContainer}
              >
                <div className={classes.lightBg}>
                  <FormDivider
                    data={t("common_basic_details")}
                    className={classes.reviewHeading}
                  />
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {t("mpr_id")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {formData.MPRId}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {t("mpr_name")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {formData.MPRName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {t("common_description")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {formData.description}
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                            <TableCell> {t("mpr_pickup_last_date")}</TableCell>
                            <TableCell>{moment(formData.pickupDate).format("DD-MM-YYYY")}</TableCell>
                        </TableRow> */}
                      <TableRow>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {t("mpr_quotation_last_date")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {moment(formData.quotation_date).format("DD-MM-YYYY")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {t("common_service_category")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {formData.service_category}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {t("common_service_type")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {finalserviceType
                            ? finalserviceType.join(",").replace("Others", "") +
                              otherType
                            : formData.service_type}
                        </TableCell>
                      </TableRow>
                      {UlField && (
                        <TableRow>
                          <TableCell className={classes.reviewCell}>
                            {" "}
                            {t("mpr_inquiry")}
                          </TableCell>
                          <TableCell className={classes.reviewCell}>
                            {formData.inquirylead_id}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <div className={classes.lightBg}>
                  <FormDivider
                    data={t("common_inspection_slots")}
                    className={classes.reviewHeading}
                  />
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.reviewCell}>
                          {t("common_inspection_slots")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {t("common_date")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {t("common_time")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formData.slots.length > 0 ? (
                        formData.slots.map((item, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell className={classes.reviewCell}>
                                {" "}
                                Slot {key + 1}{" "}
                              </TableCell>
                              <TableCell className={classes.reviewCell}>
                                {" "}
                                {moment(item.inspectionDate).format(
                                  "DD-MM-YYYY"
                                )}{" "}
                              </TableCell>
                              <TableCell className={classes.reviewCell}>
                                {" "}
                                {item.time}{" "}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell colSpan={3} className={classes.reviewCell}>
                            {t("mpr_no_slots_found")}{" "}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <div className={classes.lightBg}>
                  <FormDivider
                    data={t("mpr_material_details")}
                    className={classes.reviewMaterialDetails}
                  />
                  <Grid
                    container
                    spacing={3}
                    className={classes.reviewMaterialDetails}
                  >
                    <Grid item lg={6} md={12} xs={12} sm={12}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.reviewCell}>
                              {t("common_file_name")}
                            </TableCell>
                            <TableCell className={classes.reviewCell}>
                              {t("common_download")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {formData.files.length > 0 ? (
                            formData.files.map((item, key) => {
                              return (
                                <TableRow key={key}>
                                  <TableCell className={classes.reviewCell}>
                                    {" "}
                                    {item.fileName}{" "}
                                  </TableCell>
                                  <TableCell className={classes.reviewCell}>
                                    {" "}
                                    <Link href={item.ref} target="_blank">
                                      {t("common_click_here")}
                                    </Link>{" "}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell
                                className={classes.reviewCell}
                                colSpan={2}
                              >
                                {" "}
                                {t("mpr_no_file_found")}{" "}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                    {/* <Grid item lg={2} md={0} xs={0} sm={0}></Grid>  */}
                    <Grid item lg={6} md={12} xs={12} sm={12}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.reviewCell}>
                              {t("common_file_name")}
                            </TableCell>
                            <TableCell className={classes.reviewCell}>
                              {t("common_download")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {formData.goodsPic.length > 0 ? (
                            formData.goodsPic.map((item, key) => {
                              return (
                                <TableRow key={key}>
                                  <TableCell className={classes.reviewCell}>
                                    {" "}
                                    {item.fileName}{" "}
                                  </TableCell>
                                  <TableCell className={classes.reviewCell}>
                                    {" "}
                                    <Link href={item.ref} target="_blank">
                                      {t("common_click_here")}
                                    </Link>{" "}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell
                                className={classes.reviewCell}
                                colSpan={2}
                              >
                                {" "}
                                {t("mpr_no_file_found")}{" "}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.lightBg}>
                  <FormDivider data={t("mpr_contact_details")} />
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {t("mpr_contact_address")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {address !== ""
                            ? address.address.address1 +
                              " , " +
                              address.address.address2 +
                              " , " +
                              address.district +
                              ", " +
                              address.state +
                              ", " +
                              address.pincode
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {t("mpr_contact_person")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {person !== ""
                            ? person.Name +
                              ", " +
                              person.mobile.code +
                              person.mobile.number +
                              ", " +
                              person.email
                            : ""}{" "}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid item lg={2} md={1} xs={0} sm={0}></Grid>
    </Grid>
  );
};

export default ReviewDetails;

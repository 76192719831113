import React from 'react'
import {
  IconButton,
    Tabs,
    Tab,
    Tooltip,
    Box,
    Card
} from '@mui/material'
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import useStyles from '../styles'
import {useDispatch,useSelector} from 'react-redux'
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
//component
import Table from '../../../components/Table/Table'


function TabPanel(props) {

    const { children, value, index, ...other } = props;

    
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box  sx={{
            p: {
              xs:1, 
              sm:1,
              lg:3,
              md:3
            },
          }}>
            {children}
          </Box>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabPannel() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
    const classes = useStyles();
    const dispatch  = useDispatch()
     // global
  
    const history = useHistory();
    const [value, setValue] = React.useState(0);
    const state = useSelector(state => state.mprList)

    const tabData = [
        {label: t("common_open") ,  index:0 , name: "Open Material Pickup List"},
        {label: t("common_draft") , index:1 , name: "Draft Material Pickup List"},
        {label: t("common_closed") , index:2 , name: "Closed Material Pickup List"},
        {label: t("common_all") , index:3 , name:"All Material Pickup List"}
    ]
    const datatableHeader = [ t("mpr_id"),t("mpr_request_name"),t('common_createdAt'),t("common_service_category"),t("common_service_type"),t("common_status"),t("common_action")]
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const onRowClick = (rowData) => {                     
        dispatch({
          type: "SET_SELECTED_MPR",
          payload : rowData
        })
        history.push('/app/ViewMpr')
    }
    let closed = []
    let draft = []
    let open = []
    let all = []
    
    return (
       <div className={classes.root}>
          <Card>
            <Tabs value={value} onChange={handleChange}  indicatorColor="primary">
                {
                    tabData.map((item,key) => {
                        return(
                            <Tab label={item.label} {...a11yProps(item.index)} classes={{ root: classes.tab }} key={key} />
                        )
                    })
                }
            </Tabs>
            
                {
                  // eslint-disable-next-line
                  (state&& state.Data)? state.Data.map((item,key) => {
                    if((item.MPR.status).toLowerCase() === ("Open").toLowerCase() || (item.MPR.status).toLowerCase() === ("Accepted").toLowerCase()
                     || (item.MPR.status).toLowerCase() === ("Pickup Completed").toLowerCase() || (item.MPR.status).toLowerCase() === ("Pickup Initiated").toLowerCase()
                     || (item.MPR.status).toLowerCase() === ("Payment completed").toLowerCase() ||(item.MPR.status).toLowerCase() === ("invoice available").toLowerCase() ||(item.MPR.status).toLowerCase() === ("Form 6 Available").toLowerCase()){
                      open.push([
                        item.MPR.external_id,
                        item.MPR.name,
                        moment(item.MPR.createdAt).format('DD-MM-YYYY'),
                        // item.MPR.description,
                        item.MPR.serviceCategory,
                        // (item.MPR.serviceType.length >1 && item.MPR.serviceType[0].includes('Others'))?item.MPR.serviceType.join(', ').replace('Others,'," "):item.MPR.serviceType[0].join(', '),
                        item.MPR.serviceType?.[0]
                        ? (item.MPR.serviceType[0].includes('Others')
                          ? item.MPR.serviceType[0].filter(item => item !== 'Others').join(',') + ',' + item.MPR.serviceType[1]
                          : item.MPR.serviceType[0].join(','))
                        : 'N/A',
                        item.MPR.status,
                        <Tooltip title="View">
                          <IconButton size="small" variant="outlined" color="primary"  onClick={()=>onRowClick( item)} >
                            <VisibilityIcon className={classes.icons}/>
                          </IconButton>
                        </Tooltip>
                      ])
       
                    }else if(item.MPR.status === "Closed"){
                      closed.push([
                        item.MPR.external_id,
                        item.MPR.name,
                        moment(item.MPR.createdAt).format('DD-MM-YYYY'),
                        // item.MPR.description,
                        item.MPR.serviceCategory,
                        item.MPR.serviceType?.[0]
                        ? (item.MPR.serviceType[0].includes('Others')
                          ? item.MPR.serviceType[0].filter(item => item !== 'Others').join(',') + ',' + item.MPR.serviceType[1]
                          : item.MPR.serviceType[0].join(','))
                        : 'N/A',
                        item.MPR.status,
                        <Tooltip title="View">
                          <IconButton size="small" variant="outlined" color="primary"  onClick={()=>onRowClick( item)} >
                            <VisibilityIcon className={classes.icons}/>
                          </IconButton>
                        </Tooltip>
                      ])
                    }else if(item.MPR.status === "Draft"){
                      draft.push([
                        item.MPR.external_id,
                        item.MPR.name,
                        moment(item.MPR.createdAt).format('DD-MM-YYYY'),
                        // item.MPR.description,
                        item.MPR.serviceCategory,
                        item.MPR.serviceType?.[0]
                        ? (item.MPR.serviceType[0].includes('Others')
                          ? item.MPR.serviceType[0].filter(item => item !== 'Others').join(',') + ',' + item.MPR.serviceType[1]
                          : item.MPR.serviceType[0].join(','))
                        : 'N/A',
                        item.MPR.status,
                      ])
                    }
                      all.push([
                        item.MPR.external_id,
                        item.MPR.name,
                        moment(item.MPR.createdAt).format('DD-MM-YYYY'),
                        // item.MPR.description,
                        item.MPR.serviceCategory,
                        item.MPR.serviceType?.[0]
                        ? (item.MPR.serviceType[0].includes('Others')
                          ? item.MPR.serviceType[0].filter(item => item !== 'Others').join(',') + ',' + item.MPR.serviceType[1]
                          : item.MPR.serviceType[0].join(','))
                        : 'N/A',
                        item.MPR.status,
                        <Tooltip title="View">
                          <IconButton size="small" variant="outlined" color="primary" onClick={()=>onRowClick( item)} >
                            <VisibilityIcon className={classes.icons}/>
                          </IconButton>
                        </Tooltip>
                      ])
                  }): ""
                  
                }
            <TabPanel value={value} index={0}>
            <Table 
                data={open} 
                header={datatableHeader} 
                name={""}
                options={{
                  filterType: 'checkbox',
                  print: false,
                  download: false,
                  filter: false,
                  selectableRows: 'none',
                }}
              />

            </TabPanel>
            <TabPanel value={value} index={1}>
                  <Table 
                            data={draft} 
                            header={datatableHeader} 
                            name={""}
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                        }} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                  <Table 
                            data={closed} 
                            header={datatableHeader} 
                            name={""}
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                        }} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                  <Table 
                            data={all} 
                            header={datatableHeader} 
                            name={""}
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                        }} />
            </TabPanel>

            
            
        </Card>
      </div>
    );
}
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  typo: {
    color: theme.palette.text.hint,
  },
  content: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(8),
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(6),
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonDiv: {
    float: "right",
    marginTop: theme.spacing(1),
  },
  form1: {
    padding: theme.spacing(4),
    marginTop: "2rem",
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  },
  title: {
    font: "400 2.125rem / 1.17647059 Roboto,RobotoDraft,sans-serif",
    fontWeight: "300",
    margin: "16px 0 32px 0",
    textAlign: "left",
  },
  label: {
    textAlign: "left",
    marginTop: "2rem",
    marginBottom: "1rem",
  },
  stepper: {
    marginTop: theme.spacing(6),
  },
  paperButton: {
    marginTop: "2rem",
    textAlign: "right",
    padding: "1rem",
  },
  finish: {
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  HeaderContainer: {
    padding: theme.spacing(1.5),
    margin: theme.spacing(1.5),
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  tenantHeader: {
    display: "flex",
    // alignItems:'center',
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px",
    },
  },
  tenantSubHeader: {
    paddingTop: theme.spacing(0.3),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(0),
      whiteSpace: "nowrap",
      marginLeft: "3px",
      marginBottom: theme.spacing(2),
    },
  },
  divAlign: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  tagDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px",
  },
  editLotDiv: {
    marginTop: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  buttonOutlined: {
    // color: theme.palette.primary.main,
    // border: "1px solid",
    // borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  tagButton: {
    margin: "auto",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  deleteButton: {
    color: theme.palette.primary.main,
  },
  themeIcon: {
    color: theme.palette.primary.main,
  },
  switchBase: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.primary.main,
    },
    checked: {},
    track: {},
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  listItem: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  form: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(0),
    marginTop: "3rem",
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    height: "10rem",
  },
  fileTypeInput: {
    display: "inline-block",
    wordWrap: "break-word",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    padding: "7px",
    borderRadius: "4px",
  },
  reviewCell: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
  },
  reviewCell2: {
    border: "1px solid",
    fontWeight: "bolder",
    borderColor: theme.palette.primary.main,
  },
  blueBg: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  spanAlert: {
    color: "#ff0040",
  },
  finalizepaper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px",
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "50%",
    height: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

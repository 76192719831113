import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  CircularProgress,
  Link,
} from "@mui/material";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FileBase64 from "react-file-base64";
//styles
import useStyles from "../styles";

//components
import { fileupload } from "../MprState";

const MaterialDetails = ({ setForm, formData }) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [uploadedFile, setUploadedFile] = useState(formData.files);
  const [uploadedGoodsFile, setUploadedGoodsFIle] = useState(formData.goodsPic);
  const [showLoader, setShowLoader] = useState(false);
  const [showGoodsLoader, setShowGoodsLoader] = useState(false);
  // eslint-disable-next-line
  const [type, setType] = useState("");
  // eslint-disable-next-line
  const [getfileData, setGetFileData] = useState("");
  var stateMpr = useSelector((state) => state.mprid);
  var loggedInDatas = useSelector((state) => state.userdata);

  // const handleOnDone = (e) => {
  //   setGetFileData(e)
  // }

  const handleChange = (e, type) => {
    type === "MaterialList" ? setShowLoader(true) : setShowGoodsLoader(true);
    setType(type);
    var fileName = e.name;
    var filetype = e.type.split("/");
    var baseData = e.base64.split(",");

    let getTokenData = {
      abbrevation: loggedInDatas.getData.abbrevation,
      fileType: "materialPic",
      folderName: stateMpr,
      expireLimt: 1800,
      extension: filetype.length > 0 ? filetype[1] : "",
    };
    fileupload(
      baseData.length > 0 ? baseData[1] : "",
      getTokenData,
      e.type
    ).then((response) => {
      if (response.status === "200") {
        if (type === "MaterialList") {
          formData.files.push({
            fileName: response.fileName || response.filename,
            ref: response.reference,
            folderName: response.folderName || response.folder || response.FolderName,
          });
          setUploadedFile((uploadedFile) => [
            ...uploadedFile,
            { fileName: fileName, ref: response.reference },
          ]);
          setShowLoader(false);
        } else {
          formData.goodsPic.push({
            fileName: response.fileName || response.filename,
            ref: response.reference,
            folderName: response.folderName || response.folder || response.FolderName,
          });
          setUploadedGoodsFIle((uploadedGoodsFile) => [
            ...uploadedGoodsFile,
            { fileName: fileName, ref: response.reference },
          ]);
          setShowGoodsLoader(false);
        }
      }
    });
  };
  useEffect(() => {
    GenerateRows();
    GenerateGoodsRows();
    // eslint-disable-next-line
  }, [uploadedFile, uploadedGoodsFile]);

  const GenerateRows = () => {
    //var rows = Array.from(new Set(uploadedFile))
    return formData.files.map((item, key) => {
      return (
        <TableRow key={key}>
          <TableCell>{item.fileName}</TableCell>
          <TableCell>
            <Link href={item.ref} target="_blank" underline="hover">
              {t("common_click_here")}{" "}
            </Link>
          </TableCell>
        </TableRow>
      );
    });
  };
  const GenerateGoodsRows = () => {
    //var rows = Array.from(new Set(uploadedGoodsFile))
    return formData.goodsPic.map((item, key) => {
      return (
        <TableRow key={key}>
          <TableCell>{item.fileName}</TableCell>
          <TableCell>
            <Link href={item.ref} target="_blank" underline="hover">
              {t("common_click_here")}{" "}
            </Link>
          </TableCell>
        </TableRow>
      );
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={3} xs={0} sm={0}></Grid>
      <Grid item lg={6} md={6} xs={12} sm={12}>
        <Paper
          className={classes.paper}
          classes={{
            root: classnames(classes.widgetRoot, {
              [classes.noWidgetShadow]: null,
            }),
          }}
        >
          <div className={classes.form}>
            <Grid container spacing={3}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // style={{ width: "width: calc(100% - 2*15px)" }}
              >
                <h3 className={classes.title}>{t("mpr_material_details")}</h3>
                <span>{t("common_upload_details")}</span>
                <Grid container spacing={2}>
                  <Grid item lg={11} md={11} sm={12} xs={12}>
                    <div className={classes.fileTypeInput}>
                      <FileBase64
                        className={classes.input}
                        multiple={false}
                        onDone={(e) => handleChange(e, "MaterialList")}
                      />
                    </div>
                    <span style={{display:"block"}}>{t("mpr_upload_below")}</span>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <label htmlFor="material-file">
                      {showLoader ? <CircularProgress /> : <></>}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid lg={12} md={12} xs={12} sm={12} item>
                {uploadedFile.length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("common_file_name")}</TableCell>
                        <TableCell>{t("common_reference")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <GenerateRows />
                    </TableBody>
                  </Table>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid item lg={3} md={3} xs={0} sm={0}></Grid>
      <Grid item lg={3} md={3} xs={0} sm={0}></Grid>
      <Grid item lg={6} md={6} xs={12} sm={12}>
        <Paper

          className={classes.paper}
          classes={{
            root: classnames(classes.widgetRoot, {
              [classes.noWidgetShadow]: null,
            }),
          }}
        >
          <div className={classes.form2}>
            <Grid container spacing={3}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                // style={{ width: "width: calc(100% - 2*15px)" }}
              >
                <h3 className={classes.title}>{t("mpr_goods_details")}</h3>
                <span>{t("common_upload_images")}</span>
                <Grid container spacing={2}>
                <Grid item lg={11} md={11} sm={12} xs={12}>
                    <div className={classes.fileTypeInput}>
                      <FileBase64
                        className={classes.input}
                        multiple={false}
                        onDone={(e) => handleChange(e, "GoodsList")}
                      />
                    </div>
                    <span style={{display:"block"}}>{t("mpr_upload_below")}</span>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <label htmlFor="material-file">
                      {showGoodsLoader ? <CircularProgress /> : <></>}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "1rem" }}>
              <Grid lg={12} md={12} xs={12} sm={12} item>
                {uploadedGoodsFile.length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("common_file_name")}</TableCell>
                        <TableCell>{t("common_reference")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <GenerateGoodsRows />
                    </TableBody>
                  </Table>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid item lg={3} md={3} xs={0} sm={0}></Grid>
    </Grid>
  );
};

export default MaterialDetails;

import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
// import Typography from "@mui/material/Typography";

function formatTime(value) {
  return `${String(value).padStart(2, "0")}:00`;
}

const minTimeRange = 1;

export default function TimeRangeSlider({ selectedRange, onRangeChange }) {

  const handleRangeChange = (event, newRange, activeThumb) => {
    if (!Array.isArray(newRange)) {
      return;
    }

    if (newRange[1] - newRange[0] < minTimeRange) {
      if (activeThumb === 0) {
        const clamped = Math.min(newRange[0], 24 - minTimeRange);
        onRangeChange([clamped, clamped + minTimeRange]);
      } else {
        const clamped = Math.max(newRange[1], minTimeRange);
        onRangeChange([clamped - minTimeRange, clamped]);
      }
    } else {
      onRangeChange(newRange);
    }
  };

//   const startTime = formatTime(selectedRange[0]);
//   const endTime = formatTime(selectedRange[1]);

  return (
    <Box sx={{ width: 250 }}>
      {/* <Typography id="range-slider" sx={{ marginBottom: 4 }}>
        Time Range: {startTime + " - " + endTime}
      </Typography> */}
      <Slider
        getAriaLabel={() => "Time Range Shift"}
        value={selectedRange}
        onChange={handleRangeChange}
        valueLabelDisplay="auto"
        valueLabelFormat={formatTime}
        valueLabelDisplayFormat={formatTime}
        min={9}
        max={18}
        step={1}
        disableSwap
      />
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  CircularProgress,
} from "@mui/material";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//components
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import CustomButton from "../../components/CustomButton";
import BasicDetails from "./pages/BasicLotDetails";
import ImageUpload from "./pages/ImageUpload";
import NotificationContainer from "../notifications/NotificationContainer";
import { addLot } from "./MarketplaceState";
import ReviewDetails from "./pages/ReviewDetails";

function getStepContent(step, formData, setForm, validateOnChange, validate) {
  switch (step) {
    case 0:
      return (
        <BasicDetails
          formData={formData}
          setForm={setForm}
          validateOnChange={validateOnChange}
        />
      );
    case 1:
      return <ImageUpload formData={formData} setForm={setForm} />;

    case 2:
      return <ReviewDetails formData={formData} setForm={setForm} />;
    default:
      return "Unknown step";
  }
}

export default function AddLot(props) {
  // eslint-disable-next-line
  const userDetails = useSelector((state) => state);
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const steps = getSteps();
  var tagOptionSelected = [];

  const AddLot = [
    { label: t("common_lot"), link: "/#/app/marketplace" },
    { label: t("lot_add_new"), link: "" },
  ];

  function getSteps() {
    return [
      t("add_lot_details"),
      t("lot_upload_images"),
      t("common_review_details"),
    ];
  }

  const defaultData = {
    title: "",
    description: "",
    lastDate: "",
    email: userDetails?.userdata?.getData?.email,
    files: [],
    filesWithReference: [],
    basicPrice: "",
    tags: [],
    location: "",
    incrementValue: "",
    detaileDescription: "",
    // errors: false,
    // errorMessage: {},
    // setErrors:{}
    // hours: "",
    // minutes: "",
    // amOrpm: ""
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setForm] = useState(defaultData);
  const [message, setMessage] = React.useState("");
  const [notify, setNotify] = React.useState(false);
  const [type, setType] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [isError, setIsError] = useState(false);

  const handleNext = () => {
    if (activeStep === 0) {
      if (formData.title === "") {
        setIsError(true);
        setMessage("Please enter the title");
        setType("error");
        setTimeout(() => setIsError(false), 2000);
      } else if (formData.basicPrice === "") {
        setIsError(true);
        setMessage("Please enter the base price");
        setType("error");
        setTimeout(() => setIsError(false), 2000);
      } else if (formData.location === "") {
        setIsError(true);
        setMessage("Please enter the location");
        setType("error");
        setTimeout(() => setIsError(false), 2000);
      } else if (formData.incrementValue === "") {
        setIsError(true);
        setMessage("Please enter the increment Amount");
        setType("error");
        setTimeout(() => setIsError(false), 2000);
      } else if (formData.email === "") {
        setIsError(true);
        setMessage("Please enter the Email");
        setType("error");
        setTimeout(() => setIsError(false), 2000);
      } else if (formData.lastDate === "") {
        setIsError(true);
        setMessage("Please enter the close date");
        setType("error");
        setTimeout(() => setIsError(false), 2000);
      } else if (formData.description === "") {
        setIsError(true);
        setMessage("Please enter the description");
        setType("error");
        setTimeout(() => setIsError(false), 2000);
      } else if (formData.detaileDescription === "") {
        setIsError(true);
        setMessage("Please enter the Detailed Description");
        setType("error");
        setTimeout(() => setIsError(false), 2000);
      } else if (formData.tags && formData.tags.length === 0) {
        setIsError(true);
        setMessage("Please select the tags");
        setType("error");
        setTimeout(() => setIsError(false), 2000);
      } else {
        setIsError(false);
        setMessage("");
        setType("");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (formData.filesWithReference.length === 0) {
        setIsError(true);
        setMessage("Please Upload the images");
        setType("error");
      } else {
        setIsError(false);
        setMessage("");
        setType("");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
    }

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // const handleNext = () => {
  //   console.log(formData)
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePublish = () => {
    let tagsArray = [];
    formData.tags.map((item) => {
      tagsArray.push(item.value);
    });
    const data = {
      data: {
        title: formData.title,
        description: formData.description,
        lastDate: formData.lastDate + " " + "23" + ":" + "59",
        images: formData.filesWithReference,
        basePrice: formData.basicPrice,
        tagId: tagsArray,
        location: formData.location,
        increamentAmount: formData.incrementValue,
        detailedDescription: formData.detaileDescription,
        tenantId: localStorage.getItem("customerid"),
        status: "open",
        email: formData.email,
      },
    };
    setShowLoader(true);
    addLot(data).then((response) => {
      if (response.status === 200) {
        setMessage("Lot added successfully");
        setType("success");
        setNotify(true);
        setTimeout(() => props.history.push("/app/marketplace"), 2000);
      } else if (response.status === 500) {
        setMessage(response.message);
        setType("error");
        setNotify(true);
      } else {
        let msg = [];
        if (response.ErrorData) {
          // eslint-disable-next-line
          response.ErrorData.details.map((item) => {
            msg.push(item.message);
          });
          setMessage(`${msg.join()}`);
          setType("error");
          setNotify(true);
        } else {
          setMessage("Something went Wrong! Please try again!");
          setType("error");
          setNotify(true);
        }
      }
      setShowLoader(false);
    });
  };

  return (
    <>
      <BreadCrumbs data={AddLot} />
      <div className={classes.content}>
        {notify ? (
          <NotificationContainer message={message} notificationtype={type} />
        ) : (
          <></>
        )}
        {isError ? (
          <NotificationContainer message={message} notificationtype={type} />
        ) : (
          <></>
        )}
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {getStepContent(activeStep, formData, setForm)}
          <Paper
            className={classes.paperButton}
            classes={{
              root: classnames(classes.widgetRoot, {
                [classes.noWidgetShadow]: null,
              }),
            }}
          >
            <div style={{display:'flex',justifyContent:'flex-end'}}>
            <Button href="/#/app/marketplace" className={classes.button}>
              {t("common_cancel")}
            </Button>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
            >
              {t("common_back")}
            </Button>
          
            {activeStep === steps.length - 1 ? (
             <div >
                {showLoader ? (
                  <CircularProgress />
                ) : (
                  <CustomButton
                    variant="contained"
                    title={t("common_publish")}
                    onclick={() => handlePublish()}
                  />
                )}
              </div>
            ) : (
              <CustomButton
                variant="contained"
                title={t("common_next")}
                onclick={handleNext}
              />
            )}
              </div>
          </Paper>
        </div>
      </div>
    </>
  );
}

import React from "react";
import MUIDataTable from "mui-datatables";
import { useMediaQuery } from '@mui/material';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
export default function Table(props) {
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              backgroundColor: "#FF000",
              margin: isSmallScreen ? "-24px" : "0px",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            data: {
              fontWeight: "bold",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides:{
            root: {
              ...(props.data.length === 0
                ? {
                    marginLeft:"-21px"
                  }
                : {}), 
            },

          }
        },
        MUIDataTableBodyRow: {
          styleOverrides:{
            root: {
              ...(isSmallScreen && {
                borderBottom: "6px solid rgba(0, 0, 0, 0.15)",
                
              }),
            },

          }
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            filterPaper: {
                maxWidth: "80%"
            },
          },
        },
      },
    });

  return (
    <StyledEngineProvider injectFirst>
    <ThemeProvider theme={getMuiTheme}>
      <MUIDataTable
        title={props.name}
        data={props.data}
        columns={props.header}
        options={props.options}
      />
    </ThemeProvider>
   </StyledEngineProvider>
  );
}

import React,{useState} from 'react'
import { Grid,Paper,Divider,Avatar,Button } from '@mui/material'
import classnames from "classnames";
import moment from 'moment'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SendIcon from '@mui/icons-material/Send';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useTranslation } from 'react-i18next';

import useStyles from "./styles";

const Users = ({data,inspection,quotation,invoice,form6,form6Data,closeAll}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const [selectedButton, setSelectedButton] = useState(null); 

    let actions = [
        {title : t("mpr_inspection") , icon : <HowToRegIcon/>,func: inspection,disable:false},
        {title : t("common_quotations") , icon : <DescriptionIcon />,func: quotation,disable:false},
        {title : t("mpr_invoice") , icon : <ReceiptIcon />,func: invoice,disable:false},
        // eslint-disable-next-line
        {title : t("common_form")+""+"-6" , icon : <DescriptionIcon />,func: form6,disable:(form6Data.formstatus == "Draft" || Object.keys(form6Data).length == 0)?true:false},
        {title : t("common_send_message"), icon : <SendIcon />,href:"/#/app/messages",disable:true},
    ]
    return(
        <Paper className={classes.paper1} classes={{ root: classnames(classes.widgetRoot, {
            [classes.noWidgetShadow] :null
            }) }}>
            <Grid container >
                <Grid item xs={3} className={classes.avatargrid}>
                    <Avatar className={classes.avatar}>M</Avatar>
                </Grid>
                <Grid item xs={9}>
                    <h4>{data.external_id}</h4>
                    <p>{t("mpr_name")}: {data.name}<br/> {t("common_createdAt")} : {moment(data.createdAt).format('DD-MM-YYYY')} </p>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                {/* <Grid item xs={12} className={classes.userGrid}>
                    <p>
                        {t("mpr_label_organisation")}:
                        <span style={{fontSize:'18px',fontWeight:"bold"}}> binbag.in</span>
                    </p>
                    
                </Grid> */}
                 <Grid item xs={12} className={classes.userGrid}>
                  <h4 style={{display:"inline"}}>{t("common_mpr")} {t("common_status")}: </h4>{data.status}
                  </Grid> 
                <Grid item xs={4} className={classes.buttonGrid}>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12} className={classes.userActionGrid}>
                    {
                        actions.map((item,key) => {
                            return(
                                <Grid item lg={12} md={5} sm={12} xs={12} key={key} className={classes.userActionGrid}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        className={`${classes.buttonOutlined} ${selectedButton === item.title && !closeAll ? classes.selectedBtn : ''}`}
                                        startIcon={item.icon}
                                        style = {{width:'100%', justifyContent:'unset'}}
                                        href={item.href}
                                        disabled={item.disable}
                                        onClick={() => {
                                           setSelectedButton(item.title);
                                            item.func()
                                          }}
                                    >
                                        {item.title}
                                    </Button>
                                </Grid>
                            )
                        })
                    }
                    
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Users
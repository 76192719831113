import React,{useState} from 'react';
import {
  Button,
  Grid,
  Paper,
  CircularProgress
} from '@mui/material'
import classnames from "classnames";
import {
  useForm,
  FormProvider,
} from "react-hook-form";
import { useTranslation } from 'react-i18next';


//components
import useStyles from "./styles";
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import BasicDetails from './pages/BasicDetails'
import { useDispatch } from "react-redux";
import {addCustomer } from './CustomerState'
import NotificationContainer from '../notifications/NotificationContainer'

function getSteps() {
  return ['Basic Details'];
}

function getStepContent(step,formData,setForm) {

  switch (step) {
    case 0:
      return <BasicDetails formData={formData} setForm={setForm}/>
    default:
      return 'Unknown step';
  }
}

export default function AddCustomer(props) {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const methods = useForm({
    defaultValues: {
      companyName : "",
      contactName:"",
      email : "",
      mobile : "",
      pan_no:"",
      taxNo:"",
      abbreviation:"",
      notificationAllowed:"",
      countryCode:"+91",
      companySize : "",
      createdAt:new Date().toISOString(),
      isActive:true,
      isprimary:true,
      isTemperory:true,
      createdBy:"System"
    }
  })
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const [showLoader,setShowLoader] = useState(false);
  const [message,setMessage] = React.useState("")
  const [notify,setNotify] = React.useState()
  const [type,setType] = React.useState("")
  const classes = useStyles();
  const breadcrumb = [
    {label: t("common_customers") , link: "/#/app/customers"},
    {label: t("customer_add_new") , link: ""},
  ]

  const handleNext = (data) => {
    if (activeStep === steps.length - 1) {
      setShowLoader(true)
            addCustomer(data).then(response => {
              if(response.status === "200"){
                setMessage(response.message)
                setType("success")
                setNotify(true)
                setTimeout(()=> 
                props.history.push('/app/customers'),2000)
              }else if(response.status === "401"){
                setMessage(response.message)
                setType("error")
                setNotify(true)
                setTimeout(() => {
                  dispatch({ type: "LOG_OUT" });
                  localStorage.clear();
                }, 2000);
              }else if(response.status === "500"){
                  setMessage(response.message)
                  setType("error")
                  setNotify(true)
                  setTimeout(() => {
                    setMessage('')
                    setType("")
                    setNotify(false)
                  }, 4000);
              }
              else{
                 let msg = []
                 if(response.ErrorData){
                   // eslint-disable-next-line
                   response.ErrorData.details.map(item => {
                     msg.push(item.message)
                   })
                  setMessage(`${msg.join()}`)
                  setType("error")
                  setNotify(true)
                 }else{
                    setMessage("Something went Wrong! Please try again!")
                    setType("error")
                    setNotify(true)
                 }
              }
              setShowLoader(false)
            })
    }else{
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  }


  return(
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.form}>
      <Grid container spacing={3} >
        {(notify)?<NotificationContainer message={message} notificationtype={type} /> : <></>}
        <Grid item lg={3} md={1} xs={0} sm={0}></Grid>
        <Grid item lg={6} md={11} xs={12} sm={12}>
          <Paper className={classes.paperButton} classes={{ root: classnames(classes.widgetRoot, {
                  [classes.noWidgetShadow] :null})
              }}> 
          {
            
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleNext)}>
                  <Grid container spacing={2} >
                    <Grid item lg={2} md={2} sm={1} xs={1}></Grid>
                    <Grid item lg={8} md={10} sm={10} xs={10} style={{width:'width: calc(100% - 2*15px)'}}>
                      {getStepContent(activeStep)}
                      <div style={{display:'flex',alignItems:"center",justifyContent:"flex-end"}}>
                        <Button href="/#/app/customers"  className={classes.button}>
                          {t("common_back")}
                        </Button> 
                       {
                         (showLoader)?<CircularProgress  /> :
                            <Button className={classes.button} variant="contained" color="primary" type="submit" >
                              {activeStep === steps.length - 1 ? t("common_finish") : t("common_next")}
                            </Button>
                       }
                      </div> 
                    </Grid>
                  </Grid>
                </form>
              </FormProvider> 
          }  
          </Paper>
        </Grid>
        <Grid item lg={3} md={1} xs={0} sm={0}></Grid>
      </Grid>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector,useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//components
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "./components/styles";
import Users from "./components/User";
import { getMPRData } from "./MprState";
import MPRDetails from "./components/ViewMPR/MPRDetails";
import MaterialDetails from "./components/ViewMPR/MaterialDetails";
import SlotDetails from "./components/ViewMPR/SlotDetails";
import InspectionRequests from "./components/ViewMPR/InspectionRequests";
import QuotationList from "./components/ViewMPR/QuotationList";
import InvoiceList from "./components/ViewMPR/invoiceContainer/InvoiceList";
import FormSix from "./components/ViewMPR/FormSix";

const ViewMpr = () => {
  // eslint-disable-next-line
  const { t,i18n } = useTranslation();
  var classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory()
  const state = useSelector((state) => state.selectedMPR);
  const [userData, setUserData] = useState([]);
  const [wonLead, setWonLead] = useState("");
  const [dashboardData, setDashboardData] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [goodsList, setGoodsList] = useState([]);
  const [slotData, setSlotData] = useState([]);
  const [slotChoosen, setSlotChoosen] = useState([]);
  const [quotation, setQuotation] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  const [form6Data, setForm6Data] = useState({});
  const [form6content, setForm6content] = useState({});
  const [transactionAmount,setTransactionAmount] = useState({})

  const [showInspection, setShowInspection] = useState(false);
  const [showQuotationList, setShowQuotationList] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [showFormSix, setShowFormSix] = useState(false);
  const [closeAllTabs, setCloseAllTabs] = useState(false)


  const breadcrumb_viewTenant = [
    { label: t("common_material_pickup"), link: "/#/app/MPR" },
    { label: state.MPR && state.MPR.external_id, link: "" },
  ];

  const toggleTab = (tabName) => {
    const tabState = {
      showInspection: false,
      showQuotationList: false,
      showInvoice: false,
      showFormSix: false,
    };
  
    tabState[tabName] = true;
    setTabStates(tabState);
  };

  const setTabStates = (newTabStates) => {
    setShowInspection(newTabStates.showInspection);
    setShowQuotationList(newTabStates.showQuotationList);
    setShowInvoice(newTabStates.showInvoice);
    setShowFormSix(newTabStates.showFormSix);
  };

  const handleShowInspection = () => {
   toggleTab('showInspection')
   setCloseAllTabs(false)
  };
  const handleShowQuotation = () => {
   toggleTab('showQuotationList')
   setCloseAllTabs(false)
  };
  const handleShowInvoice = () => {
   toggleTab('showInvoice')
   setCloseAllTabs(false)
  };
  const handleShowFormSix = () => {
   toggleTab('showFormSix')
   setCloseAllTabs(false)
  };
  const handleCloseAll=()=>{
    toggleTab('');
    setCloseAllTabs(true)
  }

  useEffect(() => {
    let dashboard = [];
    if (state.MPR && state.MPR.external_id) {
    getMPRData(state.MPR && state.MPR.external_id,dispatch).then((response) => {
      setUserData(response.leadListMPR);
      setMaterialList(response.leadListMPR.item_list_file);
      setGoodsList(response.leadListMPR.goodsPic);
      setSlotData(response.slotlost);
      setSlotChoosen(response.slotsChoosenByTenant);
      setQuotation(response.quotationList);
      setInvoiceData(response.thisInvoice);
      if (response.wonLead) setWonLead(response.wonLead);
      if (response.thisform6contnet) setForm6Data(response.thisform6contnet);
      if (response.thisform6file) setForm6content(response.thisform6file);
      if (response.transactionAmount) setTransactionAmount(response.transactionAmount)
      dashboard.push([
        {
          label: t("mpr_dashboard_label1"),
          count: response.leadSentInspection,
        },
        {
          label: t("mpr_dashboard_label2"),
          count: response.leadRejectedByTenant,
        },
        { label: t("mpr_dashboard_label3"), count: response.openLead },
        { label: t("mpr_dashboard_label4"), count: response.assignedLead },
        { label: t("mpr_dashboard_label5"), count: response.NoOfLeads },
        { label: t("mpr_dashboard_label6"), count: response.leadSentQuotation },
      ]);
      setDashboardData(dashboard);
    })
  }
    else {
      history.push('/app/MPR')
    }
    // eslint-disable-next-line
  }, [showQuotationList, showInvoice]);
  return (
    <>
      <BreadCrumbs data={breadcrumb_viewTenant} />
      <div className={classes.content}>
        <Grid container spacing={3} >
          <Grid
            item
            lg={3}
            md={12}
            xs={12}
            sm={12}
            className={classes.scrolling}
          >
            <Users
              data={userData}
              inspection={handleShowInspection}
              quotation={handleShowQuotation}
              invoice={handleShowInvoice}
              form6={handleShowFormSix}
              form6Data={form6Data}
              closeAll={closeAllTabs}
            />
          </Grid>
          <Grid item lg={9} md={12} xs={12} sm={12}>
            {showInspection ? (
              <InspectionRequests
                slotData={slotChoosen}
                lead={state.MPR && state.MPR.external_id}
                close={handleCloseAll}
                userData={userData}
              />
            ) : showQuotationList ? (
              <QuotationList
                close={handleCloseAll}
                quotation={quotation}
                userData={userData}
              />
            ) : showInvoice ? (
              <InvoiceList
                close={handleCloseAll}
                invoice={invoiceData}
                wonLead={wonLead}
                mprID={userData.external_id}
                transactionAmount = {transactionAmount}
              />
            ) : showFormSix ? (
              <FormSix form6Data={form6content} close={handleCloseAll} />
            ) : (
              <>
                {/* <MPRDashboard dashboardData={dashboardData} /> */}
                <MPRDetails userData={userData} />
                <MaterialDetails
                  materialList={materialList}
                  goodsList={goodsList}
                />
                <SlotDetails slotData={slotData} />
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ViewMpr;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import { withStyles } from "@mui/styles";

import {
  TextField,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  IconButton,
  CircularProgress,
  Box,
  Tooltip,
} from "@mui/material";

import Table from "../../../components/Table/Table";
import {
  deleteTag,
  getAllTags,
  updateTagName,
  updateStatus,
} from "../MarketplaceState";
import EditIcon from "@mui/icons-material/Edit";
import NotificationContainer from "../../notifications/NotificationContainer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#2E7D32",
    "&$checked": {
      color: "#2E7D32",
    },
    "&$checked + $track": {
      backgroundColor: "#2E7D32",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function ViewTags() {
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);
  const [tags, setTags] = useState([]);
  const [id, setId] = useState([]);
  const [tag, setTag] = useState([]);
  const [value, setValue] = useState(0);
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [notification, setNotification] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);

  const onRowClick = (index) => {
    var id = "";
    // eslint-disable-next-line
    tag.map((item, key) => {
      if (key === index) {
        id = item._id;
      }
    });
    deleteTag(id).then((response) => {
      setShowLoader(true);
      if (response.status === "200") {
        setShowLoader(false);
        setType("success");
        setMessage(response.message);
        setNotification(true);
      } else {
        setShowLoader(false);
        let msg = [];
        setNotification(true);
        setType("error");
        if (response.ErrorData) {
          // eslint-disable-next-line
          response.ErrorData.details.map((item) => {
            msg.push(item.message);
          });
          setMessage(`${msg.join()}`);
        } else {
          setMessage("Something went Wrong! Please try again!");
        }
      }
    });
  };

  const handleUpdateTag = () => {
    setShowLoader(true);
    updateTagName(id, tags).then((response) => {
      if (response.status === 200) {
        setMessage("Tag name Updated Successfully");
        setType("success");
        setNotification(true);
        setShowLoader(false);
        setOpen(false);
        setTimeout(() => getAllTag(), 1000);
      } else {
        setType("error");
        setMessage("Something went wrong, Try again!");
        setNotification(true);
        setShowLoader(false);
        setOpen(false);
      }
    });
  };

  const handleEdit = (index) => {
    setId(index._id);
    setTags(index.item);
    setOpen(true);
  };

  const handleChange = (event) => {
    updateStatus(event.target.id, event.target.checked).then((response) => {
      if (response.status === 200) {
        setType("success");
        setMessage("Status Updated Successfully");
        setNotification(true);
        setTimeout(() => getAllTag(), 1000);
      } else {
        setType("error");
        setMessage("Something went wrong, Try again!");
        setNotification(true);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAllTag = () => {
    setFetchLoader(true);
    getAllTags().then((response) =>
      response
        ? (setNotification(false),
          setType(""),
          setMessage(""),
          setTag(response.data.tags),
          setFetchLoader(false))
        : (setTag([]), setFetchLoader(false))
    );
  };

  useEffect(() => {
    getAllTag();
    // eslint-disable-next-line
  }, []);

  var datatableData = [];

  const dataTableHeader = [
    {
      label: t("tags"),
      options: {
        filter: true,
      },
    },
    {
      label: t("common_status"),
      options: {
        filter: false,
      },
    },
    {
      label: t("common_action"),
      options: {
        filter: false,
      },
    },
  ];

  return (
    <>
      <div>
        {notification ? (
          <NotificationContainer message={message} notificationtype={type} />
        ) : (
          <></>
        )}
        <Card>
          <TabPanel value={value} index={0} key={1}>
            {
              // eslint-disable-next-line
              tag.map((item, key) => {
                datatableData.push([
                  item.item,
                  <PurpleSwitch
                    defaultChecked={item.isActive}
                    id={item._id}
                    onChange={handleChange}
                    classes={{
                      switchBase: classes.switchBase,
                      track: classes.track,
                      checked: classes.checked,
                    }}
                  />,
                  <Tooltip title="Update">
                    <IconButton
                      onClick={() => handleEdit(item)}
                      className={classes.themeIcon}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>,
                ]);
              })
            }
            <Table
              data={datatableData}
              header={dataTableHeader}
              name={t("tag_list")}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
                textLabels: {
                  body: {
                    noMatch: fetchLoader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#0D6937", marginRight: "5px" }}
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      "No Tags Found"
                    ),
                  },
                },
              }}
            />
          </TabPanel>
        </Card>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Update the tag name.</DialogTitle>
        <DialogContent sx={{ width: "300px" }}>
          {/* <DialogContentText>
                    Please update the tag name.
                </DialogContentText> */}
          <TextField
            variant="standard"
            id="tagName"
            // label="Tag Name"
            fullWidth
            type="text"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {showLoader ? (
            <CircularProgress
              style={{ height: "30px", width: "30px", marginRight: "14px" }}
            />
          ) : (
            <>
              {" "}
              <Button variant="default" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                onClick={handleUpdateTag}
                style={{ marginRight: "14px" }}
              >
                Update
              </Button>
            </>
          )}
          {/* <Button variant="default" onClick={handleClose}>Cancel</Button>
                <Button variant="outlined" color="primary" onClick={handleUpdateTag}>Update</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}

import {apiUrl } from '../../data/config'



export function ValidateToken(token){   
    let url = apiUrl+'/v1/passwordVal'
    return fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token': token
          }
    }).then(function(response) {
        if(response.ok){
            return response.json().then((data) => {
                return data
            })
        }else{
            throw new Error('Failed to validate token');
        }
    }).catch(function(error) {
        return {status:"500", error: 'Failed to validate token', Message: error.message };
    });
}

export function passwordSet(username,password){
    let url = apiUrl+'/v1/passwordSet'
    let bodyData = {
        email : username,
        password: password
    }

    return fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json'
          },
        body: JSON.stringify(bodyData)
    }).then(function(response) {
        if(response.ok){
            return response.json().then((data) => {
               return data
            })
        }else{
            return{
                status : "500",
                message : "Something went wrong"
            }
        }
    })
}

export function TokenRegenerate(data){
    let url = apiUrl+'/v1/regenerate'
    return fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
        },
        body: JSON.stringify(data)
    })
        .then(function(response) {
            return response.json().then((data) => {
                return data
             })
        })
}
export function Userlogin(username,password,dispatch){
    let url = apiUrl+'/v1/login'
    let data = {
      email : username,
      password: password
    }
    return fetch(url,{
      method:'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body: JSON.stringify(data)
    }).then(function(response) {
        return response.json().then((data) => {
          if(response.ok){
            if(data.status === "200"){
              getData(dispatch)
            }
            return data
            }else{
            dispatch({type:"LOG_OUT"})
            localStorage.clear()
          }
        })
        .catch(function (error) {
            console.error('Network error:', error);
            return { error: 'Network error', message: error.message };
        });
      });
  }
  

export function logout(dispatch){
    dispatch({type:"LOG_OUT"})
    localStorage.clear()
}

export function getData(dispatch){
    let url = apiUrl+'/v1/extractData'

   return   fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
    }).then(function (response) {
        if(response.ok){
            return response.json().then((data) => {
                if(data.status === "200"){
                    localStorage.setItem("type" , data.getData.type) 
                    localStorage.setItem("customerid",data.getData.customerid)
                    dispatch({
                        type:"SET_USER_DATA",
                        payload:data
                    })
                }else{
                    localStorage.clear()
                    dispatch({
                        type:"LOG_OUT"
                    })
                }
                return data
            })
        }else{
            localStorage.clear()
            dispatch({
                type:"LOG_OUT"
            })
        }
        
    }).catch(function (error) {
        console.error('Network error:', error);
        return { error: 'Network error', message: error.message };
      });
}

export function forgotPassword(data){
    let url = apiUrl+'/v1/forgetPassword'
    return fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json'
        },
        body: JSON.stringify(data)
    }).then(function(response) {
        return response.json().then((data) => {
            return data
         })
    }).catch(function (error) {
        console.error('Network error:', error);
        return { error: 'Network error', message: error.message };
      });
}
import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Paper,
  TextField,
  CircularProgress,
} from "@mui/material";
import useStyles from "../styles";
import classnames from "classnames";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import { getMPUser, updateMPUser } from "../MarketplaceState";
import NotificationContainer from "../../notifications/NotificationContainer";

const EditMPUser = () => {
  const { userId } = useParams();

  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [user, setUser] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});

  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [notify, setNotify] = useState(false);
  const [type, setType] = useState("");

  const [formEdited, setFormEdited] = useState(false); 

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("Edit Buyer"), link: "" },
  ];

 

  useEffect(() => {
    fetchUserData();
  }, [userId]);
  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const fetchUserData = async () => {
    try {
      const response = await getMPUser(userId);
      setUser(response.data.user[0]);

      setDefaultValues({
        name: response.data.user[0]?.name?.fname || "",
        email: response.data.user[0]?.email || "",
        mobile: response.data.user[0]?.mobile || "",
        gst: response.data.user[0]?.gst || "",
        pan: response.data.user[0]?.pan || "",
      });
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const methods = useForm({
    defaultValues: {
      name: {
        fname: defaultValues.name,
      },
      mobile: defaultValues.mobile,
      email: defaultValues.email,
      gst: defaultValues.gst,
      pan: defaultValues.pan,
    },
  });

  const handleFormSubmit = async (formData) => {
    const userObject = {
      name: {
        fname: formData.name,
      },
      mobile: formData.mobile,
      email: formData.email,
      gst: formData.gst,
      pan: formData.pan,
    };
    // console.log("formData:", userObject);
    setShowLoader(true);
    try {
      const response = await updateMPUser(userId, userObject);
      setShowLoader(false);
      if (response.status == 200) {
        setMessage("User updated successfully");
        setType("success");
        setNotify(true);
        setTimeout(() => history.push("/app/mpUsers"), 2000);
      } else {
        setMessage(response.message);
        setType("error");
        setNotify(true);
        setTimeout(() => setNotify(false), 2000);
      }
    } catch (error) {
      console.error("API Error:", error);
      setMessage(error.message);
      setType("error");
      setNotify(true);
      setShowLoader(false);
      setTimeout(() => setNotify(false), 2000);
    }
  };

  return (
    <div className={classes.content}>
      <BreadCrumbs data={breadcrumb} />
      {notify ? (
        <NotificationContainer message={message} notificationtype={type} />
      ) : (
        <></>
      )}

      <Grid container spacing={3}>
        <Grid item lg={3} md={1} xs={0} sm={0}></Grid>
        <Grid item lg={6} md={10} xs={12} sm={12}>
          <Paper
            className={classes.paperButton}
            classes={{
              root: classnames(classes.widgetRoot, {
                [classes.noWidgetShadow]: null,
              }),
            }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleFormSubmit)}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label="Name"
                      fullWidth
                      margin="normal"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={defaultValues.name?.fname}
                      {...methods.register("name", { required: true })}
                      onChange={() => setFormEdited(true)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      label="Email"
                      type="email"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={defaultValues.email}
                      {...methods.register("email")}
                      onChange={() => setFormEdited(true)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      inputProps={{ maxLength: 10 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={defaultValues.mobile}
                      {...methods.register("mobile", { required: true })}
                      onChange={() => setFormEdited(true)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="GST"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      defaultValue={defaultValues.gst}
                      {...methods.register("gst", { required: true })}
                      onChange={() => setFormEdited(true)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="PAN"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      defaultValue={defaultValues.pan}
                      {...methods.register("pan", { required: true })}
                      onChange={() => setFormEdited(true)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {showLoader ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => history.push("/app/mpUsers")}
                          className={classes.buttonOutlined}
                        >
                          {t("Back")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ marginLeft: "10px" }}
                          disabled={!formEdited} 
                        >
                          {t("Submit")}
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Paper>
        </Grid>
        <Grid item lg={3} md={1} xs={0} sm={0}></Grid>
      </Grid>
    </div>
  );
};

export default EditMPUser;

import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  dashBox: {
    borderRadius: "10px",
    fontWeight: "bold",
    padding: "10px",
  },

  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },

  content: {
    // background: "white",
    // margin: "-24px",
    // marginTop: "24px",
    padding: theme.spacing(4),
    height: "100dvh",
    paddingTop: theme.spacing(8),
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(8),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(8),
    },
  },
  statusDiv: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      // margin: theme.spacing(1),
      // marginTop:
      margin: "-24px",
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      // marginTop: theme.spacing(3),
      // margin: theme.spacing(3),
    },
  },

  title: {
    marginLeft: "10px",
    marginBottom: "10px",
  },

  HeaderContainerEdit: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    justifyContent: "space-between",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  tagButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },

  form1: {
    marginTop: "2rem",
  },
  fileTypeInput: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    padding: "10px",
    borderRadius: "4px",
    marginLeft: "10px",
  },

  tableCell: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    border: "none",
  },

  dashLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "60vh",
  },

  dashBody: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    marginTop: "-10px",
  },
  optionsContainer: {
    marginTop: "10px",
    marginBottom: "18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  box: {
    display: "flex",
    gap: 10,
    width: "70%",
    // border: ".1px solid gray",
    // padding: "5px",
    // alignItems: "start",
    // borderRadius: "3px",
  },
  box2: {
    display: "flex",
    gap: 10,
    width: "70%",
    justifyContent: "space-between",
    // border: ".1px solid gray",
    // padding: "5px",
    // alignItems: "start",
    // borderRadius: "3px",
  },
}));

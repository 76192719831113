import React,{useState} from "react";
import {
  Grid,
  Paper,
  Avatar,
  TextField,
  Button,
} from "@mui/material";
import useStyles from "./styles";
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
const Profile = () => {
  
  const[name,setName]= useState("")
  const[email,setEmail]=useState("")

  var classes = useStyles();
  const breadcrumb = [
    {label: "Home" , link: "/#/app/dashboard"},
    {label: "Profile" , link: ""},
  ] 

  return <>
  <BreadCrumbs data={breadcrumb} />
  <Grid>
    <Paper elevation={10} className={classes.paperStyle}>
      <Grid align="center">
        <Avatar className={classes.AvatarStyle}></Avatar>
        <h2>Profile</h2>
      </Grid>
      <TextField
        variant="standard"
        id="username"
        margin="normal"
        value={name}
        onChange={e => setName(e.target.value)}
        placeholder="User Name"
        type="text"
        fullWidth />
      <TextField
        variant="standard"
        id="email"
        margin="normal"
        value={email}
        onChange={e => setEmail(e.target.value)}
        placeholder="Email Address"
        type="email"
        fullWidth />
      <Button  variant="contained" color="primary"  className={classes.Buttondesign} >
        Password Reset
      </Button>
    </Paper>
  </Grid>
  </>;
};

export default Profile;

import React, { useState } from 'react';
import {
    Button,
    Grid,
    Paper,
    CircularProgress
} from '@mui/material'

import {
    useForm,
    FormProvider,
} from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';


//components
import useStyles from "./styles";
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import NotificationContainer from '../notifications/NotificationContainer'
import BasicDetails from './pages/BasicTagDetails';
import { addTag } from './MarketplaceState';


function getSteps() {
    return ['Basic Details'];
}

function getStepContent(step, formData, setForm) {

    switch (step) {
        case 0:
            return <BasicDetails formData={formData} setForm={setForm} />
        default:
            return 'Unknown step';
    }
}

export default function AddTag(props) {
    // eslint-disable-next-line
    const { t, i18n } = useTranslation();
    const methods = useForm({
        defaultValues: {
            tagName: "",
        }
    })
    let history = useHistory();
    const [skippedSteps, setSkippedSteps] = useState([]);
    const [activeStep, setActiveStep] = useState(0);

    const [notification, setNotification] =useState({
        message: "",
        type: "",
        show: false,
        showLoader: false,
    });
    const steps = getSteps();
    const classes = useStyles();
    const breadcrumb = [
        { label: t("common_tags"), link: "/#/app/listTags" },
        { label: t("tag_add_new"), link: "" },
    ]

    const handleNext = (data) => {
        if (activeStep === steps.length - 1) {
            setNotification(prevState => ({
                ...prevState,
                showLoader: true,
            }));
            addTag(data).then(response => {
                if (response.status === 200) {
                    setNotification({
                        message: "Tag name added successfully",
                        type: "success",
                        show: true,
                        showLoader: true,
                    });
                    setTimeout(() => {
                        setNotification(prevState => ({
                            ...prevState,
                            showLoader: false, 
                        }));
                        history.push('/app/listTags');
                    }, 2000);
                } else if (response.status === 500) {
                    setNotification({
                        message: response.message,
                        type: "error",
                        show: true,
                        showLoader: false,
                    });
                }
                else {
                    let msg = []
                    if (response.ErrorData) {
                        // eslint-disable-next-line
                        response.ErrorData.details.map(item => {
                            msg.push(item.message)
                        })
                        setNotification({
                            message: msg.join(),
                            type: "error",
                            show: true,
                            showLoader: false,
                        });
                    } else {
                        setNotification({
                            message: "Something went Wrong! Please try again!",
                            type: "error",
                            show: true,
                            showLoader: false,
                        });
                    }
                }
               
            })
        } else {
            setActiveStep(activeStep + 1);
            setSkippedSteps(
                skippedSteps.filter((skipItem) => skipItem !== activeStep)
            );
        }
    }


    return (
        <>
            <BreadCrumbs data={breadcrumb} />
            <div className={classes.content}>
                <Grid container spacing={3} >
                    {(notification.show) ? <NotificationContainer message={notification.message} notificationtype={notification.type} /> : <></>}
                    <Grid item lg={3} md={1} xs={0} sm={0}></Grid>
                    <Grid item lg={6} md={10} xs={12} sm={12}>
                        <Paper className={classes.paperButton} classes={{
                        }}>
                            {
                                <FormProvider {...methods}>
                                    <form onSubmit={methods.handleSubmit(handleNext)}>
                                        <Grid container spacing={3} >
                                            <Grid item lg={2} md={2} sm={1} xs={1}></Grid>
                                            <Grid item lg={8} md={9} sm={10} xs={10} style={{ width: 'width: calc(100% - 2*15px)' }}>
                                                {getStepContent(activeStep)}
                                                <div className={classes.tagDiv}>
                                                <Button href="/#/app/listTags" className={classes.button}>
                                                    {t("common_back")}
                                                </Button>
                                                {
                                                    (notification.showLoader) ? <CircularProgress /> :
                                                        <Button className={classes.button} variant="contained" color="primary" type="submit" >
                                                            {activeStep === steps.length - 1 ? t("common_finish") : t("common_next")}
                                                        </Button>
                                                }
                                                  </div>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </FormProvider>
                            }
                        </Paper>
                    </Grid>
                    <Grid item lg={3} md={1} xs={1} sm={0}></Grid>
                </Grid>
            </div>
        </>
    )
}

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SelectUserDialog from "./SelectUserDialog";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Card,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import SelectMode from "./SelectMode";
import QuestionsDialog from "./QuestionsDialog";
import classnames from "classnames";
import { CloudDownload, DeleteOutline } from "@mui/icons-material";
import useStyles from "../styles";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import {
  getFile,
  statusUpdate,
  getRecyclerUsersUnderAdmin,
  getAllCommunicationMethod,
  getAllinquiryLeadsStatus,
} from "../InquiryLeadsState";
import NotificationContainer from "../../notifications/NotificationContainer";
import { createMprId } from "../InquiryLeadsState";
import ClipboardCopy from "./ClipboardCopy";
import ViewInquiryDetails from "./ViewInquiryDetails";
import ConfirmDialog from "./ConfirmDialog";

const EditInquiryLead = () => {
  const history = useHistory();
  var classes = useStyles();
  const { t, i18n } = useTranslation();
  const InquiryLeadData = useSelector((state) => state);
  var usersData = useSelector((state) => state.userdata);

  const [inquiryLeadData, setInquiryLeadData] = useState([
    InquiryLeadData.selectedInquiryLead,
  ]);
  //for getting user roles
  let dispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loggedUserId, setLoggedUserId] = useState("");
  const [status, setStatus] = useState(inquiryLeadData[0]?.status);

  const checkUserRoles = (usersData) => {
    if (usersData && usersData.getData) {
      if (
        usersData.getData.type === "tenant" &&
        !usersData.getData.roles.includes("user")
      ) {
        //
        setIsAdmin(true);
        setLoggedUserId(usersData?.getData?._id);
      } else {
        setIsAdmin(false);
      }
    }
  };

  //notifications
  const [type, setType] = useState("");
  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");

  const [showLoader, setShowLoader] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [inquiryLeadsStatus, setInquiryLeadsStatusStatus] = useState([]);
  //mode of addressing and followup modal
  const [options, setoptions] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedModes, setSelectedModes] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Mode Of Follow up");

  const [mprID, setMprID] = useState(inquiryLeadData[0]?.materialRequestID);
  const [disabledStatus, setDisabledStatus] = useState(false);

  const getInquiryLeadsStatus = async () => {
    try {
      setStatusLoading(true);
      const response = await getAllinquiryLeadsStatus();

      if (response.status === "200") {
        const backendStatusOptions = response.data.data[0].inquiryStatus;
        if (
          usersData &&
          usersData.getData &&
          usersData.getData.type === "tenant" &&
          usersData.getData.roles.includes("user")
          // inquiryLeadData[0]?.statusHistory[0]?.owner[0]?.length != 0
        ) {
          const filteredOptions = backendStatusOptions.filter(
            (option) => option !== "Unassigned"
          );
          setInquiryLeadsStatusStatus(filteredOptions);
        } else {
          setInquiryLeadsStatusStatus(backendStatusOptions);
        }
        setStatusLoading(false);
      } else if (response.status === "401") {
        setType("error");
        setMessage(response.message);
        setNotify(true);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      }
    } catch (error) {
      setInquiryLeadsStatusStatus(["No status available"]);
      setStatusLoading(false);
    }
  };
  let leadOwnerId = inquiryLeadData[0]?.statusHistory[0]?.owner[0]?._id;
  let currentLoggedInUser = usersData?.getData?._id;
  const disableStatus = () => {
    if (leadOwnerId !== currentLoggedInUser || leadOwnerId === undefined) {
      setDisabledStatus(true);
    } else if (inquiryLeadData[0]?.status === "Unassigned") {
      setDisabledStatus(true);
    } else {
      setDisabledStatus(false);
    }
  };

  useEffect(() => {
    checkUserRoles(usersData);
    disableStatus();
    getInquiryLeadsStatus();
    if (inquiryLeadData[0] == undefined) {
      history.push("/app/inquiryLeads");
    }
  }, [loggedUserId]);

  ////////// for selectMode dialog
  const getCommunicationMethods = async () => {
    try {
      const response = await getAllCommunicationMethod();
      if (response.status === "200") {
        const responseModes = response.data;
        const activeModes = responseModes?.filter(
          (item) => item.isActive === true
        );
        setoptions(activeModes);
      } else if (response.status === "401") {
        setType("error");
        setMessage(response.message);
        setNotify(true);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      }
    } catch (error) {
      setoptions(["No data available"]);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    getCommunicationMethods();
  };

  const handleCloseDialog = () => {
    setSelectedModes([]);
    setTextareaValue("");
    setStatus(inquiryLeadData[0].status);
    setOpenDialog(false);
  };

  const clearAll = () => {
    setSelectedModes([]);
    setTextareaValue("");
    setOpenDialog(false);
    setShowLoader(false);
    setQuestionsDialogOpen(false);
    setTitle("");
    setDateLabel("");
  };
  const handleSelectModeChange = (event) => {
    setSelectedModes(event.target.value);
  };
  const updateStatus = async (data) => {
    setShowLoader(true);
    await statusUpdate(data).then((response) => {
      if (response.status === "200") {
        setType("success");
        setMessage("Successfully updated!");
        setNotify(true);
        setStatus(data.status);
        clearAll();
        handleConfirmDialogClose();
        setTimeout(() => {
          setNotify(false);
        }, 2000);
      } else if (response.status === "401") {
        setType("error");
        setNotify(true);
        setMessage(response.message);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
        handleConfirmDialogClose();
      } else {
        setType("error");
        setStatus(data.status);
        setStatus(inquiryLeadData[0].status);
        setNotify(true);
        clearAll();
        handleConfirmDialogClose();
      }
    });
    setShowLoader(false);
  };

  function getSelectedModeIds(data, selectedModes) {
    const selectedModeIds = data
      .filter((item) => selectedModes.includes(item.mode))
      .map((item) => item._id);
    return selectedModeIds;
  }

  const handleConfirm = () => {
    const selectedModeIds = getSelectedModeIds(options, selectedModes);
    if (status === "Addressed" && selectedModes.length > 0) {
      let data = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        // owner: usersData.getData._id,
        modeOfAddressing: {
          addressing: selectedModeIds,
          notes: textareaValue,
        },
      };
      updateStatus(data);

      // clearAll();
    } else if (status === "Followed Up" && selectedModes.length > 0) {
      let data = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        // owner: usersData.getData._id,
        modeOfFollowup: {
          followup: selectedModeIds,
          notes: textareaValue,
        },
      };
      updateStatus(data);

      // clearAll();
    } else {
      setStatus(inquiryLeadData[0].status);
    }
  };
  ////////////////////////////////////////////////////////

  //to assign and reassign user to a lead
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [recyclerUsers, setRecyclerUsers] = useState([]);
  const [userAssignTitle, setUserAssignTitle] = useState("Assign To");
  const [currentAssignedUser, setCurrentAssignedUser] = useState(
    inquiryLeadData[0]?.statusHistory[0]?.owner[0]?.userName
  );
  const fetchRecyclerUsersUnderAdmin = async () => {
    try {
      const response = await getRecyclerUsersUnderAdmin();
      if (response.status === "200") {
        setRecyclerUsers(response.userDetail);
      }
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleDialogConfirmationCancel = () => {
    handleConfirmDialogClose();
  };

  const handleDialogConfirmation = () => {
    handleMoveToTrash();
  };

  const handleMoveToTrash = () => {
    let data = {
      inquiryId: inquiryLeadData[0]._id,
      status: "Rejected",
      // owner: loggedUserId,
      adminId: loggedUserId,
      rejected: {
        notes: "Trash data entered by user",
      },
    };
    updateStatus(data);
  };

  const handleUserAssignOpen = () => {
    setUserAssignTitle("Assign To");
    setOpen(true);
    fetchRecyclerUsersUnderAdmin();
  };
  const handleUserReAssignOpen = () => {
    setUserAssignTitle("Reassign To");
    setOpen(true);
    fetchRecyclerUsersUnderAdmin();
  };

  const handleClose = () => {
    setOpen(false);
    setShowLoader(false);
  };

  const handleUserSelect = (value) => {
    setSelectedUser(value);
  };

  const statusUpdateToAssignUser = async (data) => {
    try {
      setShowLoader(true);
      const response = await statusUpdate(data);
      if (response.status === "200") {
        setMessage("User Assigned Successfully");
        setType("success");
        setNotify(true);
        handleClose();
        setTimeout(() => {
          setShowLoader(false);
          setNotify(false);
          history.push("/app/inquiryLeads");
        }, 1000);
      } else if (response.status === "401") {
        setShowLoader(false);
        setType("error");
        setNotify(true);
        handleClose();
        setMessage(response.message);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      }
    } catch (error) {
      setShowLoader(false);
      setMessage(error.message);
      setType("error");
      setNotify(true);
      handleClose();
      setTimeout(() => {
        setNotify(false);
      }, 1000);
    }
  };

  const handleSelectedUserConfirm = () => {
    const assignData = {
      inquiryId: inquiryLeadData[0]._id,
      owner: selectedUser._id,
      adminId: loggedUserId,
    };
    if (userAssignTitle === "Reassign To") {
      assignData.status = status;
    } else {
      assignData.status = "Assigned";
    }
    statusUpdateToAssignUser(assignData);
  };

  const [quotationStatusUpdateData, setQuotationStatusUpdateData] = useState(
    {}
  );

  /////////////////////////////////////////////////////////////fun to generate mpr id
  const generateMPRid = (callback) => {
    let sendData = {
      abbrevation: inquiryLeadData[0]?.companyName,
    };

    createMprId(sendData, dispatch)
      .then((response) => {
        setMprID(response?.MPRID);
        setQuotationStatusUpdateData({
          inquiryId: inquiryLeadData[0]._id,
          status: "Quotation Sent",
          materialRequestID: response?.MPRID,
        });
        callback();
      })
      .catch((error) => {
        console.error("Error:", error);
        setMprID("Unable to generate MPR ID try again!!!!");
        callback();
      });
  };

  /////////////for Questions dialog
  const [questionsDialogOpen, setQuestionsDialogOpen] = useState(false);
  const [dialogOptionsRenderCondition, setDialogOptionsRenderCondition] =
    useState("");
  const [title, setTitle] = useState("Custom Dialog Title");
  const [dateLabel, setDateLabel] = useState("Custom Date Label");
  const [modeLabel, setModeLabel] = useState("Select Mode");

  const handleOpenQuestionsDialog = (dialogOpenPrompt) => {
    getCommunicationMethods();
    setDialogOptionsRenderCondition(dialogOpenPrompt);
    if (dialogOpenPrompt === "awaiting_response") {
      setTitle("When was the last time you reached out to the customer?");
      setDateLabel("Select Date and Time");
      setQuestionsDialogOpen(true);
    } else if (dialogOpenPrompt === "field_visit_planned") {
      setTitle("When are you planning to do the field visit?");
      setDateLabel("Scheduled Date");
      setQuestionsDialogOpen(true);
    } else if (dialogOpenPrompt === "field_visit_completed") {
      setTitle("When did you do the field visit?");
      setDateLabel("Completed Date:");
      setQuestionsDialogOpen(true);
    } else if (dialogOpenPrompt === "followup_requested") {
      setTitle("When is follow up requested?");
      setDateLabel("Requested Date:");
      setQuestionsDialogOpen(true);
    } else if (dialogOpenPrompt === "quotation_sent") {
      if (!inquiryLeadData[0].materialRequestID) {
        setTitle("Adding Material Pickup Request ID");
        generateMPRid(() => {
          setQuestionsDialogOpen(true);
        });
      } else {
        setTitle("Existing Material Pickup Request ID");
        setQuestionsDialogOpen(true);
        setQuotationStatusUpdateData({
          inquiryId: inquiryLeadData[0]._id,
          status: "Quotation Sent",
        });
      }
    } else if (dialogOpenPrompt === "accepted") {
      setTitle(
        "Are you certain you want to proceed with accepting this inquiry?"
      );
      setQuestionsDialogOpen(true);
    } else if (dialogOpenPrompt === "rejected") {
      setTitle("Reason for rejection");
      setQuestionsDialogOpen(true);
    }
  };
  const handleCloseQuestionsDialog = () => {
    setQuestionsDialogOpen(false);
    setStatus(inquiryLeadData[0].status);
  };
  function convertDateToISOString(inputDateString) {
    const inputDate = new Date(inputDateString);
    return inputDate.toISOString();
  }

  const handleQuestionDataConfirm = (data) => {
    if (status === "Awaiting Response") {
      const selectedModeIds = getSelectedModeIds(options, data?.selectedModes);
      const outputDateString = convertDateToISOString(
        data.selectedDate?.toString()
      );
      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        // owner: usersData.getData._id,
        awaitingResponse: {
          modeOfContact: selectedModeIds,
          dateAndTimeOfLastContact: outputDateString,
          notes: data?.textFieldValue,
        },
      };
      updateStatus(statusUpdateData);
    }
    if (status === "Field Visit Planned") {
      const outputDateString = convertDateToISOString(
        data.selectedDate?.toString()
      );
      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        // owner: usersData.getData._id,
        fieldVisitPlanned: {
          dateAndTime: outputDateString,
          notes: data?.textFieldValue,
        },
      };
      updateStatus(statusUpdateData);
    }
    if (status === "Field Visit Completed") {
      const outputDateString = convertDateToISOString(
        data.selectedDate?.toString()
      );
      const extractedImages = data.uploadedImages?.map((image) => {
        return {
          fileName: image.fileName || image.filename,
          folderName: image.folderName || image.folder || image.FolderName,
        };
      });
      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        // owner: usersData.getData._id,
        fieldVisitCompleted: {
          feedback: data?.textFieldValue,
          dateAndTime: outputDateString,
          photos: extractedImages,
        },
      };
      updateStatus(statusUpdateData);
    }
    if (status === "Followup Requested") {
      const outputDateString = convertDateToISOString(
        data.selectedDate?.toString()
      );

      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        // owner: usersData.getData._id,
        followupRequested: {
          dateAndTime: outputDateString,
          notes: data?.textFieldValue,
        },
      };
      updateStatus(statusUpdateData);
    }
    if (status === "Quotation Sent") {
      updateStatus(quotationStatusUpdateData);
      // console.log(quotationStatusUpdateData);
    }
    if (status === "Accepted") {
      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
      };
      updateStatus(statusUpdateData);
    }
    if (status === "Rejected") {
      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        rejected: {
          notes: data?.textFieldValue,
        },
      };
      updateStatus(statusUpdateData);
    }

    // setConfirmedData(data);
  };
  //////////////////////////////////////////

  //////for handling the select status change
  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
    if (selectedStatus === "Addressed") {
      setDialogTitle("Mode Of Addressing");
      handleOpenDialog(true);
    }
    if (selectedStatus === "Awaiting Response") {
      handleOpenQuestionsDialog("awaiting_response");
    }

    if (selectedStatus === "Followed Up") {
      setDialogTitle("Mode Of Follow up");
      handleOpenDialog(true);
    }
    if (selectedStatus === "Followup Requested") {
      handleOpenQuestionsDialog("followup_requested");
    }
    if (selectedStatus === "Field Visit Planned") {
      handleOpenQuestionsDialog("field_visit_planned");
    }

    if (selectedStatus === "Field Visit Completed") {
      handleOpenQuestionsDialog("field_visit_completed");
    }
    if (selectedStatus === "Quotation Sent") {
      handleOpenQuestionsDialog("quotation_sent");
    }
    if (selectedStatus === "Accepted") {
      handleOpenQuestionsDialog("accepted");
      // updateStatus(statusUpdateData);
    }
    if (selectedStatus === "Rejected") {
      handleOpenQuestionsDialog("rejected");
    }
  };

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          setType("error");
          setMessage(response.message);
          setNotify(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setType("error");
        setMessage("An error occurred while downloading the file.");
        setNotify(true);
      });
  };

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("Inquiries"), link: "/#/app/inquiryLeads" },
    { label: t("Edit"), link: "" },
  ];
  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card>
                {/*  */}
                <Grid
                  container
                  spacing={1}
                  className={classes.HeaderContainerEdit}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className={classes.tenantHeader}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5">
                        {t("Edit")} {t("Inquiry")}
                      </Typography>
                      <ClipboardCopy copyText={inquiryLeadData[0]?.inquiryID} />
                    </div>
                    {!isAdmin && (
                      <div style={{ marginRight: "25px" }}>
                        <Button
                          variant="outlined"
                          onClick={() => history.push("/app/inquiryLeads")}
                          color="primary"
                          className={classes.tagButton}
                        >
                          {t("Back")}
                        </Button>
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    style={{
                      display: "flex",
                    }}
                  >
                    {isAdmin &&
                      inquiryLeadData[0]?.statusHistory[0]?.owner[0]
                        ?.userName && (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography variant="h5">
                              {t("Assigned to")}:
                            </Typography>
                            <Typography
                              variant="h5"
                              style={{
                                fontWeight: "400",
                                marginBottom: "0px",
                                marginLeft: "3px",
                              }}
                            >
                              {
                                inquiryLeadData[0]?.statusHistory[0]?.owner[0]
                                  ?.userName
                              }
                            </Typography>
                          </div>
                        </>
                      )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      justifyContent: "space-between",
                      paddingRight: "24px",
                    }}
                  >
                    {isAdmin &&
                      inquiryLeadData[0]?.statusHistory[0]?.owner[0]
                        ?.userName && (
                        <div>
                          <Button
                            variant="outlined"
                            onClick={() => handleUserReAssignOpen()}
                            color="primary"
                            className={classes.tagButton}
                            style={{ marginRight: "12px" }}
                          >
                            {t("common_reassign")}
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => history.push("/app/inquiryLeads")}
                            color="primary"
                            className={classes.tagButton}
                          >
                            {t("Back")}
                          </Button>
                        </div>
                      )}
                    {isAdmin &&
                      !inquiryLeadData[0]?.statusHistory[0]?.owner[0]
                        ?.userName && (
                        <div>
                          <Tooltip title="Move to trash">
                            <Button
                              variant="outlined"
                              onClick={() => handleConfirmDialogOpen()}
                              color="primary"
                              className={classes.deleteButton}
                              style={{
                                marginRight: "12px",
                              }}
                              disabled={status == "Rejected"}
                            >
                              {showLoader ? (
                                <CircularProgress
                                  size={22}
                                  className={classes.deleteButton}
                                />
                              ) : (
                                <DeleteOutline />
                              )}
                            </Button>
                          </Tooltip>
                          <Button
                            variant="outlined"
                            onClick={() => handleUserAssignOpen()}
                            color="primary"
                            className={classes.tagButton}
                            style={{ marginRight: "12px" }}
                          >
                            {t("common_assign")}
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => history.push("/app/inquiryLeads")}
                            color="primary"
                            className={classes.tagButton}
                          >
                            {t("Back")}
                          </Button>
                        </div>
                      )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            {notify ? (
              <NotificationContainer
                message={message}
                notificationtype={type}
              />
            ) : (
              <></>
            )}
            <Paper
              className={classes.paper}
              classes={{
                root: classnames(classes.widgetRoot, {
                  [classes.noWidgetShadow]: null,
                }),
              }}
            >
              <Paper
                className={classes.viewLeads}
                // elevation={3}
                style={{ padding: "18px" }}
              >
                <Grid container spacing={3} className={classes.viewDiv}>
                  <Grid item lg={7} md={7} xs={12} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={12}>
                        <Typography
                          variant="h5"
                          style={{ marginLeft: "10px", marginBottom: "12px" }}
                        >
                          {t("Details")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Paper elevation={3}>
                      <Table className={classes.table}>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              <b>{t("name")}: </b> <br />
                              {inquiryLeadData[0]?.name
                                ? inquiryLeadData[0]?.name
                                : "N/A"}
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              <b>{t("Company")}: </b> <br />
                              {inquiryLeadData[0]?.companyName
                                ? inquiryLeadData[0]?.companyName
                                : "N/A"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              <b>{t("common_email")}: </b> <br />
                              {inquiryLeadData[0]?.email
                                ? inquiryLeadData[0]?.email
                                : "N/A"}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <b>{t("Contact no")}: </b> <br />
                              {inquiryLeadData[0]?.contact
                                ? inquiryLeadData[0]?.contact
                                : "N/A"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              <b>{t("Message")}: </b> <br />
                              {inquiryLeadData[0]?.shortNotes}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <b>{t("common_status")}: </b> <br />
                              <FormControl variant="standard" fullWidth>
                                <Select
                                  variant="standard"
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={status}
                                  label="Status"
                                  disabled={disabledStatus || statusLoading}
                                  onChange={handleStatusChange}
                                >
                                  {statusLoading ? (
                                    <MenuItem>Loading...</MenuItem>
                                  ) : (
                                    inquiryLeadsStatus.map((option) => (
                                      <MenuItem
                                        key={option}
                                        value={option}
                                        disabled={
                                          option === "Assigned" ||
                                          option === "Unassigned"
                                        }
                                        style={{
                                          color:
                                            option === "Assigned" ? "gray" : "",
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))
                                  )}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={classes.tableCell}>
                              <b>{t("Inquiry type")}: </b> <br />
                              {inquiryLeadData[0]?.inquiryType
                                ? inquiryLeadData[0]?.inquiryType
                                : "N/A"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                  <Grid item lg={5} md={5} xs={12} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item lg={12}>
                        <Typography
                          variant="h5"
                          style={{ paddingLeft: "8px", marginBottom: "12px" }}
                        >
                          {t("common_attachment")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Paper elevation={3}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <b>{t("common_file")} </b>
                            </TableCell>
                            <TableCell>
                              <b>{t("common_action")}</b>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inquiryLeadData[0]?.attachmentDetails?.length > 0 ? (
                            inquiryLeadData[0].attachmentDetails.map((item) => {
                              return (
                                <TableRow key={item?.fileName}>
                                  <TableCell> {item.fileName}</TableCell>
                                  <TableCell>
                                    <IconButton
                                      className={classes.deleteicon}
                                      onClick={() => handleDownload(item)}
                                      size="large"
                                    >
                                      <CloudDownload />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <TableRow>
                              <TableCell colSpan={2}>No Data Found</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Paper>
          </Grid>
        </Grid>

        {/* statushistory table */}
        <div>
          <ViewInquiryDetails inquiryData={inquiryLeadData[0]} />
        </div>
      </div>

      <SelectUserDialog
        open={open}
        handleClose={handleClose}
        users={recyclerUsers}
        handleUserSelect={handleUserSelect}
        handleSelectedUserConfirm={handleSelectedUserConfirm}
        showLoader={showLoader}
        title={userAssignTitle}
        currentAssignedUser={currentAssignedUser}
      />
      <SelectMode
        openDialog={openDialog}
        handleDialogClose={handleCloseDialog}
        title={dialogTitle}
        options={options}
        selectedModes={selectedModes}
        onModeChange={handleSelectModeChange}
        showTextarea={true}
        textareaValue={textareaValue}
        onTextareaChange={(e) => setTextareaValue(e.target.value)}
        onConfirm={handleConfirm}
        showLoader={showLoader}
      />
      <QuestionsDialog
        open={questionsDialogOpen}
        onClose={handleCloseQuestionsDialog}
        title={title}
        dateLabel={dateLabel}
        modeLabel={modeLabel}
        modeOptions={options}
        onConfirm={handleQuestionDataConfirm}
        dialogOptionsRenderCondition={dialogOptionsRenderCondition}
        showLoader={showLoader}
        mprID={mprID}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Moving to trash"
        description="Are you sure you want to proceed?"
        onCancel={handleDialogConfirmationCancel}
        onConfirm={handleDialogConfirmation}
        isLoading={showLoader}
      />
    </>
  );
};

export default EditInquiryLead;

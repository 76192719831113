import React, { useState } from 'react';
import { Typography } from "../../components/Wrappers/Wrappers";
import { Grid, Button, Card } from "@mui/material";
import { useTranslation } from 'react-i18next';

//components
import useStyles from "./styles";
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import ViewTags from './components/ViewTags';


export default function Tags(props) {
    // eslint-disable-next-line
    const { t, i18n } = useTranslation();
    var classes = useStyles();

    const breadcrumb = [
        { label: t("common_home"), link: "/#/app/dashboard" },
        { label: t("common_lot"), link: "/#/app/marketplace" },
        { label: t("common_tags"), link: "" },
    ]

    return (
        <>
            <BreadCrumbs data={breadcrumb} />
            <div className={classes.content}>
                <div className={classes.pageTitleContainer}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Card>
                             <div className={classes.HeaderContainer} >
                                    <div className={classes.tenantHeader}>
                                        <Typography variant="h3" size="sm"> {t("common_tags")}</Typography>    
                                    </div>
                                    <div className={classes.divAlign}>
                                       <Button variant="outlined" href="/#/app/addTags" color="primary" className={classes.buttonOutlined} style={{marginRight:"10px"}}>
                                            {t("tag_add_new")}
                                        </Button>
                                        <Button variant="outlined" href="/#/app/marketplace" color="primary" className={classes.buttonOutlined}>
                                            {t("common_back")}
                                        </Button>
                                    </div>
                                </div>
         
                            </Card>
                        </Grid>
                    </Grid>
                </div>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <ViewTags />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

import React, { useState } from "react";
import { Grid, Paper, CircularProgress } from '@mui/material';
import classnames from "classnames";
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import '../style.css'
import FileBase64 from 'react-file-base64';
import useStyles from "../styles";
import { fileUpload } from "../MarketplaceState";

const ImageUpload = ({ setForm, formData }) => {

  const [uploadedFile, setUploadedFile] = useState(formData.files)
  const [uploadedFileReference, setUploadedFileReference] = useState(formData.filesWithReference)
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false)
  const [imageAlert, setImageAlert] = useState(false)
  const { t, i18n } = useTranslation();
  var newArray = [...uploadedFileReference]
  

  const handleImageChange = async (e) => {
    await e.map(async (item, key) => {
      if (item.type === "image/png" || item.type === "image/jpeg" || item.type === "image/jpg") {
        setShowLoader(true)
        setImageAlert(false)
        var fileName = item.name
        var fileType = fileName.split('.').pop()
        var baseData = item.base64.split(',')
        let getTokenData = {
          "abbrevation": Math.floor(Math.random() * 100) + "Mrkplc",
          "fileType": fileType,
          "folderName": "mrkplc/",
          "expireLimt": 1800,
          "extension": fileName.split('.').pop()
        }
        getAwsResponse(baseData, getTokenData, e)
      }
      else {
        setImageAlert(true)
      }
    })

  };

  const getAwsResponse = async (baseData, getTokenData, e) => {
    await fileUpload((baseData.length > 0) ? baseData[1] : "", getTokenData, e.type).then(async (response) => {
      if (response.status === "200") {
        const objwithOutRef = { fileName:response.fileName || response.filename, imageId: response.fileName || response.filename, folderName: response.folderName || response.folder || response.FolderName,}
        formData.files.push(objwithOutRef)
        setUploadedFile(uploadedFile => [...uploadedFile, objwithOutRef])
        setShowLoader(false)
        const objwithRef = { fileName:response.fileName || response.filename, ref: response.reference, imageId: response.fileName  || response.filename, folderName: response.folderName || response.folder || response.FolderName,}
        formData.filesWithReference.push(objwithRef)
        newArray = formData.filesWithReference
        setUploadedFileReference([...newArray])
        if (newArray.length === uploadedFileReference.length) { }
        setShowLoader(false)
      } else {
        setShowLoader(false)
      }
    })
  }

  const deleteFile = (data, index) => {
    let array = [...uploadedFileReference];
    const newArray = array.filter(m => m.imageId !== data.imageId);
    setUploadedFileReference(newArray)
    formData.filesWithReference = newArray
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} md={3} xs={0} sm={0} ></Grid>
        <Grid item lg={6} md={6} xs={12} sm={12}>
          <Paper className={classes.paper} classes={{
            root: classnames(classes.widgetRoot, {
              [classes.noWidgetShadow]: null
            })
          }}>
            <div className={classes.form}>
              <Grid container spacing={3}>
                <Grid 
                item 
                lg={12} 
                md={12} 
                sm={12} 
                xs={12} 
                // style={{ width: 'width: calc(100% - 2*15px)' }} 
                >
                  <span>{t("common_upload_details")}</span>
                  <Grid container spacing={2} > 
                    <Grid item lg={11} md={11} sm={12} xs={12}>
                      <div className={classes.fileTypeInput}>
                        <FileBase64
                          className={classes.input}
                          multiple={true}
                          onDone={(e) => handleImageChange(e)}
                        />
                      </div>
                      {(imageAlert) ? <span className={classes.spanAlert}>Please upload .png,.jpg,.jpeg image formats</span> : <></>}
                    </Grid>
                    <Grid item lg={4} md={4} sm={2} xs={2}>
                      <label htmlFor="lot-file">
                        {(showLoader) ? <CircularProgress /> : <></>}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <div className="result" style={{marginTop:"1rem"}}>
        {uploadedFileReference.length > 0 &&
          uploadedFileReference.map((data, index) => {
            return (
              <div className="img-wrap">

                <img src={data.ref} alt="" className="image-wrapper" key={index}/>
                {/* <button type="button" onClick={() => deleteFile(index)}>
                  delete
                </button> */}
                <CancelIcon className="close" onClick={() => deleteFile(data, index)} />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ImageUpload;
import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  content:{
    padding: theme.spacing(4),
    paddingTop: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1), 
      paddingTop: theme.spacing(10),
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonDiv:{
    float: 'right',
    marginTop:theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form:{
    padding: theme.spacing(4),
    paddingTop:theme.spacing(0),
    marginTop: '3rem',
    [theme.breakpoints.up("md")]: {
      // paddingTop:theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      // padding: theme.spacing(0),
    },
  },
  form2:{
    padding: theme.spacing(4),
    paddingTop:theme.spacing(0),
    marginTop: '1.2rem',
  },
  title :{
    font: '400 2.125rem / 1.17647059 Roboto,RobotoDraft,sans-serif',
    fontWeight: '300',
    margin: '16px 0 36px 0',
    textAlign: 'left',
  },
  label:{
      textAlign: 'left',
      marginTop: '2rem',
      marginBottom: '1rem'
  },
  stepper: {
    marginTop:theme.spacing(5),
  },
  paperButton:{
    display:"flex",
    alignItems:"center",
    justifyContent:'flex-end',
    marginTop:'1rem',
    padding:'1rem',

  },
  finish:{
    color: '#000',
    textDecoration: 'none',
    "&:hover": {
      textDecoration:'none',
    }
  },
  HeaderContainer:{
    padding:theme.spacing(1),
    margin:theme.spacing(1),
    display:'flex',
    justifyContent: "space-between",
    [theme.breakpoints.up('sm')]: {
      alignItems:'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  littleMargin:{
    [theme.breakpoints.down('sm')]: {
      marginBottom:"10px"
    },
  },
  tenantHeader:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent:'flex-start',
      marginBottom:'14px'
    },
  },
  tenantSubHeader:{
    paddingTop: theme.spacing(0.5)
  },
  tabpanel:{
    backgroundColor: "#008040"
  },
  addicon:{
    margin: '16px 0 32px 0',
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDivider: {
    flexGrow: 1,
    height: 3,
    backgroundColor: theme.palette.primary.main,
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontWeight:'700'
  },
  avatarColor:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  deleteicon:{
    color: theme.palette.danger.main,
  },
  contactForm:{
    // marginTop: '2rem',
    padding:"1rem",
    borderRadius:"10px",
  },
  formPaper:{
    // border:'1px dashed',
    borderColor:theme.palette.primary.main,
    padding:'1rem'
  },
  cardPaper:{
    border:'1px solid',
    borderColor:theme.palette.primary.main,
    padding:"10px"
  },
  fileTypeInput:{
    display: "inline-block", 
    wordWrap: "break-word",
    border:'1px solid',
    borderColor:theme.palette.primary.main,
    padding:'7px',
    borderRadius:'4px',
  },
  buttonOutlined:{
    color: theme.palette.primary.main,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    marginLeft:'1rem',
    "&:hover" : {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText
    }
},
  icons :{
    color: theme.palette.primary.main
  },
  lightBg :{
    backgroundColor:"#fff",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  blueBg: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  reviewCell:{
    border : '1px solid',
    borderColor : theme.palette.primary.main,
    wordWrap: 'break-word',
    maxWidth:"188px"
  },
  reviewContainer:{
    border : '1px solid',
    borderColor : theme.palette.primary.main,
    width:'calc(100% - 2*15px)',
    padding:'unset !important',
    marginBottom: theme.spacing(3)
  },
  input:{
    margin:"14px 0"
  },
  form1: {
    padding: theme.spacing(4),
    marginTop: '2rem',
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(1), 
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1), 
      paddingBottom : theme.spacing(1), 
    },
  },
  slider:{
    display:"flex",
    alignItems:'center',
    justifyContent:"space-between",
    marginTop: theme.spacing(2.4), 
    marginBottom:theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      flexDirection:"column",
      alignItems:"flex-start",
    },
  },
  spacing:{
    [theme.breakpoints.down("md")]: {
      marginBottom:theme.spacing(4),
    },
  }
}));
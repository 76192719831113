import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    Collapse, 
    IconButton,
    Button,
    Tooltip, 
    CircularProgress,
    FormControlLabel,
    Typography
} from '@mui/material'

import {
    ExpandMore as DropDownIcon,
    ExpandLess as DropUpIcon
}  from '@mui/icons-material';
import {List as ListIcon} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {useDispatch} from 'react-redux'

import useStyles from "./styles";
import {StyledBadge,TealSwitch} from './styles'
import {assignLead} from '../../LeadState'
import CalenderView from '../CalenderView'
import Table from '../../../../components/Table/Table'
import NotificationContainer from '../../../notifications/NotificationContainer'



const MPRSlot  = ({list,slotData,lead,close,choosen,inspectionClose}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const dispatch = useDispatch()
    const [isGrid , setisGrid] = useState(false)
    const [collapse,setCollapse] = useState(true)
    const [slot,setSlot] = useState([])
    const [showLoader,setShowLoader] = useState(false)
    const [message,setMessage] = useState("")
    const [notification,setNotification] = useState(false)
    const [type,setType] = useState("")
    const [switchStatus,setSwitchStatus] = useState(false)
    // eslint-disable-next-line
    const [switchDisable , setSwitchDisable] = useState(false)

    const handleChange = (e) => {
        setSwitchStatus(e.target.checked)
        if(switchStatus === false){
            let data = {
                external_id : lead,
                leadData:{
                    "status": "Inspection completed",
                    "inspectionCompleted":true
                },
                mpr_id:list.Mpr_id
            }
            apiCall(data,"Successfully Updated the Status")
        }
    }

    const handleRequest = (date,time) => {
        setShowLoader(true)
            let data = {
                external_id : lead,
                leadData:{
                    "inspection_slot":{
                        "inspectionDate": date,
                        "time": time,
                        "status":"selected"
                    },
                "status": "Inspection Slot choose"
                },
                mpr_id:list.Mpr_id
            }
            apiCall(data,"Successfully Sent Inspection Request")
    }

    const apiCall = (data,mes) => {
        if(lead){
            assignLead(data,dispatch).then(response => {
                if(response.status === "200"){
                    setType("success")
                    setMessage(mes)
                    setNotification(true)
                    setShowLoader(false)
                    setTimeout(()=>close(),2000)
                }else{
                    setType("error")
                    setMessage("Something went wrong")
                    setNotification(true)
                    setShowLoader(false)
                }
            })
        }else{
            setType("error")
            setMessage("Something went wrong")
            setNotification(true)
            setShowLoader(false)
        }
    }
   
    useEffect(() => {
        
        let data  = []
        let action = ""
        if(list.inspectionCompleted){
            setSwitchStatus(true)
            setSwitchDisable(true)
        }

        if(slotData.length > 0){
            // eslint-disable-next-line
            slotData.map(item => {

                if(item.slotInDetail.flag === true && item.slotStatus === "NA" ){
                    action = <Button size="small"  variant="outlined" disabled = {(list.status === "Inspection Slot choose" || list.status === "Slot Accepted" || list.inspectionCompleted)?true:false}  color="primary" onClick={() => handleRequest(item.slotInDetail.slots.inspectionDate,item.slotInDetail.slots.time)} className={classes.buttonOutlined} style={{marginLeft:"0px",marginRight:"0px"}}>
                                     {t("mpr_send_request")} 
                             </Button>
                }
                else if (item.slotInDetail.flag === false ){
                 action = <Button size="small"  variant="outlined" disabled={true}  color="primary" onClick={() => handleRequest(item.slotInDetail.slots.inspectionDate, item.slotInDetail.slots.time)}   className={classes.buttonOutlined} style={{marginLeft:"0px",marginRight:"0px"}}>
                                 {t("mpr_send_request")} 
                             </Button>
                }
                else if ( item.slotInDetail.flag === true && item.slotStatus === "selected"){
                    action = <span className={classes.choosedSlotSpan}>{t("mpr_waiting_approval")}</span>
                }
                else if (item.slotInDetail.flag === true && item.slotStatus === true){
                    action = <>
                       <span className={classes.choosedSlotSpan}>{t("common_accepted")}</span>
                        <div style={{ display: 'block' }}>
                        <FormControlLabel
                        control={<TealSwitch checked={switchStatus} color="primary" disabled={false} onChange={handleChange} name="inspection_complete" />}
                        // label={t("common_inspection_completed")}
                        label={
                            <Typography sx={{
                                fontSize: {
                                  lg: 16, 
                                  sm: 14, 
                                  xs: 12
                                },
                              }}>
                             {t("common_inspection_completed")}
                            </Typography>
                          }
                        />
                        </div>

                    </>
                }
                else if ( item.slotInDetail.flag === true && item.slotStatus ===  false){
                    action = <span className={classes.rejectedSlotSpan}>{t("common_rejected")}</span>
                }
                data.push([
                    item.slotInDetail.slots.inspectionDate,
                    item.slotInDetail.slots.time,
                    (showLoader)?<CircularProgress style={{color:"#0D6937"}} /> : action
                ])
            })
        } else if(choosen?.length > 0){
            // eslint-disable-next-line
            choosen?.map(item => {
                data.push([
                    item.slots.inspectionDate,
                    item.slots.time,
                    (showLoader) ?<CircularProgress style={{color:"#0D6937"}}/> : <Button size="small"  variant="outlined" disabled={!item.flag}  color="primary" onClick={() => handleRequest(item.slots.inspectionDate, item.slots.time)} className={classes.buttonOutlined}>
                                 {t("mpr_send_request")} 
                    </Button> 
                ])
            })
        }
       setSlot(data)
       // eslint-disable-next-line
    }, [slotData,showLoader,choosen,switchStatus])

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    const userHeader = [
        
        {
            label: t("mpr_inspection_date"),
            options: {
                filter: true,
            }
        },
        {
            label: t("mpr_time_range"),
            options: {
                filter: true,
            }
        },
        {
            name: t("common_action"),
            options: {
              filter: false,
              sort: false,
            }
          },
    ]

    return (
        <Paper className={classes.Datapaper}>
            {
                (notification)?<NotificationContainer message={message} notificationtype={type}/>:<></>
            }
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={8} md={8} xs={8} sm={8}>
                    <StyledBadge badgeContent={(list.MPRDetails) && list.MPRDetails.slot.slots.length} color="primary">
                        <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("common_inspection_slots")}</span>
                    </StyledBadge>
                </Grid>
                <Grid item xs={4} lg={4} md={4} sm={4} className={classes.iconGridWithButton}>
                    <Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} style={{marginRight:"3px"}} onClick={() => {inspectionClose()}}>{t("common_close")}</Button>
                    <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
                        {(collapse)?<DropDownIcon /> :<DropUpIcon />}
                    </IconButton>
                </Grid>         
            </Grid>
            <Collapse in={collapse}>
                <Grid cointainer spacing={3} className={classes.grid} >
                    <Grid item xs={12} className={classes.grid}>
                        {
                            (isGrid)?
                            <Paper className={classes.Datapaper} elevation={3}>
                                <Grid container spacing={1}>
                                    <Grid item lg={12}  className={classes.iconGrid}>
                                    <Tooltip title={t("common_list_view")}>
                                        <IconButton
                                            className={classes.collapseIcon}
                                            onClick={()=>setisGrid(!isGrid)}
                                            size="large">
                                            <ListIcon />
                                        </IconButton>
                                    </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item lg={12}>
                                        <CalenderView />
                                    </Grid>
                                </Grid>
                                
                            </Paper>: <Table 
                            data={slot} 
                            header={userHeader} 
                            name="" 
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                            }} />
                        }
                    </Grid>
                </Grid>
            </Collapse>

            
        </Paper>
    );
}
export default MPRSlot
import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    Collapse, 
    IconButton,
   
} from '@mui/material'
import {
    ExpandMore as DropDownIcon,
    ExpandLess as DropUpIcon
}  from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import Table from '../../../../components/Table/Table'
import useStyles from "./styles";



const SlotDetails = ({slotData}) => {
    const { t } = useTranslation();
    var classes = useStyles();
    const[collapse,setCollapse] = useState(true)
    const [slot,setSlot] = useState([])
    useEffect(() => {
        
        let data  = []
       // eslint-disable-next-line
        slotData.map(item=>{
           data.push([
              item.slots.inspectionDate ,
               item.slots.time

           ])
        })
        setSlot(data)
    }, [slotData])
    

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    const userHeader = [
        
        {
            label: t("mpr_inspection_date"),
            options: {
                filter: true,
            }
        },
        {
            label: t("common_time") +""+ t("common_slots"),
            options: {
                filter: true,
            }
        },
    ]

    return (
        <Paper className={classes.Datapaper}>
            <Grid container spacing={3} className={classes.grid}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
                    <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("mpr_slot_details")}</span>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} className={classes.iconGrid}>
                    <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
                        {(collapse)?<DropDownIcon /> :<DropUpIcon />}
                    </IconButton> 
                </Grid> 
            </Grid> 
            <Collapse in={collapse}>
                <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Table 
                            data={slot} 
                            header={userHeader} 
                            name="" 
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                                pagination:false
                            }} />
                    </Grid>  
                </Grid>
            </Collapse>         
        </Paper>
    );
}
export default SlotDetails
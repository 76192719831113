import { makeStyles } from "@mui/styles";
export default makeStyles(theme => ({
    notificationComponent: {
        paddingRight: theme.spacing(4),
    },
    progress: {
        visibility: "hidden",
    },
    notification: {
        display: "flex",
        alignItems: "center",
        background: "transparent",
        boxShadow: "none",
        overflow: "visible",
    },
    toastsContainer: {
        width: 400,
        marginTop: theme.spacing(6),
        right: 0,
        [theme.breakpoints.down("sm")]: {
            width: 340,
        },
    },
    notificationCloseButton: {
        position: "absolute",
        right: theme.spacing(2),
    },
    tostifyBody:{
        margin: "auto 0",
        flex: "1 1 auto",
        padding: "6px"
    },
    
}))
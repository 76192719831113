import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card, Tabs, Tab, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getInquiryLeads,
  getRecyclerUsersUnderAdmin,
  statusUpdate,
  getAllAssignedLeads,
  getAllAdminAssiginedLeads,
} from "../InquiryLeadsState";
import SelectUserDialog from "./SelectUserDialog";
import useStyles from "../styles";
import Table from "../../../components/Table/Table";
import TabPanel, {
  tableOptions,
  a11yProps,
  inquiryTableHeader,
  tabdata1,
  tabdata2,
  tabdata3,
} from "./tableConfig";
import NotificationContainer from "../../notifications/NotificationContainer";
export default function InquiryLeadView() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const [open, setOpen] = useState(false);
  const [tabData, setTabData] = useState([...tabdata3, ...tabdata2]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [inquiryLeads, setInquiryLeads] = useState([]);
  const [adminAssignedLeads, setAdminAssignedLeads] = useState([]);
  const [recyclerUsers, setRecyclerUsers] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [message, setMessage] = useState("");
  const [notify, setNotify] = useState(false);
  const [type, setType] = useState("");
  const [assigned, setAssigned] = useState(false);
  const [loggedUserId, setLoggedUserId] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const [pageData, setPageData] = useState({ page: 1, limit: 10 });
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 20, 50]);

  const editUnclassifiedLead = (data) => {
    dispatch({
      type: "SET_SELECTED_INQUIRY",
      payload: data,
    });

    history.push("/app/editInquiryLeads");
  };

  const handleViewDuplicate = (data) => {
    dispatch({
      type: "SET_SELECTED_DUPlICATE",
      payload: data,
    });
    history.push("/app/duplicateInquiries");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("TabValue", newValue);
  };

  var data = useSelector((state) => state.userdata);
  let isRUser = "";
  let isAuser = "";

  isRUser =
    data &&
    data.getData &&
    data.getData.type === "tenant" &&
    data.getData.roles.includes("user");

  isAuser =
    data &&
    data.getData &&
    data.getData.type === "tenant" &&
    !data.getData.roles.includes("user");

  const fetchInquiryLeads = async () => {
    try {
      setShowLoader(true);
      const response = await getInquiryLeads();
      if (response.status === "200") {
        setInquiryLeads(response.data);
        setShowLoader(false);
      } else if (response.status === "401") {
        setShowLoader(false);
        setType("error");
        setNotify(true);
        setMessage(response.message);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      }
    } catch (error) {
      setShowLoader(false);
      // console.error("API Error:", error);
    }
  };

  const fetchAllAdminAssiginedLeads = async (adminId) => {
    try {
      const response = await getAllAdminAssiginedLeads(adminId);
      if (response.status === "200") {
        setAdminAssignedLeads(response.data);
      }
    } catch (error) {}
  };

  //for recycler user login he will only get leads that are assigned to him
  const fetchAssignedInquiryLeadsToUser = async (data) => {
    try {
      setShowLoader(true);
      const response = await getAllAssignedLeads(data);
      if (response.status === "200") {
        setInquiryLeads(response.data);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const fetchRecyclerUsersUnderAdmin = async () => {
    try {
      const response = await getRecyclerUsersUnderAdmin();

      if (response.status === "200") {
        setRecyclerUsers(response.userDetail);
      }
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  const handleAssignDialogOpen = (dataItem) => {
    setSelectedUser(null);
    setOpen(true);
    setSelectedData(dataItem);
    fetchRecyclerUsersUnderAdmin();
  };

  useEffect(() => {
    setLoggedUserId(data?.getData?._id);

    if (isAuser) {
      setTabData([...tabdata1, ...tabdata2]);
      fetchInquiryLeads();
      fetchAllAdminAssiginedLeads({ adminId: data?.getData?._id });
    } else if (isRUser) {
      fetchAssignedInquiryLeadsToUser({
        ownerId: data?.getData?._id,
      });
    } else {
      return;
    }
  }, [isAuser, isRUser, assigned]);

  const handleUserSelect = (selectedUser) => {
    setSelectedUser(selectedUser);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Function to handle notifications
  const setNotification = (message, type, duration = 2000) => {
    setMessage(message);
    setType(type);
    setNotify(true);
    // setTimeout(() => setNotify(false), duration);
  };

  // Main function to update status and assign user
  const statusUpdateToAssignUser = async (userData) => {
    setShowLoader(true);

    try {
      const response = await statusUpdate(userData);

      switch (response.status) {
        case "200":
          setNotification("User Assigned Successfully", "success");
          setOpen(false);
          setTimeout(() => {
            fetchInquiryLeads();
            fetchAllAdminAssiginedLeads({ adminId: data?.getData?._id });
          }, 2000);
          break;
        case "400":
          setNotification("Failed to Assign user!!!", "error");
          break;
        case "401":
          setNotification(response.message, "error");
          setTimeout(() => {
            dispatch({ type: "LOG_OUT" });
            localStorage.clear();
          }, 2000);
          break;
        default:
          throw new Error(`Unexpected status: ${response.status}`);
      }
    } catch (error) {
      console.error("API Error:", error.message);
      setNotification(
        "An unexpected error occurred. Please try again.",
        "error"
      );
    } finally {
      setShowLoader(false);
      setOpen(false);
    }
  };

  const handleSelectedUserConfirm = () => {
    let updateData = {
      inquiryId: selectedData._id,
      status: "Assigned",
      owner: selectedUser._id,
      adminId: data?.getData?._id,
    };
    statusUpdateToAssignUser(updateData);
  };

  const filteredData = tabData.map((tab) => {
    if (isAuser) {
      if (tab.label === "All Inquiries") {
        return inquiryLeads;
      } else if (tab.label === "Unassigned") {
        return inquiryLeads.filter((item) => {
          // here return in a sorted way
          return item.status === tab.label;
        });
      } else {
        return adminAssignedLeads.filter((item) => {
          return item.status === tab.label;
        });
      }
    } else if (tab.label === "All Inquiries") {
      return inquiryLeads;
    } else {
      return inquiryLeads.filter((item) => {
        return item.status === tab.label;
      });
    }
  });

  const formatDateAndTime = (dateTime) => {
    return moment(dateTime).format("DD/MM/YYYY");
  };

  const handleRowClick = useCallback(
    (rowData) => {
      const selectedId = rowData[0];
      const filteredData = inquiryLeads?.find(
        (inquiryReq) => inquiryReq?.inquiryID === selectedId
      );
      if (filteredData.duplicateStatus?.isDuplicate) {
        handleViewDuplicate(filteredData?._id);
      } else {
        editUnclassifiedLead(filteredData);
        setNotification("Resolve the duplicate", "info");
      }
    },
    [inquiryLeads]
  );

  const inquiryDataMapping = (dataItem) => [
    dataItem?.inquiryID ? dataItem?.inquiryID : "N/A",
    dataItem?.dateAndTimeOfEmail
      ? formatDateAndTime(dataItem.dateAndTimeOfEmail)
      : "N/A",
    dataItem?.name ? dataItem.name : "N/A",
    dataItem?.companyName ? dataItem.companyName : "N/A",
    dataItem?.contact
      ? dataItem.contact
      : dataItem.email
        ? dataItem.email
        : "N/A",
    dataItem?.status ? dataItem.status : "N/A",
    isAuser && dataItem.statusHistory[0]?.owner[0]?.userName
      ? dataItem.statusHistory[0].owner[0].userName
      : "-----",

    // "n/a"
  ];

  return (
    <>
      {notify ? (
        <NotificationContainer message={message} notificationtype={type} />
      ) : (
        <></>
      )}
      <div style={{ height: "100%" }}>
        <Card>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            {tabData.map((item, key) => {
              return (
                <Tab
                  label={t(item.label)}
                  {...a11yProps(key)}
                  classes={{ root: classes.tab }}
                  key={key}
                />
              );
            })}
          </Tabs>
          {tabData?.map((item, key) => {
            return (
              <TabPanel value={value} index={key} key={item.index}>
                <Table
                  data={filteredData[value].map((dataItem) =>
                    inquiryDataMapping(dataItem)
                  )}
                  header={inquiryTableHeader}
                  name={t(item.name)}
                  options={{
                    ...tableOptions(
                      showLoader,
                      rowsPerPageOptions,
                      setPageData,
                      handleRowClick,
                      totalPages,
                      pageData.page
                    ),
                    tableBodyHeight: "550px",
                    sortOrder: {
                      name: "Date",
                      direction: "desc",
                    },
                  }}
                />
              </TabPanel>
            );
          })}
        </Card>
      </div>
      <SelectUserDialog
        open={open}
        handleClose={handleClose}
        users={recyclerUsers}
        handleUserSelect={handleUserSelect}
        handleSelectedUserConfirm={handleSelectedUserConfirm}
        showLoader={showLoader}
        title={"Assign To"}
      />
    </>
  );
}

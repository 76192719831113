import FormControl from "@mui/material/FormControl";
import { Grid, Box } from "@mui/material";
import {
  Typography,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "../../styles";
import HeaderComp from "../../components/HeaderComp";
import NotificationContainer from "../../../notifications/NotificationContainer";
import { formatIndianCurrency } from "../../../../utils/currencyFormat";
import { ConfirmationDialog } from "../../components/ConfirmationDialog";
import moment from "moment";
import {
  actionOnPaymentRequest,
  getFile,
  getPaymentHistoryStatus,
} from "../../InternalFormState";
import StatusHistory from "../../components/StatusHistory";
import { PAYMENT_STATUS } from "../../components/config/contants";
import RenderAttachments from "../../components/RenderAttachments";

const EditPaymentRequest = () => {
  var classes = useStyles();
  const supplierPaymentStatus = [
    "Pending",
    "Approved",
    "Partial Payment",
    "New Partial Payment",
    "Payment Completed",
    "Sold",
    "Closed",
    "Rejected",
  ];
  const supplierData = useSelector((state) => state.selectedSupplierPayment);
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedData, setSelectedData] = useState(supplierData);
  const loggedInData = useSelector((state) => state.userdata.getData);
  const [selectedStatus, setSelectedStatus] = useState(selectedData?.status);
  const [loader, setLoader] = useState(false);
  const [isViewer, setIsViewer] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isAuthorizer, setIsAuthorizer] = useState(false);
  const [isOnlyUser, setIsOnlyUser] = useState(false);
  const [isSameApprover, setIsSameApprover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [statusHistory, setStatusHistoy] = useState([]);
  const { t, i18n } = useTranslation();

  const [notification, setNotification] = useState({
    message: "",
    notify: false,
    type: "",
  });

  //confirm dialog box
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedStatus(selectedData?.status);
  };

  const handleConfirmDialog = (
    confirm,
    approverComments,
    rejectionReason,
    transactionID,
    adminComments,
    uploadedFiles,
    amountPaid,
    selectedRadioValue,
    saleData
  ) => {
    if (confirm) {
      takeActionOnPaymentRequest(
        selectedData,
        selectedStatus,
        rejectionReason,
        approverComments,
        transactionID,
        adminComments,
        uploadedFiles,
        amountPaid,
        saleData
      );
    } else {
      setSelectedStatus(selectedData?.status);
      setDialogOpen(false);
    }
  };

  const handleStatusChange = (event) => {
    let selectedValue = event.target.value;
    handleOpenDialog();
    setSelectedStatus(selectedValue);

    if (
      selectedData &&
      loggedInData?._id == selectedData?.raisedBy?.[0]?._id &&
      loggedInData?.roles.some((item) => item === "Approver")
    ) {
      setIsSameApprover(true);
    } else {
      setIsSameApprover(false);
    }
  };

  useEffect(() => {
    if (
      loggedInData &&
      loggedInData?.roles.some((item) => "user" === item) &&
      loggedInData?.roles.some((item) => "Viewer" === item)
    ) {
      setIsViewer(true);
    } else if (
      loggedInData &&
      loggedInData?.roles.some((item) => "user" === item) &&
      loggedInData?.roles.length === 1
    ) {
      setIsOnlyUser(true);
    } else if (loggedInData?.roles.some((item) => "Approver" === item)) {
      setIsApprover(true);
    } else if (loggedInData?.roles.some((item) => "Authorizer" === item)) {
      setIsAuthorizer(true);
    }
    getPaymentHistory();
  }, []);

  useEffect(() => {
    if (selectedData == undefined) {
      history.push("/app/internalForms");
    }
  }, [supplierData]);

  const updateSupplierBuyerState = (data) => {
    dispatch({
      type: "SET_SELECTED_SUPPLIER_PAYMENT",
      payload: data,
    });
  };

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          setNotification({
            message: response.message,
            notify: true,
            type: "success",
          });
        }
      })
      .catch((error) => {
        setNotification({
          message: "An error occurred while downloading the file.",
          notify: true,
          type: "error",
        });
      });
  };

  async function getPaymentHistory() {
    let updatedFormData = {
      paymentStatusId: selectedData?._id,
    };
    try {
      const response = await getPaymentHistoryStatus(updatedFormData);
      if (response.status === "200") {
        setStatusHistoy(response?.data[0]);
      }
    } catch (error) {
      setNotification({
        message: "An error occurred. Please try again.",
        type: "error",
        notify: true,
      });
      setStatusHistoy([]);
    }
  }

  async function takeActionOnPaymentRequest(
    formData,
    newStatus,
    rejectionReason,
    approverComments,
    transactionID,
    adminComments,
    uploadedFiles,
    amountPaid,
    saleData
  ) {
    setIsLoading(true);

    try {
      // formData.attachments.push(...uploadedFiles);

      let updatedFormData = {
        ...formData,
        status: newStatus,
        raisedBy: formData?.raisedBy[0]?._id,
        requestType: "supplierPayment",
        attachments: uploadedFiles,
      };

      if (newStatus === PAYMENT_STATUS.REJECTED && rejectionReason !== "") {
        updatedFormData.reasonForRejection = rejectionReason;
      } else if (newStatus === PAYMENT_STATUS.APPROVED) {
        updatedFormData.approverComments = approverComments;
      } else if (newStatus === PAYMENT_STATUS.PAYMENT_COMPLETED) {
        updatedFormData.transactionID = transactionID;
        updatedFormData.adminComments = adminComments;
      } else if (newStatus === PAYMENT_STATUS.NEW_PARTIAL_PAYMENT) {
        updatedFormData.transactionID = transactionID;
        updatedFormData.adminComments = adminComments;
        updatedFormData.amountPaid = amountPaid;
        updatedFormData.status = PAYMENT_STATUS.PARTIAL_PAYMENT;
      } else if (newStatus === PAYMENT_STATUS.SOLD) {
        updatedFormData.soldDetails = saleData;
      } else if (newStatus === PAYMENT_STATUS.CLOSED) {
        updatedFormData.adminComments = adminComments;
      }

      const response = await actionOnPaymentRequest(updatedFormData);
      if (response.status === "200") {
        setNotification({
          message: "Successfully updated!",
          notify: true,
          type: "success",
        });
        getPaymentHistory();
        setIsLoading(false);
        setSelectedStatus(selectedStatus);
        updateSupplierBuyerState(response?.data?.response);
        setTimeout(() => {
          setNotification((prevState) => ({
            ...prevState,
            notify: false,
          }));
        }, 5000);
        setDialogOpen(false);
      } else if (response.status == "400") {
        setNotification({
          message: response?.message || "Something went wrong!",
          notify: true,
          type: "error",
        });
        setIsLoading(false);
      }
    } catch (error) {
      setNotification({
        message: "An error occurred. Please try again.",
        type: "error",
        notify: true,
      });

      setIsLoading(false);
      setDialogOpen(false);
    }
  }

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("Supplier Payments"), link: "/#/app/internalForms" },
    { label: t("Edit"), link: "" },
  ];
  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      {notification.notify ? (
        <NotificationContainer
          message={notification.message}
          notificationtype={notification.type}
        />
      ) : (
        <></>
      )}
      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <HeaderComp
            selectedStatus={selectedStatus}
            selectedData={selectedData}
            backUrlPayload="supplierPaymentComp"
          />
        </div>

        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={5}
            // sx={{ borderRight: { md: ".1px solid gray" } }}
          >
            <Paper sx={{ height: "100%" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Date")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {moment(selectedData?.date).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Raised by")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.raisedBy[0]?.username ||
                        selectedData?.raisedBy[0]?.userName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Payment from")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.paymentFrom}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <RenderAttachments
                attachments={selectedData?.attachments}
                handleDownload={handleDownload}
              />{" "}
              <Box sx={{ marginLeft: "16px", padding: "10px 0" }}>
                {selectedData?.remarks && (
                  <Box mb={2}>
                    <Typography fontWeight="600" fontSize="14px">
                      <b>{t("Remarks")}:</b>
                    </Typography>
                    {selectedData?.remarks}
                  </Box>
                )}
                {selectedData?.reasonForRejection && (
                  <>
                    <Typography
                      sx={{ color: "#FF5C93" }}
                      fontWeight="600"
                      fontSize="14px"
                    >
                      {t("Rejected reason")}:
                    </Typography>
                    {selectedData?.reasonForRejection}
                  </>
                )}
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={7}>
            <Paper>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>{t("Total amount")}:</b>
                    </TableCell>
                    <TableCell>
                      <b> {formatIndianCurrency(selectedData?.amount)} </b>
                    </TableCell>
                  </TableRow>
                  {selectedData?.status === PAYMENT_STATUS.PARTIAL_PAYMENT && (
                    <TableRow>
                      <TableCell>
                        <b>{t("Paid")}:</b>
                      </TableCell>
                      <TableCell>
                        <b>{formatIndianCurrency(selectedData?.amountPaid)}</b>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <b>{t("Amount due")}:</b>
                    </TableCell>
                    <TableCell>
                      {selectedData?.status === PAYMENT_STATUS.APPROVED ||
                      selectedData?.status === PAYMENT_STATUS.PENDING ? (
                        <b>{formatIndianCurrency(selectedData?.amount || 0)}</b>
                      ) : (
                        <b>
                          {formatIndianCurrency(selectedData?.amountDue || 0)}
                        </b>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ border: "none" }}>
                      <Typography variant="subtitle2" gutterBottom>
                        <b>{t("Account details")}</b>
                      </Typography>
                      <Typography variant="body2">
                        Name: {selectedData?.paymentDetails?.accountHolderName}
                        <br />
                        Bank: {selectedData?.paymentDetails?.bankName}
                        <br />
                        Account no:{" "}
                        {selectedData?.paymentDetails?.accountNumber}
                        <br />
                        IFSC: {selectedData?.paymentDetails?.ifscCode}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ border: "none" }}>
                      <Box>
                        <Typography variant="subtitle2" gutterBottom>
                          <b>{t("Status")}</b>
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            disabled={
                              selectedStatus === PAYMENT_STATUS.CLOSED ||
                              selectedStatus === "Completed"
                            }
                          >
                            {supplierPaymentStatus.map((option) => (
                              <MenuItem
                                key={option}
                                value={option}
                                disabled={
                                  isOnlyUser
                                    ? option !== PAYMENT_STATUS.SOLD
                                    : option === PAYMENT_STATUS.PENDING ||
                                      option ===
                                        PAYMENT_STATUS.PARTIAL_PAYMENT ||
                                      (option ===
                                        PAYMENT_STATUS.PAYMENT_COMPLETED &&
                                        isApprover) ||
                                      (option ===
                                        PAYMENT_STATUS.PARTIAL_PAYMENT &&
                                        isApprover) ||
                                      (option ===
                                        PAYMENT_STATUS.NEW_PARTIAL_PAYMENT &&
                                        isApprover) ||
                                      (option === PAYMENT_STATUS.SOLD &&
                                        isApprover)
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>

        <div className={classes.statusDiv}>
          <StatusHistory statusData={statusHistory} editable={true} />
        </div>
      </div>

      <ConfirmationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        inputStatus={selectedStatus}
        title="Changing status to "
        textFieldLabel="Reason"
        isLoading={isLoading}
        imageFileName="SupplierPaymentDocs"
      />
    </>
  );
};

export default EditPaymentRequest;

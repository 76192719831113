import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    Button, 
    IconButton,
    Dialog,
    Tooltip,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Typography,
    Box,
} from '@mui/material'
import { useTranslation } from 'react-i18next';
import {useDispatch,useSelector} from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CommentIcon from '@mui/icons-material/Comment';
import EditQuotation from "./EditQuotation";
import useStyles from "../styles";
import QuotationForm from "./QuotationForm";
import CreateQuotation from "./CreateQuotation";
import Table from '../../../../../components/Table/Table';
import {getFile,getLead} from '../../../LeadState';
import QuotationPDF from "./QuotationPDF";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from '@mui/icons-material/Edit';
import {getEditQuotation} from '../../../LeadState'
import DescriptionComponent from "../../../../../components/DescriptionComponent";

function currencyFormat(num) {
    return parseInt(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

const Quotations = ({list,quotation,hide,quotationDetails}) => {
    var classes = useStyles();
    const { t,i18n } = useTranslation();
    const dispatch = useDispatch()
    const state = useSelector(state => state.selectedLead)
    let external_id = (state.length > 0)? state[0].external_id : "";
    const [quotations,setQuotations] = useState(quotation)
    const [lists,setLists] = useState(list)
    const [showAdd,setShowAdd] = useState(false)
    const [quotationData, setquotationData] = useState([])
    const [qoutUrl,setQuotUrl] = useState("")
    const [showPDF,setShowPDF] = useState(false)
    const [editEnabled,setEditEnabled]=useState(false)
    const [showCreateQuotation,setShowCreateQuotation]=useState(false)
    const [editData,SetEditData]=useState("")
   
    const [open, setOpen] = useState(false);
    const [comments,setComments] = useState("")

        const handleClickOpen = (item) => {
            setOpen(true);
            setComments(item.customerComments)
        };
        const handleClose = () => {
            setOpen(false);
        };
    
    const handleAdd = () => {
        setShowAdd(!showAdd)
        getLead(external_id,dispatch).then(response => {
            setQuotations(response.quotation)
            setLists(response.thisLeadDetails)
        })
    }

    const handleCreateQuotation =()=>{
        setShowCreateQuotation((prev)=>!prev)
        setShowAdd(false)
        getLead(external_id,dispatch).then(response => {
            setQuotations(response.quotation)
            setLists(response.thisLeadDetails)
        })
    }

    const handleEditQuotation =(item)=>{
        setShowAdd(false)
        getLead(external_id,dispatch).then(response => {
            setQuotations(response.quotation)
            setLists(response.thisLeadDetails)
        })
       if (item?.quoteStatus === "reQuote" && item.quotationNo !== undefined){
         let data={
            "quotationNo":item.quotationNo
         }
         getEditQuotation(data).then(response => {
            if(response.status === "200"){
              SetEditData(response.data)
              setEditEnabled((prev)=>!prev)
            } else {
                console.error("Failed to retrieve data.");
            }
         })
        }else {
            console.error("Failed to retrieve data.");
        }
    }

    const handleshowhidepdf = () => {
        setShowPDF(!showPDF)
    }
    
    const handlepdf = (data) => {
        let body  = {
            "fileName" : data.fileName,
            "folderName" : data.folderName,
            "expireLimt" : 1800
        }
        getFile(body).then(response => {
            setQuotUrl(response.reference)
            setShowPDF(true)
        })
    }

    const [tooltipStates, setTooltipStates] = useState([]);

    const toggleTooltipState = (index) => {
        const newTooltipStates = [...tooltipStates];
        newTooltipStates[index] = !newTooltipStates[index];
        setTooltipStates(newTooltipStates);
      };

    function generateTooltipContent( item, index) {
    
        return (
          <Button
            style={{ marginLeft: "-22px" }}
            onClick={() => toggleTooltipState(index)}
          >
            <CustomWidthTooltip
              title={<Typography variant="body1">{item}</Typography>}
              open={tooltipStates[index]}
              onClose={() => toggleTooltipState(index)}
            >
              <DescriptionIcon
                style={{cursor: "pointer" }}
                // sx={{ m: 1 }}
                color="success"
              ></DescriptionIcon>
            </CustomWidthTooltip>
          </Button>
        );
      }

    useEffect(() => { 
        let data = [];
        const latestQuotation = quotations.reduce((latest, current) => {
            return current.genratedDate > latest.genratedDate ? current : latest;
        }, quotations[0]);
        (quotations?.length > 0) && quotations.map((item,index) => {
           const isLatest = item.genratedDate === latestQuotation.genratedDate;
           const showEditIcon = isLatest && item.quoteStatus === "reQuote" && item.quotationNo !== undefined;
           const row = [
                item.quotationNo ? item.quotationNo : item.referenceName,
                <DescriptionComponent description={item.description}/>,
                // generateTooltipContent(item.description, index),
                item.totalAmount.region + " " + currencyFormat(item.totalAmount.amount),
                item.quoteStatus === "accepted" ? "Accepted" : item.quoteStatus,
                (item.customerComments !="")?<Box sx={{marginLeft:"-13px",marginTop:{
                    sm:"-8px",
                    xs:"-8px",
                    md:"6px"
                }}}>
                <IconButton
                    className={classes.collapseIcon}
                    onClick={()=>handleClickOpen(item)}
                    size="large">
                    <CommentIcon />
                </IconButton>
                <IconButton
                    className={classes.collapseIcon}
                    onClick={()=>handlepdf(item)}
                    size="large">
                    <PictureAsPdfIcon />
                </IconButton>
                </Box>:<Box sx={{marginLeft:"-13px",marginTop:{
                    sm:"-8px",
                    xs:"-8px",
                    md:"6px"
                }}}>
                <IconButton
                    className={classes.collapseIcon}
                    onClick={()=>handlepdf(item)}
                    size="large">
                    <PictureAsPdfIcon />
                </IconButton></Box>,
                (showEditIcon ?
                <IconButton
                className={classes.collapseIcon} size="large"
                disabled={list.status === "Quotation Accepted"}
                >
                    <EditIcon  onClick={()=>handleEditQuotation(item)}/>
                </IconButton>: <></>)
            ]
            isLatest ? data.unshift(row) : data.push(row);
        })
        setquotationData(data)

    },[showPDF,showAdd,quotations,editEnabled,editData])
    

    const userHeader = [
        
        {
            label: t("quotation_name"),
            options: {
                filter: true,
            }
        },
        {
            label: t("common_description"),
            options: {
                filter: true,
            }
        },
        {
            name: t("common_value"),
            options: {
              filter: false,
            }
          },
          {
            name: t("common_status"),
            options: {
              filter: false,
            }
          },
          {
            name: t("common_attachment"),
            options: {
              filter: false,
            }
          },
          {
            name: t("common_action"),
            options: {
              filter: false,
            }
          },
    ]
    
   const handleCloseEdit =()=>{
    setEditEnabled(prev => !prev)
    setShowAdd(false)
        getLead(external_id,dispatch).then(response => {
            setQuotations(response.quotation)
            setLists(response.thisLeadDetails)
        })
   }

    return(
        <Paper className={classes.Datapaper}>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={8} md={6} lg={4}>
                    <span style={{fontSize:'19px',fontWeight:'lighter'}}>
                        {t("common_quotations")}
                    </span>
                </Grid>
                <Grid item md={6} lg={8} className={classes.iconGrid}>
                    {
                       (showPDF =="")? <Button size="small" variant="outlined" color="primary"  className={classes.buttonOutlined} sx={{margin: {sm: '8px',xs:'8px' }}} onClick={()=> {hide()}}>
                        {t("common_back")}
                       </Button> 
                        : <Button size="small" variant="outlined" color="primary"  className={classes.buttonOutlined} onClick={handleshowhidepdf}>
                     {t("common_close")}
                    </Button>
                    }
                    {((lists.status).toLowerCase() == ("Quotation Accepted").toLowerCase() || (lists.status).toLowerCase() == ("Quotation sent").toLowerCase() || (lists.status).toLowerCase() == ("requote").toLowerCase())?<></>:
                        (showPDF =="")?<><Button size="small" variant="outlined" color="primary"  className={classes.buttonOutlined} onClick={handleAdd}>Upload
                     </Button>
                     <Button size="small" variant="outlined" color="primary" className={classes.buttonOutlined} onClick={handleCreateQuotation} sx={{margin: {sm: '8px',xs:'8px' }}}>Create</Button>
                     </>:<></>
                    }
                    
                </Grid>
                       {(showAdd)? 
                        <>
                            <Grid item lg={2} md={2}></Grid>
                            <Grid item lg={8} md={8} xs={12}> 
                           <QuotationForm  close={handleAdd} list={list}/>
                            </Grid> 
                            <Grid item lg={2} md={2}></Grid>
                        </>: 
                          (showCreateQuotation)? 
                          <>
                              <Grid item lg={12} xs={12}> 
                                  <CreateQuotation  close={handleCreateQuotation} list={list} />
                              </Grid>
                          </>:
                          (editEnabled) ?(
                                <>
                                   <Grid item lg={12} xs={12}> 
                                     <EditQuotation  close={handleCloseEdit} list={list} quotationDetails={editData}/>
                                 </Grid>
                               </>
                          ) :
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                        {
                            (showPDF !="")?<QuotationPDF url={qoutUrl} close={handleshowhidepdf}/> :<Table 
                            data={quotationData} 
                            header={userHeader} 
                            name="" 
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                            }} />
                        }  
                    </Grid>
                    }      
            </Grid>
            <Dialog onClose={handleClose}  open={open}>
                <DialogTitle id="title" onClose={handleClose}>
                {t("customer_comments")}
                </DialogTitle>
                <DialogContent >
                    <DialogContentText gutterBottom>
                        {comments}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Paper>
    )
}
export default Quotations
import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    content:{
        padding: theme.spacing(4),
        paddingTop: theme.spacing(18),
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(1), 
          paddingTop: theme.spacing(19),
        }
      },
    paper1:{ 
        [theme.breakpoints.up("lg")]: {
        position:"fixed",
        overflow: "auto",
        zIndex:"1000",
        width:"22%",
          },
    },
    scrolling:{
        [theme.breakpoints.up("sm")]: {
            marginTop:"-24px"
          },
        [theme.breakpoints.down("sm")]: {
            marginTop:"0"
        },  
    },

    Datapaper:{
        marginBottom:theme.spacing(4),
    },
    grid:{
        padding: theme.spacing(1)
    },
    userGrid:{
        paddingRight:theme.spacing(1),
        paddingTop:theme.spacing(1.8),
        alignItems:"center",
        textAlign:'center'
    },
    buttonGrid:{
        paddingTop:theme.spacing(2)
    },
    backIcon:{
        "&:hover": {
           textDecoration :'none'
        }
    },
    backIconGrid:{
        marginTop:'1rem',
        textAlign : 'right'
    },
    collapseIcon:{
        cursor:'pointer',
        color: theme.palette.primary.main,
        "&:hover": {
            //color: theme.palette.secondary.main
        }
    },
    iconGrid:{
        textAlign : 'right'
    },
    avatargrid:{
        alignSelf:'center',
        padding: theme.spacing(1)
    },
    userActionGrid:{
        margin:'1rem'
    },
    buttonOutlined:{
        color: theme.palette.primary.main,
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        // marginLeft:'1rem',
        "&:hover" : {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.contrastText
        }
    },
    selectedBtn:{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText,
      },
  
    
}));
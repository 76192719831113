import React from "react";
import { Grid, Button, Card, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { inquriryFormUrl } from "../../data/config";
//components
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import InquiryLeadView from "./components/InquiryLeadView";
import { useSelector } from "react-redux";

const InquiryLeads = () => {
  const { t, i18n } = useTranslation();
  var data = useSelector((state) => state.userdata);
  var classes = useStyles();
  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("Inquiries"), link: "" },
  ];

  const localStorageData = {
    customerid: localStorage.getItem("customerid"),
    type: localStorage.getItem("type"),
    userId: data?.getData?._id,
  };

  const serializedData = Object.keys(localStorageData)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(localStorageData[key])}`
    )
    .join("&");

  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <div className={classes.HeaderContainer}>
                  <div className={classes.tenantHeader}>
                    <Typography variant="h4" size="sm">
                      {" "}
                      {t("all_Inquiries")}
                    </Typography>
                  </div>

                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Button
                      variant="outlined"
                      href={`${inquriryFormUrl}?${serializedData}`}
                      color="primary"
                      className={classes.buttonOutlined}
                    >
                      {t("create_inquiry")}
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <InquiryLeadView />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default InquiryLeads;

import React,{useEffect, useState} from 'react'
import {
    Tabs,
    Tab,
    Box,
    Card,
    IconButton,
    Tooltip,
    CircularProgress
} from '@mui/material'
import { useHistory } from "react-router-dom";
import useStyles from '../styles';
import { useTranslation } from 'react-i18next'
import {useSelector,useDispatch} from 'react-redux'
import moment from 'moment'
import VisibilityIcon from '@mui/icons-material/Visibility';

//component
import Table from '../../../components/Table/Table'
import {getLeadList} from '../LeadState'


function TabPanel(props) {
    const { children, value, index, ...other } = props;
   
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
}

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabPannel() {
    const classes = useStyles();
    // eslint-disable-next-line
    const { t, i18n } = useTranslation();
     // global
    
    const history = useHistory();
    const [value, setValue] = useState(0);
    const data = useSelector(state => state.userdata.getData)
    const dispatch  = useDispatch()
    const [list,setList] = useState([])
    const [fetchLoader,setFetchLoader] = useState(true)
    let tabData = []
    let assigned = []
    let unassigned = []
    
    useEffect(()=> {
      (data) && getLeadList(data.customerid,dispatch).then((response) => {
        if(response && response.status === "200"){
          if(response.result)
          setList(response.result.finalLeadlist)
          setFetchLoader(false)
          dispatch({
            type : "SET_TENANT_ADDRESS_LIST",
            payload : response.result.branchForThisTenant
          })
          dispatch({
            type : "SET_TENANT_CONTACT_LIST",
            payload : response.result.allUsers
          })
        }else {
          setFetchLoader(false)
        }
      })
      // eslint-disable-next-line
    },[data])

    if(data && data.roles.some(item => "user" === item)){
      tabData = [
        {label: t("lead_mylead") ,  index:0 , name: "",data : assigned},
    ]
    }else{
       tabData = [
        {label: t("lead_platform") , index:1 , name: "",data : assigned},
        {label: t("lead_mylead") ,  index:0 , name: "",data : unassigned},
    ]
    }
    
    const datatableHeader = [
      {
        label: t("lead_id"),
        options: {
          filter: true,
        }
      },
      {
        label: t("common_customer_name"),
        options: {
          filter: true,
        }
      },
      {
        label: t("lead_generated_on"),
        options: {
          filter: true,
        }
      },
      {
        label: t("common_status"),
        options: {
          filter: true,
        }
      },
      {
        label: t("common_reason"),
        options: {
          filter: true,
        }
      },
      // {
      //   label: t("lead_stage"),
      //   options: {
      //     filter: true,
      //   }
      // },
      {
        name: t("common_action"),
        options: {
          filter: false,
        }
      }
      ]
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const onRowClick = (rowData) => {
      let selectedData = []
      if(list && list.length > 0){
        // eslint-disable-next-line
        list.map((item,key) => {
          if( item.external_id === rowData){
            selectedData.push(item)
          }
        })
      }
      dispatch({
        type : "SET_SELECTED_LEAD",
        payload : selectedData
      })
       history.push('/app/ViewLead')
    }
  
        
    return (
      <div className={classes.root}>
          <Card>
            <Tabs value={value} onChange={handleChange}  indicatorColor="primary">
                {
                    tabData.map((item,key) => {
                        return(
                            <Tab label={item.label} {...a11yProps(item.index)} classes={{ root: classes.tab }} key={key} />
                        )
                    })
                }
            </Tabs>
                {
                  // eslint-disable-next-line
                 ( list && list.length >0) && list.map((item,key) => {
                   if(item.assigned_to !== data._id){
                    unassigned.push([
                       item.external_id,
                       item.customerName,
                       moment(item.createdAt).format("DD-MM-YYYY"),
                       item?.status === "Open"? "Unassigned" : item?.status?.charAt(0).toUpperCase() + item?.status?.slice(1),
                      //  item.reason,
                       item?.reason?.charAt(0).toUpperCase() + item?.reason?.slice(1),
                      //  item.leadStage,
                       <Tooltip title="View">
                          <IconButton size="small" variant="outlined"  onClick={()=>onRowClick( item.external_id)} color='success'>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                     ])
                   }
                   else if(item.assigned_to === data._id){
                    assigned.push([
                      item.external_id,
                       item.customerName,
                       moment(item.createdAt).format("DD-MM-YYYY"),
                      //  item.status,
                       item?.status?.charAt(0).toUpperCase() + item?.status?.slice(1),
                       item?.reason?.charAt(0).toUpperCase() + item?.reason?.slice(1),
                      //  item.leadStage,
                       <Tooltip title="View">
                          <IconButton size="small" variant="outlined" onClick={()=>onRowClick(item.external_id)} color='success'>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                    ])
                   }
                  })
                  
                }
                {
                  tabData.map((item,key) => {
                    return(
                      <TabPanel value={value} index={item.index} key = {key}>
                        <Table 
                          data={item.data} 
                          header={datatableHeader} 
                          name={""}
                          options={{
                              filterType: 'checkbox',
                              print: false,
                              download: false,
                              filter:false,
                              selectableRows:'none',
                              textLabels: {
                                body: {
                                  noMatch: fetchLoader ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <CircularProgress
                                        style={{ color: "#0D6937", marginRight: "5px" }}
                                      />{" "}
                                      Loading...
                                    </div>
                                  ) : (
                                    "No Leads Found"
                                  ),
                                },
                              },
                          }} />
                      </TabPanel>
                    )
                  })
                }
        </Card>
      </div>
    );
}
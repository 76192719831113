import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "auto",
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginLeft:theme.spacing(7),
        marginTop: theme.spacing(8)
    },
    noWidgetShadow: {
        boxShadow: 'none'
    },
    breadcrumbs: {
        backgroundColor: '#fff',
    },
    breadCrumbDiv:{
        paddingLeft:'3.2rem',
        [theme.breakpoints.down("sm")]:
        {
            paddingLeft:theme.spacing(0)
        }
    }
}));
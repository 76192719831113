import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    Collapse, 
    IconButton,
    Button, 
    Tooltip,
    CircularProgress
} from '@mui/material'
import {
    ExpandMore as DropDownIcon,
    ExpandLess as DropUpIcon,
    Check as CheckIcon,
    Cancel as CancelIcon
}  from '@mui/icons-material';
import {useDispatch,useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next';

import Table from '../../../../components/Table/Table'
import useStyles from "./styles";
import NotificationContainer from '../../../notifications/NotificationContainer'
import {assignLead,getMPRData} from '../../MprState'


const InspectionRequest = ({slotData,lead,close,userData}) => {
    // eslint-disable-next-lin
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const dispatch = useDispatch()
    const state = useSelector(state => state.selectedMPR)
    const[collapse,setCollapse] = useState(true)
    const [slot,setSlot] = useState([])
    const [showLoader,setShowLoader] = useState(false)
    const [message,setMessage] = useState("")
    const [notification,setNotification] = useState(false)
    const [type,setType] = useState("")
    const [respponseList,setResponseLead] = useState(slotData)


    const handleAccept = (slot, leadid,action) => {
        setShowLoader(true)
        let messgae = (action === "accept")?"Accepted the Slot":"Rejected the Slot"
        if(lead){
            let data = {
                external_id : leadid,
                leadData:{
                    "inspection_slot":{
                        "inspectionDate": slot.inspectionDate,
                        "time": slot.time,
                        "status": (action === "accept")?true:false
                    },
                "status": (action === "accept")? "Slot Accepted" : "Slot rejected"
                },
                mpr_id: userData.external_id
            }
            assignLead(data,dispatch).then(response => {
                if(response.status === "200"){
                    setType("success")
                    setMessage(messgae)
                    setNotification(true)
                    setShowLoader(false)
                    getMPRData(state.MPR.external_id,dispatch).then(res => {
                        setResponseLead(res.slotsChoosenByTenant)
                    })
                    // setTimeout(()=>close(),2000)
                }else{
                    setType("error")
                    setMessage("Something went wrong")
                    setNotification(true)
                    setShowLoader(false)
                }
            })
        }else{
            setType("error")
            setMessage("Something went wrong")
            setNotification(true)
            setShowLoader(false)
        }
    }


    useEffect(() => {
        
        let data  = []
        // eslint-disable-next-line
       respponseList.map(item=>{
           // eslint-disable-next-line
            item.receivedSlots.map(slot => {
                data.push([
                    slot.inspectionDate ,
                    slot.time,
                     item.TenantName,
                    
                        (showLoader)?<CircularProgress /> :(slot.status === "selected")?
                         <>
                            <Tooltip title = "Accept" > 
                            <IconButton
                                className={classes.collapseIcon}
                                onClick ={() => handleAccept(slot,item.Lead_id,"accept")}
                                size="large">
                                <CheckIcon />
                            </IconButton>   
                            </Tooltip>
                            
                            <Tooltip title = "Reject" > 
                            <IconButton
                                className={classes.collapseIcon}
                                onClick ={() => handleAccept(slot,item.Lead_id,"reject")}
                                size="large">
                                <CancelIcon style={{color:'red'}} />
                            </IconButton>   
                            </Tooltip>
                        </> : (slot.status === true)? <span>{t("common_accepted")}</span> : 
                        <span className={classes.rejectedSlotSpan}>{t("common_rejected")}</span>
                     
                 ])
            })
           
        })
        setSlot(data)
        // eslint-disable-next-line
    }, [slotData,respponseList])
    

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    const userHeader = [
        
        {
            label: t("mpr_inspection_date"),
            options: {
                filter: true,
            }
        },
        {
            label: t("common_time") +""+ t("common_slot"),
            options: {
                filter: true,
            }
        },
        {
            label: t("customer_company_name"),
            options: {
                filter: true,
            }
        },
        {
            label: t("common_action"),
            options: {
                filter: true,
            }
        },
    ]

    return (
        <Paper className={classes.Datapaper}>
            {
                (notification)?<NotificationContainer message={message} notificationtype={type}/>:<></>
            }
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={8}>
                    <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("mpr_inspection_requests")}</span>
                </Grid>
                <Grid item lg={2}>
                    <Button variant = "outlined" size="small" onClick={()=> close()}>{t("common_close")}</Button>
                </Grid>
                <Grid item xs={2} className={classes.iconGrid}>
                    <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
                        {(collapse)?<DropDownIcon /> :<DropUpIcon />}
                    </IconButton>
                </Grid>
            </Grid> 
            <Collapse in={collapse}>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Table 
                            data={slot} 
                            header={userHeader} 
                            name="" 
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                                pagination:false
                            }} />
                    </Grid>  
                </Grid>
            </Collapse>         
        </Paper>
    );
}
export default InspectionRequest
import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    Collapse, 
    IconButton,
    Button
} from '@mui/material'
import {
    ExpandMore as DropDownIcon,
    ExpandLess as DropUpIcon
}  from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


import useStyles from "./styles";
import QuotationPDF from "../../../leads/components/ViewLead/quotations/QuotationPDF";
import {getFile} from '../../../leads/LeadState'

const FormSix = ({form6Data,close}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const [collapse,setCollapse] = useState(true)
    const [pdfurl,setPdfurl] = useState("")
    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    useEffect(()=>{
        let body = {
            "fileName" : form6Data.fileName || form6Data.filename,
            "folderName" : form6Data.folderName || form6Data.folder || form6Data.FolderName,
            "expireLimt" : 1800
        }
        getFile(body).then(response => {
            setPdfurl(response.reference)
        })
        // eslint-disable-next-line
    },[])

    return (
        <Paper className={classes.Datapaper}>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={8}>
                    <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("common_form")} 6</span>
                </Grid>
                <Grid item lg={4} className={classes.iconGrid}>
                    <Button variant="outlined" onClick={()=>close()} color="primary" size="small" className={classes.buttonOutlined} >{t("common_close")}</Button>
                    <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
                        {(collapse)?<DropDownIcon /> :<DropUpIcon />}
                    </IconButton> 
                </Grid> 
            </Grid>
            <Collapse in={collapse}>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid container spacing={3} className={classes.grid}>
                        <QuotationPDF url={pdfurl} />
                    </Grid> 
                </Grid>
            </Collapse>          
        </Paper>
    );
}
export default FormSix
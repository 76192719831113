import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  widgetWrapper: {
    display: "flex",
    // minHeight: "100%",
    "&:hover": {
      boxShadow: theme.customShadows.widget,
    }
  },
  widgetHeader: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  widgetRoot: {
    boxShadow: theme.customShadows.widget,
  },
  widgetBody: {
    padding: theme.spacing(3),
  },
  noPadding: {
    padding: 0,
  },
  paper: {
    display: "flex",
    overflow: "hidden",
    flexGrow: 1,
    flexDirection: "column",
    minHeight: "100px",
    height: "200px",
  },
  noWidgetShadow: {
    boxShadow: 'none'
  },
  dashboardImage:{
    // opacity:'0.5',
    width:theme.spacing(5),
    [theme.breakpoints.only("sm")]: {
      width:theme.spacing(3),
    },
    [theme.breakpoints.only("xs")]: {
      width:theme.spacing(3),
    },
  }
}));

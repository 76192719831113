import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import "./i18next";
import { createReduxStore } from "./Store";

ReactDOM.render(
  <LayoutProvider>
    <Provider store={createReduxStore()}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <Suspense fallback="Loading...">
            <App />
          </Suspense>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </LayoutProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState } from 'react'
import {
    Grid,
    Paper,
    Button
  } from '@mui/material'
  import classnames from "classnames";
import useStyles from "./styles";
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import { useTranslation } from 'react-i18next';


const defaultData = {
    branchName: "",
    location:"",
    email:"",
    mobile:""
  };
export default function AddContact() {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    const breadcrumb = [
        {label: t("common_admin") , link: "/#/app/admin"},
        {label: t("common_branch") , link: "/#/app/tenent_branch"},
        {label: t("common_add")+" "+t("common_branch") , link: ""},
      ]
      // eslint-disable-next-line
      const [formData, setForm] = useState(defaultData);
    //   const fields = [
    //     { label : t("common_branch")+" "+t("common_name"),name : "branchName",value : formData.branchName },
    //     { label : t("common_location"),name : "location",value : formData.location },
    //     { label : t("common_email"),name : "email",value : formData.email },
    //     { label : t("common_mobile_no"),name : "mobile",value : formData.mobile },
    //   ]
      const classes = useStyles();
      
    return(
        <>
            <BreadCrumbs data={breadcrumb} />
            <div className={classes.content}>
                <Grid container spacing={3}>
                    <Grid lg={3} md={1} xs={1} sm={1}></Grid>
                    <Grid lg={6} md={10} xs={10} sm={10}>
                        <Paper className={classes.paper} classes={{ root: classnames(classes.widgetRoot, {
                            [classes.noWidgetShadow] :null
                        }) }}>
                            <div className={classes.form} >
                                <Grid container spacing={3}>
                                    <Grid item lg={12} md={12} xs={12} sm={12} style={{width:'width: calc(100% - 2*15px)'}}>
                                        <h3 className={classes.title}>{t("common_basic_details")}</h3>
                                        {/* {
                                            fields.map((item,key) =>{
                                                return(
                                                    <ItemForm label={item.label} name={item.name} value={item.value} onChange={setForm} />
                                                )
                                            })
                                        } */}
                                    </Grid>
                                </Grid>  
                            </div>
                        </Paper>
                        <Paper className={classes.paperButton} classes={{ root: classnames(classes.widgetRoot, {
                                [classes.noWidgetShadow] :null})
                        }}>
                             <Button  href="/#/app/tenent_branch" className={classes.button}>
                                {t("common_back")}
                            </Button>
                            <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  href="/#/app/tenent_branch"
                >{t("common_finish")}</Button>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
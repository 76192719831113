import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";


import {  contactsMessages, Message } from './generateFakeData'
import Avatar from './components/Avatar'
import ContactBox from './components/ContactBox'
import MessagesBox from './components/MessagesBox'
import ChatInputBox from './components/ChatInputBox'
import Search from './components/Search'
import Welcome from './components/Welcome'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import {getMessages} from './MessageState'

import './App.css'

const MessageContainer = () =>{
    const [data, setData] = useState(contactsMessages)
    const [contactSelected, setContactSelected] = useState({})
    const [currentMessages, setCurrentMessages] = useState([])
    const [message, setMessage] = useState('')
    const [search, setSearch] = useState('')
    // eslint-disable-next-line
    const [filteredContacts, setFilterContacts] = useState([])
    const state = useSelector((state) => state.selectedMPR); 
    const [mprData,setMprData] = useState([])

    useEffect(() => {
        let bodyData = {
            mpr_Id : state.MPR.external_id
        }
        getMessages(bodyData).then(response => {
            if(response.status === "200"){
                setMprData(response.mprDetails)
            }
            
        })
        const currContact = data.find((d) => d.contact.id === contactSelected.id)
        setCurrentMessages((currContact && currContact.messages) || [])
        filterContacts(data, search)
        // eslint-disable-next-line
    }, [contactSelected, data, search])

    function pushMessage() {
        const index = data.findIndex((d) => d.contact.id === contactSelected.id)
        const newData = Object.assign([], data, {
            [index]: {
                contact: contactSelected,
                messages: [...data[index].messages, new Message(true, message, new Date())],
            },
        })

        setData(newData)
        setMessage('')
    }

    function filterContacts(data, search) {
        const result = data.filter(({ contact }) => {
            return !search || contact.name.toLowerCase().includes(search.toLowerCase())
        })
        setFilterContacts(result)
    }
    const breadcrumb = [
        {label: "Home" , link: "/#/app/dashboard"},
        {label: "Leads" , link: "/#/app/leads"},
        {label: "Messages" , link: ""},
      ] 

    return (
        <>
        <BreadCrumbs data={breadcrumb} />
        <div className="app">
            <aside>
                <Search search={search} setSearch={setSearch} />
                <div className="contact-boxes">
                {// eslint-disable-next-line
                    mprData.map(item => {
                       <ContactBox
                            contact={item}
                            key={item.mpr_Id}
                            setContactSelected={setContactSelected}
                            messages={[]}
                        /> 
                    })
                }
                </div>
            </aside>
            {contactSelected.id ? (
                <main>
                    <header>
                        <Avatar user={contactSelected} showName />
                    </header>
                    <MessagesBox messages={currentMessages} />
                    <ChatInputBox message={message} setMessage={setMessage} pushMessage={pushMessage} />
                </main>
            ) : (
                <Welcome />
            )}
        </div>
        </>
    )
}

export default MessageContainer

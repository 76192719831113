import React from "react";
import clsx from "clsx";
import { Drawer, List, Divider, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { People as PeopleIcon, Menu as MenuIcon } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import LabelIcon from "@mui/icons-material/Label";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import DashboardIcon from "@mui/icons-material/Dashboard";

function SideDrawer({ location }) {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  var data = useSelector((state) => state.userdata);
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };
  var structure = [];

  if (
    data &&
    data.getData &&
    data.getData.type === "tenant" &&
    !data.getData.roles.includes("user")
  ) {
    structure = [
      {
        id: 0,
        label: t("common_dashboard"),
        link: "/app/dashboard",
        icon: <DashboardIcon />,
      },
      {
        id: 1,
        label: t("common_customers"),
        link: "/app/customers",
        icon: <PeopleIcon />,
      },
      {
        id: 2,
        label: t("common_leads"),
        link: "/app/leads",
        icon: <LabelIcon />,
      },

      {
        id: 4,
        label: t("common_marketplace"),
        link: "/app/marketplace",
        icon: <ShoppingCartIcon />,
      },
      {
        id: 6,
        label: t("Buyers"),
        link: "/app/mpUsers",
        icon: <GroupAddIcon />,
      },
      {
        id: 5,
        label: t("Inquiries"),
        link: "/app/inquiryLeads",
        icon: <AssignmentIcon />,
      },
      {
        id: 3,
        label: t("common_admin"),
        link: "/app/admin",
        icon: <SettingsIcon />,
      },
      {
        id: 4,
        label: t("Request Forms"),
        link: "/app/internalForms",
        icon: <FormatAlignLeftIcon />,
      },
    ];
  } else if (
    data &&
    data.getData &&
    data.getData.type === "tenant" &&
    data.getData.roles.includes("user")
  ) {
    structure = [
      {
        id: 0,
        label: t("common_dashboard"),
        link: "/app/dashboard",
        icon: <DashboardIcon />,
      },
      {
        id: 2,
        label: t("common_leads"),
        link: "/app/leads",
        icon: <LabelIcon />,
      },
      {
        id: 3,
        label: t("common_customers"),
        link: "/app/customers",
        icon: <PeopleIcon />,
      },
      {
        id: 5,
        label: t("Buyers"),
        link: "/app/mpUsers",
        icon: <GroupAddIcon />,
      },
      {
        id: 4,
        label: t("Inquiries"),
        link: "/app/inquiryLeads",
        icon: <AssignmentIcon />,
      },
      {
        id: 4,
        label: t("Request Forms"),
        link: "/app/internalForms",
        icon: <FormatAlignLeftIcon />,
      },
    ];
  } else if (
    data &&
    data.getData &&
    data.getData.type === "Customer" &&
    data.getData.roles.includes("Customer_Admin")
  ) {
    structure = [
      {
        id: 0,
        label: t("common_dashboard"),
        link: "/app/dashboard",
        icon: <DashboardIcon />,
      },
      { id: 1, label: t("common_mpr"), link: "/app/MPR", icon: <PeopleIcon /> },
      {
        id: 2,
        label: t("common_admin"),
        link: "/app/customeradmin",
        icon: <SettingsIcon />,
      },
    ];
  } else if (
    data &&
    data.getData &&
    data.getData.type === "Customer" &&
    data.getData.roles.includes("Branch_Admin")
  ) {
    structure = [
      {
        id: 0,
        label: t("common_dashboard"),
        link: "/app/dashboard",
        icon: <DashboardIcon />,
      },
      { id: 1, label: t("common_mpr"), link: "/app/MPR", icon: <PeopleIcon /> },
    ];
  }

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List className={classes.sidebarList}>
        <img
          src={"assets/img/sidebar.png"}
          alt="Binbag"
          width="50%"
          style={{ marginLeft: "25px" }}
        />
      </List>
      <Divider />
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={state}
            {...link}
          />
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <IconButton
        onClick={toggleDrawer(true)}
        className={classes.headerMenuButton}
        size="large"
      >
        <MenuIcon className={classes.headerIcon} />
      </IconButton>
      <Drawer anchor={"left"} open={state} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}
export default withRouter(SideDrawer);

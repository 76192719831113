import React, { useState } from "react";
import { Grid, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Widget from "../../components/Widget";
import useStyles from "./styles";

//components
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";

export default function Index() {
  var classes = useStyles();
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const data = useSelector((state) => state.userdata.getData);

  const breadcrumb = [
    {label:t("common_home"), link:"/#/app/dashboard"},
    { label: t("common_admin"), link: "" },
  ];
  //var type = localStorage.getItem('type')
  var structure = [];

  if (data && data.type === "tenant") {
    structure.push(
      { title: t("common_contacts"), link: "/#/app/Recyclercontacts" },
      { title: t("common_settings"), link: "/#/app/setting" }
    );
  }
  if (
    data &&
    data.type === "Customer" &&
    data.roles.includes("Customer_Admin")
  ) {
    structure.push(
      { title: t("common_branch"), link: "/#/app/customerBranch" },
      { title: t("settings_details"), link: "/#/app/details" }
    );
  }
  // if(data.type == 'Customer' && data && data.roles.includes("Branch_Admin")){
  //     structure.push(
  //         {title: t("common_branch"),link:"/#/app/customerBranch"})
  // }

  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.fakeToolbar} />
      <Grid
        container
        spacing={4}
        className={classes.content}
        // style={{ paddingTop: "40px" }}
      >
        {structure.map((item, key) => {
          return (
            <Grid item lg={3} md={4} sm={6} xs={12} key={key}>
              <Link
                href={item.link}
                className={classes.widgetlink}
                underline="hover"
              >
                <Widget
                  title={item.title}
                  Image="assets/img/binbag.png"
                  upperTitle
                  // Description={t("common_description")}
                  bodyClass={classes.fullHeightBody}
                  className={classes.card}
                  // subtitle={t("settings_subtitle")}
                ></Widget>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

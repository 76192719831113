import React from "react";
import {
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import classnames from "classnames";

// styles
import useStyles from "./styles";

export default function Widget({
  children,
  Description,
  Image,
  title,
  icon,
  noBodyPadding,
  bodyClass,
  disableWidgetMenu,
  header,
  noHeaderPadding,
  headerClass,
  style,
  noWidgetShadow,
  subtitle,
  ...props
}) {
  var classes = useStyles();

  return (
    <div className={classes.widgetWrapper} style={style && {...style}}>
      <Paper className={classes.paper} classes={{ root: classnames(classes.widgetRoot, {
        [classes.noWidgetShadow]: noWidgetShadow
        }) }}>
        <div className={classnames(classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [bodyClass]: bodyClass,
        })}>
         
            <React.Fragment>
            <Grid container spacing={4}>
              <Grid item lg={8} >
                <Typography variant="h4" color="textSecondary" >
                  {title}
                </Typography>
              </Grid>
              <Grid item lg={3} style={{width:"200",height:"200"}} >
                <Typography  color="textSecondary"  >
                 {
                   (Image)?<img src={Image} alt="BinBag"  className={classes.dashboardImage}/>:null
                 } 
                </Typography>
              </Grid>
              <Grid item lg={12} >
                <Typography variant="h6" color="textSecondary" noWrap>
                  {Description}
                </Typography>
              </Grid>
              <Grid item lg={8} >
                <Typography variant="h6" color="textSecondary" noWrap>
                  {subtitle}
                </Typography>
              </Grid>
             
              <Grid item lg={4} >
                {icon}
              </Grid>
            </Grid>
             
            </React.Fragment>
        </div>
      </Paper>
    </div>
  );
}

import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    Button, 
    TextField,
    CircularProgress
} from '@mui/material'
import { useTranslation } from 'react-i18next';
import {useDispatch} from 'react-redux'

import useStyles from "./styles";
import {assignLead} from '../../LeadState'
import NotificationContainer from '../../../notifications/NotificationContainer'


const RejectedLead = ({list,hide,lead}) => {
    var classes = useStyles();
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    const dispatch = useDispatch()
    const[desc,setDesc] = useState("")
    const [message,setMessage] = useState("")
    const [notification,setNotification] = useState(false)
    const [type,setType] = useState("")
    const [showLoader,setShowLoader] = useState(false)
    var [showButton,setShowButton] = useState(true)
    //const myId = localStorage.getItem('customerid')

    const handleChange = (e) => {
        setDesc(e.target.value)
    }
    useEffect(() => {
        if(desc !== "")
        setShowButton(false)
        else setShowButton(true)
    },[desc])

    const handleAssign = () => {
        setShowLoader(true)
        if(lead){
            let data = {
                external_id : lead,
                leadData:{
                    status : "Closed",
                    description: desc,
                    reason : "rejected"
                },
                mpr_id:list.Mpr_id
            }
            assignLead(data,dispatch).then(response => {
                if(response.status === "200"){
                    setType("success")
                    setMessage("Rejected the Lead Successfully")
                    setNotification(true)
                    setTimeout(()=> hide(),2000)
                    setShowLoader(false)
                }else{
                    setType("error")
                    setMessage("Something went wrong")
                    setNotification(true)
                    setShowLoader(false)
                }
            })
        }else{
            setType("error")
            setMessage("Something went wrong")
            setNotification(true)
            setShowLoader(false)
        }
    }

    return (
        <Paper className={classes.Datapaper}>
            {
                (notification)?<NotificationContainer message={message} notificationtype={type}/>:<></>
            }
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={10}>
                    <span style={{fontSize:'19px',fontWeight:'lighter'}}>
                        {t("lead_reject")}
                    </span>
                </Grid>
                <Grid item lg={2} className={classes.iconGrid}></Grid>
                <Grid item lg={12} xs={12}>
                    <TextField
                        id="filled-multiline-flexible"
                        label={t("lead_reject_message")}
                        multiline
                        minRows={4}
                        value={desc}
                        fullWidth
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} className={classes.asignButton}>
                    <Button size="small" variant="outlined" onClick={hide}>
                        {t("common_close")}
                    </Button>
                    {
                        (showLoader)? <CircularProgress />: 
                        <Button size="small" variant="outlined" className={classes.buttonOutlined} onClick = {handleAssign}
                        disabled={
                            showButton
                          }
                        >
                            {t("common_reject")}
                        </Button>
                    }
                    
                </Grid>
            </Grid>
        </Paper>
    );
}
export default RejectedLead
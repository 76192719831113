import React,{useEffect,useState} from "react";
import { Grid,Paper,Tooltip, IconButton,Link } from '@mui/material'
import {
    ExpandLess as DropUpIcon}  from '@mui/icons-material';
import {useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';

//components
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs'
import useStyles from "../../components/styles";

import Users from "../../../../components/ViewData/Users";

const ViewInspectionDetails = () => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation;
    var classes = useStyles();
    const state = useSelector(state => state.selectedLead)
    const [list,setList] = useState([])
    // eslint-disable-next-line
    const [slotData,setSlotData] = useState([])
    
    
    useEffect(() => {
       if(state){
           let data = []
           // eslint-disable-next-line
           state.map(item => {
               setList(item)
               if(item.MPRDetails){
                   // eslint-disable-next-line
                   item.MPRDetails.slot.slots.map(slot => {
                      
                    data.push([
                            item.MPRDetails.slot.Mpr_id,
                            item.MPRDetails.slot.createdAt,
                            slot.inspectionDate,
                            slot.time
                           ])
                      
                   })
               }
               setSlotData(data)
           })
       }
       // eslint-disable-next-line
    }, [list])
    const breadcrumb_viewTenant = [
        {label: t("common_leads") , link: "/#/app/leads"},
        {label: (list.Lead)&&list.Lead.external_id , link: ""},
    ]
    // const userHeader = [
    //     {
    //         label: t("mpr_id"),
    //         options: {
    //           filter: true,
    //         }
    //     },
    //     {
    //         label: t("common_createdAt"),
    //         options: {
    //             filter: true,
    //         }
    //     },
    //     {
    //         label: t("mpr_inspection_date"),
    //         options: {
    //             filter: true,
    //         }
    //     },
    //     {
    //         label: t("common_time") +""+ t("common_slot"),
    //         options: {
    //             filter: true,
    //         }
    //     },
    //     {
    //         name: t("common_action"),
    //         options: {
    //           filter: false,
    //           sort: false,
    //           empty: true,
    //           customBodyRenderLite: (dataIndex, rowIndex) => {
    //             return (
    //               <Button size="small" variant="outlined" color="primary"  className={classes.buttonOutlined}>
    //                {t("mpr_send_request")}
    //               </Button>
    //             );
    //           }
    //         }
    //       },
    // ]
    return <>
    <BreadCrumbs data={breadcrumb_viewTenant}/>
        <div className={classes.content}>
            <Grid container spacing={3}>
                    <Grid item lg={3} md={12} xs={12} sm={12} style={{paddingTop:'0px'}}>
                        <Users data={list}/>
                    </Grid>
                    <Grid item lg={9} md={12} xs={12} sm={12} >
                        <Paper className={classes.Datapaper}>
                            <Grid container spacing={3} className={classes.grid}>
                            <Grid item lg={8}>
                                <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("mpr_inspection_requests")}</span>
                                
                            </Grid>
                            <Grid item lg={1}>
                            
                            </Grid>
                            <Grid item xs={2} className={classes.backIconGrid}>
                                <Link
                                    className={classes.backIcon}
                                    href="/#/app/ViewLead"
                                    color="primary"
                                    underline="hover">{t("common_back")}</Link>
                            </Grid>
                            <Grid item xs={1} className={classes.iconGrid}>
                            <Tooltip title="Back" aria-label="Back">
                                <IconButton className={classes.collapseIcon} href="/#/app/ViewLead" size="large">
                                    <DropUpIcon />
                                </IconButton>
                                </Tooltip>
                            </Grid>
                                
                               
                                   
                               
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>    
                
        </div>
    </>;
};

export default ViewInspectionDetails;
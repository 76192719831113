import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from '../styles'
import {
    Card, Grid, Box, FormControl, InputLabel, Select, MenuItem, Button,
    Modal, Fade, Backdrop, TextField,Badge,CircularProgress
} from '@mui/material';
import { useMediaQuery } from "@mui/material";
//component
import { useHistory } from "react-router-dom";
import Table from '../../../components/Table/Table';
import { getLotBids, finalizeBids, PaymentRecived, capitalize, convertINR , updateLot } from '../MarketplaceState';
import NotificationContainer from '../../notifications/NotificationContainer';
import { useSelector } from "react-redux";
import { bidSelect } from '../../../data/config';
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import { Typography } from '../../../components/Wrappers/Wrappers';
import moment from 'moment';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}



export default function ViewBids(props) {


    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const history = useHistory()
    const lotId = useSelector((state) => state)
    const classes = useStyles();
    // eslint-disable-next-line no-useless-computed-key
    const { ['_id']:_id,['__v']:__v,['createdAt']:createdAt, ...filteredLot } = lotId.selectedLot 
    const [open, setOpen] = useState(false);
    const [disableOption, setdisableOption] = useState(false)
    const [isFinalized, setisFinalized] = useState()
    // const [showLoader, setShowLoader] = useState(false)
    const [type, setType] = React.useState("")
    const [value, setValue] = useState(0);
    //finalize bid

    const [bids, setBids] = useState([])
    const [selectedoption, setSelectedoption] = useState([])

    const { t, i18n } = useTranslation();
    // const [message, setMessage] = React.useState("")
    const [notification, setNotification] = useState()
    const [message, setMessage] = useState("")

    //buttons
    const [finalaizeBtn, setfinalaizeBtn] = useState(true);
    const [paymentRecivedBtn, setpaymentRecivedBtn] = useState(false);
    const [filteredOptions , setFilteredOptions] = useState();
    const [bankDetails , setbankDetails] = useState(localStorage.getItem("bankDeatils"))
    const [count , setCount] = useState()
    const [movenextbtn , setMovenextBtn] = useState(false)
    const [fetchLoader,setFetchLoader]=useState(false)
    
    const customername  = lotId.userdata.getData ? lotId.userdata.getData :{"customerName":"CustomerName"}
    const [paymentDetails, setpaymentDetails] = useState({
        "companyName": customername.customerName ? customername.customerName : "CompanyName",
        "bankName": "",
        "accountNo": "",
        "ifscCode": "",
        isPaymentReceived: false
    })

    const handelChange = (e) => {
        const { name, value } = e.target;
        setpaymentDetails({
            ...paymentDetails,
            [name]: value
        })
    }



    // const handleClose = () => {
    //     setOpen(false);
    // };
    const handleSelectChange = async (e, item) => {
        setfinalaizeBtn(false)
        const qurey = (e === 'Deselect')
        if (qurey) {
            const itemIndex = selectedoption.indexOf(item.value)
            selectedoption.splice(itemIndex, 1)
            const updateBids = [...bids];
            const index = updateBids.indexOf(item);
            updateBids[index] = { ...item };
            updateBids[index].value = null;
            setBids(updateBids)
        }
        if (!qurey) {
            setSelectedoption([...selectedoption, e])
            const updateBids = [...bids];
            const index = updateBids.indexOf(item);
            updateBids[index] = { ...item };
            updateBids[index].value = e;
            setBids(updateBids)
        }

        // const filteredOptions = selectOptions.filter((options)=>options !== e);
        // setSelectOptions(filteredOptions);
    }

    const disableSelect = (item) => {
        const arr = ['1', '2', '3']
        let filtered = selectedoption.filter((item, index) => selectedoption.indexOf(item) === index);
        const isSel = (JSON.stringify(arr) === JSON.stringify(filtered))
        // eslint-disable-next-line no-mixed-operators
        const isLost = (item.status === 'lost' || isSel && item.value === null)
        isLost ? setdisableOption(true) : setdisableOption(false)
    }



    const finalizeBid = async (e) => {
        e.preventDefault()
       
        const newSelectionValue = {
            progressStatus:"finalized",
            progressCode:1
        }
        const updatedLotDetalis = {...filteredLot,...newSelectionValue} 
        const data = {"data":updatedLotDetalis}
        await finalizeBids(bids, paymentDetails).then(response => {
            if (response.status === 200) {
                getLotBid()
                updateLot(data,lotId.selectedLot._id)
                setType("success")
                setMessage("Bids Finalized Successfully")
                setNotification(true)
                setTimeout(function () {
                    setOpen(false)
                }, 3000);
                setNumberFInalize()
            }
        })
      
        // let filtered = selectedoption.filter((item, index) => selectedoption.indexOf(item) === index);
        // setFilteredOptions(filtered)
        // setCount(filtered.length)
        // if(filtered.length === 1){
        //     setMovenextBtn(true)
        // }

    }    

    const moveNext = async () => {
        const changedbidArray = bids.map((bid) => {
            if (bid.value === '1') {
                const x = { ...bid, value: null }
                return x
            }
            if (bid.value === '2') {
                return { ...bid, value: '1' }
            }
            if (bid.value === '3') {
                const x = { ...bid, value: '2' }
                return x
            }
            if (bid.value === null || bid.value === '' || bid.value === "null") {
                const x = { ...bid, value: null }
                return x
            }
        })

        await finalizeBids(changedbidArray, paymentDetails).then(response => {
            if (response.status === 200) {
                setType("success")
                setMessage("Bids moved Successfully")
                setNotification(true)
                setpaymentRecivedBtn(true)
                getLotBid()
                setCount(count-1)
            }
        })
         diableMovenext() 
    }

    const setNumberFInalize = () =>{
        var max = bids.reduce((acc, data) => acc = acc > data.value ? acc : data.value, 0);
        setCount(max)
          
    }

    const setNumber = (data) =>{
        var max = data.reduce((acc, data) => acc = acc > data.value ? acc : data.value, 0);
        if(max ==='1'){
            setMovenextBtn(true)
        }
        setCount(max)
    }

    const diableMovenext = () =>{
       if(count <= 2){
           setMovenextBtn(true)
       }
       else{
        setMovenextBtn(false)
       }

    }
    const paymetRecived = async () => {
        const obj = ({ ...paymentDetails, isPaymentReceived: true })
        const newSelectionValue = {
            progressStatus:"payment Received",
            progressCode:2
        }
        const updatedLotDetalis = {...filteredLot,...newSelectionValue} 
        const data = {"data":updatedLotDetalis}
        const changedbidArray = bids.map((bid) => {
            if (bid.value === '1') {
                return { ...bid, value: "1" }
            }
            if (bid.value === '2') {
                return { ...bid, value: null }
            }
            if (bid.value === '3') {
                return { ...bid, value: null }
            }
            if (bid.value === null || bid.value === '') {
                const x = { ...bid, value: null }
                return x
            }
        })
        await PaymentRecived(changedbidArray, obj).then(response => {
            if (response.status === 200) {
                getLotBid()
                updateLot(data,lotId.selectedLot._id)
                setType("success")
                setMessage("Payment Recived Successfully")
                setNotification(true)
                setpaymentRecivedBtn(true)
               
            }
        })
    }


    const getLotBid = async () => {
        const id = lotId.selectedLot._id ? lotId.selectedLot._id : parseInt(localStorage.getItem("lotId"));
        setFetchLoader(true);
        try {
            const response = await getLotBids(id);
            if (response.status == 200) {
                setBids(response.data);
                setisFinalized(response.data[0]?.status === 'open');
                setpaymentRecivedBtn(response.data.some(bid => bid.status === "won"));
                setNumber(response.data);
                setFetchLoader(false)
            } else {
                setBids([]);
                setisFinalized(true);
                setFetchLoader(false);
                history.push("/app/marketplace");
            }

        } catch (error) {
            setFetchLoader(false);
            history.push("/app/marketplace");
        }
        localStorage.setItem("lotId", lotId.selectedLot._id);
    };
    
    useEffect(() => {
        getLotBid()
    }, [])


    var datatableData = []

    const selectHeader = () => {
        if (lotId.selectedLot.status === "closed") {
            return [
                {
                    label: t("mobile_number"),
                    options: {
                        filter: true,
                    },
                },
                {
                    label: t("gst"),
                    options: {
                        filter: true,
                    },
                },
                {
                    label: t("bidder_amount"),
                    options: {
                        filter: false,
                    }
                },
                {
                    label: t("bid_status"),
                    options: {
                        filter: false,
                    }
                },
                {
                    label: t("bid_date_time"),
                    options: {
                        filter: false,
                    }
                },
                {
                    label: t("bid_option"),
                    options: {
                        filter: false,
                    }
                },
            ]
        }

        else {
            return [
                {
                    label: t("mobile_number"),
                    options: {
                        filter: true,
                    },
                },
                {
                    label: t("gst"),
                    options: {
                        filter: true,
                    },
                },
                {
                    label: t("bidder_amount"),
                    options: {
                        filter: false,
                    }
                },
                {
                    label: t("bid_status"),
                    options: {
                        filter: false,
                    }
                },
                {
                    label: t("bid_date_time"),
                    options: {
                        filter: false,
                    }
                }
            ]
        }
    }

    const breadcrumb = [
        { label: t("common_home"), link: "/#/app/dashboard" },
        { label: t("common_lot"), link: "/#/app/marketplace" },
        { label: t("common_bids"), link: "" },
    ]

    const toolBar = () => {
        if (lotId.selectedLot.status === "closed") {
            if (!isFinalized) {
                return (
                    <div style={{display:'flex',alignItems:"center",justifyContent:"space-between"}}>
                        <Button variant="outlined" style={{ marginRight: '15px' }} href="/#/app/marketplace" className={classes.buttonOutlined} >
                            {t("common_back")}
                        </Button>

                        <Badge badgeContent={count-1} color="primary"  anchorOrigin={{vertical: 'top',horizontal: 'left',}}>
                        <Button variant="outlined" disabled={movenextbtn||paymentRecivedBtn} style={{ marginRight: '15px' }} onClick={() => moveNext()} color="primary" className={classes.buttonOutlined}>
                            {t("move_next")}
                        </Button>
                        </Badge>

                        <Button variant="outlined" disabled={paymentRecivedBtn} onClick={() => paymetRecived()} color="primary" className={classes.buttonOutlined}>
                            {t("payment_recived")}
                        </Button>

                    </div>
                )
            }
            else {
                return (
                    <div>
                        <Button href="/#/app/marketplace" variant="outlined" style={{ marginRight: '15px' }} className={classes.buttonOutlined}>
                            {t("common_back")}
                        </Button>
                        <Button variant="outlined" disabled={finalaizeBtn} onClick={() => setOpen(true)} color="primary" className={classes.buttonOutlined}>
                            {t("bid_finalize")}
                        </Button>
                    </div>
                )
            }
        }
        else {
            return (
                <>
                    <Button href="/#/app/marketplace" variant="outlined" className={classes.buttonOutlined}>
                        {t("common_back")}
                    </Button>
                </>
            )
        }


    }

    const modelBody = () => {
        return (
            <div className={classes.finalizepaper} >
                <form onSubmit={(e) => finalizeBid(e)}  >
                    <h1 className={classes.modal}>  {t("bank_details")}</h1>
                    <InputLabel style={{ padding: "5px" }} for="name" >  {t("company_name")}</InputLabel>
                    <TextField
                        style={{ padding: "5px" }}
                        id='name'
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        required
                        value={paymentDetails.companyName}
                        readOnly
                    />

                    <InputLabel style={{ padding: "5px" }} for="name" >  {t("bank_name")}</InputLabel>
                    <TextField
                        style={{ padding: "5px" }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        value={paymentDetails.bankName}
                        name="bankName"
                        onChange={(e) => handelChange(e)}
                    />

                    <InputLabel style={{ padding: "5px" }} for="name" >  {t("ifsc_code")}</InputLabel>
                    <TextField
                        style={{ padding: "5px" }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        value={paymentDetails.ifscCode}
                        name='ifscCode'
                        onChange={(e) => handelChange(e)}


                    />

                    <InputLabel style={{ padding: "5px" }} for="name" >{t("account_no")}</InputLabel>
                    <TextField
                        style={{ padding: "5px" }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        value={paymentDetails.accountNo}
                        name='accountNo'
                        onChange={(e) => handelChange(e)}


                    />
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: '10px' }}>
                        <Button style={{ marginRight: '10px' }} type="submit" variant='contained' color="primary"> {t("common_submit")}</Button>
                        <Button variant='contained' color="secondary" onClick={() => setOpen(false)}>{t("common_cancel")}</Button>
                    </div>
                </form>
            </div>
        )
    }

    const dropDownbody = (item) => {
        if (lotId.selectedLot.status === "closed") {
            return (
                <Select
                    variant="standard"
                    labelId="Bides Drop"
                    id="bid"
                    value={item.value === "Deselect" ? null : item.value}
                    onPointerEnter={() => disableSelect(item)}
                    onChange={(e) => {
                        handleSelectChange(e.target.value, item)
                    }}>
                    {
                        bidSelect.map((item, key) => {
                            return <MenuItem value={item} disabled={disableOption} key={key}>{item}</MenuItem>

                        })
                    }
                </Select>
            );
        }
        else {
            <></>
        }

    }

    const defaultUser = {
        "mobile": "123456789",
        "name": {
            "fname": "User Name"
        },
        "email": "usergamil@gmail.com",
        "orgName": null,
        "address": {
            "line1": null,
            "line2": null,
            "pin": null,
            "district": null,
            "state": null
        },
        "gst": "09AAACH7409R1ZZ",
        "pan": "",
        "notifyToken": "",
        "createdAt": "2022-05-20T08:32:22.626Z",
    }
    return <>
        <BreadCrumbs data={breadcrumb} />
        {(notification) ? <NotificationContainer message={message} notificationtype={type} /> : <></>}
        <div className={classes.content}>
            <div className={classes.pageTitleContainer}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <div className={classes.HeaderContainer} >
                                <div className={classes.tenantHeader}>
                                    <Typography variant="h3" size="sm"> {t("bid_span")}</Typography>
                                </div>
                                    <Typography style={isSmallScreen ? {display:"block"} : { textAlign:"center" }} variant="h5" size="sm">{t("base_price")} : {convertINR(lotId.selectedLot.basePrice)}</Typography>
                                <div style={{display:"flex",justifyContent:"flex-end"}}>
                                {toolBar()}
                                    </div>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <div >
                        <Card>
                            <TabPanel value={value} index={0} key={1}>
                                {
                                    bids && Array.isArray(bids) && bids?.map((item, key) => {
                                        const user = item.user[0] ? item.user[0] : defaultUser
                                        datatableData.push([
                                            user.mobile ? user.mobile : "mobile",
                                            user.gst ? user.gst : "-",
                                            item.amount ? convertINR(item.amount) : "Amount",
                                            item.status ? capitalize(item.status) : "Status",
                                            item.createdAt?moment(item.createdAt).format('DD/MM/YYYY hh:mm A'):"Date",
                                            <div style={{ marginTop: '1rem' }}>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                                        <FormControl variant="standard" className={classes.formControl}>
                                                            <InputLabel id="bid" ></InputLabel>

                                                            {dropDownbody(item)}

                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ])
                                    })
                                }
                                <Table style={{ backgroundColor: "red" }}
                                    data={datatableData}
                                    header={selectHeader()}
                                    name={`${t("bid_list")} : ${lotId.selectedLot.title}`}
                                    options={{
                                        filterType: 'checkbox',
                                        print: false,
                                        download: false,
                                        filter: false,
                                        selectableRows: 'none',
                                        textLabels: {
                                            body: {
                                              noMatch: fetchLoader ? (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <CircularProgress
                                                    style={{ color: "#0D6937", marginRight: "5px" }}
                                                  />{" "}
                                                  Loading...
                                                </div>
                                              ) : (
                                                "No Bids Found"
                                              ),
                                            },
                                          },
            
                                    }}
                                />
                            </TabPanel>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        </div>
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                closeAfterTransition
                // BackdropComponent={Backdrop}
                // BackdropProps={{
                //     timeout: 500,
                // }}
            >
                <Fade in={open}>
                    {modelBody()}
                </Fade>
            </Modal>
        </div>

    </>;
}
import {apiUrl} from '../../data/config'

export function uploadCertificate(data,dispatch)
{
    let url = apiUrl+'/v1/tenant/uploadCert'
    
    return fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body : JSON.stringify(data)
    }).then(response => {
        return response.json().then((data) => {
            if(data.status === "200"){
                return data
            } else if(data.status === "500"){
                dispatch({
                    type : 'LOG_OUT'
                })
                localStorage.clear()
               return{
                   status : "500",
                   message : "Something Went Wrong!"
               }
            }
            
        })
    })
}

export function getCertificateData(data, dispatch){
    let url = apiUrl+'/v1/tenant/getCertData';
    
    return fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json().then((data) => {
            if(data.status === "200"){
                return data;
            } else if(data.status === "500"){
                return {
                    status: "500",
                    message: "Something Went Wrong!"
                };
            } else if(data.status === "401"){
                dispatch({
                    type : 'LOG_OUT'
                });
                localStorage.clear();
                return data;
            }
        });
    }).catch(error => {
        return {
            "status": "500",
            "message": "Network error. Please try again later."
        };
    });
}

export function getCustomerDetails(data)
{
    let url = apiUrl+'/v1/customer/adminData'
    try{
        return fetch(url,{
            method:'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type':'application/json',
                'token' : localStorage.getItem('token')
            },
            body : JSON.stringify(data)
        }).then(response => {
            return response.json().then((data) => {
                if(response.ok){
                    if(data.status === "200"){
                        return data
                    } else if(data.status === "500"){
                       return{
                           status : "500",
                           message : "Something Went Wrong!"
                       }
                    }
                }else{
                    return{
                        status : "500",
                        message : "Something Went Wrong!"
                    }
                }
               
            })
        })
    }catch(e){
        console.error(e)
    }
   
}

export function updatePanGst(data){
    let url = apiUrl+'/v1/customer/saveCustomerUpdate'
    return fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body : JSON.stringify(data)
    }).then(response => {
        return response.json().then((data) => {
            if(response.ok){
                return data
            }else {
                return{
                    "status" : "500",
                    "message" : "Something went wrong"
                }
            }
        })
    })

}
import React,{useState} from 'react'
import { Grid,Paper,Divider,Avatar,Button,CircularProgress } from '@mui/material'
import classnames from "classnames";
import moment from 'moment'
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import {useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next'

import useStyles from "./styles";
import NotificationContainer from '../../pages/notifications/NotificationContainer';



const Users = ({list,show,reject,quotation,inspection,payment,invoice,schedule,formsix,form6Data,closeLead,closeAll}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const loginData = useSelector(state => state.userdata)
    const [selectedButton, setSelectedButton] = useState(null); 
    // eslint-disable-next-line
    const [message,setMessage] = useState("")
    // eslint-disable-next-line
    const [notification,setNotification] = useState(false)
    // eslint-disable-next-line
    const [type,setType] = useState("")
    // eslint-disable-next-line
    const [showloader, setShowLoader] = useState(false)
    let actions = []
    let form6Button = ""
    let won_disable = "" 
    let assign_disable =" "
    let close_disable = false
    if(Object.keys(form6Data).length >0 ){
        form6Button = false
    }else{
        form6Button = true
    }
    
    if(list && Object.keys(list).length > 0 && (list.reason).toLowerCase()  === ("Won").toLowerCase()){
        won_disable = false
    }else{
        won_disable = true
    }
    
    if(list && Object.keys(list).length > 0 && (list.status).toLowerCase() === ("Closed").toLowerCase() &&  (list.reason).toLowerCase()  === ("rejected").toLowerCase()){
        close_disable = true
    }
    if(list && Object.keys(list).length > 0 && (list.status).toLowerCase()  === ("Open").toLowerCase()){
        assign_disable = true
    }else{
        assign_disable = false
    }
   


    if( 
        loginData && loginData.getData && !loginData.getData.roles.includes('user')){
        actions = [
            {title : t("common_assign")+" "+t("common_lead") , icon : <PersonAddIcon/>,func: show,disable:close_disable},
            {title : t("common_reject")+" "+t("common_lead") , icon : <CancelIcon />,func:reject,disable:close_disable},
            {title : t("mpr_inspection") , icon : <HowToRegIcon/>,func: inspection,disable:assign_disable},
            {title : t("common_quotations") , icon : <DescriptionIcon />,func:quotation,disable:assign_disable},
            {title : t("common_make_payment") , icon : <CreditCardIcon />,func:payment,disable:won_disable},
            {title : t("mpr_invoice") ,icon : <ReceiptIcon />,func: invoice,disable:won_disable},
            {title : t("common_materialpickup"),icon : <LocalShippingIcon/>,func:schedule,disable:won_disable},
            // eslint-disable-next-line
            {title : t("common_form")+""+"-6",icon: <DescriptionIcon />, func: formsix,disable:form6Button},
            {title : t("common_close"),icon : <CancelPresentationIcon />,func:closeLead,disable:close_disable},
            {title : t("common_send_message"), icon : <SendIcon />,href:"/#/app/messages",disable:true},
        ]
    }
    else if( loginData && loginData.getData && loginData.getData.roles.includes('user')){
        actions = [
            {title : t("mpr_inspection") , icon : <HowToRegIcon/>,func: inspection,disable:close_disable},
            {title : t("common_quotations") , icon : <DescriptionIcon />,func:quotation,disable:close_disable},
            {title : t("common_make_payment") , icon : <CreditCardIcon />,func:payment,disable:won_disable},
            {title : t("mpr_invoice") ,icon : <ReceiptIcon />,func: invoice,disable:won_disable},
            {title : t("common_materialpickup"),icon : <LocalShippingIcon/>,func:schedule,disable:won_disable},
            // eslint-disable-next-line
            {title : t("common_form")+""+"-6",icon: <DescriptionIcon />, func: formsix,disable:form6Button },
            {title : t("common_close"),icon : <CancelPresentationIcon />,func:closeLead,disable:close_disable},
            {title : t("common_send_message"), icon : <SendIcon />,href:"/#/app/messages",disable:true},
        ]
    }
    
    
    return(
        <Paper className={classes.paper} classes={{ root: classnames(classes.widgetRoot, {
            [classes.noWidgetShadow] :null
            }) }}>
            <Grid container >
                {
                    (notification)?<NotificationContainer message={message} notificationtype={type} />:<></>
                }
                <Grid item md={3} xs={3} className={classes.avatargrid}>
                    <Avatar className={classes.avatar}>L</Avatar>
                </Grid>
                <Grid item md={9} xs={9}>
                <h4 style={{wordBreak:'break-all'}}>{(list)&& list.external_id}</h4>
                <p>
                    {t("lead_generated_on")} : {(list) && moment(list.createdAt).format('DD-MM-YYYY')} <br/> 
                    {t("common_status")} : {(list) && (list?.status === "Open" ? "Unassigned ": list?.status?.charAt(0).toUpperCase() + list?.status?.slice(1))}</p>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item  xs={12} className={classes.userActionGrid}>
                    {
                        actions.map((item,key) => {
                            return(
                                <Grid item xs={12} key={key} className={classes.userActionGrid}>
                                    {
                                        (item.title === t("common_close") && showloader)?<CircularProgress />:<Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        startIcon={item.icon}
                                        className={`${classes.buttonOutlined} ${selectedButton === item.title && !closeAll ? classes.selectedBtn : ''}`}
                                        style={{
                                            width: '100%',
                                            justifyContent: 'unset',
                                           
                                        }}
                                        onClick={() => {
                                            setSelectedButton(item.title);
                                            item.func()
                                          }}
                                        href={item.href}
                                        disabled ={item.disable}
                                    >
                                        {item.title}
                                    </Button>
                                    }
                                    
                                </Grid>
                            )
                        })
                    }
                    
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Users
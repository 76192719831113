import {apiUrl} from '../../data/config'


export function getMessages(data){
    let url = apiUrl+'/v1/mprMessage'
    
    return fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(response => {
        if(response.ok){
            return response.json().then((data) => {
                return data
            })
        }
    })

}
import React,{useEffect, useState} from "react";
import {
  Grid,
  Paper,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button, } from '@mui/material';

import classnames from "classnames";
import {useSelector} from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
  //components
import useStyles from "../styles";

import Forms from '../../contacts/components/Forms'
import AddressForm from '../../address/components/AddressForm'

const ContactDetails = ({ setForm, formData }) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  var address = useSelector(state => state.mprData.address)
  var contact = useSelector(state => state.mprData.contacts)
  const getdata = useSelector((state) => state.userdata.getData )
  const[add,setAdd] = useState((formData.contact_address === "")?JSON.stringify(address[0]):formData.contact_address)
  const [cont,setCont] = useState(formData.contact_contact)
  const [showAddressDiv,setShowAddressDiv] = useState(false)
  const [showContactDiv,setShowContactDiv] = useState(false)
 
  formData.contact_address = add
  
  const handleChange = (event) => { 
    setAdd(event.target.value)
    formData.contact_address = event.target.value
  }

  const handleContactChange = (event) => {
    setCont(event.target.value)
    formData.contact_contact = event.target.value
  }

  const handleAddressClickOpen = () => {
    setShowAddressDiv(true)
  }
  const handleAddressClickClose = () => {
    setShowAddressDiv(false)
  }
  const handleContactClickOpen = () => {
    setShowContactDiv(true)
  }
  const handleContactClickClose = () => {
    setShowContactDiv(false)
  }

  useEffect(()=>{},[add])

  return (
    <Grid container spacing={3}>
    <Grid item lg={3} md={1} xs={0} sm={0} ></Grid>
    <Grid item lg={6} md={10} xs={12} sm={12}>
  <Paper className={classes.paper} classes={{ root: classnames(classes.widgetRoot, {
      [classes.noWidgetShadow] :null
      }) }}>
  <div className={classes.form} >
     <Grid container spacing={3}> 

      <Grid item lg={12} md={10} xs={12}  sm={12} >
        <Grid item lg={12}><h3 className={classes.title}>{t("mpr_contact_details")}</h3></Grid>   
        {
          (showAddressDiv)? <> <h3>{t("common_add_address")}</h3><Paper className={classes.formPaper}> <AddressForm close={handleAddressClickClose} where="modal"/> </Paper> </>: 
          <div>
            <h3>{t("common_select_address")}</h3>
        <RadioGroup aria-label="adress" name="address" value={add} onChange={handleChange}>
          <Grid container spacing={3}>
        {
         (address.length >0) && address.map((item,key) => {
          let addressParts = [
            item.address.address1,
            item.address.address2
          ].filter(Boolean).join(', ');
          
          let value1 = [
            addressParts,
            item.district,
            item.state,
            item.pincode
          ].filter(Boolean).join(', ');
          
          value1 = addressParts ? value1 : value1;
          
            return(
              <Grid item lg={6} md={6} xs={12} sm={12} key={key}>
                <Paper className={classes.cardPaper}>
                  <FormControlLabel value={JSON.stringify(item)} control={<Radio />} label={value1} />
                </Paper>
              </Grid>
            )
          })
        }
          <Grid item lg={6} md={6} xs={12} sm={12}>
            {
            getdata.roles.includes("Customer_Admin") ?
              <Button  size="small" onClick={handleAddressClickOpen}  variant="outlined" color="primary"   startIcon={<AddIcon />}>
                {t("common_add_address")}
              </Button> : <></>
            }
          </Grid> 
        </Grid>
        </RadioGroup>
          </div>
        }
        
        <hr />
        
       {
         (showContactDiv)?<><h3>{t("common_add_contact")}</h3> <Forms close={handleContactClickClose} where="modal"/> </>: <div>
         <h3>{t("common_select_contact")}</h3> <RadioGroup aria-label="contact" name="contact" value={cont} onChange={handleContactChange}>
           
         <Grid container spacing={3}>
           
       {
        (contact.length >0) &&  contact.map((item,key) => {
           let value1 = item.Name+", "+item.email+", "+item.mobile.code+" "+item.mobile.number
           return(
             <Grid item lg={6} md={6} xs={12} sm={12} key={key}>
               <Paper className={classes.cardPaper}>
                 <FormControlLabel style={{wordBreak:'break-all'}} value={JSON.stringify(item)} control={<Radio />} label={value1} />
               </Paper>
             </Grid>
           )
         })
       }
         <Grid item lg={6} md={6} xs={12} sm={12}>
           {
             !getdata.roles.includes("Customer_Admin")?<Button  size="small" variant="outlined" color="primary" onClick={handleContactClickOpen}  startIcon={<AddIcon />}>
             {t("common_add_contact")}
            </Button> : <></>
           }
           
         </Grid>
       </Grid>
       </RadioGroup></div>
       }
      </Grid>
      
    </Grid>
  </div>
  </Paper>
  </Grid>
  <Grid item lg={3} md={1} xs={1} sm={0} ></Grid>
  </Grid>
  );
};

export default ContactDetails;

import React from "react";
import eventsList from "../../../data/events";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

moment.locale("en");
// Calander.momentLocalizer(moment);
const localizer = momentLocalizer(moment)
//const allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);

const CalenderView = () => {
  return (
    <div style={{ height: 550, paddingTop: 0, paddingLeft: 10 }}>
      <Calendar localizer={localizer} style={{ height: 500, width: 900 }} events={eventsList} />
    </div>
  );
};

export default CalenderView;

import React, { useEffect, useState } from "react";
import { Switch, withRouter } from "react-router-dom";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import CustomRoute from "./CustomRoute";

// pages
import Dashboard from "../../pages/dashboard/Dashboard";

import Customers from "../../pages/customers/Customers";
import AddCustomer from "../../pages/customers/AddCustomer";
import Contacts from "../../pages/contacts/Contacts";
import AddContact from "../../pages/contacts/AddContact";
import TenantBranch from "../../pages/tenent_branch/TenentBranch";
import AddBranch from "../../pages/tenent_branch/AddBranch";
import Setting from "../../pages/setting/Setting";
import Administration from "../../pages/administration";
import Leads from "../../pages/leads/Leads";
import ViewLead from "../../pages/leads/ViewLead";
import AddLead from "../../pages/leads/AddLead";
import viewInspectionRequests from "../../pages/material_pickup_request/pages/inspection_details/ViewInspectionDetails";
import viewQuotationDetails from "../../pages/material_pickup_request/pages/inspection_details/ViewQuotationDetails";
import Mpr from "../../pages/material_pickup_request/Mpr";
import AddMpr from "../../pages/material_pickup_request/AddMpr";
import ViewMpr from "../../pages/material_pickup_request/ViewMpr";
import Address from "../../pages/address/Address";
import Message from "../../pages/messages/Message";
import Profile from "../../pages/profile/Profile";
import Details from "../../pages/setting/Details";
// import addressForm from "../../pages/address/components/AddressForm"

import ViewInspectionDetails from "../../pages/leads/pages/inspection_details/ViewInspectionDetails";

// context
import { useLayoutState } from "../../context/LayoutContext";
import AddressForms from "../../pages/address/components/AddressForm";

import { getCertificateData } from "../../pages/setting/SettingState";
import { getData } from "../../pages/LoginContainer/LoginState";
import CustomerBranch from "../../pages/administration/customerBranch/CustomerBranch";
import AddCustomerBranch from "../../pages/administration/customerBranch/AddCustomerBranch";

// marketplace
import Lots from "../../pages/marketplace/Lots";
import Tags from "../../pages/marketplace/Tags";
import AddLot from "../../pages/marketplace/AddLot";
import AddTag from "../../pages/marketplace/AddTags";
import EditLot from "../../pages/marketplace/components/EditLot";
import ViewBids from "../../pages/marketplace/components/ViewBids";
import MPUsers from "../../pages/marketplace/pages/MPUsers";
import AddMPUser from "../../pages/marketplace/AddMPUser";
import EditMPUser from "../../pages/marketplace/components/EditMPUser";

// unclassified leads
import InquiryLeads from "../../pages/inquiryLeads/InquiryLeads";

import InquiryLeadView from "../../pages/inquiryLeads/components/InquiryLeadView";
import EditInquiryLead from "../../pages/inquiryLeads/components/EditInquiryLead";
import DuplicateInquiries from "../../pages/inquiryLeads/components/DuplicateInquiries";
import Error from "../../pages/error/Error";

//internal forms
import InternalForms from "../../pages/internalForms/InternalForms";
import EditPaymentRequest from "../../pages/internalForms/paymentRequests/supplierCustomer/EditPaymentRequest";
import EditVendorRequest from "../../pages/internalForms/paymentRequests/vendor/EditVendorRequest";
import EditReimbursement from "../../pages/internalForms/reimbursements/EditReimbursement.js";
import EditMiscellaneousReq from "../../pages/internalForms/miscellaneousRequests/EditMiscellaneousReq.js";
function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  const dispatch = useDispatch();
  var type = localStorage.getItem("type");
  const [verified, setVerified] = useState("");
  const [valid, setValid] = useState("");
  //
  var data = useSelector((state) => state.userdata);
  var custid = data.getData && data.getData.customerid;
  var roles = data.getData && data.getData.roles;

  const callHandleData = () => {
    let bodyData = {
      tenID: custid,
    };
    getCertificateData(bodyData, dispatch).then((resp) => {
      if (resp.status === "200") {
        if (resp.tenantData !== null && resp.tenantData !== undefined) {
          setVerified(resp.tenantData.isVerified);
          setValid(resp.tenantData.isValid);
        }
      }
    });
  };

  useEffect(() => {
    const localStorageType = localStorage.getItem("type");
    if (localStorageType === "tenant") {
      callHandleData();
    }
    getData(dispatch);
    // eslint-disable-next-line
  }, [verified]);

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} data={data} />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <Switch>
            <CustomRoute
              exact
              path="/app/dashboard"
              component={Dashboard}
              type={type}
            />
            <CustomRoute path="/app/profile" component={Profile} type={type} />
            <CustomRoute path="/app/messages" component={Message} type={type} />
            <CustomRoute path="/app/setting" component={Setting} type={type} />
            <CustomRoute path="/app/details" component={Details} type={type} />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              exact
              path="/app/customers"
              component={Customers}
              type={type}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/addCustomer"
              component={AddCustomer}
              type={type}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/tenent_branch"
              component={TenantBranch}
              type={type}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/addBranch"
              component={AddBranch}
              type={type}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/admin"
              component={Administration}
              type={type}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/leads"
              component={Leads}
              type={type}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/AddLead"
              component={AddLead}
              type={type}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/ViewLead"
              component={ViewLead}
              type={type}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/viewLeadInspectionRequests"
              type={type}
              component={ViewInspectionDetails}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/viewLeadQuotationDetails"
              type={type}
              component={viewQuotationDetails}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/tenant/addMPR"
              type={type}
              component={AddMpr}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/Recyclercontacts"
              component={Contacts}
              type={type}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/addRecyclerContact"
              component={AddContact}
              type={type}
            />

            {/* unclassifed leads */}
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/inquiryLeads"
              component={InquiryLeads}
            />
            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/unClassifiedLeadView"
              component={InquiryLeadView}
            />

            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/editInquiryLeads"
              component={EditInquiryLead}
            />

            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/duplicateInquiries"
              component={DuplicateInquiries}
            />

            <CustomRoute
              condition="Customer"
              path="/app/viewInspectionRequests"
              type={type}
              component={viewInspectionRequests}
            />
            <CustomRoute
              condition="Customer"
              path="/app/viewQuotationDetails"
              type={type}
              component={viewQuotationDetails}
            />
            <CustomRoute
              condition="Customer"
              path="/app/MPR"
              type={type}
              component={Mpr}
              role={roles}
            />
            <CustomRoute
              condition="Customer"
              path="/app/addMPR"
              type={type}
              component={AddMpr}
            />
            <CustomRoute
              condition="Customer"
              path="/app/viewMPR"
              type={type}
              component={ViewMpr}
            />
            <CustomRoute
              condition="Customer"
              path="/app/customeradmin"
              component={Administration}
              type={type}
            />
            <CustomRoute
              condition="Customer"
              path="/app/contacts"
              component={Contacts}
              type={type}
            />
            <CustomRoute
              condition="Customer"
              path="/app/addContact"
              component={AddContact}
              type={type}
            />
            <CustomRoute
              condition="Customer"
              path="/app/address"
              component={Address}
              type={type}
            />
            <CustomRoute
              condition="Customer"
              path="/app/addressForm"
              component={AddContact}
              type={type}
            />
            <CustomRoute
              condition="Customer"
              path="/app/addAdress"
              component={AddressForms}
              type={type}
            />
            <CustomRoute
              condition="Customer"
              path="/app/customerBranch"
              component={CustomerBranch}
              type={type}
              role={roles}
            />
            <CustomRoute
              condition="Customer"
              path="/app/addCustomerBranch"
              component={AddCustomerBranch}
              type={type}
            />

            {/* internal forms */}

            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/internalForms"
              component={InternalForms}
            />

            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/editSupplierPayment"
              component={EditPaymentRequest}
            />

            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/editVendorPayment"
              component={EditVendorRequest}
            />

            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/editReimbursement"
              component={EditReimbursement}
            />

            <CustomRoute
              verified={verified}
              role={roles}
              valid={valid}
              condition="tenant"
              path="/app/miscellaneousRequest"
              component={EditMiscellaneousReq}
            />

            {/* marketplace */}
            <CustomRoute path="/app/marketplace" component={Lots} />
            <CustomRoute path="/app/listTags" component={Tags} />
            <CustomRoute path="/app/addLots" component={AddLot} />
            <CustomRoute path="/app/addTags" component={AddTag} />
            <CustomRoute path="/app/editLot" component={EditLot} />
            <CustomRoute path="/app/viewBids" component={ViewBids} />
            <CustomRoute path="/app/mpUsers" component={MPUsers} />
            <CustomRoute path="/app/addMPUser" component={AddMPUser} />
            <CustomRoute
              path="/app/editMPUser/:userId"
              component={EditMPUser}
            />
            <CustomRoute path="*" component={Error} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);

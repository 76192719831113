import React from "react";
import { Grid, Card, Typography, Button, Box } from "@mui/material";
import ClipboardCopy from "../../inquiryLeads/components/ClipboardCopy";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const HeaderComp = ({ selectedData, selectedStatus, backUrlPayload }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBackToPrevious = () => {
    dispatch({ type: "SET_BACK_TAB", payload: backUrlPayload });
    history.push("/app/internalForms");
  };

  const idToDisplay =
    selectedData?.misReqId ||
    selectedData?.paymentId ||
    selectedData?.reimburseId;

  return (
    <Card sx={{ width: "100%" }}>
      <Box p={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={5}>
            <Box display="flex" alignItems="center">
              <Typography variant="h5" mr={1}>
                {t("Edit")} {idToDisplay}
              </Typography>
              <ClipboardCopy copyText={idToDisplay} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5">{selectedStatus}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={handleBackToPrevious}
                color="primary"
                className={classes.tagButton}
              >
                {t("Back")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default HeaderComp;

import React,{useEffect} from "react";
import { 
  Grid,
  Card,
 } from "@mui/material";
 import {useDispatch,useSelector} from 'react-redux'
 import { useTranslation } from 'react-i18next';

//components
import { Typography } from "../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import TabPannel from './components/TabPannel'
import CustomButton from "../../components/CustomButton";
import {getMprList} from './MprState'


const Mpr = () => {
    var classes = useStyles();
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const { t, i18n } = useTranslation();
    const getdata = useSelector((state) => state.userdata.getData )
    useEffect(()=>{
      let branchid = getdata? getdata.thisCustomerBranch  : ""
      getMprList(dispatch,branchid)
      // eslint-disable-next-line
    },[])
    const MPRData = [
      { label: t("common_home"), link: "/#/app/dashboard" },
      {label: t("common_material_pickup") , link: ""},
    ]
    return (
        <>
          <BreadCrumbs data={MPRData} />
            <div className={classes.content}>
            <div className={classes.pageTitleContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <Card>
                    <div className={classes.HeaderContainer} >
                        <Typography variant="h3" size="sm" className={classes.littleMargin}>{t( "all_material_pickup_requests")}</Typography> 
                        <CustomButton variant="outlined" link="/#/app/addMPR" title={t("mpr_add_new")} color="success" />
                    </div>
                  </Card> 
                </Grid>
              </Grid>
            </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TabPannel />
            </Grid>
          </Grid>
        </div>
      </>
    )
}
export default Mpr
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Typography } from "@mui/material";

const Graph = ({ title, amountData, soldData, yaxisLabel, formatter }) => {
  const chartData = [
    { name: "Jan", amount: amountData[0], sold: soldData ? soldData[0] : null },
    { name: "Feb", amount: amountData[1], sold: soldData ? soldData[1] : null },
    { name: "Mar", amount: amountData[2], sold: soldData ? soldData[2] : null },
    { name: "Apr", amount: amountData[3], sold: soldData ? soldData[3] : null },
    { name: "May", amount: amountData[4], sold: soldData ? soldData[4] : null },
    { name: "Jun", amount: amountData[5], sold: soldData ? soldData[5] : null },
    { name: "Jul", amount: amountData[6], sold: soldData ? soldData[6] : null },
    { name: "Aug", amount: amountData[7], sold: soldData ? soldData[7] : null },
    { name: "Sep", amount: amountData[8], sold: soldData ? soldData[8] : null },
    { name: "Oct", amount: amountData[9], sold: soldData ? soldData[9] : null },
    {
      name: "Nov",
      amount: amountData[10],
      sold: soldData ? soldData[10] : null,
    },
    {
      name: "Dec",
      amount: amountData[11],
      sold: soldData ? soldData[11] : null,
    },
  ];

  return (
    <div className="app" style={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h6" align="center" gutterBottom>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height={360}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{ fontSize: 12 }} />
          <YAxis
            tick={{ fontSize: 8 }}
            tickFormatter={(value) => formatter(value)}
          />
          <Tooltip formatter={formatter} />
          <Legend />
          <Line
            type="monotone"
            dataKey="amount"
            stroke="#0e6837"
            name="Amount"
          />
          {soldData && (
            <Line
              type="monotone"
              dataKey="sold"
              stroke="#1976d2"
              name="Sold Amount"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Graph;

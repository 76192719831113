import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(2),
        button: {
          display: 'block',
          marginTop: theme.spacing(2),
        },
        formControl: {
          margin: theme.spacing(1),
          width: "100%"
        },
      },
    },
    paper:{
      margin: "50px auto ",
      padding: "50px",
      [theme.breakpoints.up("sm")]: {
        width: "600px",
       },
    },
    form:{
      padding: theme.spacing(4),
      paddingTop:theme.spacing(0),
      marginTop: '3rem',
      [theme.breakpoints.up("md")]: {
        // paddingTop:theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        // padding: theme.spacing(0),
      },
    },
    content: {
      padding: theme.spacing(6),
      paddingTop: theme.spacing(12),
      [theme.breakpoints.only("sm")]: {
        padding: theme.spacing(1), 
        paddingTop: theme.spacing(12),
      },
      [theme.breakpoints.only("xs")]: {
        padding: theme.spacing(0), 
        paddingTop: theme.spacing(12),
      },
    },
    fileTypeInput:{
      marginTop:'1rem',
      borderBottom:'1px solid',
      borderColor:"rgba(0, 0, 0, 0.42)",
      padding:'7px',
    },
    PickupformField:{
      backgroundColor:'#fff',
      padding:'4rem',
      marginRight:'1rem',
    },
    certificateLabel:{
      paddingBottom:theme.spacing(3)
    },
    collapseIcon:{
      cursor:'pointer',
      color: theme.palette.primary.main,
      "&:hover": {
          //color: theme.palette.secondary.main
      }
  },
  certText:{
    padding: theme.spacing(1),
    textAlign:'center',
    border:'1px solid',
    borderColor: theme.palette.primary.main
  },
  detailsButton:{
    paddingTop:'1rem',
    textAlign:'right'
  },
  margin:{
    margin:"10px 0"
  }
  }));
  export default useStyles;
import React from "react";
import { Grid,Paper,Tooltip, IconButton,Link } from '@mui/material'
import {
    ExpandLess as DropUpIcon,
    List as ListIcon,
    Event as EventIcon
}  from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

//components
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs'
import useStyles from "../../components/styles";

import Table from '../../../../components/Table/Table'
import CalenderView from '../../components/CalenderView'
import Users from "../../components/User";

const ViewInspectionDetails = () => {
    var classes = useStyles();
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();

    const [isGrid , setisGrid] = React.useState(false)
    const breadcrumb_viewTenant = [
        {label: t("common_material_pickup") , link: "/#/app/MPR"},
        {label: "df" , link: ""},
    ]
    const userData = [
        ["Alex","alex@binbag.com","Active","10-05-2021 9:00-11:00"],
        ["Ameer", "ameer@binbag.com","Active","10-05-2021 3:00-5:00"],
        ["Jack","jack@binbag.com","InActive" , "14-05-2021 4:00 - 6:00"]
    ]
    const userHeader = [t("recycler"),t("recycler_mail_id"),t("common_status"),t("common_time")+" "+t("common_slot")]
    return <>
    <BreadCrumbs data={breadcrumb_viewTenant}/>
        <div className={classes.content}>
            <Grid container spacing={3}>
                    <Grid item lg={3} md={12} xs={12} sm={12} style={{paddingTop:'0px'}}>
                        <Users />
                    </Grid>
                    <Grid item lg={9} md={12} xs={12} sm={12} >
                        <Paper className={classes.Datapaper}>
                            <Grid container spacing={3} className={classes.grid}>
                            <Grid item lg={8}>
                                <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("mpr_inspection_requests")}</span>
                                
                            </Grid>
                            <Grid item lg={1}>
                            
                            </Grid>
                            <Grid item xs={2} className={classes.backIconGrid}>
                                <Link
                                    className={classes.backIcon}
                                    href="/#/app/viewMpr"
                                    color="primary"
                                    underline="hover">{t("common_back")}</Link>
                            </Grid>
                            <Grid item xs={1} className={classes.iconGrid}>
                            <Tooltip title="Back" aria-label="Back">
                                <IconButton className={classes.collapseIcon} href="/#/app/viewMpr" size="large">
                                    <DropUpIcon />
                                </IconButton>
                                </Tooltip>
                            </Grid>
                                
                               
                                    <Grid item xs={12} className={classes.grid}>
                                    {
                                        (isGrid)?
                                        <Paper className={classes.Datapaper} elevation={3}>
                                            <Grid container spacing={1}>
                                                <Grid item lg={12}  className={classes.iconGrid}>
                                                <Tooltip title="List View">
                                                    <IconButton
                                                        className={classes.collapseIcon}
                                                        onClick={()=>setisGrid(!isGrid)}
                                                        size="large">
                                                        <ListIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item lg={12}>
                                                    <CalenderView />
                                                </Grid>
                                            </Grid>
                                            
                                        </Paper>: <Table 
                                        data={userData} 
                                        header={userHeader} 
                                        name="" 
                                        options={{
                                            filterType: 'checkbox',
                                            print: false,
                                            download: false,
                                            filter:false,
                                            customToolbar: () => {
                                                return (
                                                    <Tooltip title="Calender View" >
                                                    <IconButton
                                                        style={{ order: 1 }}
                                                        className={classes.collapseIcon}
                                                        onClick={()=>setisGrid(!isGrid)}
                                                        size="large">
                                                      <EventIcon />
                                                    </IconButton>
                                                    </Tooltip>
                                                );
                                              },
                                        }} />
                                    }
                                   
                                    </Grid>
                               
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>    
                
        </div>
    </>;
};

export default ViewInspectionDetails;
import { IconButton, Tooltip, Box, Typography } from "@mui/material";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import React, { useState } from "react";

const RenderAttachments = ({ attachments, handleDownload }) => {
  const [openedFiles, setOpenedFiles] = useState({});

  const handleFileOpen = (item) => {
    handleDownload(item);
    setOpenedFiles((prev) => ({ ...prev, [item.fileName]: true }));
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="p" fontWeight="600" fontSize="14px" pr={1}>
        Attachments:
      </Typography>
      {attachments?.length > 0 ? (
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          // justifyContent="space-between"
        >
          <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
            {attachments.map((item) => (
              <Tooltip key={item.fileName} title={item.fileName}>
                <IconButton
                  onClick={() => handleFileOpen(item)}
                  color={openedFiles[item.fileName] ? "primary" : "default"}
                >
                  <FilePresentIcon />
                </IconButton>
              </Tooltip>
            ))}
          </Box>
        </Box>
      ) : (
        <Typography variant="p" style={{ fontSize: 14 }}>
          No files uploaded
        </Typography>
      )}
    </Box>
  );
};

export default RenderAttachments;

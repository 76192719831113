import FormControl from "@mui/material/FormControl";
import { Box, Grid } from "@mui/material";
import {
  Typography,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Select,
  MenuItem,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "../styles";
import NotificationContainer from "../../notifications/NotificationContainer";
import { formatIndianCurrency } from "../../../utils/currencyFormat";
import { ConfirmationDialog } from "../components/ConfirmationDialog";
import moment from "moment";
import {
  actionOnReimbursement,
  getFile,
  getPaymentHistoryStatus,
} from "../InternalFormState";
import StatusHistory from "../components/StatusHistory";
import HeaderComp from "../components/HeaderComp";
import RenderAttachments from "../components/RenderAttachments";

const EditReimbursement = () => {
  var classes = useStyles();
  const reimburseData = useSelector((state) => state.selectedReimbursement);
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedData, setSelectedData] = useState(reimburseData);
  const loggedInData = useSelector((state) => state.userdata.getData);
  const [selectedStatus, setSelectedStatus] = useState(selectedData?.status);
  const [isViewer, setIsViewer] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isAuthorizer, setIsAuthorizer] = useState(false);
  const [isOnlyUser, setIsOnlyUser] = useState(false);
  const [isSameApprover, setIsSameApprover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusHistory, setStatusHistoy] = useState([]);
  const { t, i18n } = useTranslation();

  const allStatus = ["Pending", "Approved", "Completed", "Rejected"];
  const [notification, setNotification] = useState({
    message: "",
    notify: false,
    type: "",
  });

  //confirm dialog box
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedStatus(selectedData?.status);
  };

  const handleConfirmDialog = (
    confirm,
    approverComments,
    rejectionReason,
    transactionID,
    adminComments,
    uploadedFiles
  ) => {
    if (confirm) {
      takeActionOnReimbursementRequest(
        selectedData,
        selectedStatus,
        rejectionReason,
        approverComments,
        transactionID,
        adminComments,
        uploadedFiles
      );
    } else {
      setSelectedStatus(selectedData?.status);
      setDialogOpen(false);
    }
  };

  const handleStatusChange = (event) => {
    let selectedValue = event.target.value;
    handleOpenDialog();
    setSelectedStatus(selectedValue);

    if (
      selectedData &&
      loggedInData?._id == selectedData?.raisedBy?.[0]?._id &&
      loggedInData?.roles.some((item) => item === "Approver")
    ) {
      setIsSameApprover(true);
    } else {
      setIsSameApprover(false);
    }
  };

  useEffect(() => {
    if (
      loggedInData &&
      loggedInData?.roles.some((item) => "user" === item) &&
      loggedInData?.roles.some((item) => "Viewer" === item)
    ) {
      setIsViewer(true);
    } else if (
      loggedInData &&
      loggedInData?.roles.some((item) => "user" === item) &&
      loggedInData?.roles.length === 1
    ) {
      setIsOnlyUser(true);
    } else if (loggedInData?.roles.some((item) => "Approver" === item)) {
      setIsApprover(true);
    } else if (loggedInData?.roles.some((item) => "Authorizer" === item)) {
      setIsAuthorizer(true);
    }
    getStatusHistory();
  }, []);

  useEffect(() => {
    if (selectedData == undefined) {
      history.push("/app/internalForms");
    }
  }, [reimburseData]);

  const updateSupplierBuyerState = (data) => {
    dispatch({
      type: "SET_SELECTED_REIMBURSEMENT",
      payload: data,
    });
  };

  const handleBackToPrevious = () => {
    dispatch({
      type: "SET_BACK_TAB",
      payload: "reimbursementComp",
    });
    history.push("/app/internalForms");
  };

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          setNotification({
            message: response.message,
            notify: true,
            type: "success",
          });
        }
      })
      .catch((error) => {
        setNotification({
          message: "An error occurred while downloading the file.",
          notify: true,
          type: "error",
        });
      });
  };

  async function getStatusHistory() {
    let updatedFormData = {
      paymentStatusId: selectedData?._id,
    };

    try {
      const response = await getPaymentHistoryStatus(updatedFormData);
      if (response.status === "200") {
        setStatusHistoy(response?.data[0]);
      }
    } catch (error) {
      setNotification({
        message: "An error occurred. Please try again.",
        type: "error",
        notify: true,
      });
      setStatusHistoy([]);
    }
  }

  async function takeActionOnReimbursementRequest(
    formData,
    newStatus,
    rejectionReason,
    approverComments,
    transactionID,
    adminComments,
    uploadedFiles
  ) {
    try {
      formData.attachments.push(...uploadedFiles);
      let updatedFormData = {
        ...formData,
        status: newStatus,
        raisedBy: formData?.raisedBy[0]?._id,
      };

      if (newStatus === "Rejected" && rejectionReason !== "") {
        updatedFormData.reasonForRejection = rejectionReason;
      } else if (newStatus === "Approved") {
        updatedFormData.approverComments = approverComments;
      } else if (newStatus === "Completed") {
        updatedFormData.transactionID = transactionID;
        updatedFormData.adminComments = adminComments;
      }

      const response = await actionOnReimbursement(updatedFormData);

      if (response.status === "200") {
        setNotification({
          message: "Successfully updated!",
          notify: true,
          type: "success",
        });

        getStatusHistory();
        setIsLoading(false);
        setSelectedStatus(selectedStatus);
        updateSupplierBuyerState(response?.data?.response);
        setTimeout(() => {
          setNotification((prevState) => ({
            ...prevState,
            notify: false,
          }));
        }, 5000);
        setDialogOpen(false);
      } else {
        setNotification({
          message: "An error occurred. Please try again.",
          type: "error",
          notify: true,
        });
      }
    } catch (error) {
      setNotification({
        message: "An error occurred. Please try again.",
        type: "error",
        notify: true,
      });
      setIsLoading(false);
      setDialogOpen(false);
    }
  }

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("Reimbursements"), link: "/#/app/internalForms" },
    { label: t("Edit"), link: "" },
  ];
  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      {notification.notify ? (
        <NotificationContainer
          message={notification.message}
          notificationtype={notification.type}
        />
      ) : (
        <></>
      )}
      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <HeaderComp
            selectedStatus={selectedStatus}
            selectedData={selectedData}
            backUrlPayload="reimbursementComp"
          />
        </div>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Date")}: </b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {moment(selectedData?.date).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Raised by")}: </b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.raisedBy[0]?.username}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Payment from")}: </b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.paymentFrom}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <RenderAttachments
                      attachments={selectedData?.attachments}
                      handleDownload={handleDownload}
                    />
                  </TableRow>

                  {selectedData?.remarks && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <b>{t("Remarks")}: </b>
                        {selectedData.remarks}
                      </TableCell>
                    </TableRow>
                  )}
                  {selectedData?.reasonForRejection && (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography sx={{ color: "error.main" }}>
                          <b>{t("Rejected reason")}: </b>
                          {selectedData.reasonForRejection}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2, height: "100%" }}>
              <Typography variant="p">
                <b>{t("Amount")}: </b>
                {formatIndianCurrency(selectedData?.amount)}
              </Typography>
              <Box mt={4}>
                <Typography variant="p">
                  <b>Status: </b>
                </Typography>
                <FormControl variant="outlined" fullWidth sx={{ mt: 1 }}>
                  <Select
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    disabled={isOnlyUser || selectedStatus === "Completed"}
                  >
                    {allStatus.map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        disabled={
                          option === "Pending" ||
                          (option === "Completed" && (isViewer || isApprover))
                        }
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <div className={classes.statusDiv}>
          <StatusHistory statusData={statusHistory} editable={false} />
        </div>
      </div>

      <ConfirmationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        inputStatus={selectedStatus}
        title="Changing status to "
        textFieldLabel="Reason"
        isLoading={isLoading}
        imageFileName="ReimbursementDocs"
      />
    </>
  );
};

export default EditReimbursement;

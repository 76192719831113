import React,{useState} from "react";
import { 
    Grid,
    Paper,
    Collapse, 
    IconButton,
    Typography,
    Button
} from '@mui/material'
import {
    ExpandMore as DropDownIcon,
    ExpandLess as DropUpIcon
}  from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import moment from 'moment'

import useStyles from "./styles";

const MPRDetails = ({userData}) => {
    const { t } = useTranslation();
    var classes = useStyles();
    const [collapse,setCollapse] = useState(true)
    const [expanded, setExpanded] = useState(false);

    const textLimit = 100;
    
    const text = userData?.description
    const toggleExpanded = () => {
      setExpanded(!expanded);
    };

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    return (
        <Paper className={classes.Datapaper}>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <span style={{fontSize:'19px',fontWeight:"lighter"}}>{t("common_mpr_details" )}</span>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2} className={classes.iconGrid}>
                    <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
                        {(collapse)?<DropDownIcon /> :<DropUpIcon />}
                    </IconButton> 
                </Grid> 
            </Grid>
            <Collapse in={collapse}>
                {/* <Grid container spacing={2} className={classes.grid}> */}
                    <Paper elevation={3} className={classes.detailsDiv}>
                        <Grid container spacing={3} className={classes.grid}>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                <h4>{t("mpr_name")}</h4>
                                {userData.name}
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                <h4>{t("common_service_category")}</h4>
                                {userData.serviceCategory}
                            </Grid> 
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                <h4>{t("common_service_type")}</h4>
                                {userData?.serviceType && Array.isArray(userData?.serviceType) && (
                                <>
                                    {userData.serviceType.length > 0
                                    ? userData.serviceType[0]
                                        ? userData.serviceType.length > 1 && userData.serviceType[0].includes("Others")
                                        ? userData.serviceType[0].filter((item) => item !== "Others").join(", ") + ", " + userData.serviceType[1]
                                        : userData.serviceType[0].join(", ")
                                        : "N/A"
                                    : "N/A"}
                                </>
                                )}
                           </Grid>
                            {/* <Grid item lg={4}>
                                <h4>{t("lead_generated_on")}</h4>
                                {moment(userData.createdAt).format('DD-MM-YYYY')}
                            </Grid>  */}
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                <h4>{t("mpr_quotation_last_date")}</h4>
                                {moment(userData.QuatationLastDate).format('DD-MM-YYYY')}
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                <h4>{t("mpr_last_date")}</h4>
                                {moment(userData.MPRLastDate).format('DD-MM-YYYY')}
                            </Grid> 
                            <Grid item lg={4} md={4} sm={6} xs={6}>
                                <h4>{t("common_mpr")} {t("common_status")}</h4>
                                {userData.status}
                            </Grid> 
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <h4>{t("common_mpr")} {t("common_description")}</h4>
                                {text?.length > textLimit || expanded ? 
                                 <div>
                                 <Typography variant="body1" style={{display:'inline'}}>
                                     {expanded ? text : `${text?.slice(0, textLimit)}...`}
                                 </Typography>
                                 <Button color="primary" onClick={toggleExpanded}>
                                     {expanded ? 'Show Less' : 'Show More'}
                                 </Button>
                                 </div> : text
                                }
                               
                            </Grid> 
                        </Grid>
                    </Paper> 
                {/* </Grid> */}
            </Collapse>          
        </Paper>
    );
}
export default MPRDetails
import React, { useEffect, useState } from "react";
import { Grid, Link } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// styles
import useStyles from "./styles";
// components
import Widget from "../../components/Widget";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { getData } from "../LoginContainer/LoginState";
import { getCertificateData } from "../setting/SettingState";
import NotificationContainer from "../notifications/NotificationContainer";

export default function Dashboard(props) {
  const { t, i18n } = useTranslation();
  var classes = useStyles();
  var theme = useTheme();
  const dispatch = useDispatch();
  const [verified, setVerified] = useState("");
  const [valid, setValid] = useState("");
  const [message, setMessage] = useState("");
  const [notify, setNotification] = useState(false);
  const [type, setType] = useState("");
  var data = useSelector((state) => state.userdata);
  var custid = data.getData
    ? data.getData.customerid
    : localStorage.getItem("customerid");
  const breadcrumb = [
    // { label: t("common_home"), link: "" },
    { label: t("common_dashboard"), link: "" },
  ];
  useEffect(() => {
    let bodyData = {
      tenID: custid,
    };
    getData(dispatch);
    if (
      data.getData &&
      data.getData.type === "tenant" &&
      !data.getData.roles.includes("user")
    ) {
      getCertificateData(bodyData, dispatch).then((resp) => {
        if (
          resp.status == "200" &&
          resp.tenantData !== null &&
          resp.tenantData !== undefined
        ) {
          setVerified(resp.tenantData.isVerified);
          setValid(resp.tenantData.isValid);
          if (resp.tenantData.isVerified === false) {
            setMessage("Please add Pollution Certificate");
            setType("error");
            setNotification(true);
          }
          if (resp.tenantData.isValid === false) {
            setMessage(
              "Your Pollution Certificate is Expired! Please contact Admin!!"
            );
            setType("error");
            setNotification(true);
          }
        }
      });
    }
  }, []);
  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        {notify ? (
          <NotificationContainer message={message} notificationtype={type} />
        ) : (
          <></>
        )}

        <Grid container spacing={4}>
          {/* customer */}
          {localStorage.getItem("type") === "Customer" ? (
            <>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Link
                  href="/#/app/MPR"
                  className={classes.widgetLink}
                  underline="hover"
                >
                  <Widget
                    title={t("common_material_pickup")}
                    upperTitle
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    Image="assets/img/binbag.png"
                  ></Widget>
                </Link>
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Link
                  href="/#/app/admin"
                  className={classes.widgetLink}
                  underline="hover"
                >
                  <Widget
                    title={t("common_admin")}
                    upperTitle
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    Image="assets/img/binbag.png"
                  ></Widget>
                </Link>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {/* tenant admin */}
          {localStorage.getItem("type") == "tenant" && (
            <>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Link
                  href="/#/app/leads"
                  className={classes.widgetLink}
                  underline="hover"
                >
                  <Widget
                    title={t("db_leads")}
                    upperTitle
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    Image="assets/img/binbag.png"
                  ></Widget>
                </Link>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Link
                  href="/#/app/inquiryLeads"
                  className={classes.widgetLink}
                  underline="hover"
                >
                  <Widget
                    title={t("manage_inquiries")}
                    upperTitle
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    Image="assets/img/binbag.png"
                  ></Widget>
                </Link>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Link
                  href="/#/app/customers"
                  className={classes.widgetLink}
                  underline="hover"
                >
                  <Widget
                    title={t("db_customer")}
                    upperTitle
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    Image="assets/img/binbag.png"
                  ></Widget>
                </Link>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Link
                  href="/#/app/mpUsers"
                  className={classes.widgetLink}
                  underline="hover"
                >
                  <Widget
                    title={t("Manage Buyers")}
                    upperTitle
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    Image="assets/img/binbag.png"
                  ></Widget>
                </Link>
              </Grid>

              {/* <Grid item lg={3} md={8} sm={6} xs={12}>
                <Widget
                  title={t("db_performance")}
                  upperTitle
                  className={classes.card}
                  bodyClass={classes.fullHeightBody}
                  Image="assets/img/binbag.png"
                ></Widget>
              </Grid> */}
              {/* <Grid item lg={3} md={8} sm={6} xs={12}>
                <Widget
                  title={t("db_server")}
                  upperTitle
                  className={classes.card}
                  bodyClass={classes.fullHeightBody}
                  Image="assets/img/binbag.png"
                ></Widget>
              </Grid> */}
              {/* <Grid item lg={3} md={4} sm={6} xs={12}>
                <Widget
                  title={t("db_review")}
                  upperTitle
                  className={classes.card}
                  Image="assets/img/binbag.png"
                ></Widget>
              </Grid> */}
              {/* <Grid item xs={12}></Grid> */}
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Link
                  href="/#/app/internalForms"
                  className={classes.widgetLink}
                  underline="hover"
                >
                  <Widget
                    title={t("Request Forms")}
                    upperTitle
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    Image="assets/img/binbag.png"
                  ></Widget>
                </Link>
              </Grid>
            </>
          )}
          {localStorage.getItem("type") == "tenant" &&
          !data.getData?.roles?.includes("user") ? (
            <>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Link
                  href="/#/app/marketplace"
                  className={classes.widgetLink}
                  underline="hover"
                >
                  <Widget
                    title={t("common_marketplace")}
                    upperTitle
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    Image="assets/img/binbag.png"
                  ></Widget>
                </Link>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Link
                  href="/#/app/admin"
                  className={classes.widgetLink}
                  underline="hover"
                >
                  <Widget
                    title={t("common_admin")}
                    upperTitle
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    Image="assets/img/binbag.png"
                  ></Widget>
                </Link>
              </Grid>
              {/* internal forms */}
              
            </>
          ) : (
            <></>
          )}
        </Grid>
      </div>
    </>
  );
}

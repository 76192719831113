import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import { NumberFormatCustom } from "../../../utils/currencyFormat";

const EditHistoryDialog = ({
  open,
  onClose,
  item,
  onSave,
  editableFields,
  isLoading,
}) => {
  const [updatedComment, setUpdatedComment] = useState(item?.comments || "");
  const [updatedAmount, setUpdatedAmount] = useState(item?.amountPaid || "");

  const handleSave = () => {
    const updatedItem = { ...item };

    if (editableFields.includes("comment")) {
      updatedItem.updatedComment = updatedComment;
    }

    if (editableFields.includes("amount")) {
      updatedItem.updatedAmount = updatedAmount;
    }

    onSave(updatedItem);
    onClose();
  };

  useEffect(() => {
    setUpdatedComment(item?.comments);
    setUpdatedAmount(item?.amountPaid);
  }, [item]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { width: "100%", maxWidth: "400px", minHeight: "120px" },
      }}
    >
      <DialogTitle>Edit History Item</DialogTitle>
      <DialogContent>
        <Alert severity="info" sx={{ mb: 3 }}>
          Note: Only latest Partial Payment amount can be edited.
        </Alert>
        {editableFields.includes("comment") && (
          <TextField
            autoFocus
            margin="dense"
            label="Comment"
            type="text"
            minRows={5}
            multiline
            value={updatedComment}
            onChange={(e) => setUpdatedComment(e.target.value)}
            sx={{ width: "100%" }}
          />
        )}
        {editableFields.includes("amount") && (
          <TextField
            margin="dense"
            label="Update Amount"
            fullWidth
            value={updatedAmount}
            onChange={(e) => setUpdatedAmount(e.target.value)}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            sx={{ my: 2 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditHistoryDialog;

const initialState = {
  isAuthenticated: localStorage.getItem("token") ? true : false,
  userdata: [],
  mprid: "",
  mprList: [],
  mprData: [],
  customerList: [],
  selectedCustomer: [],
  selectedLead: [],
  tenantAddressList: [],
  tenantContactList: [],
  show_assigned: false,
  show_rejected: false,
  selectedMPR: [],
  tenantSelectedPDF: [],
  selectedBranch: {},
  selectedUser: [],
  selectedLotId: "",
  selectedLot: {},
  selectedUnclassifiedLead: {},
};

export function CommonReducer(state = initialState, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuthenticated: true,
      };
    case "LOGIN_FAILURE":
      return {
        ...state,
        isAuthenticated: false,
      };
    case "LOG_OUT":
      return {
        ...state,
        isAuthenticated: false,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userdata: action.payload,
      };
    case "SET_MPR_ID":
      return {
        ...state,
        mprid: action.payload,
      };
    case "MPR_LIST":
      return {
        ...state,
        mprList: action.payload,
      };
    case "SET_ADD_MPR_DATA":
      return {
        ...state,
        mprData: action.payload,
      };
    case "SET_TENANT_CUSTOMER_LIST":
      return {
        ...state,
        customerList: action.payload,
      };
    case "SET_SELECTED_CUSTOMER_DATA":
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    case "SET_SELECTED_LEAD":
      return {
        ...state,
        selectedLead: action.payload,
      };

    case "SET_TENANT_ADDRESS_LIST":
      return {
        ...state,
        tenantAddressList: action.payload,
      };
    case "SET_TENANT_CONTACT_LIST":
      return {
        ...state,
        tenantContactList: action.payload,
      };
    case "SHOW_ASSIGNED":
      return {
        ...state,
        show_assigned: action.payload,
      };
    case "SHOW_REJECTED":
      return {
        ...state,
        show_rejected: action.payload,
      };
    case "SET_SELECTED_MPR":
      return {
        ...state,
        selectedMPR: action.payload,
      };
    case "SET_SELECTED_QUOTATION":
      return {
        ...state,
        tenantSelectedPDF: action.payload,
      };
    case "SET_SELECTED_BRANCH":
      return {
        ...state,
        selectedBranch: action.payload,
      };
    case "SET_SELECTED_USER":
      return {
        ...state,
        selectedUser: action.payload,
      };
    case "SET_SELECTED_LOT_ID":
      return {
        ...state,
        selectedLotId: action.payload,
      };
    case "SET_SELECTED_LOT":
      return {
        ...state,
        selectedLot: action.payload,
      };
    case "SET_SELECTED_INQUIRY":
      return {
        ...state,
        selectedInquiryLead: action.payload,
      };
    case "SET_SELECTED_DUPlICATE":
      return {
        ...state,
        selectedDuplicateInquiry: action.payload,
      };
    case "SET_SELECTED_SUPPLIER_PAYMENT":
      return {
        ...state,
        selectedSupplierPayment: action.payload,
      };
    case "SET_SELECTED_VENDOR_PAYMENT":
      return {
        ...state,
        selectedVendorPayment: action.payload,
      };
    case "SET_SELECTED_REIMBURSEMENT":
      return {
        ...state,
        selectedReimbursement: action.payload,
      };
      case "SET_SELECTED_MISCELLANEOUS_REQUEST":
        return {
          ...state,
          selectedMiscellaneousRequest: action.payload,
        };
    case "SET_BACK_TAB":
      return {
        ...state,
        selectedBackTab: action.payload,
      };
    default:
      return state;
  }
}

import React, { useState } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";

import useStyles from "../passwordset/styles";
import { TokenRegenerate } from "../LoginState";

function RefreshToken(props) {
  var classes = useStyles();
  var [activeTabId, setActiveTabId] = useState(0);
  var [message, setMessage] = useState(
    "Token is expired. Please click here to regenerate token"
  );
  var [isLoading, setIsLoading] = useState(false);
  var [showButton, setShowButton] = useState(true);

  const handleClick = () => {
    setIsLoading(true);
    TokenRegenerate(props.data).then((response) => {
      if (response.status === "200") {
        setMessage(
          "Token has sent to the registered email id. Please check email for further reference!"
        );
        setIsLoading(false);
        setShowButton(false);
      } else {
        setMessage(
          "Token is invalid! Please contact Admin for more information!"
        );
        setIsLoading(false);
        setShowButton(false);
      }
    });
  };

  return (
    <Paper className={classes.paperContainer}>
      <Tabs
        value={activeTabId}
        onChange={(e, id) => setActiveTabId(id)}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Token Reset" classes={{ root: classes.tab }} />
      </Tabs>

      <React.Fragment>
        <Typography color="primary" className={classes.tokenText}>
          {message}
        </Typography>

        <div className={classes.resetButton}>
          {isLoading ? (
            <CircularProgress size={26} className={classes.loginLoader} />
          ) : showButton ? (
            <Button
              color="primary"
              size="large"
              variant="contained"
              className={classes.forgetButton}
              onClick={handleClick}
            >
              Regenerate
            </Button>
          ) : (
            <></>
          )}
        </div>
      </React.Fragment>
    </Paper>
  );
}
export default RefreshToken;

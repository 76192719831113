import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Button } from '@mui/material';


const useStyles = makeStyles( theme=>({
    outlined:{
        "&:hover" : {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }
    },
    contained:{
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        backgroundColor:theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginLeft:theme.spacing(1),
        "&:hover" : {
            backgroundColor: "#fffff" ,
            color : theme.palette.primary.main,
            border: '1px solid',
            borderColor: theme.palette.primary.main
        }
    }
  }));

const CustomButton = (props) => {
    const classes = useStyles();
    return(
        <>
            {
                (props.variant === "outlined")?
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        href={props.link} 
                        onClick={props.onclick}
                        className={classes.outlined}>
                            {props.title}
                    </Button>:
                    <Button  
                        color="primary" 
                        href={props.link} 
                        className={classes.contained} 
                        onClick={props.onclick}
                        component={props.component}
                        >
                            {props.title}
                    </Button>
            }
        </>
    )
}
export default CustomButton
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import { getMPUsers, deleteMPUser, updateMPUser } from "../MarketplaceState";
import { useHistory } from "react-router-dom";
const ViewMPUsers = () => {
  const classes = useStyles();
  const history = useHistory();
  const [usersData, setUsersData] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { t, i18n } = useTranslation();
  const [loading,setLoading]=useState(false)
  useEffect(() => {
    fetchUsersData();
  }, []);

  const fetchUsersData = async () => {
    try {
      setLoading(true)
      const response = await getMPUsers();
      setUsersData(response.data.users);
      // console.log(response);
      setLoading(false)
    } catch (error) {
      console.error("API Error:", error);
      setLoading(false)
    }
  };
  const handleEdit = (userId) => {
    // console.log(`Edit user with ID: ${userId}`);
    history.push(`/app/editMPUser/${userId}`);
  };

  const handleDelete = (userId) => {
    setSelectedUserId(userId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    // console.log(`Delete user with ID: ${selectedUserId}`);
    deleteMPUser(selectedUserId)
      .then((response) => {
        // console.log("User deleted:", response);
        fetchUsersData();
      })
      .catch((error) => {
        console.error("Delete error:", error);
      });

    setDeleteDialogOpen(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>GST</TableCell>
              <TableCell>PAN</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          
          {loading && 
            <TableBody>
              <TableCell colSpan={6} style={{textAlign:'center'}}>
               <CircularProgress/>
             </TableCell>
            </TableBody>  
          }
            {
              !loading && 
                <TableBody>
                {usersData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      {t("No Buyers data found!!!")}
                    </TableCell>
                  </TableRow>
                ) : (
                  usersData.map((user) => (
                    <TableRow key={user._id}>
                      <TableCell>{user.name.fname}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.gst}</TableCell>
                      <TableCell>{user.pan}</TableCell>
                      <TableCell>{user.mobile}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.tagButton}
                          onClick={() => handleEdit(user._id)}
                        >
                          {t("Edit")}
                        </Button>
                        {/* <Button
                          variant="outlined"
                          style={{
                            color: "red",
                            marginLeft: "8px",
                            borderColor: "red",
                          }}
                          onClick={() => handleDelete(user._id)}
                        >
                          {t("Delete")}
                        </Button> */}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
              }
        </Table>
      </TableContainer>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("Delete")} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Are you sure you want to delete this buyer?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary" autoFocus>
            {t("No")}
          </Button>
          <Button onClick={handleConfirmDelete}  style={{color:'red'}}>
          {t("Yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewMPUsers;

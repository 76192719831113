import React, { useState, useEffect } from "react";
import { Typography } from "../../components/Wrappers/Wrappers";
import {
  Grid,
  Button,
  Card,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
//components
import useStyles from "./styes";
import Table from "../../components/Table/Table";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { createMprId } from "../material_pickup_request/MprState";
import NotificationContainer from "../notifications/NotificationContainer";
import { updateContact } from "./ContactState";

export default function Contacts() {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  var classes = useStyles();
  const [contact, setContact] = useState([]);
  // eslint-disable-next-line
  const [type, setType] = useState("");
  // eslint-disable-next-line
  const [message, setMessage] = useState("");
  // eslint-disable-next-line
  const [notification, setNotification] = useState(false);
  // eslint-disable-next-line
  const [showLoader, setShowloader] = useState(false);

  const [reload, setReload] = useState([]);

  var datatableData = [];
  const datatableHeader = [
    {
      label: t("common_full_name"),
      name: "contact_name",
      options: {
        filter: true,
      },
    },
    {
      label: t("common_email"),
      name: "email",
      options: {
        filter: true,
      },
    },
    {
      label: t("common_mobile_no"),
      name: "mobile",
      options: {
        filter: true,
      },
    },
    {
      label: t("common_status"),
      name: "status",
      options: {
        filter: true,
      },
    },
    {
      label: t("Roles"),
      name: "roles",
      options: {
        filter: true,
      },
    },
    {
      label: t("Change Role"),
      name: "change role",
      options: {
        filter: true,
      },
    },
    {
      label: t("common_action"),
      name: "action",
      options: {
        filter: true,
      },
    },
  ];

  const history = useHistory();
  const dispatch = useDispatch();
  var data = useSelector((state) => state.userdata);
  let updatedById = data?.getData?._id;
  let sendData = {
    abbrevation: data?.getData?.abbrevation,
    customerid: localStorage.getItem("customerid"),
  };
  useEffect(() => {
    setShowloader(true);
    createMprId(sendData, dispatch).then((response) => {
      if (response) {
        setContact(response.contacts);
      } else {
        setContact([]);
      }
      setShowloader(false);
    });
  }, [reload]);

  const handleEdit = (data) => {
    dispatch({
      type: "SET_SELECTED_USER",
      payload: data,
    });
    history.push("/app/addRecyclerContact");
  };

  const breadcrumb = [
    // {label: t("common_home") , link: "/#/app/dashboard"},
    {
      label: t("common_admin"),
      link:
        localStorage.getItem("type") === "tenant"
          ? "/#/app/admin"
          : "/#/app/customeradmin",
    },
    { label: t("common_contacts"), link: "" },
  ];
  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        {notification ? (
          <NotificationContainer message={message} notificationtype={type} />
        ) : (
          <></>
        )}
        <div className={classes.pageTitleContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <div className={classes.HeaderContainer}>
                  <div className={classes.tenantHeader}>
                    <Typography variant="h3" size="sm">
                      {t("contact_span")}
                    </Typography>
                    {/* <span className={classes.tenantSubHeader}>&nbsp;{t("contact_span")}</span> */}
                  </div>

                  <div>
                    <Button
                      variant="outlined"
                      href={
                        localStorage.getItem("type") === "tenant"
                          ? "/#/app/addRecyclerContact"
                          : "/#/app/addContact"
                      }
                      color="primary"
                      className={classes.buttonOutlined}
                    >
                      {t("contact_add_new")}
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={4} className={classes.tablePadding}>
          <Grid item xs={12}>
            {
              // eslint-disable-next-line
              contact.map((item, key) => {
                const isAdmin = item.roles && item.roles.includes("Admin");
                const isTmanager = item.roles && item.roles.includes("Tmanger");
                const isViewer = item.roles && item.roles.includes("Viewer");
                const isApprover =
                  item.roles && item.roles.includes("Approver");
                const isAuthorizer =
                  item.roles && item.roles.includes("Authorizer");
                const contactId = item._id;
                const data = {
                  contactId,
                  updatedById,
                };
                const toggleTmanager = async () => {
                  try {
                    const updatedRoles = isTmanager
                      ? item.roles.filter((role) => role !== "Tmanger")
                      : [...(item.roles || []), "Tmanger"];
                    let result = await updateContact(data, updatedRoles);
                    if (result.status == "200") {
                      setMessage("Role Updated to Tmanger");
                      setType("success");
                      setNotification(true);
                      setReload(updatedRoles);
                      setTimeout(() => {
                        setMessage("");
                        setType("");
                        setNotification(false);
                      }, 2000);
                    } else {
                      setMessage("An error occured try again");
                      setType("error");
                      setNotification(true);
                      setTimeout(() => {
                        setMessage("");
                        setType("");
                        setNotification(false);
                      }, 2000);
                    }
                  } catch (error) {
                    console.error("Error toggling Tmanager:", error);
                  }
                };

                const toggleViewer = async () => {
                  try {
                    const updatedRoles = isViewer
                      ? item.roles.filter((role) => role !== "Viewer")
                      : [...(item.roles || []), "Viewer"];
                    let result = await updateContact(data, updatedRoles);
                    if (result.status == "200") {
                      setMessage("Role Updated to Viewer");
                      setType("success");
                      setNotification(true);
                      setReload(updatedRoles);
                      setTimeout(() => {
                        setMessage("");
                        setType("");
                        setNotification(false);
                      }, 2000);
                    } else {
                      setMessage("An error occured try again");
                      setType("error");
                      setNotification(true);
                      setTimeout(() => {
                        setMessage("");
                        setType("");
                        setNotification(false);
                      }, 2000);
                    }
                  } catch (error) {
                    console.error("Error toggling viewer:", error);
                  }
                };

                const toggleApprover = async () => {
                  try {
                    const updatedRoles = isApprover
                      ? item.roles.filter((role) => role !== "Approver")
                      : [...(item.roles || []), "Approver"];
                    let result = await updateContact(data, updatedRoles);
                    if (result.status == "200") {
                      setMessage("Role Updated to Approver");
                      setType("success");
                      setNotification(true);
                      setReload(updatedRoles);
                      setTimeout(() => {
                        setMessage("");
                        setType("");
                        setNotification(false);
                      }, 2000);
                    } else {
                      setMessage("An error occured try again");
                      setType("error");
                      setNotification(true);
                      setTimeout(() => {
                        setMessage("");
                        setType("");
                        setNotification(false);
                      }, 2000);
                    }
                  } catch (error) {
                    console.error("Error toggling approver:", error);
                  }
                };
                const toggleAuthorizer = async () => {
                  try {
                    const updatedRoles = isAuthorizer
                      ? item.roles.filter((role) => role !== "Authorizer")
                      : [...(item.roles || []), "Authorizer"];
                    let result = await updateContact(data, updatedRoles);
                    if (result.status == "200") {
                      setMessage("Role Updated to Authorizer");
                      setType("success");
                      setNotification(true);
                      setReload(updatedRoles);
                      setTimeout(() => {
                        setMessage("");
                        setType("");
                        setNotification(false);
                      }, 2000);
                    } else {
                      setMessage("An error occured try again");
                      setType("error");
                      setNotification(true);
                      setTimeout(() => {
                        setMessage("");
                        setType("");
                        setNotification(false);
                      }, 2000);
                    }
                  } catch (error) {
                    console.error("Error toggling authorizer:", error);
                  }
                };
                datatableData.push([
                  item.Name,
                  item.email,
                  item.mobile.code +
                    "-" +
                    (item.mobile.number ? item.mobile.number : ""),
                  item.isActive ? "Active" : "In Active",
                  item.roles ? item.roles.join(",") : "N/A",
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          classes={{
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                          }}
                          disabled={isAdmin}
                          checked={isTmanager}
                          onClick={toggleTmanager}
                        />
                      }
                      label="Tmanger"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          classes={{
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                          }}
                          // disabled={isAdmin}
                          checked={isViewer}
                          onClick={toggleViewer}
                        />
                      }
                      label="Viewer"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          classes={{
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                          }}
                          // disabled={isAdmin}
                          checked={isApprover}
                          onClick={toggleApprover}
                        />
                      }
                      label="Approver"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          classes={{
                            track: classes.switch_track,
                            switchBase: classes.switch_base,
                          }}
                          // disabled={isAdmin}
                          checked={isAuthorizer}
                          onClick={toggleAuthorizer}
                        />
                      }
                      label="Authorizer"
                    />
                  </FormGroup>,

                  <IconButton
                    onClick={() => handleEdit(item)}
                    className={classes.themeIcon}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>,
                ]);
              })
            }

            <Table
              data={datatableData}
              header={datatableHeader}
              name={t("contact_list")}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
                textLabels: {
                  body: {
                    noMatch: showLoader ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#0D6937", marginRight: "5px" }}
                        />{" "}
                        Loading...
                      </div>
                    ) : (
                      "No Contacts Found"
                    ),
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Link,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.background.light,
  // color: theme.palette.common.white,
}));

const LeaveTypeCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: theme.palette.primary.main,
}));

const DataCell = styled(TableCell)(({ theme }) => ({
  // backgroundColor: theme.palette.success.light,
  color: theme.palette.success.dark,
  fontWeight: "bold",
}));

export const LeaveDash = ({ leaveData, showLeavePolicy }) => {
  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const formatLeaveData = (value) => (value !== undefined ? value : "N/A");

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Leave Type</StyledTableCell>
              <StyledTableCell align="center">Available</StyledTableCell>
              <StyledTableCell align="center">Taken</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <LeaveTypeCell>Casual Leaves</LeaveTypeCell>
              <DataCell align="center">
                {formatLeaveData(leaveData.casual_leave_left)}
              </DataCell>
              <DataCell align="center">
                {formatLeaveData(leaveData.casual_leaves)}
              </DataCell>
            </TableRow>
            <TableRow>
              <LeaveTypeCell>Emergency Leaves</LeaveTypeCell>
              <DataCell align="center">
                {formatLeaveData(leaveData.emergency_leave_left)}
              </DataCell>
              <DataCell align="center">
                {formatLeaveData(leaveData.emergency_leaves)}
              </DataCell>
            </TableRow>
            <TableRow>
              <LeaveTypeCell>Marriage Leaves</LeaveTypeCell>
              <DataCell align="center">
                {formatLeaveData(leaveData.marriage_leave_left)}
              </DataCell>
              <DataCell align="center">
                {formatLeaveData(leaveData.marriage_leave)}
              </DataCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
        Extra Leave Details
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Month</StyledTableCell>
              <StyledTableCell align="center">Extra Leave Days</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaveData?.extra_leave && leaveData.extra_leave.length > 0 ? (
              leaveData.extra_leave.map((leave) => (
                <TableRow key={leave._id}>
                  <TableCell>{monthArray[leave.month - 1]}</TableCell>
                  <DataCell align="center">{leave.total_leave}</DataCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  No extra leave has been taken
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {showLeavePolicy && (
        <Box mt={2}>
          <Typography>
            Read HR policy{" "}
            <Link
              href="https://docs.google.com/spreadsheets/d/1WMCnQuqoJVz5ELMQ7a9s6_m5YWugGjep/edit?usp=sharing&ouid=108342766653445997441&rtpof=true&sd=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              Here
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

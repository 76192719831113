import React from 'react'

export default function Welcome() {
    return (
        <div className="welcome">
            {/* <img src={introImg} alt="" /> */}
            <h2>Keep your phone connected</h2>
        </div>
    )
}

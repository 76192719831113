import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    backgroundColor: "#FAFAFA",
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("lg")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0),
      width: "100%",
    },
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  iconButton: {
    color: theme.palette.primary.main,
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },

  errorMessage: {
    textAlign: "center",
    marginBottom: "100px",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  resetButton: {
    width: "100%",
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
  tokenText: {
    marginTop: theme.spacing(4),
    // width: "400px",
    flexWrap: "wrap",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
    padding: "40 px",
  },
  loginLoader: {
    // marginLeft: theme.spacing(4),
  },
  paperContainer: {
    padding: theme.spacing(4),
    width: "80%",
  },
  notificationItem: {
    marginTop: theme.spacing(2),
  },
  notificationCloseButton: {
    position: "absolute",
    right: theme.spacing(2),
  },
  toastsContainer: {
    width: 400,
    marginTop: theme.spacing(6),
    right: 0,
  },
  progress: {
    visibility: "hidden",
  },
  // typo: {
  //   width: "500px",
  //   [theme.breakpoints.down("sm")]: {
  //     width: "300px",
  //   },
  //   [theme.breakpoints.only("md")]: {
  //     width: "240px",
  //   },
  // },
  notification: {
    display: "flex",
    alignItems: "center",
    background: "transparent",
    boxShadow: "none",
    overflow: "visible",
  },
  notificationComponent: {
    paddingRight: theme.spacing(4),
  },
  tostifyBody: {
    margin: "auto 0",
    flex: "1 1 auto",
    padding: "6px",
  },
}));

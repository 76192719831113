import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Tabs, Tab, Card } from "@mui/material";
import { useHistory } from "react-router-dom";
import useStyles from "../styles";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getAllMiscellaneousRequests } from "../InternalFormState";
import NotificationContainer from "../../notifications/NotificationContainer";
import MiscellaneousRequestForm from "./MiscellaneousRequestForm";
import { formatIndianCurrency } from "../../../utils/currencyFormat";
import Table from "../../../components/Table/Table";
import {
  TabPanel,
  a11yProps,
  miscellaneousTableHeader,
  getStatusStyle,
} from "../components/config/tableHelper";
import SectionHeader from "../components/SectionHeader";
import { tableOptions } from "../components/config/tableConfig";

export default function TabPanelMiscellaneousReq() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const history = useHistory();
  const data = useSelector((state) => state.userdata.getData);
  const dispatch = useDispatch();

  const [miscellaneousReqs, setMiscellaneousReqs] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [notification, setNotification] = useState({
    message: "",
    notify: false,
    type: "",
  });
  const [pageData, setPageData] = useState({ page: 1, limit: 10 });
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 20, 50]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Memoize tabData calculation
  const tabData = useMemo(() => {
    if (
      data &&
      data.roles.some((item) => "user" === item) &&
      !data.roles.some((item) => "Viewer" === item) &&
      !data.roles.some((item) => "Approver" === item)
    ) {
      return [{ label: t("My Requests"), index: 0, name: "myRequests" }];
    } else if (
      (data && data?.roles.some((item) => "Viewer" === item)) ||
      data?.roles.some((item) => "Approver" === item) ||
      data?.roles.some((item) => "Admin" === item)
    ) {
      return [
        { label: t("All"), index: 0, name: "allRequests" },
        { label: t("Pending"), index: 1, name: "pendingRequests" },
      ];
    }
    return [];
  }, [data, t]);

  // Memoize event handlers
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleToggleView = (data) => {
    setShowForm(data);
    fetchMiscellaneousPaymentReqs(pageData);
  };

  const handleRowClick = (rowData) => {
    const selectedId = rowData[0];
    const filteredID = miscellaneousReqs?.find(
      (otherReq) => otherReq?.misReqId === selectedId
    );
    toggleModal();
    setSelectedData(filteredID);
    handleViewMissReq(filteredID);
  };

  const handleViewMissReq = (data) => {
    dispatch({
      type: "SET_SELECTED_MISCELLANEOUS_REQUEST",
      payload: data,
    });
    history.push("/app/miscellaneousRequest");
  };

  // Fetch data
  const fetchMiscellaneousPaymentReqs = async (pageData) => {
    setFetchLoader(true);
    try {
      const allMiscellaneousReqs = await getAllMiscellaneousRequests(pageData);

      if (allMiscellaneousReqs.status === "200") {
        setMiscellaneousReqs(allMiscellaneousReqs.data);
        setTotalPages(allMiscellaneousReqs.totalCount);
      } else {
        setMiscellaneousReqs([]);
      }
      setFetchLoader(false);
    } catch (error) {
      setNotification({
        message: "Error fetching Miscellaneous requests!",
        type: "error",
        notify: true,
      });
      setFetchLoader(false);
    }
  };

  useEffect(() => {
    fetchMiscellaneousPaymentReqs(pageData);
    setIsModalOpen(false);
  }, [pageData]);

  useEffect(() => {
    const maxRowsPerPage = totalPages || 10;
    const dynamicRowsPerPageOptions = [10, 20, 50].filter(
      (option) => option <= maxRowsPerPage
    );
    if (!dynamicRowsPerPageOptions.includes(maxRowsPerPage)) {
      dynamicRowsPerPageOptions.push(maxRowsPerPage);
    }
    setRowsPerPageOptions(dynamicRowsPerPageOptions);
  }, [totalPages]);

  // Memoize the requests data
  const requestsData = useMemo(() => {
    let myRequests = [];
    let allRequests = [];
    let pendingRequests = [];

    miscellaneousReqs.forEach((item) => {
      const statusStyle = getStatusStyle(item.status);
      const row = [
        item?.misReqId,
        moment(item?.date).format("DD-MM-YYYY"),
        item?.otherOption[0]?.option,
        item?.paymentFrom,
        formatIndianCurrency(item?.amount),
        item?.raisedBy[0]?.username,
        <span style={statusStyle}>{item?.status}</span>,
      ];

      if (item?.status === "Pending") {
        pendingRequests.push(row);
      }
      if (data._id === item.raisedBy[0]._id) {
        myRequests.push(row);
      }
      allRequests.push(row);
    });

    return { myRequests, allRequests, pendingRequests };
  }, [miscellaneousReqs, data._id]);

  return (
    <>
      {notification.notify && (
        <NotificationContainer
          message={notification.message}
          notificationtype={notification.type}
        />
      )}

      <div className={classes.root}>
        <SectionHeader
          title={showForm ? "New Request" : "Miscellaneous"}
          buttonText={showForm ? "All Requests" : "New"}
          buttonAction={() => setShowForm((prev) => !prev)}
          buttonStyle={{ margin: "15px 10px" }}
        />

        {showForm ? (
          <MiscellaneousRequestForm toggleView={handleToggleView} />
        ) : (
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
            >
              {tabData.map((item, key) => (
                <Tab
                  key={key}
                  label={item.label}
                  {...a11yProps(item.index)}
                  classes={{ root: classes.tab }}
                />
              ))}
            </Tabs>
            {tabData.map((item, key) => (
              <TabPanel value={value} index={item.index} key={key}>
                <Table
                  data={requestsData[item.name]}
                  header={miscellaneousTableHeader}
                  name={""}
                  options={{
                    ...tableOptions(
                      fetchLoader,
                      rowsPerPageOptions,
                      setPageData,
                      handleRowClick,
                      "Miscellaneous Payments.csv",
                      totalPages,
                      pageData.page
                    ),
                    tableBodyHeight: "550px",
                  }}
                />
              </TabPanel>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

import {apiUrl} from '../../data/config'
import { Buffer } from 'buffer';


export function addLead(data,dispatch){
    let url = apiUrl+'/v1/tenant/leadOnboard'
    return fetch(url,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json().then((data) => {
            if(data.status === "200"){
                return data
            }else if(data.status === "500"){
                dispatch({
                    type : 'LOG_OUT'
                })
                localStorage.clear()
                return data
            }else{
                return response
            }
            
        })
    })
}


export function addMPR(data,status,dispatch){
    let url = apiUrl+'/v1/customer/MPROnboarding'
    var address = JSON.parse(data.contact_address)
    var user;
    if(Object.keys(data.contact_contact).length >0){
        user = JSON.parse(data.contact_contact)
    }else user = {}
    let service =[]
    let finalService  = []
        service = data.service_type[data.service_type.length -1]
        finalService.push(service)
        finalService.push(data.other_service_type)
        
    let mprData = {
        "mpr": {
            "external_id":data.MPRId,
            "name":data.MPRName,
            "description":data.description,
            "itemList":[],
            "item_list_file":data.files,
            "goodsPic": data.goodsPic,
            "MPRLastDate": new Date(data.pickupDate).toISOString(),
            "QuatationLastDate": new Date(data.quotation_date).toISOString(),
            "thisAddress" : address._id,
            "thisUser": (Object.keys(user).length >0 )?user._id:"",
            "serviceCategory":data.service_category,
            "serviceType":finalService,
            "createdAt":new Date().toISOString(),
            "updatedAt":"",
            "createdBy":"Self",
            "updatedBy":"",
            "status":status,
            "tenant":data.tenantId,
            "customer" : data.customerId,
            "inquiryID":(localStorage.getItem("type") === "tenant") ? data.inquirylead_id :""
        },
        "inspection":data.slots
    }
   return fetch(url,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body : JSON.stringify(mprData)
    }).then((response) => {
        return response.json().then((data) => {
            if( response.ok){
               return data
            }else{
                return {
                    status : "500",
                    message : "Something went wrong"
                }
            }
        })
    })
    
}

export function createMprId(data, dispatch){
    let url = apiUrl+'/v1/customer/MPRLoad';
    
    return fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(response => {
        if(response.ok){
            return response.json().then((data) => {
                if(data.status === "200"){
                    dispatch({
                        type: "SET_MPR_ID",
                        payload : data.MPRID
                    });
                    dispatch({
                        type: "SET_ADD_MPR_DATA",
                        payload : data
                    });
                    return data;
                } else if(data.status === "500" || data.status === "401"){
                    dispatch({
                        type: 'LOG_OUT'
                    });
                    localStorage.clear();
                    return data;
                }
            });
        } else {
            if (response.status === 401 || response.status === 500) {
                dispatch({
                    type: 'LOG_OUT'
                });
                localStorage.clear();
            }
            return {
                "status": response.status.toString(),
                "message": "Unexpected error"
            };
        }
    }).catch(error => {
        return {
            "status": "500",
            "message": "Network error. Please try again later."
        };
    });
}

export function getMprList(dispatch, branchid) {
    let url = apiUrl+'/v1/customer/allMPR';
    let bodyData = {
        customer: localStorage.getItem('customerid'),
        CustomerBranch: branchid
    };
    return fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
        },
        body: JSON.stringify(bodyData)
    }).then(response => {
        if (response.ok) {
            return response.json().then((data) => {
                if (data.status === "200") {
                    dispatch({
                        type: "MPR_LIST",
                        payload: data
                    });
                    return data;
                } else if (data.status === "500") {
                    dispatch({
                        type: 'LOG_OUT'
                    });
                    localStorage.clear();
                    return data;
                }
            });
        } else {
            dispatch({
                type: 'LOG_OUT'
            });
            localStorage.clear();
            return {
                status: "500",
                message: "Something went wrong"
            };
        }
    }).catch(error => {
        dispatch({
            type: 'LOG_OUT'
        });
        localStorage.clear();
        return {
            "status": "500",
            "message": "Network error. Please try again later."
        };
    });
}

export function fileupload(formData,getTokenData,fileType){
    let url = apiUrl+'/v1/fileinAWS'
    let getRef = apiUrl+'/v1/filegetAWS'
  return  fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body : JSON.stringify(getTokenData)
    }).then(response => {
        if(response.ok){
            return response.json().then((data) => {
                 return fetch(data.reference.url,{
                     method:'PUT',
                     headers: {
                        'ContentType':fileType,
                        'Content-Type': fileType,
                        'ContentEncoding': 'base64',
                        'Content-Encoding':'base64'
                     },
                     body:Buffer.from(formData, 'base64')
                 }).then(res => {
                     if(res.ok){
                        return fetch(getRef , {
                            method:'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type':'application/json',
                                'token' : localStorage.getItem('token')
                            },
                            body: JSON.stringify({
                                fileName : data.reference.fileName || data.reference.filename,
                                folderName : data.reference.folderName || data.reference.folder || data.reference.FolderName,
                                expireLimt : 1800
                            })
                        }).then(list => {
                            return list.json().then((returnData ) => {
                                return{
                                    status : "200",
                                    message : "File Uploaded SuccessFully",
                                    fileName : data.reference.fileName || data.reference.filename,
                                    reference : returnData.reference,
                                    folderName : data.reference.folderName || data.reference.folder || data.reference.FolderName,
                                }
                            })
                        })
                         
                     }else{
                         return {
                             status : "500",
                             message : "Something went wrong"
                         }
                     }
                 })
            })
        }
    })
  
}

export function getMPRData(id,dispatch){
    let url = apiUrl+'/v1/customer/viewMPR'
    let data = {
        "mprId" : id
    }
    return  fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body : JSON.stringify(data)
      }).then(response => {
          if(response.ok){
              return response.json().then((data) => {
                  if(data.status === "200"){
                    return data
                  }else if(data.status === "500"){
                    dispatch({
                        type : 'LOG_OUT'
                    })
                    localStorage.clear()
                    return data
                }
                  
              })
          }else{
            return {
                status : "500",
                message : "Something went wrong"
            }
        }
      })
}

export function assignLead(data,dispatch){
    let url = apiUrl+'/v1/tenant/actionOnLead'
    return fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body : JSON.stringify(data)
    }).then(response => {
        return response.json().then((data) => {
            if(data.status === "200"){
                return data
            } else if(data.status === "500"){
                dispatch({
                    type : 'LOG_OUT'
                })
                localStorage.clear()
                return data
            }
            
        })
    })
}

export function shortList(data,dispatch){
    let url = apiUrl+'/v1/tenant/shorlist'
    return fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body : JSON.stringify(data)
    }).then(response => {
        return response.json().then((data) => {
            if(data.status === "200"){
                return data
            } else if(data.status === "500"){
                dispatch({
                    type : 'LOG_OUT'
                })
                localStorage.clear()
                return data
            }
            
        })
    })
}

export function rejectAllLead(data,dispatch){
    let url = apiUrl+'/v1/tenant/quoteAccept'
    return fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body : JSON.stringify(data)
    }).then(response => {
        return response.json().then((data) => {
            if(data.status === "200"){
                return data
            } else if(data.status === "500"){
                dispatch({
                    type : 'LOG_OUT'
                })
                localStorage.clear()
                return data
            }
            
        })
    })
}
//Production

const apiUrl = "https://apiportal.binbag.in"
const apiMPUrl = "https://apimobile.binbag.in"
const joinUrl = "https://join.binbag.in/#/app/addCustomer"
const inquriryFormUrl = "https://inquiry.binbag.in/"

//dev

// const apiUrl = "https://devapiportal.binbag.in";
// const apiMPUrl = "https://devapimobile.binbag.in";
// const joinUrl = "https://devjoin.binbag.in/#/addCustomer";
// const inquriryFormUrl = "https://devinquiry.binbag.in/";
  

const languageList = [
  { lable: "English", value: "en", trans: "" },
  { lable: "Kannada", value: "kan", trans: "ಕನ್ನಡ" },
  { lable: "Tamil", value: "tam", trans: "தமிழ்" },
  { lable: "Telugu", value: "tel", trans: "తెలుగు" },
  { lable: "Hindi", value: "hin", trans: "हिंदी" },
];
const stateDrop = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  " Daman and Diu",
  "Delhi",
  "Jammu and Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];
const hoursList = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const minutesList = ["00", "30"];
const amOrpmList = ["AM", "PM"];
const bidSelect = ["1", "2", "3", "Deselect"];
const countryDrop = ["India"];
export {
  languageList,
  apiUrl,
  stateDrop,
  joinUrl,
  countryDrop,
  apiMPUrl,
  inquriryFormUrl,
  hoursList,
  minutesList,
  amOrpmList,
  bidSelect,
};

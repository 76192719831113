import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    Input,
    InputLabel,
    Button,
    CircularProgress,
    Select,
    MenuItem,
    Paper,
    FormHelperText
} from "@mui/material"
import { useDispatch,useSelector } from "react-redux";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs"
import useStyles from "../../address/styes";
import {stateDrop,countryDrop} from '../../../data/config'
import {addAddress} from '../AddressState'
import {createMprId} from '../../material_pickup_request/MprState'
import NotificationContainer from '../../notifications/NotificationContainer'

const AddressForms = (props) => {
    // eslint-disable-next-line
    const { t, i18n } = useTranslation();
   
    const classes = useStyles();
  
    let history = useHistory();
    const dispatch = useDispatch()
    const [address1, setaddress1] = useState("")
    const [address2, setaddress2] = useState("")
    const [city,setCity] = useState("")
    const [state, setState] = useState("")
    const [country, setCountry] = useState("India")
    const [pincode,setPincode] = useState("")
    const [showLoader,setShowLoader] = useState(false)
    const [type,setType] = useState("")
    const [message,setMessage] = useState("")
    const [notify,setNotify] = useState(false)
    const [disabled,setDisabled] = useState(true)
    const getdata = useSelector((state) => state.userdata )
    const custData = useSelector((state) => state)
    var branchData = (custData.selectedBranch)?custData.selectedBranch._id:""
    var customerData = (custData.selectedCustomer)?custData.selectedCustomer._id:""
    const reset = () => { 
        setaddress1("")
        setaddress2("")
        setCity("")
        setState("")
        setCountry("")
        setPincode("")
        
    }
    const handleClose = () => {
        props.close()
    }

    const [error, setError] = useState(false);

    const handlePincodeChange = (e) => {
        const inputValue = e.target.value;
        setPincode(inputValue);
        if (/^\d{6}$/.test(inputValue)) {
          setError(false);
          setDisabled(false)
        } else {
          setError(true);
          setDisabled(true)
        }
      };
  

    const handleSubmit = () => {
        var customerId = (localStorage.getItem('type') === "Customer")?localStorage.getItem('customerid'):customerData
        var abbriviation = (localStorage.getItem('type') === "Customer")?getdata.getData.abbrevation:custData.selectedCustomer.abbrevation
        let sentData = {
            abbrevation:abbriviation ,
            customerid:customerId,
            thisCustomerBranch : (getdata.getData && localStorage.getItem('type') === "Customer") ? getdata.getData.thisCustomerBranch:
                          (localStorage.getItem('type') === "tenant")?branchData : ""
          }
          
        var data = {
            "address":{address1:address1,address2:address2},
            "district":city,
            "state":state,
            "country":country,
            "pincode" : pincode,
            "customerId":customerId,
            "tenantId" : (getdata.getData.tenant_id && getdata.getData.tenant_id !== "")?getdata.getData.tenant_id : "" 
        }

        const fields = { address1, city, state, country, pincode, customerId };

        const emptyFields = Object.keys(fields).filter(field => fields[field] === "");
        const isAddress1Empty = fields.address1 === "";
        const isAddress2Empty = fields.address2 === "";

        if (emptyFields.length > 0 || (isAddress1Empty || isAddress2Empty)) {
          if (emptyFields.length > 0) {
            setMessage("Please fill in the required fields")
            setType('error')
            setNotify(true)
            setTimeout(() => {
                setNotify(false)
                setMessage('')
                setType('')
            }, 4000);
            return
          }
        }
        setShowLoader(true)
        addAddress(data).then((response) => {
            setShowLoader(false)
            if(response.status === "200"){
                setType("success")
                setMessage(response.message)
                setNotify(true)
                //setTimeout(()=> history.push('/app/contacts'),2000)
                createMprId(sentData,dispatch)
                if(props.where === "modal"){
                    setTimeout(() => props.close() ,2000)
                }else{
                    setTimeout(()=> history.push('/app/address'),2000)
                }
                
            }else{
                let msg = []
                if(response.ErrorData){
                    // eslint-disable-next-line
                    response.ErrorData.details.map(item => {
                        msg.push(item.message)
                    })
                    setMessage(`${msg.join()}`)
                    setNotify(true)
                    setType("error")
                }else{
                    setMessage("Something went Wrong! Please try again!")
                    setNotify(true)
                    setType("error")
                }
            }
        })
    }

    const handleCityChange = (e) => {
        const value = e.target.value.trim(); 
        setCity(value);
      };

      const handleStateChange = (selectedValue) => {
        setState(selectedValue)
      };
      
    const breadcrumb = [
        {label: t("common_home") , link: "/#/app/dashboard"},
        {label: t("cust_administration"), link: "/#/app/customeradmin"},
        {label: t("common_address") , link: ""},
      ]  
    return <>
    <BreadCrumbs data={breadcrumb} />
    <div className={classes.paper}>
    <div className={classes.formField}>
        {(notify)?<NotificationContainer message={message} notificationtype={type} />: <></>}
        <form  autoComplete="off" >
        <div>
            
            <FormControl variant="standard" fullWidth className={classes.margin} >
                <InputLabel htmlFor="address_line_1" required>{t("common_address_line_1")}</InputLabel>
                <Input
                multiline
                id="address_line_1"
                name="address_line_1"
                value={address1}
                onChange={(e)=> setaddress1(e.target.value)}
      />
    </FormControl>
        </div>
        <div>
            <FormControl variant="standard" fullWidth className={classes.margin}>
                <InputLabel htmlFor="address_line_2">{t("common_address_line_2")}</InputLabel>
                <Input multiline id="full_name" name="address_line_2" value={address2} onChange={(e)=> setaddress2(e.target.value)}/>
            </FormControl>
        </div>
        <div>
            <FormControl variant="standard" fullWidth className={classes.margin} >
                <InputLabel htmlFor="city" required>{t("common_city")}</InputLabel>
                <Input multiline id="city" value={city} onChange={handleCityChange} aria-describedby="city_error_text"/>
            </FormControl>
        </div>
        <div>
            <FormControl variant="standard" fullWidth className={classes.margin}>
                <InputLabel id="state_drop" required>{t("common_state")}</InputLabel>
                <Select
                    variant="standard"
                    labelId="state_drop"
                    id="state_drop"
                    value={state}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                    onChange={(e) => handleStateChange(e.target.value)}>
                {
                    stateDrop.map((item,key) => {
                        return <MenuItem value={item} key={key}>{item}</MenuItem>
                     
                    })
                }
                </Select>
            </FormControl>
        </div>
        <div>
            <FormControl variant="standard" fullWidth className={classes.margin}>
                <InputLabel id="country_drop" required>{t("common_country")}</InputLabel>
                <Select
                    variant="standard"
                    labelId="country_drop"
                    id="country_drop"
                    value={country}
                 
                    onChange={(e) => setCountry(e.target.value)}>
                {
                    countryDrop.map((item,key) => {
                        return <MenuItem value={item} key={key}>{item}</MenuItem>
                    })
                }
                </Select>
            </FormControl>
        </div>
        <div>
            <FormControl variant="standard" fullWidth className={classes.margin}>
                <InputLabel htmlFor="pincode" required>{t("common_pincode")}</InputLabel>
                <Input
                id="pincode"
                type="number" 
                value={pincode}
                className={classes.input}
                onChange={handlePincodeChange}
                error={error}
                inputProps={{
                pattern: '[0-9]{6}', 
                maxLength: 6, 
                inputMode: 'numeric', 
                }}
            />
            {error && (
                <FormHelperText error>
                Please enter a valid 6-digit pincode
                </FormHelperText>
            )}
            </FormControl>
        </div>
        <div style={{marginTop:'2rem',display:'flex',justifyContent:"flex-end"}}>
        {
            (props.where === "page")?<Button  href="/#/app/contacts" className={classes.button}>{t("common_back")}</Button>:<></>
        }
        {
            (props.where !=="modal")?<Button variant="outlined"  onClick={reset} className={classes.button}>{t("common_reset")}</Button>:
            <Button variant="outlined"  onClick={handleClose} className={classes.button}>{t("common_close")}</Button>
        }
        
        {
            (showLoader)?<CircularProgress /> :<Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit} disabled={disabled}>
                {t("common_finish")}</Button>
        }     
        </div>
        
        </form>
    </div>
    </div>
    </>;
}

export default AddressForms
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  IconButton,
  Box,
  Grid,
  DialogContent,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import dayjs from "dayjs";
import { NumberFormatCustom } from "../../../utils/currencyFormat";
import UploadFilesImages from "../components/UploadFilesImages";

const SaleForm = ({ onSaleDataChange }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [soldDetails, setSoldDetails] = useState([
    {
      soldTo: "",
      saleDate: new Date(),
      saleAmount: "",
      saleInvoiceId: "",
      saleComments: "",
      attachments: [],
    },
  ]);

  useEffect(() => {
    onSaleDataChange(soldDetails);
  }, [soldDetails, onSaleDataChange]);

  const handleUploadedFiles = (files) => {
    const filteredFiles = files.filter((item) => Object.keys(item).length > 0);
    const filesWithoutRef = filteredFiles.map((item) => {
      const { ref, ...newItem } = item;
      return newItem;
    });

    setUploadedFiles(filesWithoutRef);

    // Update the attachments for the last sold detail entry
    setSoldDetails((prevDetails) => {
      const newDetails = [...prevDetails];
      const lastIndex = newDetails.length - 1;
      newDetails[lastIndex] = {
        ...newDetails[lastIndex],
        attachments: filesWithoutRef,
      };
      return newDetails;
    });
  };

  const handleSoldDetailChange = (index, field, value) => {
    const newSoldDetails = [...soldDetails];

    if (field === "saleDate") {
      if (value && dayjs(value).isValid()) {
        // Format the date as an ISO string only if it's a valid date
        newSoldDetails[index][field] = dayjs(value).toISOString();
      } else {
        // If the date is invalid, set it to null or leave it unchanged
        newSoldDetails[index][field] = null; // or keep the previous value
      }
    } else {
      // For non-date fields, simply update the value
      newSoldDetails[index][field] = value;
    }

    setSoldDetails(newSoldDetails);
  };

  const addSoldDetail = () => {
    setSoldDetails([
      ...soldDetails,
      {
        soldTo: "",
        saleDate: null,
        saleAmount: "",
        saleInvoiceId: "",
        saleComments: "",
        attachments: [], // Initialize with an empty array
      },
    ]);
  };

  const removeSoldDetail = (index) => {
    const newSoldDetails = soldDetails.filter((_, i) => i !== index);
    setSoldDetails(newSoldDetails);
  };

  return (
    <DialogContent>
      {soldDetails.map((detail, index) => (
        <Box key={index}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Sold to"
                variant="outlined"
                fullWidth
                value={detail.soldTo}
                onChange={(e) =>
                  handleSoldDetailChange(index, "soldTo", e.target.value)
                }
                sx={{ marginTop: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Sale date"
                  value={detail.saleDate ? dayjs(detail.saleDate) : null}
                  disableFuture
                  onChange={(date) =>
                    handleSoldDetailChange(index, "saleDate", date)
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Amount"
                variant="outlined"
                fullWidth
                value={detail.saleAmount}
                onChange={(e) =>
                  handleSoldDetailChange(index, "saleAmount", e.target.value)
                }
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Invoice no."
                variant="outlined"
                fullWidth
                value={detail.saleInvoiceId}
                onChange={(e) =>
                  handleSoldDetailChange(index, "saleInvoiceId", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Comments(optional)"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={detail.saleComments}
                onChange={(e) =>
                  handleSoldDetailChange(index, "saleComments", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <UploadFilesImages
                title={"Upload Invoices"}
                fileUploadData={handleUploadedFiles}
                setIsUploading={setUploadingFile}
                fileUploadType={"BuyerInvoice"}
              />
            </Grid>
          </Grid>
          {soldDetails.length > 1 && (
            <IconButton
              onClick={() => removeSoldDetail(index)}
              sx={{ marginTop: "10px" }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={addSoldDetail}
        sx={{ marginTop: "10px" }}
      >
        Add Another Buyer
      </Button>
    </DialogContent>
  );
};

export default SaleForm;

import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  paperStyle:{
    padding: 50,
    height: "70vh",
    width: 390,
    margin: "100px auto",
  },
  AvatarStyle:{
    backgroundColor: "teal"
  },
  Buttondesign:{
    float: "left", 
    margin: "10px auto"

  },
 
}));

import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Paper,
  Link,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
// styles
import useStyles from "./styles";
import { joinUrl } from "../../../data/config";

// context

import { Userlogin, getData, forgotPassword } from "../LoginState";

function Login(props) {
  var classes = useStyles();
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [activeTabId, setActiveTabId] = useState(0);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [message, setMessage] = useState("");
  const [notification, setNotification] = useState(false);
  const [type, setType] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  //const state = useSelector(state => state.userdata)

  //Forgot changes
  const [title, setTitle] = useState("login");
  const [forgot, setForgot] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    Userlogin(loginValue, passwordValue, dispatch)
      .then((response) => {
        if (response.status === "success") {
          localStorage.setItem("token", response.token);
          getData(dispatch)
            .then((resp) => {
              if (resp.status === "200") {
                dispatch({ type: "LOGIN_SUCCESS" });
                setIsLoading(false);
                if (resp.getData.type === "tenant") {
                  props.history.push("/app/dashboard");
                } else if (
                  resp.getData.type === "Customer" &&
                  resp.getData.roles.includes("Customer_Admin")
                ) {
                  props.history.push("/app/dashboard");
                } else if (
                  resp.getData.type === "Customer" &&
                  resp.getData.roles.includes("Branch_Admin")
                ) {
                  props.history.push("/app/MPR");
                } else {
                  props.history.push("/app/dashboard");
                }
              }
            })
            .catch((error) => {
              setType("error");
              setMessage("An error occurred. Please try again later.");
              console.error("Error in getData:", error);
            });
        } else {
          setType("error");
          setIsLoading(false);
          setMessage("Email or Password is wrong. Please try again!");
          setLoginValue("");
          setPasswordValue("");
          dispatch({ type: "LOGIN_FAILURE" });
        }
      })
      .catch((error) => {
        setType("error");
        setMessage("Something went wrong! Please try again!!");
        setNotification(true);
        setIsLoading(false);
      });
  };

  const onForgot = () => {
    setTitle("Forgot Password");
    setType("");
    setMessage("");
    setForgot(true);
  };
  const onBack = () => {
    setTitle("Login");
    setType("");
    setMessage("");
    setForgot(false);
  };
  const handleForgotPassword = () => {
    setIsLoading(true);
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9]+.[a-z]{2,3}$/;
    if (loginValue === "") {
      setMessage("Please Enter Email Address ");
      setNotification(true);
      setType("error");
      setIsLoading(false);
    } else if (!emailPattern.test(loginValue)) {
      setMessage("Invalid email");
      setNotification(true);
      setType("error");
      setIsLoading(false);
    } else {
      let data = {
        mailID: loginValue,
      };
      forgotPassword(data)
        .then((response) => {
          if (response.status === "200") {
            setLoginValue("");
            setType("success");
            setMessage("Please check your mail for further reference");
            setNotification(true);
            setForgot(false);
            setIsLoading(false);
            setTitle("Login");
          } else if (response.status === "500") {
            setMessage(response.message);
            setType("error");
            setNotification(true);
            setForgot(false);
            setIsLoading(false);
            setTitle("Login");
          } else {
            setMessage("Something went wrong! Please try again!!");
            setType("error");
            setNotification(true);
            setForgot(false);
            setIsLoading(false);
            setTitle("Login");
          }
        })
        .catch((error) => {
          console.error("Network error:", error);
          setMessage("A network error occurred. Please try again later.");
          setType("error");
          setNotification(true);
          setIsLoading(false);
        });
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <Grid container className={classes.container}>
      {/* {
          (notification)?<NotificationContainer message={message} notificationtype={type}/>:<></>
        } */}
      <div className={classes.logotypeContainer}>
        <img
          src={`assets/img/sidebar.png`}
          alt="logo"
          width="30%"
          className={classes.logotypeText}
        />
      </div>
      <div className={classes.formContainer}>
        <Paper className={classes.paperContainer}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={title} classes={{ root: classes.tab }} />
          </Tabs>
          {type == "" && (
            <Typography
              color="primary"
              className={classes.errorMessage}
            ></Typography>
          )}
          <form onKeyDown={handleKeyDown} onSubmit={(e) => handleSubmit(e)}>
            {type == "success" && (
              <Typography color="primary" className={classes.successMessage}>
                {message}
              </Typography>
            )}
            {type == "error" && (
              <Typography color="primary" className={classes.errorMessage}>
                {message}
              </Typography>
            )}
            <TextField
              variant="standard"
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Email"
              type="email"
              fullWidth
            />
            {!forgot ? (
              <TextField
                variant="standard"
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ marginRight: "0.5rem" }}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                        disabled={passwordValue.length === 0}
                      >
                        {showPassword ? (
                          <Visibility className={classes.iconButton} />
                        ) : (
                          <VisibilityOff className={classes.iconButton} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
              />
            ) : (
              <></>
            )}

            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : !forgot ? (
                <Button
                  disabled={
                    loginValue.length === 0 || passwordValue.length === 0
                  }
                  onClick={(e) => handleSubmit(e)}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Login
                </Button>
              ) : (
                <Button
                  disabled={loginValue.length === 0}
                  onClick={handleForgotPassword}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Submit
                </Button>
              )}
              {!forgot ? (
                <Link
                  color="primary"
                  size="small"
                  onClick={onForgot}
                  style={{ cursor: "pointer" }}
                  underline="hover"
                >
                  Forgot Password
                </Link>
              ) : (
                <Button onClick={onBack} color="primary" size="large">
                  Back
                </Button>
              )}
            </div>
            <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
              New to Binbag?{" "}
              <Link
                color="primary"
                size="small"
                href={joinUrl}
                style={{ cursor: "pointer" }}
                underline="hover"
              >
                Join now
              </Link>
            </div>
          </form>
        </Paper>
      </div>
    </Grid>
  );
}

export default withRouter(Login);

import React from "react";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Grid,Button,Card } from "@mui/material";
import { useTranslation } from 'react-i18next';


//components
import useStyles from "./styles";
import Table from '../../components/Table/Table'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'


export default function TenantBranch()  {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
    var classes = useStyles();

    

    const datatableData = [
      ["BinBag", "ab@binbag.com","Active","Bangalore","1 week ago"],
      ["ABC Recycling ", "abc@abc.com","Active","Pune","1 day ago"],
      ["XYZ Recycling", "xyz@xyz.com","Active","Chennai","3 days ago"]
      ]
      const datatableHeader = [ t("common_branch")+" "+t("common_name"),t("common_email"),t("common_status"),t("common_location"),t("common_last_sign_in")]
    
    const breadcrumb = [
      {label: t("common_admin") , link: "/#/app/admin"},
      {label: t("common_branch" ), link: ""},
    ]
     
      
    return (
        <>
          <BreadCrumbs data={breadcrumb} />
            <div className={classes.content}>
            <div className={classes.pageTitleContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Card>
                    <div className={classes.HeaderContainer} >
                      <div className={classes.tenantHeader}>
                        <Typography variant="h3" size="sm">{t("common_branch")} | </Typography> 
                        <span className={classes.tenantSubHeader}>{t("common_branch")}</span>
                      </div>
                      
                      <div>
                          <Button variant="outlined" href="/#/app/addBranch" color="primary" className={classes.buttonOutlined}>
                          {t("common_branch")+" "+t("common_add")}
                          </Button>
                      </div>
                    </div>
                  </Card> 
                </Grid>
              </Grid>
            </div>
          <Grid container spacing={4}>
              <Grid item xs={12}>
                <Table 
                  data={datatableData} 
                  header={datatableHeader} 
                  name={t("branch_list") }
                  options={{
                    filterType: 'checkbox',
                    print: false,
                    download: false,
                    filter:false,
                  }} />
              </Grid>
          </Grid>
        </div>
      </>
    )
}
import React from "react";
import ResponsiveDrawer from "./components/ResponsiveDrawer";

export default function InternalForms() {
  return (
    <div style={{ margin: "0px -30px", height: "110%", background: "white" }}>
      <ResponsiveDrawer />
    </div>
  );
}

import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    IconButton,
    Button,
} from '@mui/material'
import Table from '../../../../../components/Table/Table'
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment'
import {useDispatch,useSelector} from 'react-redux'
import VisibilityIcon from '@mui/icons-material/Visibility';

import useStyles from "../styles";
import AddPayment from "./AddPayment";
import {getLead} from '../../../LeadState'

function currencyFormat(num) {
    return parseInt(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const Payment  = ({list,payment,close}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const dispatch = useDispatch()
    var loggedInDatas = useSelector(state => state.userdata)
    const header = [t("mpr_invoice_amount"),t("lead_payment_last_date"),t("common_status"),t("common_action")]
    const state = useSelector(state => state.selectedLead)
    let external_id = (state.length > 0)? state[0].external_id : "";

    const [showForm,setShowForm] = useState(false)
    const [tableData,setTableData] = useState([])
    const [paymentData,setPaymentData] = useState(payment)
    
    const data = []
    
    const handleShowForm = () => {
        setShowForm(!showForm)
        getLead(external_id,dispatch).then(response => {
            if(response && response.payment) setPaymentData(response.payment)
        })
    }
    useEffect(() => {
        if(Object.keys(paymentData).length > 0){
            let status = paymentData.paymentStatus.charAt(0).toUpperCase() + paymentData.paymentStatus.slice(1)
            data.push([
                currencyFormat(paymentData.totalAmount),
                moment(paymentData.payBy).format('DD-MM-YYYY'),
                status,
                (loggedInDatas.getData && loggedInDatas.getData.type === "tenant" && !loggedInDatas.getData.roles.includes("user"))?
                <IconButton className={classes.collapseIcon} onClick={handleShowForm} size="large">
                    {(paymentData && (paymentData.paymentStatus).toLowerCase() ===("Paid").toLowerCase())?<VisibilityIcon />:<EditIcon />}  
                </IconButton> : <></>
            ])
        }
        
        setTableData(data)
        // eslint-disable-next-line
    },[showForm,paymentData])

    return(
        
        <Paper className={classes.Datapaper}>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={8}>
                        <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("common_make_payment")}</span>
                </Grid>
                <Grid item lg={4} xs={12} className={classes.iconGridWithButton}>
                 {
                     (payment && payment.paymentStatus)?<></>:<Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} onClick={handleShowForm}>{t("common_add")}</Button>
                 }   
                    <Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} onClick={() => close()}>{t("common_close")}</Button>
                </Grid>         
            </Grid>
            <Grid container spacing={3} className={classes.grid}>
            {(showForm)? 
                        <>
                            <Grid item lg={2} sm={0}  xs={0}></Grid>
                            <Grid item lg={8} sm={12} xs={12} style={{marginBottom:"2rem"}}> 
                                <AddPayment  close={handleShowForm} list={list} payment={payment} paymentData={paymentData}/>
                            </Grid> 
                            <Grid item lg={2} sm={0} xs={0}></Grid>
                        </>: 
                <Grid item lg={12} md={12} xs={12} sm={12}>
                       <Table 
                       data={tableData} 
                       header={header} 
                       name="" 
                       options={{
                           filterType: 'checkbox',
                           print: false,
                           download: false,
                           filter:false,
                           selectableRows:'none',
                        }} />
                </Grid>
                }
            </Grid>
        </Paper>
    )
}
export default Payment
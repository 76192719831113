import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    IconButton,
    Button} from '@mui/material'
import Table from '../../../../../components/Table/Table'
import { useTranslation } from 'react-i18next';
import {useDispatch,useSelector} from 'react-redux'
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

import useStyles from "../styles";
import PickUpForm from "./PickUpForm";
import {getLead} from "../../../LeadState"

const Schedule  = ({pickup,list,close}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const dispatch = useDispatch()
    const [tableData,setTableData] = useState([])
    const [showForm,setShowForm] = useState(false)
    const [pickupData,setPickupData]  = useState(pickup)
    const [lists,setLists] = useState(list)
    const state = useSelector(state => state.selectedLead)
    let external_id = (state.length > 0)? state[0].external_id : "";

    const header = [t("common_pickup")+" "+t("common_date"),t("common_pickup")+" "+t("common_time"),t("common_description"),t("common_status"),t("common_action")]
    
    const handleShowForm = () => {
        setShowForm(!showForm)
        getLead(external_id,dispatch).then(response => {
            if(response && response.pickup) setPickupData(response.pickup)
            if(response && response.thisLeadDetails) setLists(response.thisLeadDetails)
        })
    }

    useEffect(()=> {
        let data = []
        if(Object.keys(pickupData).length > 2){
            data.push([
                pickupData.pickupDate,
                pickupData.pickupTime,
                pickupData.materialDescription,
                lists.status,
                <IconButton className={classes.collapseIcon} onClick={handleShowForm} size="large">
                    {
                        (lists.status.toLowerCase() === ("pickup initiated").toLowerCase())?<EditIcon /> :<VisibilityIcon />
                    }
                </IconButton>
            ])
        }
        setTableData(data)
        // eslint-disable-next-line
    },[pickupData,showForm])
    

    return(
        
        <Paper className={classes.Datapaper}>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={8}>
                        <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("lead_schedule_title")}</span>
                </Grid>
                <Grid item lg={4} xs={12} className={classes.iconGridWithButton}>
                    {
                        (Object.keys(pickupData).length <=2)?<Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} onClick={handleShowForm}>{t("lead_schedule_pickup")}</Button>:<></>
                    }
                    {
                        (!showForm)?<Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} onClick={()=> close()}>{t("common_close")}</Button>:<></>
                    }
                     {
                        (Object.keys(pickupData).length >2 && showForm)?<Button variant="outlined" color="primary" size="small" className={classes.buttonOutlined} onClick={() => setShowForm(false)}>{t("common_close")}</Button>:<></>
                    }
                </Grid>         
            </Grid>               
            <Grid container spacing={3} className={classes.grid}>
                {
                    (showForm)? <>
                        <Grid item lg={2}></Grid>
                        <Grid item lg={8}> 
                            <PickUpForm  close={handleShowForm} pickup={pickupData} list={lists}/>
                        </Grid> 
                        <Grid item lg={2}></Grid>
                    </> : <Grid item lg={12} md={12} xs={12} sm={12}>
                    <Table 
                        data={tableData} 
                        header={header} 
                        name="" 
                        options={{
                            filterType: 'checkbox',
                            print: false,
                            download: false,
                            filter:false,
                            selectableRows:'none',
                         }} />
                    </Grid>
                }
            </Grid>
        </Paper>
    )
}
export default Schedule
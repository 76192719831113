import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Paper,
} from "@mui/material";
import { useDispatch } from "react-redux";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import moment from "moment";

import useStyles from "../styles";
import NotificationContainer from "../../../../notifications/NotificationContainer";
import { assignLead, form6generation } from "../../../LeadState";

function ChangeTime(hour, min) {
  let time = hour > 12 ? hour - 12 : "0" + hour;
  let minute = min < 10 ? "0" + min : min;
  let ampm = hour > 12 ? "PM" : "AM";
  return time + ":" + minute + " " + ampm;
}

const PickUpForm = ({ pickup, list, close }) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  let receiver;

  if (pickup && pickup.ReceiverAddress)
    receiver =
      pickup.ReceiverAddress.Address.address1 +
      " , " +
      pickup.ReceiverAddress.Address.address2 +
      " , " +
      pickup.ReceiverAddress.City +
      ", " +
      pickup.ReceiverAddress.State +
      ", " +
      pickup.ReceiverAddress.Pincode;

  const [pickupDate, setPickupDate] = useState(
    pickup && pickup.editPickupDate
      ? new Date(pickup.editPickupDate)
      : new Date()
  );
  const [pickupTime, setPickupTime] = useState(
    pickup && pickup.editPickupTime
      ? new Date(pickup.editPickupTime)
      : new Date()
  );
  const [senderAddress, setSenderAddress] = useState(
    pickup && pickup.senderAddress ? pickup.senderAddress : ""
  );
  // eslint-disable-next-line
  const [receiverAddress, setReceiverAddress] = useState(receiver);
  const [materailDescription, setMaterialDescription] = useState(
    pickup && pickup.materialDescription ? pickup.materialDescription : ""
  );
  const [vehicleNumber, setVehicleNumber] = useState(
    pickup && pickup.vechileRegNumber ? pickup.vechileRegNumber : ""
  );
  const [vehicleType, setVehicleType] = useState(
    pickup && pickup.typeOfVehicle ? pickup.typeOfVehicle : ""
  );
  const [transportNo, setTransportNo] = useState(
    pickup && pickup.transportRegnumber ? pickup.transportRegnumber : ""
  );
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState(false);
  const [type, setType] = useState("");
  const [showloader, setShowLoader] = useState(false);

  const handlePickupDate = (date) => {
    setPickupDate(date);
  };
  const handleDateChange = (date) => {
    setPickupTime(date);
  };
  const handleSubmit = () => {
    setShowLoader(true);
    let Data = {
      external_id: list.external_id,
      leadData: {
        pickup: {
          pickupDate: moment(pickupDate).format("DD-MM-YYYY"),
          pickupTime: ChangeTime(
            pickupTime.getHours(),
            pickupTime.getMinutes()
          ),
          editPickupTime: pickupTime,
          editPickupDate: pickupDate,
          vechileRegNumber: vehicleNumber,
          typeOfVehicle: vehicleType,
          transportRegnumber: transportNo,
          senderAddress: senderAddress,
          ReceiverAddress: pickup.ReceiverAddress,
          materialDescription: materailDescription,
          pickupStatus: Object.keys(pickup).length > 2 ? true : false,
        },
        status:
          Object.keys(pickup).length > 2
            ? "pickup completed"
            : "pickup initiated",
      },
      mpr_id: list.Mpr_id
    };
    let form6 = {
      leadID: list.external_id,
    };
    assignLead(Data, dispatch).then((response) => {
      if (response.status === "200") {
        if (
          Data.leadData.status.toLowerCase() ===
          "pickup completed".toLowerCase()
        ) {
          form6generation(form6, dispatch).then((response) => {
            if (response.status === "200") {
              setType("success");
              setMessage("Material Pickup Completed Successfully");
              setNotification(true);
              setTimeout(() => close(), 2000);
              setShowLoader(false);
            } else {
              setType("error");
              setMessage("Something went wrong");
              setNotification(true);
              setShowLoader(false);
            }
          });
        } else {
          setType("success");
          setMessage("Material Pickup Initiated Successfully");
          setNotification(true);
          setTimeout(() => close(), 2000);
          setShowLoader(false);
        }
      } else {
        setType("error");
        setMessage("Something went wrong");
        setNotification(true);
        setShowLoader(false);
      }
    });
  };
  useEffect(() => {}, [showloader, notification]);
  return (
    <>
      {notification ? (
        <NotificationContainer message={message} notificationtype={type} />
      ) : (
        <></>
      )}
      {pickup.pickupStatus ? (
        <Paper elevation={3} className={classes.pickupDiv}>
          <Grid container spacing={3}>
            <Grid item lg={4}>
              <h4>{t("common_pickup") + " " + t("common_date")}</h4>
              {pickup.pickupDate}
            </Grid>
            <Grid item lg={4}>
              <h4>{t("common_pickup") + " " + t("common_time")}</h4>
              {pickup.pickupTime}
            </Grid>
            <Grid item lg={4}>
              <h4>{t("lead_pickup_vehicle_info")}</h4>
              {pickup.vechileRegNumber}
            </Grid>
            <Grid item lg={4}>
              <h4>{t("lead_pickup_vehicle_info2")}</h4>
              {pickup.typeOfVehicle}
            </Grid>
            <Grid item lg={4}>
              <h4>{t("lead_pickup_vehicle_info3")}</h4>
              {pickup.transportRegnumber}
            </Grid>
            <Grid item lg={4}>
              <h4>{t("lead_sender_address")}</h4>
              {pickup.senderAddress}
            </Grid>
            <Grid item lg={4}>
              <h4>{t("common_material_description")}</h4>
              {pickup.materialDescription}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <div className={classes.PickupformField}>
          <form autoComplete="off">
            <div>
              <Grid container spacing={3}>
                <Grid item lg={6}>
                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils} fullwidth>
                            <KeyboardDatePicker */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils} fullwidth> */}
                    <DatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label={t("common_pickup")+" "+t("common_date")}
                      format="dd/MM/yyyy"
                      value={pickupDate}
                      fullWidth
                      disablePast
                      onChange={handlePickupDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    renderInput=
                    {
                      <TextField
                      variant="standard"
                      label={t("common_pickup") + " " + t("common_date")}
                      ></TextField>
                    }
                    slotProps={{ textField: { variant: "standard" } }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item lg={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileTimePicker 
                openTo="minutes" 
                margin="normal"
                id="time-picker"
                disablePast
                label={t("common_pickup") + " " + t("common_time")}
                value={pickupTime}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                renderInput={
                  <TextField
                    label={t("common_pickup") + " " + t("common_time")}
                  ></TextField>
                }
                slotProps={{ textField: { variant: "standard" } }}
                
                />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </div>
            <div className={classes.divTop}>
              <TextField
                variant="standard"
                id="standard-basic"
                label={t("common_material_description")}
                multiline
                value={materailDescription}
                onChange={(e) => setMaterialDescription(e.target.value)}
                fullWidth
              />
            </div>
            <div className={classes.divTop}>
              <TextField
                id="outlined-multiline-static"
                label={t("lead_form_label8")}
                multiline
                rows={3}
                onChange={(e) => setSenderAddress(e.target.value)}
                defaultValue={senderAddress}
                variant="outlined"
                fullWidth
              />
            </div>
            <div className={classes.divTop}>
              <TextField
                id="outlined-multiline-static"
                label={t("lead_sender_address")}
                multiline
                rows={4}
                defaultValue={receiverAddress}
                variant="filled"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div className={classes.divTop}>
              <TextField
                variant="standard"
                id="standard-basic"
                label={t("lead_form_label7")}
                value={vehicleNumber}
                onChange={(e) => setVehicleNumber(e.target.value)}
                fullWidth
              />
            </div>
            <div className={classes.divTop}>
              <TextField
                variant="standard"
                id="standard-basic"
                label={t("lead_form_label5")}
                value={vehicleType}
                onChange={(e) => setVehicleType(e.target.value)}
                fullWidth
              />
            </div>
            <div className={classes.divTop}>
              <TextField
                variant="standard"
                id="standard-basic"
                label={t("lead_form_label6")}
                value={transportNo}
                onChange={(e) => setTransportNo(e.target.value)}
                fullWidth
              />
            </div>
            <div style={{ marginTop: "2rem", float: "right" }}>
              {showloader ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleSubmit}
                >
                  {Object.keys(pickup).length > 2
                    ? t("lead_complete_pickup")
                    : t("lead_initialize_pickup")}
                </Button>
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default PickUpForm;

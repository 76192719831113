import React,{useEffect} from 'react'

import Notification from '../../components/Notification/Notification'
import { Close as CloseIcon } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import useStyles from './styles'
import "react-toastify/dist/ReactToastify.css";


const NotificationContainer = (props) => {
    var classes = useStyles();
    function sendNotification(componentProps, options) {
        return toast(
          <Notification
            {...componentProps}
            className={classes.notificationComponent}
          />,
          options,
        );
    }
    function handleNotificationCall(notificationType,message) {
      var componentProps;
      switch (notificationType) {
        case "info":
          componentProps = {
            type: "feedback",
            message: message,
            variant: "contained",
            color: "primary",
          };
          break;
        case "error":
          componentProps = {
            type: "message",
            message: message,
            variant: "contained",
            color: "secondary"
          };
          break;
        default:
          componentProps = {
            type: "shipped",
            message: message,
            variant: "contained",
            color: "success",
          };
      }
            sendNotification(componentProps, {
            type: notificationType,
            position: toast.POSITION.TOP_RIGHT,
            progressClassName: classes.progress,
            className: classes.notification,
        });
    }
    useEffect(()=>{
        handleNotificationCall(props.notificationtype,props.message)
        // eslint-disable-next-line
    },[props.message])

    return(
        <ToastContainer
          className={classes.toastsContainer}
          closeButton={
            <CloseButton className={classes.notificationCloseButton} />
          }
          closeOnClick={false}
          progressClassName={classes.notificationProgress}
          bodyClassName={classes.tostifyBody}
        />
    )
}
function CloseButton({ closeToast, className }) {
    return <CloseIcon className={className} onClick={closeToast} />;
}
export default NotificationContainer

import React, { useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";

import useStyles from "./styles";
import { assignLead } from "../../LeadState";
import NotificationContainer from "../../../notifications/NotificationContainer";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="₹"
    />
  );
}
function currencyFormat(num) {
  return parseInt(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
const CloseLead = ({ list, close }) => {
  var classes = useStyles();
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  //const data = useSelector(state => state.userdata)

  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState(false);
  const [type, setType] = useState("");
  const [boughtValue, setBoughtValue] = useState(
    Object.keys(list.transactionAmount).length > 0
      ? list.transactionAmount.amount
      : ""
  );
  const [soldValue, setSoldValue] = useState("");
  const [region, setRegion] = useState("INR");
  const [material, setMaterial] = useState("");
  const [showloader, setShowLoader] = useState(false);

  const closeLead = () => {
    setShowLoader(true);
    let body = {
      external_id: list.external_id,
      leadData: {
        status: "Closed",
        reason: "Won",
        materialHandling: material,
        transactionValue: {
          region: region,
          amount: soldValue,
        },
      },
      mpr_id: list.Mpr_id,
    };
    assignLead(body, dispatch).then((response) => {
      if (response.status === "200") {
        setType("success");
        setMessage("Lead Closed Successfully");
        setNotification(true);
        setShowLoader(false);
        setTimeout(() => close(), 2000);
      } else {
        setType("error");
        setMessage("Something went Wrong! Please try again!!");
        setNotification(true);
        setShowLoader(false);
      }
    });
  };

  return (
    <Paper elevation={3} className={classes.Datapaper}>
      {notification ? (
        <NotificationContainer message={message} notificationtype={type} />
      ) : (
        <></>
      )}
      <Grid container spacing={3} className={classes.grid}>
        <Grid item lg={10}>
          <span
            style={{
              fontSize: "19px",
              fontWeight: "lighter",
              marginLeft: "30px",
            }}
          >
            {t("lead_close_lead")}
          </span>
        </Grid>
        <Grid item lg={2}>
          <Button
            variant="outlined"
            onClick={() => close()}
            className={classes.button}
          >
            {t("common_back")}
          </Button>
        </Grid>
      </Grid>
      {list.status === "Closed" ? (
      <Paper elevation={3} className={classes.Datapaper} style={{margin:"40px 20px",padding:"30px"}}>
        <Grid container spacing={3} className={classes.grid}>
           
              <Grid item lg={4} md={3} xs={6} sm={6}>
                <h4>{t("lead_bought_amount")}</h4>
                {currencyFormat(list.transactionAmount.amount)}
              </Grid>
              <Grid item lg={4} md={3} xs={6} sm={6}>
                <h4>{t("lead_sold_amount")}</h4>
                {currencyFormat(list.transactionValue.amount)}
              </Grid>
              <Grid item lg={4} md={3} xs={6} sm={6}>
                <h4>{t("lead_material_handling")}</h4>
                {list.materialHandling}
              </Grid>
              <Grid item lg={4} md={3} xs={6} sm={6}>
                <h4>{t("common_status")}</h4>
                {list.status}
              </Grid>
              <Grid item lg={4} md={3} xs={6} sm={6}>
                <h4>{t("common_reason")}</h4>
                {list.reason}
              </Grid>
        </Grid>
      </Paper>
      ) : (
        <Paper elevation={3} className={classes.Datapaper} style={{margin:"50px auto",}}>
          <Grid container spacing={3} >
            <Grid item lg={3} md={3} sm={1} xs={1}></Grid>
            <Grid item lg={6} md={6} sm={10} xs={10}>
                <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12} sm={12}>
              <span style={{ fontSize: "19px", fontWeight: "lighter" }}>
                {t("lead_bought_it_for")}
              </span>
            </Grid>
                  <Grid item lg={4} md={6} xs={12} sm={12}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      className={classes.formControl1}
                    >
                      <InputLabel id="region_drop">
                        {t("mpr_invoice_region")}
                      </InputLabel>
                      <Select
                        variant="standard"
                        labelId="region"
                        id="region"
                        value={region}
                        onChange={(e) => setRegion(e.target.value)}
                      >
                        <MenuItem value={"INR"}>INR</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={8} md={6} sm={12} xs={12}>
                    <TextField
                      variant="standard"
                      label={t("lead_payment_value")}
                      value={boughtValue}
                      fullWidth
                      onChange={(e) => setBoughtValue(e.target.value)}
                      name="numberformat"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item lg={12} md={6} sm={12} xs={12}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      className={classes.formControl1}
                    >
                      <InputLabel id="material_prp">
                        {t("lead_material_handling")}
                      </InputLabel>
                      <Select
                        variant="standard"
                        labelId="material_prp"
                        id="material_prp"
                        value={material}
                        onChange={(e) => setMaterial(e.target.value)}
                      >
                        <MenuItem value={"Trade"}>Trade</MenuItem>
                        <MenuItem value={"To Own Plant"}>To Own Plant</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={4} md={6} xs={12} sm={12}>
                    <FormControl
                      variant="standard"
                      fullWidth
                      className={classes.formControl1}
                    >
                      <InputLabel id="state_drop">
                        {t("mpr_invoice_region")}
                      </InputLabel>
                      <Select
                        variant="standard"
                        labelId="region"
                        id="region"
                        value={region}
                        onChange={(e) => setRegion(e.target.value)}
                      >
                        <MenuItem value={"INR"}>INR</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={8} md={6} sm={12} xs={12}>
                    <TextField
                      variant="standard"
                      label={t("lead_sold_it_for")}
                      value={soldValue}
                      fullWidth
                      onChange={(e) => setSoldValue(e.target.value)}
                      name="numberformat"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                  </Grid>
                </Grid>

              <div style={{ margin: "1rem 0 ", float: "right" }}>
                {/* <Button variant="outlined" onClick={()=>close()}  className={classes.button}>{t("common_back")}</Button> */}
                {showloader ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={closeLead}
                    className={classes.buttonOutlined}
                    
                  >
                    {t("lead_close_lead")}
                  </Button>
                )}
              </div>
            </Grid>
            <Grid lg={3} md={3} sm={1} xs={1}></Grid>
          </Grid>
        </Paper>
      )}
    </Paper>
  );
};
export default CloseLead;

import React from "react";
import { 
  Grid,
  Card,
  Button
 } from "@mui/material";
import { useTranslation } from 'react-i18next'

//components
import { Typography } from "../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
// import TabPannel from './components/TabPannel'
import TabPannel from './components/TabPannel'
 

const Leads = () => {
  const { t, i18n } = useTranslation();
    var classes = useStyles();
    const LeadData = [
      { label: t("common_home"), link: "/#/app/dashboard" },
      {label: t("common_leads") , link: ""},
    ]
    return (
        <>
          <BreadCrumbs data={LeadData} />
            <div className={classes.content}>
            <div className={classes.pageTitleContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Card>
                    <div className={classes.HeaderContainer} >
                      <div className={classes.tenantHeader}>
                      <Typography variant="h3" size="sm">
                      {" "}
                      {t("leads_span")}
                    </Typography>
                      </div>
                      <div>
                      </div>
                    </div>   
                     
                  </Card> 
                </Grid>
              </Grid>
            </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TabPannel />
            </Grid>
          </Grid>
        </div>
      </>
    )
}
export default Leads
import {
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
  Select,
  Grid,
  MenuItem,
  TextField,
  IconButton,
  Box,
  Tooltip,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Paper,
  InputAdornment,
  Typography,
  Divider
} from "@mui/material";
import NotificationContainer from "../../../../notifications/NotificationContainer";
// import inrInWords from "inr-words";
import { ToWords } from 'to-words';
import FileBase64 from "react-file-base64";
import useStyles from "../styles";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ConfirmationDialog from "./ConfirmationDialog";
import { NumericFormat } from "react-number-format";
import CachedIcon from "@mui/icons-material/Cached";
import { getFile, getLead } from "../../../LeadState";
import ImageIcon from "@mui/icons-material/Image";
import { fileupload } from "../../../../material_pickup_request/MprState";
import EditIcon from "@mui/icons-material/Edit";
import { addSignature } from "../../../LeadState";
import { assignLead, quotationEdit} from "../../../LeadState";
import CancelIcon from '@mui/icons-material/Cancel';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="₹"
    />
  );
}

const EditQuotation = (props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [fileData, setFileData] = useState("");
  const [notification, setNotification] = useState(false);
  const [type, setType] = useState("");
  const dispatch = useDispatch();
  var data = useSelector((state) => state.userdata);
  var userData = data.getData;
  const [signature,setSignature ]= useState(userData?.signature)
  const [showLoader, setShowLoader] = useState(false);
  const [genratedDate, setGeneratedDate] = useState(new Date());
  const [customer, setCustomer] = useState(
    props.quotationDetails.details.customer
  );
  const [quotationSubject, setQuotationSubject] = useState(
    props.quotationDetails.details.quotationSubject
  );
  // const [quotationContent, setQuotationContent] = useState(
  //   props.quotationDetails.Content.quotationContent
  // );
  const [thankofProposal, setThankofProposal] = useState(
    props.quotationDetails.details.thankofProposal
  );
  const [showSignatureLoader, setShowSignatureLoader] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [notes, setNotes] = useState(props.quotationDetails.details.notes);
  const [isConfirmNoteDialogOpen, setIsConfirmNoteDialogOpen] = useState(false);
  const [selectedNoteRow, setSelectedNoteRow] = useState();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const state = useSelector((state) => state.selectedLead);
  const [quotationNo, setQuotationNo] = useState(props.quotationDetails.details.quotationNo);
  const initialItemsData = [[" ", " ", " ", " ", " ", " "]];
  const [itemsData, setItemsData] = useState(props.quotationDetails.details.items);
  const [totalItems, setTotalItems] = useState(
    props.quotationDetails.details.totalItems
  );
  const [userDetails, setUserDetails] = useState(props.quotationDetails.details.UserDetails);
  const [cgstPercentage, setCgstPercentage] = useState(
    props.quotationDetails.details.gstBreakUps[0].cgstPercentage
  );
  const [cgstAmount, setCgstAmount] = useState(
    props.quotationDetails.details.gstBreakUps[0].cgstAmount
  );
  const [sgstPercentage, setSgstPercentage] = useState(
    props.quotationDetails.details.gstBreakUps[1].sgstPercentage
  );
  const [sgstAmount, setSgstAmount] = useState(
    props.quotationDetails.details.gstBreakUps[1].sgstAmount
  );
  const [igstPercentage, setIgstPercentage] = useState(
    props.quotationDetails.details.gstBreakUps[2].igstPercentage
  );
  const [igstAmount, setIgstAmount] = useState(
    props.quotationDetails.details.gstBreakUps[1].igstAmount
  );
  const [otherCharges, setOtherCharges] = useState(
    props.quotationDetails.details.otherCharges
  );
  const [subtotal, setSubtotal] = useState(
    props.quotationDetails.details.subtotal
  );
  const [totalInWords, setTotalInWords] = useState(
    props.quotationDetails.details.totalInWords
  );
  const [region, setRegion] = useState(props.quotationDetails.details.region);
  const [gstAmount, setGstAmount] = useState(0);
  const [gstBreakUps, setGstBreakUps] = useState(
    props.quotationDetails.details.gstBreakUps
  );
  const [quotationValue, setQuotationValue] = useState(
    props.quotationDetails.details.quotationValue
  );

  const toWords = new ToWords();

  useEffect(() => {
    const items = props.quotationDetails.details.items;
 
    const updatedItems = items.map(item => {
      if ("5" in item) {
        const updatedItem = Object.values(item).filter((value, index) => index !== 0);
        return updatedItem;
      } else {
        return Object.values(item);
      }
    });
  
    setItemsData(updatedItems);
  }, []);

  function calculateTotalItemsCount(itemsData) {
    return itemsData.length;
  }
  //subtotal
  function calculateSubTotalAmount(itemsData) {
    let subTotalAmount = 0;
    itemsData.forEach((row) => {
      const rowAmount = parseFloat(row[5]);
      if (!isNaN(rowAmount)) {
        subTotalAmount += rowAmount;
      }
    });

    return subTotalAmount;
  }

  const calculateGST = (subtotal, percentage) => {
    return (parseFloat(subtotal) * parseFloat(percentage)) / 100;
  };

  // const capitalizeSentence = (sentence) => {
  //   return sentence
  //     .toLowerCase()
  //     .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
  // };

  const quotationValueInWords = (amount) => {
    const numericValue = parseFloat(amount);
    if (!isNaN(numericValue)) {
      const inrWords = toWords.convert(amount,{currency:'true'});
      const [beforeRupees, afterRupees] = inrWords.split("Rupees ");
      const modifiedText = `Rupees ${beforeRupees}${afterRupees}`
      setTotalInWords(modifiedText);
    } else if (!quotationValue) {
      setTotalInWords("");
    } else {
      setTotalInWords("Invalid Amount");
    }
  };

  useEffect(() => {
    const totalItemsCount = calculateTotalItemsCount(itemsData);
    const subTotalAmount = calculateSubTotalAmount(itemsData);
    const otherChargesIncluded = otherCharges;
    const calculateCgst = calculateGST(subTotalAmount, cgstPercentage);
    setCgstAmount(calculateCgst);
    const calculateSgst = calculateGST(subTotalAmount, sgstPercentage);
    setSgstAmount(calculateSgst);
    const calculateIgst = calculateGST(subTotalAmount, igstPercentage);
    setIgstAmount(calculateIgst);

    let totalAmount = 0;
    let gst = parseFloat(calculateCgst) + parseFloat(calculateSgst) + parseFloat(calculateIgst);

    if (otherChargesIncluded !== "") {
      totalAmount =
        parseFloat(subTotalAmount) + parseFloat(gst) + parseFloat(otherChargesIncluded);
    } else {
      totalAmount = parseFloat(subTotalAmount) + parseFloat(gst);
    }
    setTotalItems(totalItemsCount);
    setSubtotal(formatIndianRupees(subTotalAmount));
    setQuotationValue(totalAmount.toFixed(2));
    quotationValueInWords(parseFloat(totalAmount.toFixed(2)));

    setGstAmount(gst);
    setGstBreakUps([
      {
        cgstPercentage: cgstPercentage,
        cgstAmount: calculateCgst,
      },
      {
        sgstPercentage: sgstPercentage,
        sgstAmount: calculateSgst,
      },
      {
        igstPercentage: igstPercentage,
        igstAmount: calculateIgst,
      },
    ]);
  }, [itemsData, otherCharges, cgstPercentage, sgstPercentage, igstPercentage]);

  const handleGenDate = (date) => {
    setGeneratedDate(date);
  };

  const handleQuotationSubChange = (e) => {
    setQuotationSubject(e.target.value);
  };

  // const handleQuotationContent = (e) => {
  //   setQuotationContent(e.target.value);
  // };

  const handleThankOfProposal = (e) => {
    setThankofProposal(e.target.value);
  };

  const handleTextFieldChange = (index, value) => {
    const newFields = [...notes];
    newFields[index] = value;
    setNotes(newFields);
    // const lastElement = newFields[newFields.length - 1];
  };

  const handleDeleteNote = (index) => {
    setSelectedNoteRow(index);
    confirmOpenNoteDialog();
  };

  const confirmOpenNoteDialog = () => {
    setIsConfirmNoteDialogOpen(true);
  };

  const confirmNoteAction = () => {
    const updatedData = [...notes];
    const newArray = updatedData.filter(
      (element, index) => index !== selectedNoteRow
    );
    setNotes(newArray);
    setSelectedNoteRow();
  };

  const confirmOpenDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  const columns = [
    { label: "S.No", width: 50 },
    { label: "Item Details", width: 350 },
    { label: "Quantity", width: 90 },
    { label: "Unit", width: 90 },
    { label: "Rate/Item", width: 100 },
    { label: "Amount", width: 100 },
  ];

  const handleDeleteRow = (rowIndex) => {
    setSelectedRow(rowIndex);
    confirmOpenDialog();
  };

  const confirmAction = () => {
    const updatedData = [...itemsData];
    updatedData.splice(selectedRow, 1);
    setItemsData(updatedData);
    setSelectedRow();
  };

  const confirmCloseNoteDialog = () => {
    setIsConfirmNoteDialogOpen(false);
  };

  function formatIndianRupees(amount) {
    return amount
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })
      .replace("₹", "");
  }

  const calculateAmountForAitem = (rowIndex) => {
    const quantity = parseFloat(itemsData[rowIndex][2]);
    const ratePerQuantity = parseFloat(itemsData[rowIndex][4]);
    if (!isNaN(ratePerQuantity) && !isNaN(quantity)) {
      const amount = quantity * ratePerQuantity;
      itemsData[rowIndex][5] = amount;
      return formatIndianRupees(amount);
    } else {
      itemsData[rowIndex][5] = "";
      return "";
    }
  };

  const handleCellChange = (value, rowIndex, colName) => {
    const updatedData = [...itemsData];
    //     if (colName === 1) {
    //       const capitalizedValue = value.toUpperCase();
    //       updatedData[rowIndex][colName] = capitalizedValue;
    // }
    if (colName === 2) {
      const onlyNumbers = /^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/;
      if (onlyNumbers.test(value)) {
        updatedData[rowIndex][colName] = value;
      } else {
        updatedData[rowIndex][colName] = "";
      }
    } else if (colName === 3) {
      const allowedCharacters = /^[a-zA-Z /]*$/;
      if (allowedCharacters.test(value)) {
        updatedData[rowIndex][colName] = value;
      } else {
        updatedData[rowIndex][colName] = "";
      }
    }else if (colName === 4){
      const parsedValue = parseFloat(value);
      const onlyNumbers = /^\d{1,10}(\.\d{1,2})?$/;
      if (onlyNumbers.test(parsedValue)) {
        updatedData[rowIndex][colName] = value;
      } 
      else if (isNaN(parsedValue)){
        updatedData[rowIndex][colName] = " ";
      }
      else {
        var roundedValue = parsedValue.toFixed(2);
        updatedData[rowIndex][colName] = roundedValue;
      }
    } else if (colName === 5) {
      const parsedValue = parseFloat(value);
      updatedData[rowIndex][colName] = !isNaN(parsedValue) ? parsedValue : "";
    } else {
      updatedData[rowIndex][colName] = value;
    }

    setItemsData(updatedData);
  };

  const handleAddRow = () => {
    const prevRow = itemsData[itemsData.length - 1];
    if (!prevRow) {
      setItemsData([initialItemsData[0]]);
    } else {
      const isRequiredFieldsEmpty = [1, 2, 3, 4].some(
        (columnName) => !prevRow[columnName]
      );
      if (isRequiredFieldsEmpty) {
        setMessage(
          "Required fields (Item Details , Quantity , Unit , Rate/Item)"
        );
        setType("error");
        setNotification(true);
        setTimeout(() => {
          setMessage("");
          setType("");
          setNotification(false);
        }, 2500);
      } else {
        setItemsData((prevData) => [...prevData, initialItemsData[0]]);
      }
    }
  };

  const handlePercentageChange = (
    gstType,
    newValue,
    setPercentage,
    setAmount
  ) => {
    const isValidInput =
      /^\d{0,3}(\.\d{0,2})?$/.test(newValue) &&
      parseFloat(newValue) >= 0 &&
      parseFloat(newValue) <= 100;
    if (isValidInput || newValue === "") {
      setPercentage(newValue);
      const calculatedAmount =
        (parseFloat(subtotal) * parseFloat(newValue)) / 100;
      setAmount(calculatedAmount);
      if (newValue !== "") {
        if (gstType === "cgst" || gstType === "sgst") {
          setIgstPercentage("0");
          setIgstAmount("0");
        } else if (gstType === "igst") {
          setCgstPercentage("0");
          setCgstAmount("0");
          setSgstPercentage("0");
          setSgstAmount("0");
        }
      }
    }
  };

  const handleCgstChange = (newValue) => {
    handlePercentageChange("cgst", newValue, setCgstPercentage, setCgstAmount);
  };

  const handleSgstChange = (newValue) => {
    handlePercentageChange("sgst", newValue, setSgstPercentage, setSgstAmount);
  };

  const handleIgstChange = (newValue) => {
    handlePercentageChange("igst", newValue, setIgstPercentage, setIgstAmount);
  };

  const addTextField = () => {
    const lastElement = notes[notes.length - 1];
    if (lastElement !== "") {
      setNotes([...notes, ""]);
    } else {
      setType("error");
      setNotification(true);
      setMessage("can you fill before note");
      setTimeout(() => {
        setMessage("");
        setType("");
        setNotification(false);
      }, 2000);
    }
  };

  const roundOffTotal = () => {
    const roundedAmount = Math.round(quotationValue);
    const formattedAmount = roundedAmount.toFixed(2);
    setQuotationValue(formattedAmount);
    const inrWords = toWords.convert(roundedAmount,{currency:'true'});
    const [beforeRupees, afterRupees] = inrWords.split("Rupees ");  
    const modifiedText = `Rupees ${beforeRupees}${afterRupees}`
    setTotalInWords(modifiedText);
  };

  const confirmCloseDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleChange = (type) => {
    if (
      fileData.type === "image/png" ||
      fileData.type === "image/jpeg" ||
      fileData.type === "image/jpg"
    ) {
      setShowSignatureLoader(true);
  
      var filetype = fileData.type.split("/");
      var baseData = fileData.base64.split(",");
      let getTokenData = {
        abbrevation: data.getData.abbrevation,
        fileType: "assets",
        folderName: props.list.external_id,
        expireLimt: 1800,
        extension: filetype.length > 0 ? filetype[1] : "",
      };
      fileupload(
        baseData.length > 0 ? baseData[1] : "",
        getTokenData,
        fileData.type
      ).then((response) => {
        if (response?.status === "200") {
          setShowSignatureLoader(false);
          let body = {
            thisItem: type,
            thisImage: {
              fileName: response.fileName || response.filename,
              folderName: response.folderName || response.folder || response.FolderName,
            },
            thisLead: props.list.external_id,
            form:"quotation"
          };
          setSignature({"fileName":response.fileName,"folderName":response.folderName})
          addSignature(body).then((resp) => {
            if (resp?.status === "200") {
              setType("success");
              setMessage(resp.message);
              setNotification(true);
              setShowSignature(true)
              setShowSignatureLoader(false);
            }else if(resp?.status === "401"){
              setMessage(resp.message)
              setType("error")
              setNotification(true)
              setShowSignatureLoader(false);
                setTimeout(() => {
                  dispatch({ type: "LOG_OUT" });
                  localStorage.clear();
                }, 2000);
            }else {
              setType("error");
              setMessage(resp.message);
              setNotification(true);
              setShowSignatureLoader(false);
            }
          });
        }
      });
    } else {
      setType("error");
      setMessage("Please upload Image");
      setNotification(true);
    }
  };


  const handleupdateImage = (e) => {
    setFileData(e);
  };

  const getFiles = (file) => {
    let bodyData = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(bodyData).then((response) => {
      window.open(response.reference, "_blank");
    });
  };

  const handleEditSignature = () => {
    setShowSignature(false);
  };

  const handleSubmit = () => {
    const items = itemsData.map((item) => {
      return {
        itemNo:item[0],
        itemDetails: item[1],
        quantity: item[2],
        unit: item[3],
        ratePerUnit: item[4],
        amount: item[5],
      };
    });
    const quotationDetails = {
      genratedDate,
      customer,
      quotationSubject,
      // quotationContent,
      items,
      thankofProposal,
      totalItems,
      quotationNo,  
      subtotal,
      gstAmount,
      gstBreakUps,
      otherCharges,
      notes,
      region,
      quotationValue,
      totalInWords,
      userDetails,
    };
    let Data = {
      external_id: props.list.external_id,
      quotationDetails,
      status: "Quotation sent",
    };
    setShowLoader(true);
    generateEditQuotation(Data);
  }

  const generateEditQuotation = async (Data) => {
    try {
      const response = await quotationEdit(Data);
      if (response.status == "200") {
        actionOnLead(response);
        setType("success");
        setMessage("Quotation pdf generated");
      } else if(response?.status === "401"){
        setMessage(response.message)
        setType("error")
        setNotification(true)
          setTimeout(() => {
            dispatch({ type: "LOG_OUT" });
            localStorage.clear();
          }, 2000);
      }else {
        setType("error");
        setMessage("Something went wrong");
      }
      setNotification(true);
      setTimeout(() => props.close(), 2000);
    } catch (error) {
      setType("error");
      setMessage("Something went wrong");
      setNotification(true);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (signature) {
      setShowSignature(true); 
    } else {
      setShowSignature(false);
    }
  }, [signature]);

  const actionOnLead = async (res) => { 
    let bodyData = {
      external_id: props.list.external_id,
      leadData: {
        quotation: {
          folderName: res.quoteLoc.folderName || res.quoteLoc.folder || res.quoteLoc.FolderName,
          fileName: res.quoteLoc.fileName || res.quoteLoc.filename,
          quoteStatus: "sent",
          quotationNo:quotationNo,
          totalAmount: {
            region: region,
            amount: quotationValue,
          },
          description: quotationSubject,
          referenceName: "quote/" + props.list.external_id,
          customerComments: "",
          genratedDate:genratedDate
        },
        status: "Quotation sent",
      },
      mpr_id: props.list.Mpr_id,
    };
    const response = await assignLead(bodyData);
    if (response?.status === "200") {
      console.log("success");
    }else if(response?.status === "401"){
      setMessage(response.message)
      setType("error")
      setNotification(true)
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
    }else{
      console.error("something went wrong");
    }
  };

  const handleCloseSignature=()=>{
    setShowSignature(true);
  }

  return (
    <>
      {notification ? (
        <NotificationContainer message={message} notificationtype={type} />
      ) : (
        <></>
      )}
      <div className={classes.quotationForm}>
        <form autoComplete="off">
          <h2>{t("Edit Quotation")} </h2>
          <Grid container spacing={1}>
          <Grid item lg={6} md={6} xs={12} sm={12}>
          <div>
                <TextField
                  className={classes.formControl}
                  type="text"
                  label={t("Quotation#")}
                  value={quotationNo}
                  onChange={(e) => setQuotationNo(e.target.value)}
                  id="invoice_no"
                  name="invoice_no"
                  required
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              </Grid>
            <Grid item lg={6} md={6} xs={11} sm={11}>
              <div className={classes.formControl}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    margin="normal"
                    id="date-picker-dialog"
                    label={t("Quotation Date")}
                    format="dd/MM/yyyy"
                    value={genratedDate}
                    fullWidth
                    readOnly
                    onChange={handleGenDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
          </Grid>
          <div>
            <TextField
              className={classes.formControl}
              type="text"
              minRows={2}
              label={t("To")}
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
              id="full_name"
              name="customer_name"
              InputLabelProps={{ shrink: true }}
              required
              InputProps={{
                readOnly: true,
              }}
            />
          </div>
          <div>
            <FormControl
              variant="standard"
              fullWidth
              className={classes.formControl}
            >
              <TextField
                id="product_des"
                required={true}
                label={t("Subject")}
                slotProps={{ textField: { variant: "standard" } }}
                multiline
                placeholder="Let your customer know what this Quotation is for"
                minRows={2}
                value={quotationSubject}
                onChange={handleQuotationSubChange}
              />
            </FormControl>
          </div>
          {/* <div>
            <FormControl
              variant="standard"
              fullWidth
              className={classes.formControl}
            >
              <TextField
                id="content"
                required={true}
                label={t("Content")}
                slotProps={{ textField: { variant: "standard" } }}
                multiline
                placeholder="fill the content"
                minRows={2}
                value={quotationContent}
                onChange={handleQuotationContent}
              />
            </FormControl>
          </div> */}
          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    {columns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        sx={{
                          padding: "6px 4px",
                          width: column.width,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell style={{ width: 10 }}>Actions</TableCell>
                  </TableRow>
                  {itemsData?.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {Object.values(row).map((cell, colIndex) => (
                        <TableCell key={colIndex} sx={{ padding: "4px" }}>
                          <TextField
                            multiline={colIndex === 1 ? true : false}
                            fullWidth
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                  display: "none",
                                },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                              padding: "0px",
                              width: columns[colIndex]
                                ? columns[colIndex].width
                                : "100%",
                            }}
                            InputProps={{
                              inputProps: {
                                autoComplete: "off",
                                style: { resize: "both" },
                              },
                            }}
                            value={
                              colIndex === 5
                                ? calculateAmountForAitem(rowIndex)
                                : cell
                            }
                            onChange={(e) =>
                              handleCellChange(
                                e.target.value,
                                rowIndex,
                                colIndex
                              )
                            }
                          />
                        </TableCell>
                      ))}

                      <TableCell>
                        <IconButton onClick={() => handleDeleteRow(rowIndex)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                margin: "6 0",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <Button onClick={handleAddRow}>
                <AddCircleIcon style={{ color: "#0D6937" }} />
                <span style={{ fontSize: "16px", marginTop: "2px" }}>
                  Add Item
                </span>
              </Button>
              <div style={{ padding: "10px" }}>Total Items: {totalItems}</div>
            </div>
          </div>
          <Grid container spacing={1}>
            <Grid item lg={6} md={6} xs={1} sm={1}>
              {" "}
            </Grid>
            <Grid item lg={6} md={6} xs={11} sm={11}>
              <TextField
                className={classes.formControl}
                label={t("Taxable Value")}
                value={subtotal}
                onChange={(e) => setSubtotal(e.target.value)}
                name="numberformat"
                id="formatted-numberformat-input-subtotal"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  readOnly: true,
                }}
              />
            </Grid>

            {/* gst calculations */}
            <Grid item lg={6} md={6} xs={1} sm={1}>
              {" "}
            </Grid>
            <Grid item lg={2} md={2} xs={5} sm={5}>
              <TextField
                className={classes.formControl}
                label={t("CGST (%)")}
                value={cgstPercentage}
                onChange={(e) => handleCgstChange(e.target.value)}
                name="numberformat"
                id="formatted-numberformat-input-cgst"
                InputLabelProps={{ shrink: true }}
                disabled={Boolean(gstAmount.igst)}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={6} sm={6}>
              <TextField
                className={classes.formControl}
                label={t("CGST Amount")}
                value={cgstAmount}
                name="numberformat"
                id="formatted-numberformat-input-cgst"
                InputLabelProps={{ shrink: true }}
                disabled={Boolean(gstAmount.igst)}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={1} sm={1}>
              {" "}
            </Grid>
            <Grid item lg={2} md={2} xs={5} sm={5}>
              <TextField
                className={classes.formControl}
                label={t("SGST (%)")}
                value={sgstPercentage}
                onChange={(e) => handleSgstChange(e.target.value)}
                name="numberformat"
                id="formatted-numberformat-input-sgst"
                InputLabelProps={{ shrink: true }}
                disabled={Boolean(gstAmount.igst)}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={6} sm={6}>
              <TextField
                className={classes.formControl}
                label={t("SGST Amount")}
                value={sgstAmount}
                name="numberformat"
                id="formatted-numberformat-input-sgst"
                InputLabelProps={{ shrink: true }}
                disabled={Boolean(gstAmount.igst)}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={1} sm={1}>
              {" "}
            </Grid>
            <Grid item lg={2} md={6} xs={5} sm={5}>
              <TextField
                className={classes.formControl}
                label={t("IGST (%)")}
                value={igstPercentage}
                onChange={(e) => handleIgstChange(e.target.value)}
                name="numberformat"
                id="formatted-numberformat-input-igst"
                InputLabelProps={{ shrink: true }}
                disabled={Boolean(gstAmount.cgst || gstAmount.sgst)}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={6} sm={6}>
              <TextField
                className={classes.formControl}
                label={t("IGST Amount")}
                value={igstAmount}
                name="numberformat"
                id="formatted-numberformat-input-igst"
                InputLabelProps={{ shrink: true }}
                disabled={Boolean(gstAmount.cgst || gstAmount.sgst)}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} xs={1} sm={1}></Grid>
            <Grid item lg={6} md={6} xs={11} sm={11}>
              <TextField
                className={classes.formControl}
                label={t("Other Charges")}
                value={otherCharges}
                onChange={(e) => setOtherCharges(e.target.value)}
                name="numberformat"
                id="formatted-numberformat-input-other-charges"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item lg={4} md={12} xs={1} sm={1}></Grid>
            <Grid item lg={2} md={6} xs={4} sm={4}>
              <FormControl
                fullWidth
                className={classes.formControl}
                style={{ marginTop: "12px" }}
              >
                <InputLabel id="state_drop">{t("Currency")}</InputLabel>
                <Select
                  label={t("Currency")}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  labelId="state_drop"
                  id="region"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                >
                  <MenuItem value={"INR"}>INR</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6} xs={7} sm={7}>
              <TextField
                className={classes.formControl}
                label={t("Total Amount")}
                value={quotationValue}
                name="numberformat"
                id="formatted-numberformat-input"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Round Off">
                        <IconButton onClick={roundOffTotal} edge="end">
                          <CachedIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
                inputProps={{ style: { fontSize: 20, fontWeight: "700" } }}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={0} sm={0}>
              {" "}
            </Grid>
            <Grid item lg={8} md={8} xs={12} sm={12}>
              <TextField
                className={classes.formControl}
                label={t("Total Amount in Words")}
                value={totalInWords}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <div>
            <Grid>
              <h3>Note:</h3>
              <div>
                {notes.map((value, index) => (
                  <Grid style={{ display: "flex" }}>
                    <TextField
                      className={classes.formControl}
                      key={index}
                      value={value}
                      placeholder="fill the notes"
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) =>
                        handleTextFieldChange(index, event.target.value)
                      }
                    />
                    <IconButton onClick={() => handleDeleteNote(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                ))}
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button onClick={addTextField}>
                  <AddCircleIcon style={{ color: "#0D6937" }} />
                  <span style={{ fontSize: "16px", marginTop: "2px" }}>
                    Add Note
                  </span>
                </Button>
              </div>
            </Grid>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <TextField
              className={classes.formControl}
              id="thankOfProposal"
              required={true}
              multiline
              label="Thank of proposal"
              placeholder="fill the notes,"
              minRows={2}
              value={thankofProposal}
              InputLabelProps={{ shrink: true }}
              onChange={handleThankOfProposal}
            />
          </div>
          <Grid className={classes.alignButtom}>
           <Grid item lg={2} md={2} xs={4} sm={4}>
              <Grid style={{textAlign:"center"}}>
                <Grid > 
                <Typography variant="h6">Contact Details</Typography>
                </Grid>
                <Grid>
                <Grid>
                  <Typography>{props.quotationDetails.details.userDetails?.userName }</Typography>
                </Grid>
                <Grid>
                  <Typography>{props.quotationDetails.details.userDetails.userMobileNo}</Typography>
                </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={10} md={10} xs={8} sm={8}>
            {showSignature ? (
                <Grid item lg={12} md={12} xs={12} sm={12} style={{display:"flex",justifyContent:"end",alignItems:"center"}}>
                    <Typography variant="h6"> Signature: </Typography>
                    <IconButton
                      onClick={() => getFiles(signature)}
                      target="_blank"
                      className={classes.collapseIcon}
                      size="large"
                    >
                      <ImageIcon />
                    </IconButton>
                    <IconButton onClick={handleEditSignature}>
                      <EditIcon className={classes.collapseIcon} size="large" />
                    </IconButton>
                </Grid>
               ) : (
                <Grid style={{display:"flex",justifyContent:"end"}}>
                 <Grid item lg={8} md={8} sm={12} xs={12}>
                  <Grid item lg={4} md={4} sm={12} xs={12} style={{justifyContent:"center"}}>
                    <Typography variant="p">Upload Signature</Typography>
                    <IconButton onClick={handleCloseSignature}>
                        <CancelIcon className="close" />
                    </IconButton>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{display:"flex",marginTop:"10px"}}>
                     <FormControl variant="standard">
                      <FileBase64
                        className={classes.input}
                        multiple={false}
                        onDone={(e) => handleupdateImage(e)}
                      />
                     </FormControl>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ textAlign: "right" }}
                   >
                    {showSignatureLoader ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => handleChange("Signature")}
                        className={classes.button}
                      >
                        {t("common_upload")}
                      </Button>
                    )}
                  </Grid>
                  <Grid  item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12} style={{marginTop:"5px",marginBottom:"5px"}}>
                  <Divider style={{ backgroundColor: 'black', height: '1px'}}/>
                  </Grid>
                </Grid>
                </Grid>
            )}
           </Grid>
            </Grid>
            <div style={{ marginTop: "2rem", float: "right" }}>
            {showLoader ? (
              <CircularProgress />
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
                disabled={quotationValue === "0.00" || quotationNo === "" || notes[0] === ""}
              >
                {t("common_publish")}
              </Button>
            )}
          </div>
        </form>
        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={confirmCloseDialog}
          onConfirm={confirmAction}
          title="Delete"
          message="Are you sure you want to delete this item?"
          confirmText="Confirm"
        />
        <ConfirmationDialog
          open={isConfirmNoteDialogOpen}
          onClose={confirmCloseNoteDialog}
          onConfirm={confirmNoteAction}
          title="Delete"
          message="Are you sure you want to delete this Note?"
          confirmText="Confirm"
        />
      </div>
    </>
  );
};
export default EditQuotation;

import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//components
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import CustomButton from "../../components/CustomButton";
import BasicDetails from "./pages/BasicDetails";
import InspectionSlots from "./pages/InspectionSlots";
import MaterialDetails from "./pages/MaterialDetails";
import ReviewDetails from "./pages/ReviewDetails";
import ContactDetails from "./pages/ContactDetails";
import { createMprId, addMPR, addLead } from "./MprState";
import NotificationContainer from "../notifications/NotificationContainer";

function getStepContent(step, formData, setForm) {
  switch (step) {
    case 0:
      return <BasicDetails formData={formData} setForm={setForm} />;
    case 1:
      return <InspectionSlots formData={formData} setForm={setForm} />;
    case 2:
      return <MaterialDetails formData={formData} setForm={setForm} />;
    case 3:
      return <ContactDetails formData={formData} setForm={setForm} />;
    case 4:
      return <ReviewDetails formData={formData} setForm={setForm} />;
    default:
      return "Unknown step";
  }
}

export default function AddMpr(props) {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles();
  const steps = getSteps();
  var data = useSelector((state) => state.userdata);
  const custData = useSelector((state) => state);
  var customerData = custData.selectedCustomer
    ? custData.selectedCustomer._id
    : "";
  var branchData = custData.selectedBranch ? custData.selectedBranch._id : "";
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [mprID, setMprID] = useState("");

  const AddMPRData = [
    { label: t("common_material_pickup"), link: "/#/app/MPR" },
    { label: t("mpr_add_new"), link: "" },
  ];

  function getSteps() {
    return [
      t("common_basic_details"),
      t("common_inspection_slots"),
      t("mpr_material_details"),
      t("mpr_contact_details"),
      t("common_review_details"),
    ];
  }
  useEffect(() => {
    let sendData = {
      abbrevation: data?.getData?.abbrevation,
      customerid:
        localStorage.getItem("type") === "Customer"
          ? localStorage.getItem("customerid")
          : customerData,
      thisCustomerBranch:
        data.getData &&
        localStorage.getItem("type") === "Customer" &&
        !data.getData.roles.includes("Customer_Admin")
          ? data.getData.thisCustomerBranch
          : localStorage.getItem("type") === "tenant"
          ? branchData
          : "",
    };
    createMprId(sendData, dispatch).then((response) => setMprID(response));
    // eslint-disable-next-line
  }, []);

  const defaultData = {
    MPRId: "",
    MPRName: "",
    pickupDate: new Date(),
    description: "",
    quotation_date: new Date(),
    slots: [],
    files: [],
    goodsPic: [],
    contact_address: "",
    contact_contact: "",
    service_type: [],
    service_category: "E-Waste",
    other_service_type: "",
    inquirylead_id: "",
    customerId:
      localStorage.getItem("type") === "Customer"
        ? localStorage.getItem("customerid")
        : customerData,
    // tenantId:
    //   localStorage.getItem("type") === "Customer"
    //     ?data.getData && data.getData.thisBranchExclusive &&
    //       data.getData.thisBranchExclusive !== ""
    //       ? data.getData.thisBranchExclusive
    //       : ""
    //     : localStorage.getItem("customerid"),
        tenantId:""
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const [formData, setForm] = useState(defaultData);
  const [message, setMessage] = React.useState("");
  const [notify, setNotification] = React.useState(false);
  const [type, setType] = React.useState("");
  const [showLoader, setShowloader] = React.useState(false);


  const displayNotification = (message, type,toggle) => {
    setMessage(message);
    setType(type);
    setNotification(toggle);
    setTimeout(() => {
      setNotification(false);
    }, 6000);
  };
  
  const handleNext = () => {
  
    if (formData.service_category === "E-Waste") {
      const lastServiceTypeArrayLength = formData.service_type.length !== 0 && formData.service_type[formData.service_type.length - 1].length;
      if (!lastServiceTypeArrayLength || !formData.MPRName.trim() || !formData.description.trim()) {
        displayNotification('Please fill in Required fields', 'error',true);
      } else {
        displayNotification('', '',false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      if (!formData.MPRName.trim() || !formData.description.trim()) {
        displayNotification('Please fill in Required fields', 'error',true);
      } else {
        displayNotification('', '',false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };
  
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handlePublish = (status) => {
    setShowloader(true)
    addMPR(formData, status, dispatch).then((response) => {
      if (response.status === "200") {
        addLead(response.Data, dispatch).then((resp) => {
          if (resp.status === "200") {
            setType("success");
            setMessage(response.message);
            setNotification(true);
            setTimeout(() => props.history.push("/app/MPR"), 2000);
            // setShowloader(false);
          }else if(response.status === "401"){
            setShowloader(false);
            setMessage(response.message)
            setType("error")
            setNotification(true)
            setTimeout(() => {
              dispatch({ type: "LOG_OUT" });
              localStorage.clear();
            }, 2000);
          }else {
            let msg = [];
            if (response.ErrorData) {
              // eslint-disable-next-line
              response.ErrorData.details.map((item) => {
                msg.push(item.message);
              });
              setType("error");
              setMessage(`${msg.join()}`);
              setNotification(true);
              setShowloader(false);
            }
          }
        });
      }else if(response.status === "401"){
        setShowloader(false);
        setMessage(response.message)
        setType("error")
        setNotification(true)
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      } else {
        let msg = [];
        if (response.ErrorData) {
          // eslint-disable-next-line
          response.ErrorData.details.map((item) => {
            msg.push(item.message);
          });
          setType("error");
          setMessage(`${msg.join().replace("thisUser", "Contact")}`);
          setNotification(true);
          setShowloader(false);
        }
        if (response.status === "404") {
          setType("error");
          setMessage(response.message);
          setShowloader(false);
          setNotification(true);
        }
      }
    });
  };

  return (
    <>
      <BreadCrumbs data={AddMPRData} />
      <div className={classes.content}>
        {notify ? (
          <NotificationContainer message={message} notificationtype={type} />
        ) : (
          <></>
        )}
        {isSmallScreen ? (
          <>
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <span>{steps[activeStep]}</span>
            </div>
            <Stepper
              activeStep={activeStep}
              style={{ padding: "18px 0px 12px 0px" }}
            >
              {steps.map((label, index) => {
                const props = {};
                const labelProps = {};
                return (
                  <Step
                    style={{ width: 24, padding: 0 }}
                    key={label}
                    {...props}
                  >
                    <StepLabel {...labelProps}></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </>
        ) : (
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}

        <div>
          {getStepContent(activeStep, formData, setForm)}
          <Paper
            className={classes.paperButton}
            classes={{
              root: classnames(classes.widgetRoot, {
                [classes.noWidgetShadow]: null,
              }),
            }}
          >
            <Button href="/#/app/MPR" className={classes.button}>
              {t("common_cancel")}
            </Button>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
            >
              {t("common_back")}
            </Button>
            {activeStep === steps.length - 1 ? (
              <>
                {showLoader ? (
                  <CircularProgress  />
                ) : (
                  <CustomButton
                    variant="contained"
                    title={t("common_publish")}
                    onclick={() => handlePublish("Open")}
                  />
                )}
              </>
            ) : (
              <CustomButton
                variant="contained"
                title={t("common_next")}
                onclick={handleNext}
              />
            )}
          </Paper>
        </div>
      </div>
    </>
  );
}

import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import classnames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";

const ReviewDetails = ({ setForm, formData }) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid lg={2} md={1} xs={1} sm={1}></Grid>
      <Grid lg={8} md={10} xs={11} sm={11}>
        <Paper
          className={classes.paper}
          classes={{
            root: classnames(classes.widgetRoot, {
              [classes.noWidgetShadow]: null,
            }),
          }}
        >
          <div className={classes.form1}>
            <Grid container spacing={3}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ width: "width: calc(100% - 2*15px)" }}
              >
                <h3 className={classes.title}>{t("common_review_details")}</h3>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.reviewContainer}
              >
                <div>
                  <Table className={classes.table}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.reviewCell2}>
                          {" "}
                          {t("lot_title")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {formData.title}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell2}>
                          {" "}
                          {t("lot_basic_price")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {formData.basicPrice}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell2}>
                          {" "}
                          {t("lot_increment_amount")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {formData.incrementValue}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell2}>
                          {" "}
                          {t("lot_location")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {" "}
                          {formData.location}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell2}>
                          {" "}
                          {t("close_date")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {moment(formData.lastDate).format("DD-MM-YYYY") +
                            " " +
                            "11" +
                            ":" +
                            "59"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell2}>
                          {" "}
                          {t("lot_decription")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {formData.description}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell2}>
                          {" "}
                          {t("lot_detailed_description")}
                        </TableCell>
                        <TableCell className={classes.reviewCell}>
                          {formData.detaileDescription}
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                                                <TableCell className={classes.reviewCell2}> {t("status")}</TableCell>
                                                <TableCell className={classes.reviewCell}>{formData.status}</TableCell>
                                            </TableRow> */}
                      <TableRow>
                        <TableCell className={classes.reviewCell2} colSpan={2}>
                          {" "}
                          {t("Uploaded_images")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.reviewCell2} colSpan={2}>
                          {
                            <div className="result">
                              {formData.filesWithReference.length > 0 &&
                                formData.filesWithReference.map(
                                  (data, index) => {
                                    return (
                                      <div className="img-wrapp">
                                        <img
                                          src={data.ref}
                                          alt=""
                                          className="image-wrapperr"
                                        />
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid lg={2} md={1} xs={0} sm={0}></Grid>
    </Grid>
  );
};

export default ReviewDetails;

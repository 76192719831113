import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import { Box } from "@mui/material";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { getFile } from "../InquiryLeadsState";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import { CloudDownload } from "@mui/icons-material";
import NotificationContainer from "../../notifications/NotificationContainer";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const ViewInquiryDetails = (inquiryData) => {
  var classes = useStyles();
  const { t, i18n } = useTranslation();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box py={3}>{children}</Box>}
      </div>
    );
  }
  const selectHeader = () => {
    return [
      {
        label: t("Updated At"),
        options: {
          filter: true,
        },
      },
      {
        label: t("Updated By"),
        options: {
          filter: true,
        },
      },
      {
        label: t("Status From"),
        options: {
          filter: true,
        },
      },
      {
        label: t("Status To"),
        options: {
          filter: true,
        },
      },
      {
        label: t("Notes"),
        options: {
          filter: false,
        },
      },
      {
        label: t("Additional Info"),
        options: {
          filter: false,
        },
      },
    ];
  };
  const [tooltipStates, setTooltipStates] = useState([]);

  const toggleTooltipState = (index) => {
    const newTooltipStates = [...tooltipStates];
    newTooltipStates[index] = !newTooltipStates[index];
    setTooltipStates(newTooltipStates);
  };
  let statusHistory = inquiryData?.inquiryData?.statusHistory;

  const formatDateAndTime = (dateTime) => {
    return moment(dateTime).format("DD/MM/YYYY [at] HH:mm");
  };

  var finalInquiryData = [];

  // notifications
  const [type, setType] = useState("");
  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          setType("error");
          setMessage(response.message);
          setNotify(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setType("error");
        setMessage("An error occurred while downloading the file.");
        setNotify(true);
      });
  };

  function generateTooltipContent(status, item, index) {
    let content = "N/A";
    if (status === item.statusForm) {
      content = "N/A";
    } else if (status === "Addressed") {
      content = item.modeOfAddressing?.notes;
    } else if (status === "Awaiting Response") {
      content = item.awaitingResponse?.notes;
    } else if (status === "Followed Up") {
      content = item.modeOfFollowup?.notes;
    } else if (status === "Followup Requested") {
      content = item.followupRequested?.notes;
    } else if (status === "Field Visit Planned") {
      content = item.fieldVisitPlanned?.notes;
    } else if (status === "Field Visit Completed") {
      content = item.fieldVisitCompleted.feedback;
    } else if (status === "Rejected") {
      content = item.rejected?.notes;
    }

    return (
      <Button
        style={{ marginLeft: "-22px" }}
        onClick={() => toggleTooltipState(index)}
      >
        <CustomWidthTooltip
          title={<Typography variant="body1">{content}</Typography>}
          open={tooltipStates[index]}
          onClose={() => toggleTooltipState(index)}
        >
          <DescriptionIcon
            style={{cursor: "pointer" }}
            sx={{ m: 1 }}
            color="success"
          ></DescriptionIcon>
        </CustomWidthTooltip>
      </Button>
    );
  }
  function renderAdditionalInfo(status, item) {
    if (status === item.statusForm) {
      return <div>Owner Reassigned</div>;
    } else if (status === "Addressed") {
      return (
        <div>
          <div>
            Mode of contact: {item.modeOfAddressing.addressing.join(",")}
          </div>
        </div>
      );
    } else if (status === "Awaiting Response") {
      return (
        <div>
          <div>
            Last contact :{" "}
            {formatDateAndTime(item.awaitingResponse.dateAndTimeOfLastContact)}
          </div>
          <div>
            Mode of contact: {item.awaitingResponse.modeOfContact.join(",")}
          </div>
        </div>
      );
    } else if (status === "Followed Up") {
      return (
        <div>
          <div>Mode of contact: {item.modeOfFollowup.followup.join(",")}</div>
        </div>
      );
    } else if (status === "Followup Requested") {
      return (
        <div>
          <div>
            Requested Date:{" "}
            {formatDateAndTime(item.followupRequested.dateAndTime)}
          </div>
        </div>
      );
    } else if (status === "Field Visit Planned") {
      return (
        <div>
          <div>
            Scheduled Date:{" "}
            {formatDateAndTime(item.fieldVisitPlanned.dateAndTime)}
          </div>
        </div>
      );
    } else if (status === "Field Visit Completed") {
      return (
        <div>
          <div>
            Completed Date:{" "}
            {formatDateAndTime(item.fieldVisitCompleted.dateAndTime)}
          </div>
          <Grid container style={{ marginLeft: "-12px" }}>
            {item.fieldVisitCompleted.photos?.length > 0 ? (
              item.fieldVisitCompleted.photos.map((photo, index) => (
                <Grid item key={index}>
                  <IconButton
                    className={classes.deleteicon}
                    onClick={() => handleDownload(photo)}
                    size="large"
                  >
                    <CloudDownload />
                  </IconButton>
                </Grid>
              ))
            ) : (
              <Grid item>
                <div style={{ marginLeft: "12px" }}>No Images uploaded</div>
              </Grid>
            )}
          </Grid>
        </div>
      );
    } else if (status === "Quotation Sent") {
      return (
        <div>
          <div>
            Attached MPR ID: {inquiryData?.inquiryData?.materialRequestID}
          </div>
        </div>
      );
    } else {
      return "N/A";
    }
  }

  return (
    <div>
      {notify ? <NotificationContainer message={message} type={type} /> : <></>}
      <TabPanel value={0} index={0} key={1}>
        {statusHistory?.map((item, index) => {
          finalInquiryData?.push([
            item.updatedAt ? formatDateAndTime(item.updatedAt) : "N/A",
            item.updatedBy ? item.updatedBy[0]?.userName : "N/A",
            item.statusForm ? item.statusForm : "N/A",
            item.statusTo ? item.statusTo : "N/A",
            generateTooltipContent(item.statusTo, item, index),
            renderAdditionalInfo(item.statusTo, item),
          ]);
        })}
        <Table
          data={finalInquiryData}
          header={selectHeader()}
          name={t("Inquiry History")}
          options={{
            filterType: "checkbox",
            print: false,
            download: false,
            filter: true,
            filterType: "dropdown",
            selectableRows: "none",
            sortOrder: {
              name: t("Updated At"),
              direction: "desc",
            },
          }}
        />
      </TabPanel>
    </div>
  );
};

export default ViewInquiryDetails;

import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useLocation, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PaymentIcon from "@mui/icons-material/Payment";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HomeIcon from "@mui/icons-material/Home";
import PaymentsIcon from "@mui/icons-material/Payments";
import TabPannelPaymnet from "../paymentRequests/supplierCustomer/TabPanelPayment";
import TabPannelRemibursement from "../reimbursements/TabPanelReimbursement";
import TabPanelLeaveRequest from "../leaveRequests/TabPanelLeave";
import TabPanelMiscellaneousReq from "../miscellaneousRequests/TabPanelMiscellaneousReq";
import { AdminDash } from "../dashboard/AdminDash";
import { EmployeeDash } from "../dashboard/EmployeeDash";
import TabPannelVendor from "../paymentRequests/vendor/VendorTabPanel";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { Tooltip } from "@mui/material";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  marginTop: "65px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  marginTop: "65px",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function ResponsiveDrawer(props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const data = useSelector((state) => state.userdata.getData);
  const [open, setOpen] = useState(true);

  const [selectedComponent, setSelectedComponent] = useState("");

  const [initialComp, setInitialComp] = useState("");

  const backTabData = useSelector((state) => state.selectedBackTab);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      data &&
      data.roles.some((item) => "user" === item) &&
      !data.roles.some((item) => "Admin" === item) &&
      !data.roles.some((item) => "Approver" === item)
    ) {
      setSelectedComponent("employeeComp");
      setInitialComp("employeeComp");
    } else if (
      data?.roles.some((item) => "Approver" === item) ||
      data?.roles.some((item) => "Admin" === item)
    ) {
      setSelectedComponent("adminComp");
      setInitialComp("adminComp");
    }
  }, [data]);

  useEffect(() => {
    if (isSmallScreen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (backTabData != undefined && backTabData != "") {
      setSelectedComponent(backTabData);
    }
  }, [backTabData]);

  const navigateToDashboard = () => {
    window.location.replace("/#/app/dashboard");
  };

  const makeDashDefault = () => {
    dispatch({
      type: "SET_BACK_TAB",
      payload: "",
    });
  };

  //for notifications routing

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");

  useEffect(() => {
    if (activeTab === "reimbursement") {
      setSelectedComponent("reimbursementComp");
    } else if (activeTab === "leaveRequest") {
      setSelectedComponent("leaveComp");
    } else if (activeTab === "supplierPayment") {
      setSelectedComponent("supplierPaymentComp");
    } else if (activeTab === "vendorPayment") {
      setSelectedComponent("vendorPaymentComp");
    } else if (activeTab === "miscellaneousRequest") {
      setSelectedComponent("miscellaneousReqComp");
    }
  }, [activeTab]);

  ////////

  const showthisComponent = (targetComponent) => {
    if (targetComponent === "backHome") {
      navigateToDashboard();
      makeDashDefault();
      return;
    }
    setSelectedComponent(targetComponent);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex", background: "white" }}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={toggleDrawer}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            {
              text: "Home",
              targetComponent: "backHome",
              icon: <HomeIcon />,
            },
            {
              text: "Dashboard",
              targetComponent: initialComp,
              icon: <DashboardIcon />,
            },
            {
              text: "Supplier Payments",
              targetComponent: "supplierPaymentComp",
              icon: <PaymentIcon />,
            },
            {
              text: "Vendor Payments",
              targetComponent: "vendorPaymentComp",
              icon: <PaymentsIcon />,
            },
            {
              text: "Reimbursements",
              targetComponent: "reimbursementComp",
              icon: <CurrencyExchangeIcon />,
            },
            {
              text: "Miscellaneous",
              targetComponent: "miscellaneousReqComp",
              icon: <MiscellaneousServicesIcon />,
            },
            {
              text: "Leave Requests",
              targetComponent: "leaveComp",
              icon: <WorkOffIcon />,
            },
          ].map((item, index) => (
            <ListItem key={index} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  borderRadius: 8,
                  "&.MuiButtonBase-root:hover": {
                    bgcolor:
                      item.targetComponent === selectedComponent
                        ? "#0D6937"
                        : "#fffff",
                  },
                  backgroundColor:
                    item.targetComponent === selectedComponent
                      ? "#0D6937"
                      : "inherit",
                  color:
                    item.targetComponent === selectedComponent
                      ? "white"
                      : "inherit",
                  m: 1,
                }}
                onClick={() => showthisComponent(item.targetComponent)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    m: open ? 1 : "auto",
                    justifyContent: "center",
                    color:
                      item.targetComponent === selectedComponent
                        ? "white"
                        : "inherit",
                  }}
                >
                  <Tooltip title={item.text}>{item.icon}</Tooltip>
                </ListItemIcon>
                {open ? (
                  <ListItemText
                    primary={item.text}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                ) : (
                  <></>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box sx={{ flexGrow: 1, py: 6, pl: 1 }}>
        {selectedComponent === "adminComp" && <AdminDash />}
        {selectedComponent === "employeeComp" && <EmployeeDash />}
        {selectedComponent === "supplierPaymentComp" && <TabPannelPaymnet />}
        {selectedComponent === "vendorPaymentComp" && <TabPannelVendor />}
        {selectedComponent === "reimbursementComp" && (
          <TabPannelRemibursement />
        )}
        {selectedComponent === "miscellaneousReqComp" && (
          <TabPanelMiscellaneousReq />
        )}
        {selectedComponent === "leaveComp" && <TabPanelLeaveRequest />}
      </Box>
    </Box>
  );
}

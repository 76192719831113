import React,{useEffect,useState} from "react";
import { 
    Grid,
    IconButton,
} from '@mui/material'
import { useTranslation } from 'react-i18next';
import {useSelector,useDispatch} from 'react-redux'
import AttachmentIcon from '@mui/icons-material/Attachment';
import moment from 'moment'
import { useHistory } from "react-router-dom";

//components
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import useStyles from "./components/styles";
import Users from '../../components/ViewData/Users'

import {
    getLead,getFile
} from './LeadState'
//component Views
import AssignLead from './components/ViewLead/AssignLead'
import RejectedLead from './components/ViewLead/RejectLead'
import MPRDetails from './components/ViewLead/MPRDetails'
import MPRSlot from './components/ViewLead/MPRSlot'
import MaterialDetails from './components/ViewLead/MaterialDetails'
import Quotations from './components/ViewLead/quotations/Quotations'
import Payment from './components/ViewLead/payments/Payment'
import Invoice from './components/ViewLead/invoice/Invoice'
import Schedule from "./components/ViewLead/MaterialPickup/Schedule";
import Form6 from './components/ViewLead/form6/Form6'
import CloseLead from "./components/ViewLead/CloseLead";


const ViewLead = () => {
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory()
    const state = useSelector(state => state.selectedLead) 
    const contactList = useSelector(state => state.tenantContactList)
    const [showAssigned, setShowAssigned] = useState(false)
    const [showRejected,setShowRejected] = useState(false)
    const [showQuotation,setShowQuotation] = useState(false)
    const [showInspection,setShowInspection] = useState(false)
    const [showPayment,setShowPayment] = useState(false)
    const [showInvoice,setShowInvoice] = useState(false)
    const [showSchedule,setShowSchedule] = useState(false)
    const [requestUpdate,setRequestUpdate] = useState(false)
    const [list,setList] = React.useState([])
    const [quotationDetails,setQuotationDetails]=useState()
    const [slotData,setSlotData] = useState([])
    const [mprData,setMprData] = useState([])
    const [martialList,setMaterialList] = useState([])
    const [goodsList,setGoodsList] = useState([])
    const [choosenSlot,setChoosenSlot] = useState({})
    const [quotation,setQuotation] = useState()
    const [paymentData,setPaymentData] = useState({})
    const [invoiceData,setInvoiceData] = useState({})
    const [pickupData,setPickupData] = useState({})
    const [formsixData,setFormsixData] = useState({})
    const [formsixFile,setFormsixFile] = useState({})
    const [showFormsix,setShowFormSix] = useState(false)
    const [showCloseLead,setShowCloseLead] = useState(false)
    const [closeAllTabs, setCloseAllTabs] = useState(false)

    const toggleTab = (tabName) => {
        const tabState = {
          showAssigned: false,
          showRejected: false,
          showQuotation: false,
          showInspection: false,
          showPayment: false,
          showInvoice: false,
          showSchedule: false,
          requestUpdate: false,
          showFormSix: false,
          showCloseLead: false,
        };
      
        tabState[tabName] = true;
        setTabStates(tabState);
      };
      
      const setTabStates = (newTabStates) => {
        setShowAssigned(newTabStates.showAssigned);
        setShowRejected(newTabStates.showRejected);
        setShowQuotation(newTabStates.showQuotation);
        setShowInspection(newTabStates.showInspection);
        setShowPayment(newTabStates.showPayment);
        setShowInvoice(newTabStates.showInvoice);
        setShowSchedule(newTabStates.showSchedule);
        setRequestUpdate(newTabStates.requestUpdate);
        setShowFormSix(newTabStates.showFormSix);
        setShowCloseLead(newTabStates.showCloseLead);
      };
      
      const handleShowAssigned = () => {
        toggleTab('showAssigned');
        setCloseAllTabs(false)
      };
      
      const handleShowRejected = () => {
        toggleTab('showRejected');
        setCloseAllTabs(false)
      };
      
      const handleShowQuotation = () => {
        toggleTab('showQuotation');
        setCloseAllTabs(false)
      };
      
    const handleShowInspection = () => {
        toggleTab('showInspection');
        setCloseAllTabs(false)
    }
    const handleShowPayment = () => {
        toggleTab('showPayment');
        setCloseAllTabs(false)
    }
    const handleShowInvoice = () => {
        toggleTab('showInvoice');
        setCloseAllTabs(false)
    }
    const handleshowSchedule = () => {
        toggleTab('showSchedule');
        setCloseAllTabs(false)
    }
    const handleRequestChange = () => {
        toggleTab('requestUpdate');
        setCloseAllTabs(false)
    }
    const handleFormSix = () => {
        toggleTab('showFormSix');
        setCloseAllTabs(false)  
    }
    const handleCloseLead = () => {
        toggleTab('showCloseLead');
        setCloseAllTabs(false)
    }
    const handleCloseAll=()=>{
        toggleTab('');
        setCloseAllTabs(true)
    }
    

    const getFiles = (file) => {
        let bodyData = {
            fileName: file.fileName || file.filename,
            folderName: file.folderName || file.folder || file.FolderName,
            expireLimt : 1000
        }
        getFile(bodyData).then(response => {
            window.open(response.reference,"_blank");
        })
    }
    
    useEffect(() => {
        let data = []
        let mprTable = []
        let goods = []
        let material = []
        let external_id = (state.length > 0)? state[0].external_id : "";
        if(state.length==0){
            history.push("/app/Leads")
            return
        }
       if(state){
           getLead(external_id,dispatch).then(response => {
            if(response && response.thisLeadDetails) setList(response.thisLeadDetails)
            if(response &&  response.quotation) setQuotation(response.quotation)
            if(response && response.thisFormContent) setFormsixData(response.thisFormContent)
            if(response && response.thisQuotationContent) setQuotationDetails(response.thisQuotationContent)
            if(response && response.form6) setFormsixFile(response.form6)
               let mpr = response?.thisLeadMPR
               
               mprTable.push([
                {label : t("mpr_name"), value : mpr?.name},
                {label : t("common_description"), value : mpr?.description},
                {label : t("mpr_last_date"), value :  moment(mpr?.MPRLastDate).format('DD-MM-YYYY')},
                {label : t("mpr_quotation_last_date") , value : moment(mpr?.QuatationLastDate).format('DD-MM-YYYY')},
                {label : t("common_service_type") , value : ((mpr?.serviceType)?.length >1) ? mpr?.serviceType.join(',').replace('Others,',''):mpr?.serviceType.join(',') },
                {label : t("common_service_category") , value : mpr?.serviceCategory},
                {label : t("common_customer_name"), value: (state)? state[0]?.customerName:""},
                {label : t("lead_assigned_to") , value : (response && response?.userDetails) ? response?.userDetails?.UserName:""}
               ])
               mpr?.item_list_file.map(file => {
                   material.push([
                       file.fileName || file.filename,
                       <IconButton
                           onClick={() => getFiles(file)}
                           target="_blank"
                           className={classes.collapseIcon}
                           size="large">
                           <AttachmentIcon />
                        </IconButton>
                   ])
               })
               mpr?.goodsPic.map(good => {
                   goods.push([
                       good.fileName || good.filename,
                       <IconButton
                           onClick={() => getFiles(good)}
                           className={classes.collapseIcon}
                           size="large">
                           <AttachmentIcon />
                        </IconButton>
                   ])
               })
                
               if(response && response?.FinalSlots) setSlotData(response.FinalSlots)
               if(response && response?.payment) setPaymentData(response.payment)
               if(response && response?.invoice) setInvoiceData(response.invoice)
               if(response && response?.pickup) setPickupData(response.pickup)
               setChoosenSlot(response?.slotlost)
               setMprData(mprTable)
               setMaterialList(material)
               setGoodsList(goods)
           })
       }
    }, [showAssigned,showRejected,
        requestUpdate,showQuotation,
        showSchedule,showFormsix,
        showInvoice,showCloseLead])
    const breadcrumb_viewTenant = [
        {label: t("common_leads") , link: "/#/app/Leads"},
        {label: (list)&&list.external_id , link: ""},
    ]   

    return(
        <>
        <BreadCrumbs data={breadcrumb_viewTenant}/>
            <div className={classes.content}>
                <Grid container spacing={3}>
                        <Grid item lg={3} md={12} xs={12} sm={12} style={{paddingTop:'0px'}}>
                            <Users 
                                list={list} 
                                show={handleShowAssigned} 
                                reject={handleShowRejected} 
                                quotation={handleShowQuotation} 
                                inspection={handleShowInspection} 
                                payment = {handleShowPayment} 
                                invoice={handleShowInvoice}
                                schedule = {handleshowSchedule} 
                                formsix = {handleFormSix}
                                form6Data = {formsixData}
                                closeLead = {handleCloseLead}
                                closeAll={closeAllTabs}
                                />
                        </Grid>
                        <Grid item lg={9} md={12} xs={12} sm={12} >
                           {
                               (showAssigned)?
                                    <AssignLead 
                                        list={list} 
                                        contactList = {contactList} 
                                        hide={handleCloseAll} 
                                        lead={(list)&&list.external_id}
                                    /> :
                               (showRejected)?
                                    <RejectedLead 
                                        list={list} 
                                        hide={handleCloseAll} 
                                        lead={(list)&&list.external_id} 
                                    /> :
                               (showQuotation)? 
                                    <Quotations 
                                        hide={handleCloseAll} 
                                        list={list} 
                                        quotation = {quotation}
                                        quotationDetails={quotationDetails}
                                    /> :
                               (showInspection)? 
                                    <MPRSlot 
                                        list={list} 
                                        slotData={slotData} 
                                        lead={(list)&&list.external_id}  
                                        choosen = {choosenSlot} 
                                        close={handleRequestChange} 
                                        inspectionClose={handleCloseAll}
                                    />:
                               (showPayment) ? 
                                    <Payment 
                                        list={list} 
                                        payment={paymentData} 
                                        close={handleCloseAll}
                                    />  : 
                               (showInvoice) ? 
                                    <Invoice 
                                        list={list} 
                                        invoice={invoiceData} 
                                        close={handleCloseAll}
                                    /> :
                               (showSchedule) ? 
                                    <Schedule 
                                        pickup={pickupData} 
                                        list={list} 
                                        close={handleCloseAll}
                                    />:
                               (showFormsix) ? 
                                    <Form6 
                                        form6Datas={formsixData} 
                                        close={handleCloseAll} 
                                        form6File = {formsixFile} 
                                        list={list}
                                    /> :
                                (showCloseLead) ? 
                                    <CloseLead 
                                        list={list}
                                        close = {handleCloseAll}
                                    />
                                :
                                <>
                                    <MPRDetails mprData={mprData} />
                                    <MaterialDetails list={list} goodsList={goodsList} martialList = {martialList} />
                                </>
                           } 
                        </Grid>
                    </Grid>     
            </div>
        </>
    )
};

export default ViewLead;
import React, { useState } from "react";

import {
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Paper,
  Grid,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useStyles from "../passwordset/styles";
import "react-toastify/dist/ReactToastify.css";
//API
import { passwordSet } from "../LoginState";
import Notification from "../../../components/Notification/Notification";

function PasswordSet(props) {
  var classes = useStyles();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  // eslint-disable-next-line
  var [loginValue, setLoginValue] = useState(props.email);
  var [passwordValue, setPasswordValue] = useState("");
  var [confirmValue, setConfirmValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  var [showButton, setShowButton] = useState(false);
  const [showError, setshowError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [confirmError, setConfirmError] = useState(false);
  const history = useHistory();
  //call api

  const confirmPassword = (val) => {
    const isActive = hasSpecialCharacter(val);
    setConfirmValue(val);
    if (val.length > 8 && isActive) {
      if (val === passwordValue) {
        setConfirmError(false);
        setShowButton(true);
      } else {
        setShowButton(false);
        setConfirmError(true);
      }
    } else {
      setShowButton(false);
      setConfirmError(true);
    }
  };

  //######################################################
  function sendNotification(componentProps, options) {
    return toast(
      <Notification
        {...componentProps}
        className={classes.notificationComponent}
      />,
      options
    );
  }

  function handleNotificationCall(notificationType, message) {
    var componentProps = {
      type: "shipped",
      message: message,
      variant: "contained",
      color: "success",
    };

    sendNotification(componentProps, {
      type: notificationType,
      position: toast.POSITION.TOP_RIGHT,
      progressClassName: classes.progress,
      className: classes.notification,
    });
  }
  //######################################################

  const handleSubmit = () => {
    setshowError(false);
    passwordSet(props.email, passwordValue).then((response) => {
      if (response.status === "200") {
        setIsLoading(false);
        setLoginValue("");
        setPasswordValue("");
        setConfirmValue("");
        handleNotificationCall("info", response.message);
        setTimeout(() => history.push("/login"), 2000);
      } else {
        setIsLoading(false);
        handleNotificationCall("error", response.message);
      }
    });
  };

  function hasSpecialCharacter(password) {
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    return specialCharacterRegex.test(password);
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const HandlePasswordValue = (value) => {
    const isActive = hasSpecialCharacter(value);
    setPasswordValue(value);
    setConfirmValue("");
    setshowError(false);
    if ((value.length > 0 && value.length < 8) || !isActive) {
      setshowError(true);
      setDisabled(true);
      setShowButton(false);
    } else if (value.length > 8 && isActive) {
      setTimeout(() => {
        setshowError(false);
        setDisabled(false);
        if (confirmValue === "") {
          setShowButton(false);
        }
      }, 500);
    } else {
      setshowError(false);
      setShowButton(false);
    }
  };

  return (
    <>
      <ToastContainer
        className={classes.toastsContainer}
        closeButton={
          <CloseButton className={classes.notificationCloseButton} />
        }
        closeOnClick={false}
        progressClassName={classes.notificationProgress}
        bodyClassName={classes.tostifyBody}
      />
      <Paper className={classes.paperContainer}>
        <Tabs
          value={activeTabId}
          onChange={(e, id) => setActiveTabId(id)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Set Password" classes={{ root: classes.tab }} />
        </Tabs>
        <React.Fragment>
          <Fade in={error} style={{ padding: "1rem" }}>
            <Typography color="secondary" className={classes.errorMessage}>
              {error}
            </Typography>
          </Fade>
          <TextField
            variant="standard"
            id="password"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ marginRight: "0.5rem" }}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    disabled={passwordValue.length === 0}
                  >
                    {showPassword ? (
                      <Visibility className={classes.iconButton} />
                    ) : (
                      <VisibilityOff className={classes.iconButton} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={passwordValue}
            onChange={(e) => HandlePasswordValue(e.target.value)}
            margin="normal"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            fullWidth
          />
          {showError && (
            <Typography color="secondary">
              {passwordValue.length < 8
                ? "Password should be 8 characters in length."
                : "Password should contain at least one special character"}
            </Typography>
          )}
          <TextField
            disabled={disabled}
            variant="standard"
            id="confirm password"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ marginRight: "0.5rem" }}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleConfirmPasswordVisibility}
                    edge="end"
                    disabled={confirmValue.length === 0}
                  >
                    {showConfirmPassword ? (
                      <Visibility className={classes.iconButton} />
                    ) : (
                      <VisibilityOff className={classes.iconButton} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={confirmValue}
            onChange={(e) => confirmPassword(e.target.value)}
            margin="normal"
            placeholder="Confirm password"
            type={showConfirmPassword ? "text" : "password"}
            fullWidth
          />
          {confirmError && (
            <Typography color="secondary">
              Password and Confirm Password do not match.
            </Typography>
          )}
          <div className={classes.formButtons}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <Button
                disabled={showButton === false}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                size="large"
              >
                Submit
              </Button>
            )}
          </div>
        </React.Fragment>
      </Paper>
    </>
  );
}
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

export default PasswordSet;

import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    Collapse, 
    IconButton,
    Button,
 
} from '@mui/material'
import {
    ExpandMore as DropDownIcon,
    ExpandLess as DropUpIcon
}  from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import moment from 'moment'

import Table from "../../../../../components/Table/Table"
import useStyles from "../styles";

import {getFile} from '../../../../leads/LeadState'

import QuotationPDF from "../../../../leads/components/ViewLead/quotations/QuotationPDF";
import Invoice from "./Invoice";

function currencyFormat(num) {
    return parseInt(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const InvoiceList = ({close,invoice,wonLead,mprID,transactionAmount}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    
    var classes = useStyles();
    

    const[collapse,setCollapse] = useState(true)
    const [invoiceUrl,setInvoiceUrl] = useState("")
    const [showPDF,setShowPDF] = useState(false)
    const [showForm,setShowForm] = useState(false)
    const [invoiceData,setInvoiceData] = useState([])

  
    const handlePDF = () => {
        let body  = {
            "fileName" : invoice.fileName,
            "folderName" : invoice.folderName,
            "expireLimt" : 1800
        }
        getFile(body).then(response => {
            setInvoiceUrl(response.reference)
            setShowPDF(true)
        })
    }
    const handleShowForm = () =>{
        setShowForm(!showForm)
    }
    
    useEffect(()=> {
        let data = []
        if(Object.keys(invoice).length !== 0){ 
            data.push([
                invoice.invoiceNumber,
                currencyFormat(invoice.amount),
                moment(invoice.generatedOn).format('DD-MM-YYYY'),
                <IconButton className={classes.collapseIcon} onClick={handlePDF} size="large">
                    <PictureAsPdfIcon />
                </IconButton>
            ]) 
        }
        setInvoiceData(data)
        // eslint-disable-next-line
    },[showPDF])

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    const allHeader = [t("mpr_invoice")+" "+t("common_number"),t("lead_generated_on"),t("mpr_invoice_amount"),t("mpr_invoice")]
    

    return (
        <Paper className={classes.Datapaper}>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={7}>
                    <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("mpr_invoice")}</span>
                </Grid>
                <Grid item xs={3}>
                    {
                        (Object.keys(invoice).length === 0)? <Button variant = "outlined" color="primary" onClick={handleShowForm} size="small" className={classes.buttonOutlined}>{(showForm)?t("common_close"):t("common_add")+" "+t("mpr_invoice")}</Button>:<></>
                    }
                    {
                        (!showForm)?<Button variant="outlined" color="primary" size="small" onClick={()=>{close()}} className={classes.buttonOutlined}>{t("common_close")}</Button>:<></>
                    }
                    
                </Grid>
                <Grid item xs={2} className={classes.iconGrid}>
                    <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
                        {(collapse)?<DropDownIcon /> :<DropUpIcon />}
                    </IconButton>
                </Grid>
            </Grid> 
            <Collapse in={collapse}>
            {
                         (showPDF)?<QuotationPDF url={invoiceUrl}/> :
                         (!showForm)?
                         <Grid container spacing={3} className={classes.grid}>
                         <Grid item xs={12} className={classes.grid}>
                            <Table 
                                data={invoiceData} 
                                header={allHeader} 
                                name="" 
                                options={{
                                    filterType: 'checkbox',
                                    print: false,
                                    download: false,
                                    filter:false,
                                    selectableRows:'none'
                                }} />           
                         </Grid>
                     </Grid> : <Grid container spacing={3} className={classes.grid}>
                         <Grid item lg={2}></Grid>
                         <Grid item lg={8}>
                             <Invoice transactionAmount = {transactionAmount} wonLead={wonLead} mprID={mprID} close={handleShowForm}/>
                         </Grid>
                         <Grid item lg={2}></Grid>
                     </Grid>
            }
            </Collapse>         
        </Paper>
    );
}
export default InvoiceList
import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  typo: {
    color: theme.palette.text.hint,
  },
  tablePadding: {
    [theme.breakpoints.down("sm")]: {
      padding: "24px",
    },
  },
  content: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(10),
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(10),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(10),
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonDiv: {
    float: "right",
    marginTop: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    padding: theme.spacing(4),
    marginTop: "2rem",
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  },
  title: {
    font: "400 2.125rem / 1.17647059 Roboto,RobotoDraft,sans-serif",
    fontWeight: "300",
    margin: "16px 0 32px 0",
    textAlign: "left",
  },
  label: {
    textAlign: "left",
    marginTop: "2rem",
    marginBottom: "1rem",
  },
  stepper: {
    marginTop: theme.spacing(6),
  },
  paperButton: {
    marginTop: "2rem",
    textAlign: "right",
    padding: "1rem",
  },
  finish: {
    color: "#000",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  HeaderContainer: {
    padding: theme.spacing(1.5),
    margin: theme.spacing(1.5),
    justifyContent: "space-between",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  tenantHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      marginBottom: "14px",
    },
  },
  tenantSubHeader: {
    paddingTop: theme.spacing(0.3),
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(2),
    },
  },
  buttonOutlined: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  deleteButton: {
    color: theme.palette.primary.main,
  },
  themeIcon: {
    color: theme.palette.primary.main,
  },
  margin: {
    margin: "10px 0",
  },

  switch_track: {
    backgroundColor: "#0D6937",
  },
  switch_base: {
    color: "lightblue",
    "&.Mui-disabled": {
      color: "lightblue",
    },
    "&.Mui-checked": {
      color: "#0D6937",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#0D6937",
    },
  },
  // switch_primary: {
  //   "&.Mui-checked": {
  //     color: "#0D6937",
  //   },
  //   "&.Mui-checked + .MuiSwitch-track": {
  //     backgroundColor: "#0D6937",
  //   },
  // },
}));

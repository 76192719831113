
import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";


const CustomRoute = (props) => {
  const [returnedRoute, setReturnedRoute] = useState("");
  var type = props.type
  useEffect(() => {
    switch (props.condition) {
      case "tenant":
        return setReturnedRoute(
          (type === "tenant" && props.verified === true) ? (
            <Route {...props} />
          ) : (type === "tenant" && props.verified === false && props.role && props.role.includes("Admin")) ? (
            <Redirect to="/app/setting" />
          ) : (type === "tenant" && props.valid === false && props.role && props.role.includes("Admin")) ? (
            <Redirect to="/app/setting" />
          ) :
            (
              //<Redirect to="/app/dashboard" />
              <Route {...props} />
            )
        );

      case "Customer":
        return setReturnedRoute(
          type === "Customer" ? (
            <Route {...props} />
          ) : (
            <Redirect to="/app/leads" />
          )
        );

      default:
        return setReturnedRoute(<Route {...props} />);
    }
    // eslint-disable-next-line
  }, [props]);

  return <>{returnedRoute}</>;
};


export default CustomRoute;
import React, { useState, useEffect } from 'react'
import {
  Grid,
  Paper, FormControl,
  Input, TextField,
  InputLabel, Button, CircularProgress, Typography
} from '@mui/material'
import AsyncSelect from 'react-select/async';
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import useStyles from "../styles";
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import NotificationContainer from '../../notifications/NotificationContainer';
import { getFile, searchTag, updateLot, getTagById, fileUpload } from '../MarketplaceState';
import CancelIcon from '@mui/icons-material/Cancel';
import FileBase64 from 'react-file-base64';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      // prefix = "₹"
    />
  );
}

const EditLot = (props) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const lotData = useSelector((state) => state)
  const history = useHistory()
  const [message, setMessage] = useState("")
  const [notify, setNotify] = useState(false)
  const [type, setType] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const [showImageLoader, setShowImageLoader] = useState(false)
  const [inputValue, setValue] = useState();
  const [selectedValue, setSelectedValue] = useState([]);
  const [title, setTitle] = useState((Object.keys(lotData.selectedLot).length > 0) ? lotData.selectedLot.title : "")
  const [basePrice, setBasePrice] = useState((Object.keys(lotData.selectedLot).length > 0) ? lotData.selectedLot.basePrice : "")
  const [location, setLocation] = useState((Object.keys(lotData.selectedLot).length > 0) ? lotData.selectedLot.location : "")
  const [email, setEmail] = useState((Object.keys(lotData.selectedLot).length > 0) ? lotData.selectedLot.email : "")
  const [increamentAmount, setIncreamentAmount] = useState((Object.keys(lotData.selectedLot).length > 0) ? lotData.selectedLot.increamentAmount : "")
  const [images, setImages] = useState((Object.keys(lotData.selectedLot).length > 0) ? lotData.selectedLot.images : [])
  const [description, setDescription] = useState((Object.keys(lotData.selectedLot).length > 0) ? lotData.selectedLot.description : "")
  const [detailedDescription, setDetailedDescription] = useState((Object.keys(lotData.selectedLot).length > 0) ? lotData.selectedLot.detailedDescription : "")
  const [lastDate, setLastDate] = useState((Object.keys(lotData.selectedLot).length > 0) ? lotData.selectedLot.lastDate : "")
  const [uploadedFile, setUploadedFile] = useState([]);
  const [imageAlert, setImageAlert] = useState(false)
  const userDetails = useSelector((state) => state)
  var newArray = [...images]
  var tagOptionSelected = []
  var newTagArray = []
  const [titleError, setTitleError] = useState("");
  const [dateError, setDateError] = useState("");
  const [basicPriceError, setBasicPriceError] = useState("");
  const [incrementValueError, setIncrementValueError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [detailedDescriptionError, setdetailedDescriptionError] = useState("");
  const [isError, setIsError] = useState(false)


  const breadcrumb = [
    { label: t("common_lot"), link: "/#/app/marketplace" },
    { label: t("edit_lot"), link: "" },
  ]
  const classes = useStyles();


  const handleImageChange = async (e) => {
    await e.map(async (item, key) => {
      if (item.type === "image/png" || item.type === "image/jpeg" || item.type === "image/jpg") {
        setShowImageLoader(true)
        setImageAlert(false)
        var fileName = item.name
        var fileType = fileName.split('.').pop()
        var baseData = item.base64.split(',')
        let getTokenData = {
          "abbrevation": Math.floor(Math.random() * 100) + "Mrkplc",
          "fileType": fileType,
          "folderName": "mrkplc/",
          "expireLimt": 1800,
          "extension": fileName.split('.').pop()
                }
        getAwsResponse(baseData, getTokenData, e)
            }
            else {
        setImageAlert(true)
      }
    })

  };

  const getAwsResponse = async (baseData, getTokenData, e) => {
    await fileUpload((baseData.length > 0) ? baseData[1] : "", getTokenData, e.type).then(async (response) => {
      if (response.status === "200") {
        const objwithRef = {
          fileName: response.fileName || response.filename,
          ref: response.reference,
          imageId: response.fileName || response.filename,
          folderName: response.folderName || response.folder || response.FolderName,
        };
        images.push(objwithRef);
        newArray = images;
        setImages([...newArray]);
        if (newArray.length === images.length) {
        }
        setShowImageLoader(false);
      } else {
        setShowImageLoader(false);
      }
    });
  };

  const getAllTag = (e, callback) => {
    if (e.length > 0) {
      searchTag(e).then((response) => {
        const tempArray = [];
        if (response.data.tags) {
          response.data.tags.forEach((element) => {
            tempArray.push({
              label: `${element.item}`,
              value: element._id,
            });
          });
        } else {
          console.error("error");
        }
        callback(tempArray);
      });
    }
  };

  const handleInputChange = (value) => {
    setValue(value);
  };


  const handleChange = (value) => {
    setSelectedValue(value.value);
    value.map((item) => {
      const newwObj = { label: item.label, value: item.value };
      newTagArray.push(newwObj);
      setSelectedValue(newTagArray);
    });
  };

  const deleteFile = (data, index) => {
    let array = [...images];
    const newArray = array.filter((m) => m.fileName !== data.fileName);
    setImages(newArray);
  };

  const getTagNameById = async () => {
    let newTagArray = [];
    await lotData?.selectedLot?.tagId?.map(async (item) => {
      const newTagNameList = await getTagById(item);
      const newwObj = {
        label: newTagNameList.data.tags[0].item,
        value: newTagNameList.data.tags[0]._id,
      };
      newTagArray.push(newwObj);
      if (lotData.selectedLot.tagId.length === newTagArray.length) {
        setSelectedValue(newTagArray);
      }
    });
  };

  const getUploadedImages = async () => {
    const newarr = [];
    images.map(async (item) => {
      const GetFile = await getFile(item.fileName, item.folderName);
      const objwithRef = {
        fileName: GetFile.fileName || GetFile.filename,
        ref: GetFile.reference ,
        imageId: GetFile.fileName || GetFile.filename,
        folderName: GetFile.folderName || GetFile.folder || GetFile.FolderName,
      };
      newarr.push(objwithRef);
      if (images.length === newarr.length) {
        setImages(newarr);
      }
    });
  };

  const handleSubmit = () => {
    if (title === "") {
      setIsError(true);
      setMessage("Please enter the title");
      setType("error");
      setNotify(true);
      setTimeout(() => setIsError(false), 2000);
    } else if (basePrice === "") {
      setIsError(true);
      setMessage("Please enter the base price");
      setType("error");
      setNotify(true);
      setTimeout(() => setIsError(false), 2000);
    } else if (location === "") {
      setIsError(true);
      setMessage("Please enter the location");
      setType("error");
      setNotify(true);
      setTimeout(() => setIsError(false), 2000);
    } else if (increamentAmount === "") {
      setIsError(true);
      setMessage("Please enter the increment Amount");
      setType("error");
      setNotify(true);
      setTimeout(() => setIsError(false), 2000);
    } else if (lastDate === "") {
      setIsError(true);
      setMessage("Please enter the close date");
      setType("error");
      setNotify(true);
      setTimeout(() => setIsError(false), 2000);
    } else if (description === "") {
      setIsError(true);
      setMessage("Please enter the description");
      setType("error");
      setTimeout(() => setIsError(false), 2000);
    } else if (detailedDescription === "") {
      setIsError(true);
      setMessage("Please enter the Detailed Description");
      setType("error");
      setNotify(true);
      setTimeout(() => setIsError(false), 2000);
    } else if (selectedValue.length === 0) {
      setIsError(true);
      setMessage("Please seelct the tags");
      setType("error");
      setTimeout(() => setIsError(false), 2000);
    } else if (images.length === 0) {
      setIsError(true);
      setMessage("Please Upload the images");
      setType("error");
      setNotify(true);
      setTimeout(() => setIsError(false), 2000);
    } else {
      setIsError(false);
      const data = getFormDetails();
      setShowLoader(true);
      updateLot(data, lotData.selectedLot._id).then((response) => {
        setShowLoader(false);
        if (response.status === 200) {
          setType("success");
          setMessage("Successfully updated the Lot details!");
          setNotify(true);
          setShowLoader(false);
          setTimeout(() => props.history.push("/app/marketplace"), 2000);
        } else {
          let msg = [];
          if (response.ErrorData) {
            // eslint-disable-next-line
            response.ErrorData.details.map((item) => {
              msg.push(item.message);
            });
            setMessage(`${msg.join()}`);
            setNotify(true);
            setType("error");
          } else {
            setMessage(response.message);
            setNotify(true);
            setType("error");
          }
        }
      });
    }
  };

  const getFormDetails = () => {
    let tagsArray = [];
    selectedValue.map((item) => {
      tagsArray.push(item.value);
    });
    const isChangedDate = moment(lastDate).isAfter(moment());
    if (isChangedDate) {
      var updateFormData = {
        data: {
          title: title,
          images: images,
          location: location,
          tenantId: localStorage.getItem("customerid"),
          tagId: tagsArray,
          lastDate: lastDate,
          basePrice: basePrice,
          status: "open",
          description: description,
          detailedDescription: detailedDescription,
          email: email,
          increamentAmount: increamentAmount,
        },
      };

      return updateFormData;
    } else {
      var updateFormData = {
        data: {
          title: title,
          images: images,
          location: location,
          tenantId: localStorage.getItem("customerid"),
          tagId: tagsArray,
          lastDate: lastDate,
          basePrice: basePrice,
          status: "closed",
          description: description,
          detailedDescription: detailedDescription,
          email: email,
          increamentAmount: increamentAmount,
        },
      };

      return updateFormData;
    }
  };

  useEffect(() => {
    getUploadedImages();
    getTagNameById();
    if (Object.keys(lotData.selectedLot).length === 0) {
      history.push('/app/marketplace')
    }
    // eslint-disable-next-line
  }, []);


  const defaultDate = new Date(); 
  defaultDate.setDate(defaultDate.getDate() + 7); 
  const formattedDefaultDate = defaultDate.toISOString(); 

  var backendate = new Date(lotData?.selectedLot?.lastDate ? lotData?.selectedLot?.lastDate : formattedDefaultDate )
    .toISOString()
    .slice(0, 10);

    const getCurrentDate = () =>{
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <Grid container spacing={1}>
          <Grid item lg={2} md={1} xs={0} sm={0}></Grid>
          <Grid item lg={8} md={10} xs={12} sm={12}>
            <Paper
              className={classes.paper}
              classes={{
                root: classnames(classes.widgetRoot, {
                  [classes.noWidgetShadow]: null,
                }),
              }}
            >
              <div className={classes.form1} >
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} xs={12} sm={12}>
                    <div className={classes.formField}>
                      {notify ? (
                        <NotificationContainer
                          message={message}
                          notificationtype={type}
                        />
                      ) : (
                        <></>
                      )}
                      <form autoComplete="off">
                        <div className={classes.form1} style={{marginTop:"-10px"}}>
                          <Grid container spacing={3}>
                          <Grid item lg={12} md={12} xs={12} sm={12}> <Typography variant='h3'>Edit Lot</Typography></Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                              sm={12}
                            
                            >
                              <div>
                                <TextField
                                  id="title"
                                  label={t("lot_title")}
                                  fullWidth
                                  name="title"
                                  value={title}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setTitle(value);
                                    if (value.length <= 1) {
                                      setTitleError(
                                        "Please enter atleast 2 characters"
                                      );
                                    } else {
                                      setTitleError("");
                                      setTitle(value);
                                    }
                                  }}
                                  error={title ? titleError : ""}
                                  helperText={title ? titleError : ""}
                                  variant="outlined"
                                  margin="dense"
                                />
                              </div>
                              <div>
                                <TextField
                                  id="basic_price"
                                  label={t("lot_basic_price")}
                                  fullWidth
                                  name="basicPrice"
                                  value={basePrice}
                                  onChange={(e) => {
                                    const value = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    setBasePrice(value);
                                    if (value === "") {
                                      setBasicPriceError("Field is required");
                                    } else {
                                      setBasicPriceError("");
                                      setBasePrice(value);
                                    }
                                  }}
                                  error={basePrice ? basicPriceError : ""}
                                  helperText={basePrice ? basicPriceError : ""}
                                  variant="outlined"
                                  margin="dense"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                />
                              </div>
                              <div>
                                <TextField
                                  id="location"
                                  label={t("lot_location")}
                                  fullWidth
                                  multiline
                                  name="location"
                                  value={location}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setLocation(value);
                                    if (value === "") {
                                      setLocationError("Field is required");
                                    } else if (value.length <= 1) {
                                      setLocationError(
                                        "Please enter atleast 2 characters"
                                      );
                                    } else {
                                      setLocationError("");
                                      setLocation(value);
                                    }
                                  }}
                                  error={location ? locationError : ""}
                                  helperText={location ? locationError : ""}
                                  variant="outlined"
                                  margin="dense"
                                />
                              </div>
                              <div>
                                <TextField
                                  id="incrementValue"
                                  label={t("lot_increment_amount")}
                                  fullWidth
                                  name="incrementValue"
                                  value={increamentAmount}
                                  onChange={(e) => {
                                    setIncreamentAmount(e.target.value);
                                    const value = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    setIncreamentAmount(value);
                                    if (value === " ") {
                                      setIncrementValueError(
                                        "Field is required"
                                      );
                                    } else {
                                      setIncreamentAmount(value);
                                      setIncrementValueError("");
                                    }
                                  }}
                                  error={
                                    increamentAmount ? incrementValueError : ""
                                  }
                                  helperText={
                                    increamentAmount ? incrementValueError : ""
                                  }
                                  variant="outlined"
                                  margin="dense"
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                />
                              </div>
                              <div>
                                <TextField
                                  name="date"
                                  id="date"
                                  fullWidth
                                  label={t("close_date")}
                                  InputLabelProps={{ shrink: true }}
                                  type="date"
                                  defaultValue={backendate}
                                  onChange={(e) => {
                                    const selected = e.target.value;

                                    const isAfter = moment(selected).isAfter(
                                      moment()
                                    );

                                    if (isAfter) {
                                      setLastDate(moment(selected));
                                      setDateError("");
                                    } else {
                                      setLastDate(selected);
                                      setDateError(
                                        "Please enter the future date"
                                      );
                                    }
                                  }}
                                  error={lastDate ? dateError : ""}
                                  helperText={lastDate ? dateError : ""}
                                  variant="outlined"
                                  margin="dense"
                                  inputProps={{ min: getCurrentDate() }}
                                />
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <TextField
                                  fullWidth
                                  multiline
                                  variant="outlined"
                                  id="description"
                                  label={t("common_description")}
                                  name="description"
                                  value={description}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setDescription(value);
                                    if (value.length <= 1) {
                                      setDescriptionError(
                                        "Please enter atleast 2 characters"
                                      );
                                    } else {
                                      setDescriptionError("");
                                      setDescription(value);
                                    }
                                  }}
                                  error={description ? descriptionError : ""}
                                  helperText={
                                    description ? descriptionError : ""
                                  }
                                />
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <TextField
                                  fullWidth
                                  multiline
                                  variant="outlined"
                                  id="detaileDescription"
                                  label={t("lot_detailed_description")}
                                  name="detaileDescription"
                                  value={detailedDescription}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setDetailedDescription(value);
                                    if (value.length <= 1) {
                                      setdetailedDescriptionError(
                                        "Please enter atleast 2 characters"
                                      );
                                    } else {
                                      setdetailedDescriptionError("");
                                      setDetailedDescription(value);
                                    }
                                  }}
                                  error={
                                    detailedDescription
                                      ? detailedDescriptionError
                                      : ""
                                  }
                                  helperText={
                                    detailedDescription
                                      ? detailedDescriptionError
                                      : ""
                                  }
                                />
                              </div>
                            
                              <div style={{ marginTop: "1rem" }}>
                                <AsyncSelect
                                  id="tags"
                                  placeholder={t("lot_search_tag")}
                                  isMulti
                                  name="tags"
                                  value={selectedValue}
                                  noOptionsMessage={() => "No tag found"}
                                  loadOptions={getAllTag}
                                  onInputChange={(e) => handleInputChange(e)}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    width: "width: calc(100% - 2*15px)",
                                  }}
                                >
                                  <span>{t("common_upload_details")}</span>
                                  <Grid container spacing={2}>
                                    <Grid item lg={12} md={8} sm={12} xs={12}>
                                      <div className={classes.fileTypeInput}>
                                        <FileBase64
                                          className={classes.input}
                                          multiple={true}
                                          onDone={(e) => handleImageChange(e)}
                                        />
                                      </div>
                                      {imageAlert ? (
                                        <span className={classes.spanAlert}>
                                          Please upload .png,.jpg,.jpeg image
                                          formats
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                      <label htmlFor="lot-file">
                                        {showImageLoader ? (
                                          <CircularProgress />
                                        ) : (
                                          <></>
                                        )}
                                      </label>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        {
                          <div className="result">
                            {images.length > 0 &&
                              images.map((data, index) => {
                                return (
                                  <div className="img-wrapp" key={index}>
                                    <img
                                      src={data.ref}
                                      alt=""
                                      className="image-wrapperr"
                                    />
                                    <CancelIcon
                                      className="closee"
                                      onClick={() => deleteFile(data, index)}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        }

                        <div></div>
                        <div
                        className={classes.editLotDiv}
                        >
                          {
                            <Button
                              href="/#/app/marketplace"
                              variant="outlined"
                              className={classes.button}
                            >
                              {t("common_back")}
                            </Button>
                          }

                          {showLoader ? (
                            <CircularProgress />
                          ) : lotData.selectedLot.progressStatus ===
                            "finalized" ? (
                            ""
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              onClick={handleSubmit}
                            >
                              {t("edit_lot")}
                            </Button>
                          )}
                        </div>

                      </form>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
          <Grid item lg={2} md={1} xs={0} sm={0}></Grid>
        </Grid>
      </div>
    </>
  );
};
export default EditLot;

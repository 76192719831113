import React,{useState} from "react";
import { 
    Grid,
    Paper,
    Collapse, 
    IconButton,
    Button,
    Typography
} from '@mui/material'
import {
    ExpandMore as DropDownIcon,
    ExpandLess as DropUpIcon
}  from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import useStyles from "./styles";

const MPRDetails = ({mprData}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const [collapse,setCollapse] = useState(true)
    const [expanded, setExpanded] = useState(false);

    const textLimit = 100;
    
    const text = mprData.length >0 ? mprData[0][1]?.value : 'N/A'
    const toggleExpanded = () => {
      setExpanded(!expanded);
    };

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    return (

        <Paper className={classes.Datapaper}>
        <Grid container spacing={3} className={classes.grid}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
                <span style={{fontSize:'19px',fontWeight:"lighter"}}>{t("common_mpr_details" )}</span>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} className={classes.iconGrid}>
                <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
                    {(collapse)?<DropDownIcon /> :<DropUpIcon />}
                </IconButton> 
            </Grid> 
        </Grid>
        <Collapse in={collapse}>
                <Paper elevation={3} className={classes.detailsDiv}>
                    <Grid container spacing={3} className={classes.grid}>
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                            <h4>{t("mpr_name")}</h4>
                            {mprData.length > 0 ? mprData[0][0]?.value : "N/A"}
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                            <h4>{t("common_service_category")}</h4>
                            {mprData.length > 0 ? mprData[0][5]?.value : "N/A"}
                        </Grid> 
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                            <h4>{t("common_service_type")}</h4>
                            {mprData.length > 0 ? mprData[0][4]?.value : "N/A"}
                       </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                            <h4>{t("mpr_quotation_last_date")}</h4>
                            {mprData.length > 0 ? mprData[0][3]?.value: "N/A"}
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                            <h4>{t("mpr_last_date")}</h4>
                            {mprData.length > 0 ? mprData[0][2]?.value : "N/A"}
                        </Grid> 
                        <Grid item lg={4} md={4} sm={6} xs={6}>
                            <h4>{t("lead_assigned_to")}</h4>
                            {mprData.length > 0 ? (mprData[0][7]?.value !== undefined && mprData[0][7]?.value !== "" ? mprData[0][7]?.value : <>{t("leads_unassigned")}</>) : "N/A"}
                        </Grid> 
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <h4>{t("common_mpr")} {t("common_description")}</h4>
                            {text?.length > textLimit || expanded ? 
                             <div>
                             <Typography variant="body1" style={{display:'inline'}}>
                                 {expanded ? text : `${text?.slice(0, textLimit)}...`}
                             </Typography>
                             <Button color="primary" onClick={toggleExpanded}>
                                 {expanded ? 'Show Less' : 'Show More'}
                             </Button>
                             </div> : text
                            }
                           
                        </Grid> 
                    </Grid>
                </Paper> 
        </Collapse>          
    </Paper>
        // <Paper className={classes.Datapaper}>
        //     <Grid container spacing={3} className={classes.grid}>
        //         <Grid item lg={10}>
        //             <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("common_mpr_details")}</span>
        //         </Grid>
        //         <Grid item lg={2} className={classes.iconGrid}>
        //             <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
        //                 {(collapse)?<DropDownIcon /> :<DropUpIcon />}
        //             </IconButton> 
        //         </Grid> 
        //     </Grid>
        //     <Collapse in={collapse}>
        //         <Grid container spacing={0} className={classes.grid}>
        //             <Paper  elevation={3} className={classes.detailsDiv}>
        //                 <Grid container spacing={3} className={classes.grid}>
        //                     {
        //                         mprData.map(mpr => {
        //                             return mpr.map(ele => {
        //                                 return(
        //                                     <>
        //                                     <Grid key={ele} item lg={4} md={4} sm={6} xs={6}className={classes.detailsGrid}>
        //                                         <h4>{ele.label}</h4>
        //                                         {ele.value}
        //                                     </Grid>
        //                                     </>
        //                                 )
        //                             })
        //                         })
        //                     }
        //                 </Grid>
        //             </Paper> 
        //         </Grid>
        //     </Collapse>          
        // </Paper>
    );
}
export default MPRDetails
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FileBase64 from "react-file-base64";

import {
  FormControl,
  Input,
  InputLabel,
  Button,
  CircularProgress,
  Select,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from 'react-number-format';
import useStyles from "../styles";


import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";


import NotificationContainer from "../../../../notifications/NotificationContainer";
import { fileupload } from "../../../../material_pickup_request/MprState";
import { assignLead } from "../../../LeadState";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="₹"
    />
  );
}

const AddInvoice = (props) => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceValue, setInvoiceValue] = useState(
    props.list.transactionAmount ? props.list.transactionAmount.amount : ""
  );
  const [fileData, setFileData] = useState("");
  const [region, setRegion] = useState("INR");
  const [genratedDate, setGeneratedDate] = useState(new Date());
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState(false);
  const [type, setType] = useState("");
  const [showloader, setShowLoader] = useState(false);
  const [res, setRes] = useState("");
  const [submit, setSubmit] = useState(false);
  var loggedInDatas = useSelector((state) => state.userdata);

  const handleupdateImage = (e) => {
    setFileData(e);
  };
  const handleGenDate = (date) => {
    setGeneratedDate(date);
  };

  const handleChange = () => {
    if (fileData.type !== "application/pdf") {
      setType("error");
      setMessage("Please upload PDF File");
      setNotification(true);
    } else {
      setShowLoader(true);
      var filetype = fileData.type.split("/");
      var baseData = fileData.base64.split(",");
      let getTokenData = {
        abbrevation: loggedInDatas.getData.abbrevation,
        fileType: "invoice",
        folderName: props.list.external_id,
        expireLimt: 1800,
        extension: filetype.length > 0 ? filetype[1] : "",
      };
      fileupload(
        baseData.length > 0 ? baseData[1] : "",
        getTokenData,
        fileData.type
      ).then((response) => {
        if (response.status === "200") {
          setRes(response.fileName);
          setType("success");
          setMessage(response.message);
          setNotification(true);
          setShowLoader(false);
        }
      });
    }
  };
  const handleClose = () => {
    props.close();
  };
  const handleSubmit = () => {
    setSubmit(true);
    let Data = {
      external_id: props.list.external_id,
      leadData: {
        invoice: {
          fileName: res,
          folderName: "invoice/" + props.list.external_id,
          invoiceNumber: invoiceNo,
          generatedOn: genratedDate,
          amount: invoiceValue,
        },
        status: "Invoice available"
      },
      mpr_id:props.list.Mpr_id
    };
    assignLead(Data, dispatch).then((response) => {
      if (response.status === "200") {
        setType("success");
        setMessage("Invoice sent Successfully");
        setNotification(true);
        setTimeout(() => props.close(), 2000);
        setSubmit(false);
      } else {
        setType("error");
        setMessage("Something went wrong");
        setNotification(true);
        setSubmit(false);
      }
    });
  };
  useEffect(() => {}, [fileData, notification]);
  return <>
    {notification ? (
      <NotificationContainer message={message} notificationtype={type} />
    ) : (
      <></>
    )}
    <div className={classes.formField}>
      <form autoComplete="off">
      <h2>{t("Upload a invoice")} </h2>
        <div>
          <FormControl variant="standard" fullWidth className={classes.formControl}>
            <InputLabel htmlFor="address_line_1">
              {t("mpr_invoice") + " " + t("common_number")}
            </InputLabel>
            <Input
              id="full_name"
              name="address_line_1"
              value={invoiceNo}
              onChange={(e) => setInvoiceNo(e.target.value)}
            />
          </FormControl>
        </div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          
            <DatePicker
              sx={{width:"100%"}}
              margin="normal"
              id="date-picker-dialog"
              label={t("lead_generated_on")}
              format="dd/MM/yyyy"
              value={genratedDate}
              fullWidth
              readOnly
              onChange={handleGenDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              
              slotProps={{ textField: { variant: "standard" } }}
            />
          
          </LocalizationProvider>
        </div>
        <div>
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12} sm={12}>
              <FormControl variant="standard" fullWidth className={classes.formControl} >
                <InputLabel id="state_drop">
                  {t("mpr_invoice_region")}
                </InputLabel>
                <Select
                  variant="standard"
                  labelId="region"
                  id="region"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}>
                  <MenuItem value={"INR"}>INR</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={8} md={6} sm={12} xs={12}>
              <TextField 
                style={{ marginTop: "8px" }}
                variant="standard"
                label={t("mpr_invoice") + " " + t("mpr_invoice_amount")}
                value={invoiceValue}
                onChange={(e) => setInvoiceValue(e.target.value)}
                name="numberformat"
                id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }} />
            </Grid>
          </Grid>
        </div>
        <div className={classes.fileTypeInput}>
          <Grid container spacing={3}>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <FormControl variant="standard" className={classes.formControl}>
                <FileBase64
                  className={classes.input}
                  multiple={false}
                  onDone={(e) => handleupdateImage(e)}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              style={{ textAlign: "right",marginTop:"24px" }}
            >
              {showloader ? (
                <CircularProgress />
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleChange}
                  className={classes.button}
                >
                  {t("common_upload")}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
        <span>{t("mpr_invoice_upload_file")}</span>

        <div style={{ marginTop: "2rem", float: "right" }}>
          <Button
            variant="outlined"
            style={{ marginRight: "1rem" }}
            onClick={handleClose}
            className={classes.button}
          >
            {t("common_back")}
          </Button>
          {res !== "" ? (
            <>
              {submit ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleSubmit}
                  >
                    {t("common_publish")}
                  </Button>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  </>;
};

export default AddInvoice;
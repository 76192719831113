import React, { useState,useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    FormControl,
    FormLabel
} from "@mui/material"
import { useDispatch,useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";

import FileBase64 from 'react-file-base64';
import moment from 'moment'

import useStyles from "../styles";
import NotificationContainer from '../../notifications/NotificationContainer'
import { fileupload } from '../../material_pickup_request/MprState';
import { uploadCertificate } from '../SettingState'



const Form = (props) => {
    // eslint-disable-next-line
    const { t, i18n } = useTranslation()
    const classes = useStyles();
    const dispatch = useDispatch()
    var loggedInDatas = useSelector(state => state.userdata)
   
  
    const [valid,setValid] = useState(new Date())
    
    const [companyName,setCompanyName] = useState((loggedInDatas && loggedInDatas.getData)?loggedInDatas.getData.customerName :"")
    const [certificateNo,setCertificateNo] = useState("")
    const [fileData,setFileData] = useState("")
    const [res,setRes] = useState("")
    const [message,setMessage] = useState("")
    const [notification,setNotification] = useState(false)
    const [type,setType] = useState("")
    const [showloader, setShowLoader] = useState(false)


    const handleValidDate = (date) => {
        setValid(date)
    }
    const handleupdateImage= (e) => {
        setFileData(e)
    }
    const handleSubmit = () => {
        setShowLoader(true)
        let bodyData = {
            "thistenantID":loggedInDatas.getData.customerid,
            "certificate":{
                "status":"",
                "reason":"",
                "fileName":res.fileName,
                "folderName":res.folderName,
                "lastDate": moment(valid).format('YYYY-MM-DD'),
                "certNumber":certificateNo
            }
        }
        uploadCertificate(bodyData,dispatch).then(response => {
            if(response.status === "200"){
                setType("success")
                setMessage("Certificate Uploaded Successfully! Please wait for Response!!")
                setNotification(true)
                setShowLoader(false)
                setTimeout(()=>  props.close(),2000)
            }else{
                setType("error")
                setMessage("Something went wrong")
                setNotification(true)
                setShowLoader(false)
            }
        })
    }
    const handleFileUpload = () => {
        if(fileData.type && fileData.type !== "application/pdf"){
            setType("error")
            setMessage("Please upload PDF File")
            setNotification(true)
        }else{
            setShowLoader(true)
            if (fileData) {
            var filetype = fileData.type.split('/')
            var baseData = fileData.base64.split(',')
            let getTokenData = {
                "abbrevation" : loggedInDatas.getData.abbrevation,
                "fileType" : "Cert",
                "folderName": loggedInDatas.getData._id,
                "expireLimt" :1800,
                "extension" : (filetype.length >0) ? filetype[1] : ""
            }
            fileupload((baseData.length >0)?baseData[1]:"",getTokenData,fileData.type ).then((response) => {
                if(response.status === "200"){
                    setRes(response)
                    setType("success")
                    setMessage(response.message)
                    setNotification(true)
                    setShowLoader(false)
                }
              })
            } else {
                console.error("fileData is undefined");
            }
        }
    }
      useEffect(() => {
      }, [showloader,notification])
    return <>
        {
            (notification)?<NotificationContainer message={message} notificationtype={type}/>:<></>
        }
        {
            <div className={classes.PickupformField}>
            <form  autoComplete="off" >
            <div className={classes.margin}>
                <TextField
                    variant="standard"
                    id="standard-basic"
                    label={t("customer_company_name")}
                    multiline
                    value={companyName}
                    onChange = {(e)=>setCompanyName(e.target.value)}
                    fullWidth />
            </div>
            <div className={classes.margin}>
                <TextField
                    variant="standard"
                    id="standard-basic"
                    label={t("common_pollution_certificate_number")}
                    value={certificateNo}
                    onChange = {(e)=>setCertificateNo(e.target.value)}
                    fullWidth />
            </div>
            <div className={classes.margin}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                        // margin="normal"
                        id="date-picker-dialog"
                        label={t("common_valid_till")}
                        format="dd/MM/yyyy"
                        value={valid}
                        fullWidth
                        onChange={handleValidDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        sx={{width:"100%",margin:"10px 0"}}
                        renderInput={
                            <TextField variant="standard" label={t("common_valid_till")}></TextField>
                          }
                        />
                   </LocalizationProvider>
            </div>
            <div className={classes.fileTypeInput}>
            <FormLabel component="legend" className={classes.certificateLabel}>{t("common_pollution_certificate")}</FormLabel>
            <Grid container spacing={3}>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <FormControl variant="standard" className={classes.margin}>
                        <FileBase64
                            className={classes.input}
                            multiple={false}
                            onDone={(e)=>handleupdateImage(e)}
                        />
                    </FormControl>
                    
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} style={{textAlign:'right'}}>
                   {(showloader)?<CircularProgress /> : <Button size="small" variant="outlined" color="primary" onClick={handleFileUpload} className={classes.button}>{t("common_upload")}</Button>} 
                </Grid>
            </Grid>
        </div>
        <span>{t("quotation_upload_file")}</span>    
        {
            (res !== "")?<div style={{marginTop:'1rem',float:'right'}}>
            {
                (showloader)?<CircularProgress /> :<Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
                   {t("common_submit")}
                </Button>
            } 
         </div>:<></>
        }
            
            </form>
        </div>
        }
    
    </>;
}

export default Form
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  Paper,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import NotificationContainer from "../notifications/NotificationContainer";
import { getCustomerDetails, updatePanGst } from "./SettingState";
import { Typography } from "@mui/material";

const Details = () => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const breadcrumb = [
    { label: t("cust_administration"), link: "/#/app/admin" },
    { label: t("settings_details"), link: "" },
  ];

  // const [value, setValue] = useState(0);
  const [gst, setGst] = useState("");
  const [pan, setPan] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [notify, setNotification] = useState(false);
  const [type, setType] = useState("");
  const [showLoader, setShowloader] = useState(false);
  var loggedInDatas = useSelector((state) => state.userdata);
  const [gstError, setGstError] = useState("");
  const [panError, setPanError] = useState("");
  const [isGstDisabled, setIsGstDisabled] = useState(true);
  const [isPanDisabled, setIsPanDisabled] = useState(true);

  // const handleChange = (event, newValue) => {
  //     setValue(newValue);
  // };

  useEffect(() => {
    let data = {
      customerId: loggedInDatas.getData?.customerid,
    };
    if (!data.customerId) {
      history.push("/app/admin");
      return;
    }
    getCustomerDetails(data).then((response) => {
      if (response && response.status === "200") {
        setGst(response.data.customerGst);
        setPan(response.data.customerPan);
        setCompanyName(response.data.customerName);
      } else if (response) {
        setMessage(response.message);
        setNotification(true);
        setType("error");
      } else {
        setMessage("Something went wrong");
        setNotification(true);
        setType("error");
      }
    });
    // eslint-disable-next-line
  }, [notify, showLoader]);

  const handleUpdate = () => {
    setShowloader(true);
    let data = {
      updateValue: {
        gst: gst,
        pan: pan,
      },
      thisCustomer: loggedInDatas.getData.customerid,
    };
    updatePanGst(data).then((response) => {
      if (response && response.status === "200") {
        setType("success");
        setMessage("Details Updated Successfully");
        setNotification(true);
        setShowloader(false);
      } else if (response && response.status === "500") {
        setType("error");
        setMessage(response.message);
        setNotification(true);
        setShowloader(false);
      } else {
        setType("error");
        setMessage("Something went wrong, Try again!");
        setNotification(true);
        setShowloader(false);
      }
    });
  };

  const handleChangeGst = (e) => {
    const gstValue = e.target.value;
    setGst(gstValue);

    // Perform GST validation
    const gstRegex =
      /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[0-9A-Za-z]{1}[Z]{1}[0-9A-Za-z]{1}$/;

    if (!gstRegex.test(gstValue)) {
      setGstError("Invalid GST format. Please enter a valid GST.");
      setIsGstDisabled(true);
    } else {
      setGstError("");
      setIsGstDisabled(false);
    }
  };

  const handleChangePan = (e) => {
    const panValue = e.target.value;
    setPan(panValue);

    // Perform PAN validation
    const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

    if (!panRegex.test(panValue)) {
      setPanError("Invalid PAN format. Please enter a valid PAN.");
      setIsPanDisabled(true);
    } else {
      setPanError("");
      setIsPanDisabled(false);
    }
  };
  // console.log("com",companyName);
  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        {notify ? (
          <NotificationContainer message={message} notificationtype={type} />
        ) : (
          <></>
        )}
        <Paper className={classes.paper}>
          <Grid>
            <Grid style={{ padding: "0.5rem" }}>
              <Typography variant="h3">Update Details</Typography>
            </Grid>
            <Grid style={{ padding: "0.5rem" }}>
              <TextField
                fullWidth
                id="company Name"
                label="Company Name"
                value={companyName}
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
            </Grid>
            <Grid style={{ padding: "0.5rem" }}>
              <TextField
                fullWidth
                id="outlined-gst"
                label="GST"
                onChange={handleChangeGst}
                value={gst}
                variant="standard"
              />
              {gstError && (
                <FormHelperText style={{ color: "red" }}>
                  {gstError}
                </FormHelperText>
              )}
            </Grid>
            <Grid style={{ padding: "0.5rem" }}>
              <TextField
                fullWidth
                id="outlined-pan"
                label="PAN Number"
                onChange={handleChangePan}
                value={pan}
                variant="standard"
              />
              {panError && (
                <FormHelperText style={{ color: "red" }}>
                  {panError}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}></Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className={classes.detailsButton}>
                {showLoader ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdate}
                    disabled={isGstDisabled || isPanDisabled}
                  >
                    Update
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
          {/* </TabPanel> */}
        </Paper>
      </div>
    </>
  );
};

export default Details;

import {apiUrl} from '../../data/config'

export function getBranchData(dispatch){
  let url = apiUrl+'/v1/customer/adminViewBranch'
  let data = {
      "customer_Id" : localStorage.getItem("customerid")
  }
  return fetch(url, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type':'application/json',
          'token': localStorage.getItem('token')
      },
      body: JSON.stringify(data)
  }).then(response => {
      if (response.ok) {
          return response.json().then((data) => {
              return data; 
          });
      } else {
          if (response.status === 500) {
              return {
                  "status": "500",
                  "message": "Something went wrong"
              };
          } else {
              return {
                  "status": response.status.toString(),
                  "message": "Unexpected error"
              };
          }
      }
  }).catch(error => {
      return {
          "status": "500",
          "message": "Network error. Please try again later."
      };
  });
}

export function getContactData(id, dispatch){
  let url = apiUrl+'/v1/customer/adminViewUsers';
  let data = {
      "branch_Id": id
  };
  return fetch(url, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type':'application/json',
          'token': localStorage.getItem('token')
      },
      body: JSON.stringify(data)
  }).then(response => {
      if (response.ok) {
          return response.json().then((data) => {
              return data;
          });
      } else {
          if (response.status === 500) {
              return {
                  "status": "500",
                  "message": "Something went wrong"
              };
          } else {
              return {
                  "status": response.status.toString(),
                  "message": "Unexpected error"
              };
          }
      }
  }).catch(error => {
      console.error('Fetch error:', error);
      return {
          "status": "500",
          "message": "Network error. Please try again later."
      };
  });
}


export function AddBranch(data,dispatch){
    let url = apiUrl+'/v1/customer/adminAddBranch'
    
    return  fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body : JSON.stringify(data)
      }).then(response => {
          if(response.ok){
            return response.json().then((data) => {
                // if(data.status === "200"){
                //     return data
                // }else if(data.status === "500"){
                //     dispatch({
                //         type : 'LOG_OUT'
                //     })
                //     localStorage.clear()
                //     return data
                // }
                return data
            })
          }else{
            return {
                "status" :"500",
                "message" : "Something went wrong"
            }
          }
      })
}

export function addRemoveExcelusive(data,dispatch){
    let url = apiUrl+'/v1/customer/changeExlusive'
     
    return  fetch(url,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body : JSON.stringify(data)
      }).then(response => {
          if(response.ok){
              if(response.status === "200"){
                return response.json().then((data) => {
                    return data
                })
              }else if(data.status === "500"){
                dispatch({
                    type : 'LOG_OUT'
                })
                localStorage.clear()
                return data
            }
          }else{
            return {
                "status" :"500",
                "message" : "Something went wrong"
            }
          }
      })
}

export function deleteDetails(data){
    let url = apiUrl+'/v1/customer/deletedetails'
    let bodyData = {
        deleteItem : data.condition,
        deleteid :data.id
    }
   return fetch(url,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body: JSON.stringify(bodyData)
    }).then(response => {
        return response.json().then((data) => {
             return data
        })
    })
}

export function updateContactRole(data){
    let url = apiUrl + '/v1/customer/updateDetail'
    return fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'token':localStorage.getItem("token")
        },
        body: JSON.stringify(data)
      }).then(function (response) {
        if (response.ok) {
          return response.json().then((data) => {
            return data;
          });
        } else {
          return {
            "status" : "500",
            "message": "Something went wrong"
          }
        }
      })
  }
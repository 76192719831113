import { makeStyles } from "@mui/styles";
import withStyles from '@mui/styles/withStyles';
import { Badge} from '@mui/material';

export default makeStyles(theme => ({
    Datapaper:{
        marginBottom:theme.spacing(4),
        [theme.breakpoints.down("lg")]: {
            marginTop:theme.spacing(2)
          }
       
    },
    grid:{
        padding: theme.spacing(2),
    },
    collapseIcon:{
        cursor:'pointer',
        marginTop:"-10px",
        color: theme.palette.primary.main,
        "&:hover": {
            //color: theme.palette.secondary.main
        }
    },
    iconGrid:{
        textAlign : 'right'

    },
    buttonOutlined:{
        color: theme.palette.primary.main,
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        marginLeft:'1rem',
        "&:hover" : {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.contrastText
        }
    },
    radioContactSelect: {
        border:'1px solid',
        borderColor:theme.palette.primary.main,
    },
    detailsDiv:{
        margin:'1rem',
        // padding: theme.spacing(2),
    },
    asignButton:{
        textAlign:'right'
    },
    formDiv:{
        margin:'2rem',
        border:'1px dashed',
        borderColor:theme.palette.primary.main,
        padding:'1rem'
    },
    choosedSlotSpan:{
        fontWeight:'bold',
        marginLeft:'1rem',
        color:theme.palette.primary.main,
        padding:'8px',
        borderRadius:'4px',
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        "&:hover" : {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.contrastText
          }

    },
    rejectedSlotSpan:{
        fontWeight:'bold',
        marginLeft:'1rem',
        color:theme.palette.secondary.main,
        padding:'8px',
        borderRadius:'4px',
        border: "1px solid",
        borderColor: theme.palette.secondary.main,
        "&:hover" : {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
          }
    },
    dashboardCard:{
        height:'10rem',
        textAlign:'center',
        border: "1px solid",
        borderColor: theme.palette.secondary.main,
    },
    dashboardheading:{
        fontSize: '15px',
        fontWeight: 'lighter',
        padding:"0 6px",
        // height:'6rem'
    },
    dashboardcount:{
        color:theme.palette.secondary.main,
        padding:"6px 0"
    },
    pageTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(4),
    },
    HeaderContainer:{
        padding:theme.spacing(1),
        margin:theme.spacing(1.5),
        justifyContent: "space-between",
        display:'flex'
    },
    tenantHeader:{
        alignSelf: 'center',
        display:'flex',
    },
    tenantSubHeader:{
        paddingTop: theme.spacing(0.5)
    },
    invoiceForm:{
        padding: theme.spacing(3),
        height:theme.spacing(60)
    },
    invoiceForm2:{
        padding: theme.spacing(3),
        height:theme.spacing(48),
        [theme.breakpoints.down("sm")]: {
          height:theme.spacing(55)
        },
    },
    margin:{
        margin:"12px 0"
    },
    fileTypeInput:{
        marginTop:'1rem',
        borderBottom:'1px solid',
        borderColor:"rgba(0, 0, 0, 0.42)",
        padding:'7px',
    },
}));

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -10,
      padding: '0 4px',
    },
}))(Badge);
export {StyledBadge}
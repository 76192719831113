import React, { useState,useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import {
    FormControl,
    Input,
    InputLabel,
    Button,
    CircularProgress,
    Select,
    Grid,
    MenuItem,
    TextField,
    Dialog,
    Typography,
    DialogTitle, DialogContent,DialogContentText,DialogActions
} from "@mui/material"
import { useDispatch,useSelector } from "react-redux";
import { NumericFormat } from 'react-number-format';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";

import moment from 'moment'

import useStyles from "../styles";

import NotificationContainer from '../../../../notifications/NotificationContainer'

import { assignLead,updatePanGst } from '../../../LeadState'


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix = "₹"
      />
    );
  }
function currencyFormat(num) {
    return parseInt(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const AddPayment = (props) => {
    // eslint-disable-next-line
    const { t, i18n } = useTranslation();
   
    const classes = useStyles();
    const dispatch = useDispatch()
    const [selectedPickUpDate, setSelectedPickupDate] = useState((props.payment && Object.keys(props.payment).length !== 0)?new Date(props.payment.payBy):new Date())
    const [paidOn,setPaidOn] = useState(new Date())
    const [quotevalue,setQuoteValue]  = useState((props.payment  && Object.keys(props.payment).length !== 0 )?props.payment.totalAmount:(props.list.transactionAmount !=="")?props.list.transactionAmount.amount:"")
    const [region,setRegion] = useState("INR")
    const [paymethod,setPaymethod] = useState("")
    const [transId,setTransId] = useState("")
    const [amountPaid,setAmountPaid] = useState("")
    const [message,setMessage] = useState("")
    const [notification,setNotification] = useState(false)
    const [type,setType] = useState("")
    const [showloader, setShowLoader] = useState(false);
    var loggedInDatas = useSelector(state => state.userdata)
    const [open, setOpen] = useState(false);
    const [pan,setPan] = useState("")
    const [gst,setGst] = useState("")
    const [msg,setMsg]  = useState("")
    const [showPanError,setShowPanError] = useState(false)
    const [showGstError,setShowGstError] = useState(false)
    const [customerId,setCustomerId] = useState("")
    const [messageDataGst,setMessageDataGst]=useState("")
    const [messageDataPan,setMessageDataPan]=useState("")
    

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setShowPanError(false)
      setShowGstError(false)
      setPan("")
      setGst("")
    };

    const handlepickupDate = (date) => {
        setSelectedPickupDate(date);
    };
    const handlepaidOn = (date) => {
        setPaidOn(date);
    };
    const handleSubmit = () => {
        setShowLoader(true)
        let status; let paymentStatus; let paidon;
        if( loggedInDatas.getData && loggedInDatas.getData.type === "tenant" && !loggedInDatas.getData.roles.includes("user")){
            status = "Payment Completed";
            paymentStatus = "Paid";
            paidon = paidOn
        }else{
            status = "Payment Initiated";
            paymentStatus = "Initiated";
            paidon = ""
        }

        let data = {
            external_id : props.list.external_id,
            leadData : {
                payment : {
                    totalAmount : quotevalue,
                    paymentMode : paymethod,
                    transactionID : transId,
                    payBy : selectedPickUpDate,
                    PaidOn : paidon,
                    amountPaid : amountPaid,
                    paymentStatus : paymentStatus
                },
                status : status
            },
            mpr_id:props.list.Mpr_id
        }
        assignLead(data,dispatch).then(response => {
            setGst("")
            setPan("")
            if(response.status === "200"){
                setType("success")
                setMessage("Payment Saved Successfully")
                setNotification(true)
                setTimeout(()=>  props.close(),2000)
                setShowLoader(false)
            }else if(response.status === "500"){
                setType("error")
                setMessage(response.message)
                setNotification(true)
                setShowLoader(false)
                setGst(response.gst)
                setPan(response.pan)
                setMsg( (pan === "" && gst !== "")? 
                "PAN is not available" 
                : (gst === "" && pan !=="")? 
                    "GST is not available" 
                : (pan ==="" && gst === "")? "PAN and GST is not available" : "")
                setCustomerId(response.customerId)
                handleClickOpen()
            }
            else{
                setType("error")
                setMessage("Something went wrong")
                setNotification(true)
                setShowLoader(false)
            }
        })
    }

    const handlePanGst = () => {
        if(!showGstError && !showPanError){    
            let data = {
                updateValue : {
                    "gst" : gst,
                    "pan" : pan
                },
                thisCustomer : customerId
            }
           updatePanGst(data,dispatch).then(response => {
               if(response.status === "200"){
                setType("success")
                setMessage("Details Updated Successfully")
                setNotification(true)
                handleClose()
               }else{
                setType("error")
                setMessage("Something went wrong, Try again!")
                setNotification(true)
               }
           })
        }else{
            setType("error")
            setMessage("Please check PAN and GST")
            setNotification(true)
        }
        
    }

    const handlePan =(value)=>{
      setPan(value)
      let panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/
      if(!panRegex.test(value) && value !==""){
        setShowPanError(true)
        setMessageDataPan("Invalid PAN format")
      }else{
        setShowPanError(false)
        setMessageDataPan("")
      }
    }

    const handleGst =(value)=>{
        setGst(value)
        let gstRegex = /^([0-9]){2}([A-Z]){5}([0-9]){4}([A-Z]){1}([0-9]){1}([A-Z]){1}([0-9]){1}?$/
        if(!gstRegex.test(value) && value !==""){
            setShowGstError(true)
            setMessageDataGst("Invalid Gst format") 
        }else{
            setShowGstError(false)
            setMessageDataGst("")
        }
    }

    return <>
        {
            (notification)?<NotificationContainer message={message} notificationtype={type}/>:<></>
        }
        <div className={classes.paymentformField}>
        {
            (props.paymentData  && Object.keys(props.paymentData).length !== 0 && (props.paymentData.paymentStatus).toLowerCase() === ('paid').toLowerCase())?
            <Grid container spacing={2}>
                <Grid item lg={12}><h4>{t("common_payment_details")}</h4></Grid>
                <Grid item lg={4}>
                    <h4>{t("mpr_invoice_amount")+" "+t("common_paid")}</h4>
                    {currencyFormat(props.paymentData.amountPaid)}
                </Grid>
                <Grid item lg={4}>
                    <h4>{t("lead_paid_on")}</h4>
                    {moment(props.paymentData.PaidOn).format('DD-MM-YYYY')}
                </Grid>
                <Grid item lg={4}>
                    <h4>{t("lead_payment_last_date")}</h4>
                    {moment(props.paymentData.payBy).format('DD-MM-YYYY')}
                </Grid>
                <Grid item lg={4}>
                    <h4>{t("lead_payment_mode")}</h4>
                    {props.paymentData.paymentMode}
                </Grid>
                <Grid item lg={4}>
                    <h4>{t("lead_payment_status")}</h4>
                    {props.paymentData.paymentStatus}
                </Grid>
                <Grid item lg={4}>
                    <h4>{t("lead_payment_total_amount")}</h4>
                    {currencyFormat(props.paymentData.totalAmount)}
                </Grid>
                <Grid item lg={4}>
                    <h4>{t("lead_payment_transaction")}</h4>
                    {props.paymentData.transactionID}
                </Grid>
            </Grid>:
            <form  autoComplete="off" >
            <Typography variant='h4' style={{marginBottom:"16px"}}>{t("lead_add_payment")}</Typography>
            <div>
            <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                   <DatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={t("common_payment_by")}
                    format="dd/MM/yyyy"
                    value={selectedPickUpDate}
                    fullWidth

                    onChange={handlepickupDate}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    sx={{width:"100%"}}
                    renderInput={
                        <TextField variant="standard" label={t("common_payment_by")}></TextField>
                      }
                      slotProps={{ textField: { variant: "standard" } }}
                    />
            </LocalizationProvider>
                </Grid>
                <Grid item lg={3} md={3} xs={4} sm={3}>
                    <FormControl variant="standard" fullWidth className={classes.formControl1} >
                        <InputLabel id="state_drop">{t("mpr_invoice_region")}</InputLabel>
                        <Select 
                            variant="standard"
                            labelId="region"
                            id="region"
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}>
                            <MenuItem value={"INR"} >INR</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={9} md={9} sm={9} xs={8}>
                <TextField
                    sx={{width:"100%"}}
                    variant="standard"
                    label={t("common_payment_value")}
                    value={quotevalue}
                    onChange={(e) => setQuoteValue(e.target.value)}
                    name="numberformat"
                    id="formatted-numberformat-input"
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }} />
                </Grid>
            </Grid>
        </div>
        {
            ( loggedInDatas.getData && loggedInDatas.getData.type === "tenant" && !loggedInDatas.getData.roles.includes("user"))?
            <>
                <div>
                    <FormControl variant="standard" fullWidth className={classes.margin} sx={{margin:"10px 0"}}>
                        <InputLabel htmlFor="address_line_2">{t("lead_payment_method")}</InputLabel>
                        <Input id="full_name" name="address_line_2" value={paymethod} onChange={(e)=> setPaymethod(e.target.value)}/>
                    </FormControl>
                </div>
                <div>
                    <FormControl variant="standard" fullWidth className={classes.margin} sx={{margin:"10px 0"}}>
                        <InputLabel htmlFor="address_line_2">{t("common_transaction")+"/"+t("common_reference")+" "+t("common_id")}</InputLabel>
                        <Input id="full_name" name="address_line_2" value={transId} onChange={(e)=> setTransId(e.target.value)}/>
                    </FormControl>
                </div>
                <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <DatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label={t("lead_paid_on")}
                            format="dd/MM/yyyy"
                            value={paidOn}
                            fullWidth
                            onChange={handlepaidOn}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            sx={{margin:"10px 0",width:"100%"}}
                            renderInput={
                                <TextField variant="standard"  label={t("lead_paid_on")}></TextField>
                              }
                              slotProps={{ textField: { variant: "standard" } }}
                            />
                    </LocalizationProvider>
                </div>
                <div>
                    <Grid container spacing={3}>
                        <Grid item lg={3} md={3} xs={4} sm={3}>
                            <FormControl variant="standard" fullWidth className={classes.formControl1} >
                                <InputLabel id="state_drop">{t("mpr_invoice_region")}</InputLabel>
                                <Select
                                    variant="standard"
                                    labelId="region"
                                    id="region"
                                    value={region}
                                    onChange={(e) => setRegion(e.target.value)}>
                                    <MenuItem value={"INR"} >INR</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={9} md={9} xs={8} sm={9} >
                        <TextField
                            // sx={{margin:"10px 0"}}
                            sx={{width:"100%"}}
                            variant="standard"
                            label={t("mpr_invoice_amount")+" "+t("common_paid")}
                            value={amountPaid}
                            onChange={(e) => setAmountPaid(e.target.value)}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }} />
                        </Grid>
                    </Grid>
                </div>
            </>:<></>
        }
        </form>
        }
        <div style={{marginTop:'2rem',marginBottom:"2rem",float:'right',display:"flex",alignItems:"center"}}>
          
            <Button variant="outlined" onClick={()=>props.close()}  className={classes.button}>{t("common_back")}</Button>
            {
                (showloader)?<CircularProgress /> :
                (props.paymentData  && Object.keys(props.paymentData).length !== 0 && (props.paymentData.paymentStatus).toLowerCase() === ('paid').toLowerCase())?<></>:
                    
                    <Button variant="outlined" color="primary" onClick={handleSubmit} className={classes.buttonOutlined} disabled={amountPaid.length === 0}>
                    {t("common_save")}</Button>
                
            }  
        </div>
    </div>
    <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Attention</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {msg}  for this customer, please provide needed information.
      </DialogContentText>
      <TextField
        margin="dense"
        id="pan"
        label="PAN"
        type="text"
        inputProps={{pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}" }}
        fullWidth
        variant="standard"
        onChange = {(e) =>handlePan(e.target.value)}
        value = {pan}
      />
      {showPanError ?<Typography color='error'>{messageDataPan}</Typography>:<></>}
       <TextField
        margin="dense"
        id="name"
        label="GST"
        type="text"
        fullWidth
        // eslint-disable-next-line
        inputProps={{pattern: "\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}" }}
        variant="standard"
        onChange = {(e) => handleGst(e.target.value)}
        value = {gst}
        // error = {showGstError}
      />
      {showGstError ? <Typography color='error'>{messageDataGst}</Typography> : <></> }
    </DialogContent>
    <DialogActions>
      <Button variant="default"  onClick={handleClose}>Cancel</Button>
      <Button variant="outlined" color="primary" onClick={handlePanGst} disabled={pan.length < 10 || gst.length < 15 || messageDataPan !== "" || messageDataGst !== ""} >Update</Button>
    </DialogActions>
  </Dialog>
    </>;
}

export default AddPayment
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { withRouter, useLocation, useHistory } from "react-router-dom";

// styles
import useStyles from "./styles";

import PasswordSet from "../components/PasswordSet";
import RefreshToken from "../components/RefreshToken";
import { ValidateToken } from "../LoginState";
import NotificationContainer from "../../notifications/NotificationContainer";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PasswordReset(props) {
  var classes = useStyles();
  let query = useQuery();

  var [email, setEmail] = useState("");
  var [reset, showReset] = useState(true);
  var [data, setData] = useState(null);

  const [notification, setNotification] = useState({
    message: "",
    notify: false,
    type: "",
  });

  let history = useHistory();

  useEffect(() => {
    const token = query.get("token");
    if (!token) {
      history.push("/#/login");
    } else {
      ValidateToken(token).then((response) => {;
        if (response) {
          if (response.status === "500") {
            setNotification({
              message: response.Message,
              notify: true,
              type: "error",
            });
            setTimeout(() => {
              history.push("/#/login");
            }, 2000);
          } else if (response.status === "401") {
            setNotification({
              message: response.Message,
              notify: true,
              type: "error",
            });
            showReset(false);
            setEmail(response.data.email);
            setData(response.data);
          } else {
            setEmail(response.data.email);
            setData(response.data);
          }
        }
      });
    }
  }, []);

  return (
    <>
      {notification.notify ? (
        <NotificationContainer
          message={notification.message}
          notificationtype={notification.type}
        />
      ) : (
        <></>
      )}
      <Grid container className={classes.container}>
        <div className={classes.logotypeContainer}>
          <img
            src={`assets/img/sidebar.png`}
            alt="logo"
            width="30%"
            className={classes.logotypeText}
          />
        </div>
        <div className={classes.formContainer}>
          {reset ? <PasswordSet email={email} /> : <RefreshToken data={data} />}
        </div>
      </Grid>
    </>
  );
}

export default withRouter(PasswordReset);

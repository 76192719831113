import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    Button, 
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress
} from '@mui/material'
import { useTranslation } from 'react-i18next';
import {useDispatch,useSelector} from 'react-redux'
import AddIcon from '@mui/icons-material/Add';

import useStyles from "./styles";
import {assignLead} from '../../LeadState'
import NotificationContainer from '../../../notifications/NotificationContainer'
import Forms from '../../../contacts/components/Forms'
import {getLeadList} from '../../LeadState'


const AssignLead = ({list,hide,lead}) => {
    var classes = useStyles();
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    const dispatch = useDispatch()
    const data = useSelector(state => state.userdata)
    const contactList = useSelector(state => state.tenantContactList)
    const[add,setAdd] = useState(list.assigned_to)
    const [message,setMessage] = useState("")
    const [notification,setNotification] = useState(false)
    const [type,setType] = useState("")
    const [showLoader,setShowLoader] = useState(false)
    const [showForm,setShowForm] = useState(false)

    useEffect(()=> {
        (data) && getLeadList(data.getData.customerid).then((response) => {
            if(response.status === "200"){
                if(response.result)
                    dispatch({
                        type : "SET_TENANT_CONTACT_LIST",
                        payload : response.result.allUsers
                    })
                }
            })
            // eslint-disable-next-line
        },[showForm])

    const handleChange = (event) => { 
        setAdd(event.target.value)
    }

    const handleAdd = () => {
        setShowForm(true)
    } 
    const handleContactClickClose = () =>{
        setShowForm(false)
    }

    const handleAssign = () => {
        setShowLoader(true)
        if (add === "") {
            setType("error");
            setMessage("Please select a user to assign");
            setNotification(true);
            setTimeout(() => {
                setType("");
                setMessage("");
                setNotification(false);
                setShowLoader(false);
            }, 2000);
            return;
        }        
        if(lead){
            let data = {
                external_id : lead,
                leadData:{
                    status : "assigned",
                    assigned_to: add
                },
                mpr_id:list.Mpr_id
            }
            assignLead(data,dispatch).then(response => {
                if(response.status === "200"){
                    setType("success")
                    setMessage("Successfully assigned the Lead")
                    setNotification(true)
                    setTimeout(()=> hide(),2000)
                    setShowLoader(false)
                }else{
                    setType("error")
                    setMessage("Something went wrong")
                    setNotification(true)
                    setShowLoader(false)
                }
            })
        }else{
            setType("error")
            setMessage("Something went wrong")
            setNotification(true)
            setShowLoader(false)
        }
    }

    return (
        <Paper className={classes.Datapaper}>
            {
                (notification)?<NotificationContainer message={message} notificationtype={type}/>:<></>
            }
            <Grid container spacing={3} className={classes.grid}>
                <Grid item  xs={8}>
                    <span style={{fontSize:'19px',fontWeight:'lighter'}}>
                        {
                            (list && list.assigned_to === "")? t("common_assign")+" "+t("common_lead") : t("lead_assigned_to")
                        }
                    </span>
                </Grid>
                <Grid item lg={4} className={classes.iconGrid}>
                   {
                       (!showForm)?<Button size="small" variant="outlined" color="primary" endIcon={<AddIcon />} className={classes.buttonOutlined} onClick={handleAdd}>
                       {t("contact_add")}
                         </Button>:<></>
                   } 
                </Grid>
                <Grid item lg={12}>
                    {
                        (showForm)?
                        <div className={classes.formDiv}>
                           <Forms close={handleContactClickClose} where="modal"/> 
                        </div>
                   :<RadioGroup aria-label="contact" name="contact" value={add} onChange={handleChange} >  
                        <Grid container spacing={3}>
                        {
                            contactList.map((item,key) => {
                                let value1  = item.userName+", "+item.email+", "+item.mobile.code+item.mobile.number
                                return(
                                    (data.getData && data.getData._id === item._id)?<Grid key={key} item lg={5} md={5} xs={11} sm={12}>
                                        
                                    <Paper elevation={3} className={(item._id === add) ? classes.radioContactSelect : classes.radioContact}>
                                        <FormControlLabel value={item._id} control={<Radio />} label={t("lead_assign_to_me")} />
                                    </Paper>
                                </Grid>:
                                <Grid item lg={5} md={5} xs={11} sm={12} >
                                    <Paper  elevation={3} className={(add === item._id) ? classes.radioContactSelect : classes.radioContact}>
                                        <FormControlLabel value={item._id} control={<Radio />} label={value1} />
                                    </Paper>
                                </Grid>
                                )
                            })
                        }
                        </Grid>
                    </RadioGroup>
                    }
                    
               </Grid>
               {
                   (!showForm)?<>
                   
                    <Grid item xs={12} className={classes.asignButton}>
                        <Button size="small" variant="outlined" onClick={hide}>
                            {t("common_close")}
                        </Button>
                        {
                            (showLoader)? <CircularProgress style={{marginLeft:"10px"}} />: 
                            <Button size="small" variant="outlined" color="primary"  className={classes.buttonOutlined} onClick = {handleAssign}>
                                {t("common_assign")}
                            </Button>
                        }
                    </Grid>
                   </>:<></>
               }        
            </Grid>
        </Paper>
    );
}
export default AssignLead
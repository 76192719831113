import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
  Select,
} from "@mui/material";
import classnames from "classnames";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import { createMprId } from "../MprState";

const BasicDetails = ({ setForm, formData }) => {
  const classes = useStyles();
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  var data = useSelector((state) => state.userdata)
  var custData = useSelector((state) => state)
  var branchData = (custData.selectedBranch) ? custData.selectedBranch._id : ""
  const [MPRName,setMPRName] = useState(formData.MPRName)
  const [description,setDescription] = useState(formData.description)
  const [otherServiceType,setOtherServiceType] = useState(formData.other_service_type)
  const [inquiryLeadId,setInquiryLeadId] = useState(formData.inquirylead_id)
  const [quotationDate, setQuotationDate] = useState(new Date())
  const [mprID, setMprID] = useState(null)
  const [UlField,setUlField]=useState(false)
  const [servicecat, setServicecat] = useState([])
  const [serviceType, setServiceType] = useState([])
  const [otherType, setOtherType] = useState((formData.service_type).length > 0 ? (formData.service_type[formData.service_type.length - 1]) : [])
  const [serviceCate, setServiceCate] = useState(formData.service_category)


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleServiceTypes = () => {
    let aray1 = [];
    let abbriviation = custData.selectedCustomer
      ? custData.selectedCustomer.abbrevation
      : "";
    let id = custData.selectedCustomer ? custData.selectedCustomer._id : "";
    let sentData = {
      abbrevation:
        localStorage.getItem("type") === "Customer"
          ? data && data.getData && data.getData.abbrevation
          : abbriviation,
      customerid:
        localStorage.getItem("type") === "Customer"
          ? localStorage.getItem("customerid")
          : id,
      thisCustomerBranch:
        data.getData &&
        localStorage.getItem("type") === "Customer" &&
        !data.getData.roles.includes("Customer_Admin")
          ? data.getData.thisCustomerBranch
          : localStorage.getItem("type") === "tenant"
          ? branchData
          : "",
    };
    createMprId(sentData, dispatch).then((response) => {
      if(response && response.MPRID){
      setMprID(response.MPRID);
      }
      // eslint-disable-next-line
      if(response && response.serviceCategory){
        response.serviceCategory.map((item, key) => {
          aray1.push(item.wasteStream);
        });
      setServicecat(aray1);
      // eslint-disable-next-line
      response.serviceCategory &&
        response.serviceCategory.map((item) => {
          if (serviceCate === item.wasteStream) {
            if (item.serviceType.length > 0) {
              item.serviceType.push("Others");
            }
            setServiceType(item.serviceType);
          }
        });
      }  
    });
    formData.MPRId = mprID;
  };

  useEffect(() => {
    handleServiceTypes();
    // eslint-disable-next-line
  }, [serviceCate, otherType])

  useEffect(()=>{
    handleRole()
  })


  const handleRole=()=>{
    if(localStorage.getItem('type') === "tenant"){
      setUlField(true)
    }else{
      setUlField(false)
    }
  }

  const handleMprName = (e) => {
    const value = e.target.value;
    setMPRName(value)
    formData.MPRName = e.target.value
  }

  const handleDescription = (e) => {
    const value = e.target.value;
    setDescription(value)
    formData.description = e.target.value
  }

  const handleOtherServiceType = (e) => {
    const value = e.target.value;
    setOtherServiceType(value)
    formData.other_service_type = e.target.value
  }

  const handleInquiryLeadId= (e) => {
    const value = e.target.value;
    setInquiryLeadId(value)
    formData.inquirylead_id = e.target.value
  }

  const handlequotationDate = (date) => {
    setQuotationDate(date);
    formData.quotation_date = new Date(date);
  };
  const handleOtherType = (e) => {
    setOtherType(e.target.value)
    formData.service_type.push(otherType, e.target.value)
  }

  const handleCateChange = (e) => {
    setServiceCate(e.target.value);
    formData.service_category = e.target.value;
    if (e.target.value === "Assets disposal") {
      setServiceType([]);
      setOtherType([]);
    }
  };

  return (
    <Grid container spacing={3}>
     <Grid item lg={3} md={1} xs={0} sm={0} ></Grid>
      <Grid lg={6} md={10} xs={12} sm={12} item>
        <Paper
          className={classes.paper}
          classes={{
            root: classnames(classes.widgetRoot, {
              [classes.noWidgetShadow]: null,
            }),
          }}
        >
          <div className={classes.form}>
            <Grid container spacing={3}>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
                sm={12}
                // style={{ width: "width: calc(100% - 2*15px)" }}
              >
                <h3 className={classes.title}>{t("common_basic_details")}</h3>
                <div>
                  <TextField
                    variant="standard"
                    fullWidth
                    id="mpr_id"
                    label={"Mpr Id"}
                    name="Mpr Id"
                    defaultValue={mprID}
                    value={mprID}
                    InputProps={{
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                  }} />
                </div>
                <div className={classes.input}>
                  <TextField
                    required
                    variant="standard"
                    fullWidth
                    id="mpr_name"
                    label={t("mpr_name")}
                    name="MPRName"
                    value={MPRName}
                    onChange={handleMprName} />
                </div>
                <div className={classes.input}>
                  <TextField
                    required
                    variant="standard"
                    fullWidth
                    multiline
                    id="mpr_description"
                    label={t("common_description")}
                    name="description"
                    value={description}
                    onChange={handleDescription} />
                </div>
                <div className={classes.input}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} fullWidth>
                    <DatePicker
                      sx={{ width: "100%" }}
                      id="date-picker-dialog"
                      margin="normal"
                      label={t("mpr_quotation_last_date")}
                      fullWidth
                      format="dd/MM/yyyy"
                      onChange={handlequotationDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      value={quotationDate}
                      renderInput={<TextField variant="standard"></TextField>}
                      slotProps={{ textField: { variant: "standard" } }}
                    />
                  </LocalizationProvider>
                </div>
                <div className={classes.input}>
                  <FormControl variant="standard" fullWidth className={classes.formControl}>
                    <InputLabel id="demo-mutiple-checkbox-label">
                      {t("common_service_category")}
                    </InputLabel>
                    <Select
                      variant="standard"
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      name="service_category"
                      value={serviceCate}
                      onChange={handleCateChange}>
                      {servicecat.length > 0 &&
                        servicecat.map((item, key) => (
                          <MenuItem value={item} key={key}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.input}>
                  {serviceType.length > 0 ? (
                    <FormControl variant="standard" fullWidth className={classes.formControl}>
                      <InputLabel  required id="demo-mutiple-checkbox-label">
                        {t("common_service_type")}
                      </InputLabel>
                      <Select
                       
                        variant="standard"
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={otherType}
                        onChange={handleOtherType}
                        input={<Input />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}>
                        {serviceType.length > 0 &&
                          serviceType.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox
                                checked={otherType.indexOf(name) > -1}
                              />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <></>
                  )}
                </div>
                {otherType.includes("Others") ? (
                  <TextField
                    variant="standard"
                    fullWidth
                    id="mpr_id"
                    label={"Other Service Type"}
                    name="other_service_type"
                    value={otherServiceType}
                    onChange={handleOtherServiceType}
                  />) : <></>
                }
                {UlField && <div>
                  <TextField variant="standard" fullWidth id="mpr_inquiry_id" label={t("mpr_inquiry")} name="inquirylead_id" value={inquiryLeadId} onChange={handleInquiryLeadId} />
                </div> }
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid item lg={3} md={1} xs={0} sm={0} ></Grid>
    </Grid>
  );
};

export default BasicDetails;

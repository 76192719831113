import React,{useState} from "react";
import { 
    Grid,
    Paper,
    Collapse, 
    IconButton,
   
} from '@mui/material'
import {
    ExpandMore as DropDownIcon,
    ExpandLess as DropUpIcon
}  from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import Table from '../../../../components/Table/Table'
import useStyles from "./styles";
import {StyledBadge} from './styles'



const MaterialDetails = ({list,martialList,goodsList}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    var classes = useStyles();
    const[collapse,setCollapse] = useState(true)

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    const material_header = [
        {
            label: t("common_file_name"),
            options: {
                filter: true,
            }
        },
        {
            label: t("common_reference"),
            options: {
                filter: true,
            }
        },  
    ]

    return (
        <Paper className={classes.Datapaper}>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={10}>
                    <StyledBadge badgeContent={(list.MPRDetails) && list.MPRDetails.slot.slots.length} color="primary">
                        <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("mpr_material_details")}</span>
                    </StyledBadge>
                </Grid>
                <Grid item xs={2} className={classes.iconGrid}>
                    <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
                        {(collapse)?<DropDownIcon /> :<DropUpIcon />}
                    </IconButton>
                </Grid>
            </Grid> 
            <Collapse in={collapse}>
                <Grid container spacing={3} className={classes.grid}>
                    <Grid item xs={12} sm={6} md={6} className={classes.grid}>
                        <Table 
                            data={martialList} 
                            header={material_header} 
                            name={t("common_mpr_list")} 
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                                pagination:false,
                                
                            }} />             
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={classes.grid}>
                        <Table 
                            data={goodsList} 
                            header={material_header} 
                            name={t("common_mpr_goods_list")} 
                            options={{
                                filterType: 'checkbox',
                                print: false,
                                download: false,
                                filter:false,
                                selectableRows:'none',
                                pagination:false,
                               
                            }} />
                    </Grid>  
                </Grid>
            </Collapse>         
        </Paper>
    );
}
export default MaterialDetails
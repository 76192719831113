import { makeStyles } from "@mui/styles";
import withStyles from '@mui/styles/withStyles';
import { Switch } from '@mui/material';

export default makeStyles(theme => ({
    fullHeightBody: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        // border:"1px solid #DCDCDC",
      },
      card: {
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: 'border-box',
      },
      widgetlink: {
        textDecoration:'none',
        "&:hover": {
          textDecoration:'none',
        }
      },
      fakeToolbar: {
        ...theme.mixins.toolbar,
      },
      content:{
        padding: theme.spacing(6),
        // paddingTop: theme.spacing(14),
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(1), 
          paddingTop: theme.spacing(6),
        }
      },
      HeaderContainer:{
        padding:theme.spacing(1.5),
        margin:theme.spacing(1.5),
        display:'flex',
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
          flexDirection:"column",

        },
      },
      deleteicon:{
        color: theme.palette.danger.main,
      },
      pageTitleContainer:{
        // padding:theme.spacing(1.5),
        marginBottom:theme.spacing(1.5),
        justifyContent: "space-between",
        display:'flex'
      },
      tenantHeader:{
        // display:'flex',
        // alignItems:"flex-start",
        // justifyContent:"flex-start"
      },
      tenantButtons:{
        display:"flex",
        justifyContent:"flex-end",
        alignItems:"flex-end",
        [theme.breakpoints.down('sm')]:{
          marginTop:"10px"
        }
      },
      tenantSubHeader:{
        paddingTop: theme.spacing(0.2)
      },
      buttonOutlined:{
        "&:hover" : {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }
      },
      buttonOutlinedDanger:{
        border:'1px solid',
        borderColor : theme.palette.danger.main,
        color : theme.palette.danger.main,
        [theme.breakpoints.up("sm")]: {
          marginLeft:'10px',
        },
        [theme.breakpoints.down("sm")]: {
          marginTop:'10px',
        },
        [theme.breakpoints.up("md")]: {
          marginTop:'10px',
          marginLeft:'0px',
        },
        [theme.breakpoints.up("lg")]: {
          marginTop:'0px',
          marginLeft:'10px',
        },
        "&:hover" : {
          backgroundColor: theme.palette.danger.main,
          color: theme.palette.primary.contrastText
        }
      },
      buttonOutlinedDanger2:{
        border:'1px solid',
        borderColor : theme.palette.danger.main,
        color : theme.palette.danger.main,
        "&:hover" : {
          backgroundColor: theme.palette.danger.main,
          color: theme.palette.primary.contrastText
        }
      },
      paper:{
        margin: "130px auto",
        padding: "50px",
        [theme.breakpoints.up("sm")]: {
          width: "390px",
        },
      },
      noteBranch:{
        paddingBottom:'1rem'
      },
      paperButton:{
        display:"flex",
        alignItems:"center",
        justifyContent:'flex-end',
        marginTop:'2rem',
      },
      buttonsList:{
        [theme.breakpoints.down("sm")]: {
          display:"flex",
          // alignItems:"center",
          flexDirection:"column",
          justifyContent:"space-between"
        },
      }   
}));

const TealSwitch = withStyles({
  switchBase: {
    color: '#2E7D32',
    '&$checked': {
      color: '#2E7D32',
    },
    '&$checked + $track': {
      backgroundColor:'#2E7D32',
    },
  },
  checked: {},
  track: {},
})(Switch);

export {TealSwitch}
import {apiUrl} from '../../data/config'

export function addContact(data,dispatch){
    let bodyData = {}
    let url = ""
    if(data.userId === ""){
        url = apiUrl+'/v1/customer/newUser'
        let mobile = {
            code: data.countryCode,
            number : data.mobileNumber
        }
        bodyData   = {
            "users":{ 
                    "userName": data.fullName,
                    "branchId": data.branch,
                    "referenceId": localStorage.getItem("customerid"),
                    "email": data.email,
                    "mobile":mobile,
                    "createdAt": new Date().toISOString(),
                    "createdBy": "System",
                    "updatedBy": "System",
                    "isActive": true,
                    "roles": [
                        data.role
                    ],
                    "isPrimary": true,
                    "department": [
                        "Transport,Administration"
                    ],
                    "password": "",
                    "isTemperory": true,
                    "type":localStorage.getItem('type'),
                    "employeeData": data.employeeData
                },
            "customer":data.customerId,
            "branch" : data.branch
        }
    }else if(data.userId !== ""){
        url = apiUrl+'/v1/customer/updateDetail'
        bodyData = {
            "updateItem":"contact",
            "updateid":data.userId,
            "updateData":{ 
                    "userName": data.fullName,
                    "email": data.email,
                    "mobile": {"code":data.countryCode,"number":data.mobileNumber},
                    "updatedBy": data.EditUserId,
                }
        }
    }
    

    return fetch(url,{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type':'application/json',
            'token' : localStorage.getItem('token')
        },
        body: JSON.stringify(bodyData)
    }).then(response => {
        return response.json().then((data) => {
            if(data.status === "200"){
                return data
            }else if(data.status === "401"){
                dispatch({
                    type : 'LOG_OUT'
                })
                localStorage.clear()
                return data
            }else {
                return data
            }
        })
    })
}

export function deleteContact(id) {
  let url = apiUrl + "/v1/customer/deletedetails";
  let bodyData = {
    deleteItem: "contact",
    deleteid: id,
  };
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      return data;
    });
  });
}

export function updateContact(data, updatedRole, dispatch) {
  const url = apiUrl + "/v1/customer/updateDetail";
  const bodyData = {
    updateItem: "contact",
    updateid: data.contactId,
    updateData: {
      updatedBy: data.updatedById,
      roles: updatedRole,
    },
  };
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(bodyData),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "401") {
        dispatch({
          type: "LOG_OUT",
        });
        localStorage.clear();
        return data;
      } else {
        return data;
      }
    });
  });
}

import React from "react";
import {
    TextField,
} from '@mui/material';
import {
    Controller,
    useFormContext,
} from "react-hook-form";

import useStyles from "../styles";

import { useTranslation } from 'react-i18next';



const BasicDetails = ({ setForm, formData }) => {
    // eslint-disable-next-line
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const fields = [
        { label: t("tag_title"), name: "tagName", type: "text" }
    ]

    return <>
        <h3 className={classes.title}>{t("tag_title")}</h3>
        {// eslint-disable-next-line
            fields.map((item, key) => {
                switch (item.type) {
                    case "text":
                        return (
                            <Controller
                                control={control}
                                name={item.name}
                                rules={{
                                    required: item.label + " is required.",
                                    minLength: 3,
                                    pattern: {
                                        value: /^(?=.*[a-zA-Z])[a-zA-Z0-9]*$/,
                                        message: item.label + " should contain at least one letter and can include numbers.",
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="standard"
                                        id="title"
                                        label={item.label} 
                                        fullWidth
                                        margin="normal"
                                        {...field}
                                        error={Boolean(errors?.[item.name])}
                                        helperText={
                                            errors[item.name]?.type === "required"
                                                ? errors[item.name]?.message
                                                : errors[item.name]?.type === "minLength"
                                                    ? item.label + " must have a minimum of 3 characters"
                                                    : errors[item.name]?.message // Display pattern validation message
                                        }
                                    />
                                )}
                            />
                        );
                    default: return <></>
                }
            })
        }
    </>;
};

export default BasicDetails;

import React,{useEffect,useState} from "react";
import { 
    Grid,
    Paper,
    Collapse, 
    IconButton,
    CircularProgress,
    Button,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography
} from '@mui/material'
import {
    ExpandMore as DropDownIcon,
    ExpandLess as DropUpIcon
}  from '@mui/icons-material';
import DescriptionIcon from "@mui/icons-material/Description";
import { useTranslation } from 'react-i18next';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CommentIcon from '@mui/icons-material/Comment';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { useDispatch,useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { tooltipClasses } from "@mui/material/Tooltip";
import Table from '../../../../components/Table/Table'
import useStyles from "./styles";
import {shortList,getMPRData,assignLead,rejectAllLead} from '../../MprState'
import {getFile} from '../../../leads/LeadState'
import NotificationContainer from "../../../notifications/NotificationContainer";
import QuotationPDF from "../../../leads/components/ViewLead/quotations/QuotationPDF";
import { styled } from "@mui/material/styles";


function currencyFormat(num) {
    return parseInt(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

const QuotationList = ({close,quotation,userData}) => {
    // eslint-disable-next-line
    const { t,i18n } = useTranslation();
    const dispatch = useDispatch()
    const state = useSelector(state => state.selectedMPR)
    var classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    

    const[collapse,setCollapse] = useState(true)
    // const [allQuote,setAllQuote] = useState([])
    const [fav,setFav] = useState([])
    // eslint-disable-next-line
    const [shortlistselect,setShortlistSelect] = useState(false)
    const [shortId,setShortId] = useState("")
    const [showLoader,setShowLoader] = useState("")
    const [quotationList,setQuotationList] = useState(quotation)
    const [open, setOpen] = useState(false);
    const [acceptOpen, setAcceptOpen] = useState(false);
    const [reRequest,setReRequest] = useState("")
    const [selectedItem,setSelectedItem] = useState("")
    const [selectedQuote,setSelectQuote] = useState("")
    const [showquoteload,setShowQuoteLoad] = useState(false)
    const [message,setMessage] = useState("")
    const [notification,setNotification] = useState(false)
    const [type,setType] = useState("")
    const [qoutUrl,setQuotUrl] = useState("")
    const [showPDF,setShowPDF] = useState(false)
    // eslint-disable-next-line
    const [recyclerName,setRecyclerName] = useState("")

    const handleClickOpen = (item,quote) => {
        setSelectedItem(item)
        setSelectQuote(quote)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleAcceptOpen = (item,quote) => {
        setSelectedItem(item)
        setSelectQuote(quote)
        setRecyclerName(selectedQuote.TenantName)
        setAcceptOpen(true)
    }
    const handleAcceptClose = () => {
        setAcceptOpen(false)
    }

    const handleShortlist = (data,type) => {
        setShowLoader(true)
        setShortId(data.LeadId)
        let  short = true
        if(type === "removeshortlist"){
                short = false
        }   
        let body = {
            "external_id":data.LeadId,
                "leadData":{
                    "quoteShortlist":short
                }
            }
            shortList(body,dispatch).then(response => {
                if(response.status === "200"){
                    getMPRData(state.MPR.external_id,dispatch).then(res => {
                        setQuotationList(res.quotationList)
                    })
                    setType("success")
                    setMessage("Shortlisted")
                    setNotification(true)
                    setShowLoader(false)  
                }else{
                    setType("error")
                    setMessage("Something went wrong")
                    setNotification(true)
                    setShowLoader(false) 
                }
            })
    }

    const handleRequote = () => {
        setShowQuoteLoad(true)
        let body = {
            external_id : selectedItem.LeadId,
            leadData : {
                quotation : {
                    folderName : selectedQuote.folderName || selectedQuote.folder || selectedQuote.FolderName,
                    fileName : selectedQuote.fileName || selectedQuote.filename,
                    quoteStatus : "reQuote",
                    customerComments : reRequest
                },
                status : "request for reQuotation"
            },
            mpr_id:userData.external_id
        }
        assignLead(body,dispatch).then(response => {
            if(response.status === "200"){
                getMPRData(state.MPR.external_id,dispatch).then(res => {
                    setQuotationList(res.quotationList)
                })
                setReRequest("")
                setShowQuoteLoad(false)
                setOpen(false)
                setType("success")
                setMessage("Requotation Request Sent Successfully")
                setNotification(true)
            }else{
                setOpen(false)
                setType("error")
                setShowQuoteLoad(false)
                setMessage("Something went wrong")
                setNotification(true)
            }
        })
    }

    const handleAccept = () => {
        setShowQuoteLoad(true)
        let body = {
            external_id : selectedItem.LeadId,
            leadData : {
                quotation : {
                    folderName : selectedQuote.folderName || selectedQuote.folder || selectedQuote.FolderName,
                    fileName : selectedQuote.fileName || selectedQuote.filename,
                    quoteStatus : "accepted",
                    totalAmount : {
                        region : selectedQuote.totalAmount.region,
                        amount : selectedQuote.totalAmount.amount
                    },
                    description :selectedQuote.description ,
                    referenceName : selectedItem.referenceName,
                    customerComments : reRequest
                },
                status : "Quotation Accepted",
                reason : "Won"
            },
            mpr_id:userData.external_id
        }
        let mprBody = {
            mprID : state.MPR.external_id
        }
        assignLead(body,dispatch).then(response => {
            if(response.status === "200"){
                rejectAllLead(mprBody,dispatch).then(ele => {
                    if(ele.status === "200"){
                        getMPRData(state.MPR.external_id,dispatch).then(res => {
                            setQuotationList(res.quotationList)
                        })
                        setReRequest("")
                        setShowQuoteLoad(false)
                        setAcceptOpen(false)
                        setType("success")
                        setMessage("Quotation Accepted")
                        setNotification(true)
                    }else{
                        setType("error")
                        setMessage("Something went wrong")
                        setNotification(true)
                    }
                })
                
                
            }else{
                setType("error")
                setMessage("Something went wrong")
                setNotification(true)
            }
        })
    }

    const handlePDF = (data) => {
        let body  = {
            "fileName" : data.fileName,
            "folderName" : data.folderName,
            "expireLimt" : 1800
        }
        getFile(body).then(response => {
            setQuotUrl(response.reference)
            setShowPDF(true)
        })
    }

    const [tooltipStates, setTooltipStates] = useState([]);

    const toggleTooltipState = (index) => {
        const newTooltipStates = [...tooltipStates];
        newTooltipStates[index] = !newTooltipStates[index];
        setTooltipStates(newTooltipStates);
      };
    
    function generateTooltipContent( item, index) {
    
        return (
          <Button
            style={{ marginLeft: "-22px" }}
            onClick={() => toggleTooltipState(index)}
          >
            <CustomWidthTooltip
              title={<Typography variant="body1">{item}</Typography>}
              open={tooltipStates[index]}
              onClose={() => toggleTooltipState(index)}
            >
              <DescriptionIcon
                style={{cursor: "pointer" }}
                sx={{ m: 1 }}
                color="success"
              ></DescriptionIcon>
            </CustomWidthTooltip>
          </Button>
        );
      }

    useEffect(() => {
        let all = []
        let shortlisted = []
        // eslint-disable-next-line
        quotationList.map(item=>{
            if(item.quoteShortlist){
                item.quotationSet.sort((a, b) => new Date(b.genratedDate) - new Date(a.genratedDate));
                // eslint-disable-next-line
                item.quotationSet.map((quote,index) => {
                    const isLatestQuote = quote === item.quotationSet[0];
                    shortlisted.push([
                        quote.quotationNo ? quote.quotationNo : quote.referenceName,
                        generateTooltipContent(quote.description, index),
                        quote.totalAmount.region + " " + currencyFormat( quote.totalAmount.amount),
                        <IconButton
                            className={classes.collapseIcon}
                            onClick={() => handlePDF(quote)}
                            size="large">
                            <PictureAsPdfIcon />
                        </IconButton>,
                        <>
                        { isLatestQuote && userData.status !== "Accepted" && quote.quoteStatus === "sent" && (<>
                            <Tooltip title = "Send Query">
                                <IconButton
                                    className={classes.collapseIcon}
                                    onClick={() => handleClickOpen(item,quote)}
                                    size="large">
                                    <CommentIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title = "Accept Quotation">
                                <IconButton
                                    className={classes.collapseIcon}
                                    onClick={() => handleAcceptOpen(item,quote)}
                                    size="large">
                                    <DoneOutlineIcon />
                                </IconButton>
                            </Tooltip>
                            </>
                            )}
                           {
                               (userData.status  === "Accepted" && quote.quoteStatus === "accepted" )? 
                               <span className={classes.choosedSlotSpan}>{t("common_accepted")}</span> : <></>
                                   
                               
                           }
                        </>
                    ])
                })
            }else{
                // eslint-disable-next-line
            //   (item.quotationSet) && item.quotationSet.map((quote,index) => {
            //         all.push([
            //             quote.quotationNo ? quote.quotationNo : quote.referenceName,
            //             generateTooltipContent(quote.description, index),
            //             quote.totalAmount.region + " " + currencyFormat( quote.totalAmount.amount),
            //             <IconButton
            //                 className={classes.collapseIcon}
            //                 onClick={() => handlePDF(quote)}
            //                 size="large">
            //                 <PictureAsPdfIcon />
            //             </IconButton>,
                        
            //             (showLoader && shortId === item.LeadId )?<CircularProgress /> :<IconButton
            //                 className={classes.collapseIcon}
            //                 onClick={() => handleShortlist(item,'addshortlist')}
            //                 size="large">
            //             {
            //                  <CheckBoxOutlineBlankIcon />
            //             }    
            //             </IconButton>
                        
                        
    
            //         ])
            //     })
                
            }
            
        })
        // setAllQuote(all)
        setFav(shortlisted)
        // eslint-disable-next-line
    }, [quotation,shortlistselect,quotationList,acceptOpen])

    const handleCollapse = () => {
        setCollapse(!collapse)
    }

    // const allHeader = [t("quotation_name"),t("common_description"),t("quotation_value"),t("quotation"),t("quotation_shortlist")]
    const shortHeader = [t("quotation_name"),t("common_description"),t("quotation_value"),t("quotation"),t("common_action")]

    return (
        <Paper className={classes.Datapaper}>
            {
                (notification)?<NotificationContainer message={message} notificationtype={type}/>:<></>
            }
            <Grid container spacing={3} className={classes.grid}>
                <Grid item lg={9}>
                    <span style={{fontSize:'19px',fontWeight:'lighter'}}>{t("common_quotations")}</span>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="outlined" color="primary" size="small" onClick={()=>{close()}}>{t("common_close")}</Button>
                </Grid>
                <Grid item xs={2} className={classes.iconGrid}>
                    <IconButton className={classes.collapseIcon} onClick={handleCollapse} size="large">
                        {(collapse)?<DropDownIcon /> :<DropUpIcon />}
                    </IconButton>
                </Grid>
            </Grid> 
            <Collapse in={collapse}>
            {
                         (showPDF)?<QuotationPDF url={qoutUrl}/> :<Grid container spacing={3} className={classes.grid}>
                         <Grid item xs={12} className={classes.grid}>
                             <Table 
                                 data={fav} 
                                 header={shortHeader} 
                                 name={t("common_all")+" "+t("common_quotations")} 
                                 options={{
                                     filterType: 'checkbox',
                                     print: false,
                                     download: false,
                                     filter:false,
                                     selectableRows:'none'
                                 }} />             
                         </Grid>
                         {/* <Grid item xs={12} className={classes.grid}>
                             <Table 
                                 data={allQuote} 
                                 header={allHeader} 
                                 name={t("common_all")+" "+t("common_quotations")} 
                                 options={{
                                     filterType: 'checkbox',
                                     print: false,
                                     download: false,
                                     filter:false,
                                     selectableRows:'none'
                                 }} />
                         </Grid>   */}
                     </Grid>
            }
                
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{t("quotation_request_re-quote")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
              {t("quotation_question_message")}
          </DialogContentText>
          <TextField
              variant="standard"
              autoFocus
              multiline
              margin="dense"
              id="name"
              value = {reRequest}
              onChange = {(e) => setReRequest(e.target.value)}
              type="text"
              fullWidth />
        </DialogContent>
        <DialogActions style={{marginBottom:"5px",marginRight:"3px"}}>
          <Button onClick={handleClose} color="primary">
            {t("common_cancel")}
          </Button>
         {
             (showquoteload)?<CircularProgress /> :<Button onClick={handleRequote} color="primary">
             {t("common_send")}
           </Button>
         } 
        </DialogActions>
      </Dialog>
      {/* accept dailog */}
      <Dialog
        fullScreen={fullScreen}
        open={acceptOpen}
        onClose={handleAcceptClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{t("quotation_accept_quote")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("quotation_dialog_text")}
          </DialogContentText>
          <TextField
              variant="standard"
              autoFocus
              multiline
              margin="dense"
              id="name"
              value = {reRequest}
              onChange = {(e) => setReRequest(e.target.value)}
              type="text"
              fullWidth />
        </DialogContent>
        <DialogActions style={{marginBottom:"5px",marginRight:"3px"}}>
          <Button autoFocus onClick={handleAcceptClose} color="primary">
            {t("common_close")}
          </Button>
          {
            (showquoteload)?<CircularProgress /> :<Button onClick={handleAccept} color="primary">
                {t("common_accept_agree")}
            </Button>
            } 
        </DialogActions>
      </Dialog>
            </Collapse>         
        </Paper>
    );
}
export default QuotationList
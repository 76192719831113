import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  useMediaQuery,
  CircularProgress,
  Typography,
  TextField,
  Divider,
  Grid,
  Box,
  Paper,
  IconButton,
  Chip,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SubjectIcon from "@mui/icons-material/Subject";
import { useSelector } from "react-redux";
import { LeaveDash } from "../dashboard/LeaveDash";
const LeaveActionModal = ({
  isModalOpen,
  toggleModal,
  data,
  onStatusChange,
  employeeLeaveData,
}) => {
  const loggedInData = useSelector((state) => state.userdata.getData);
  const [selectedValue, setSelectedValue] = useState(data?.status);
  const [rejectionReason, setRejectionReason] = useState(
    data?.reasonForRejection
  );
  const [loader, setLoader] = useState(false);
  const [isViewer, setIsViewer] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isAuthorizer, setIsAuthorizer] = useState(false);
  const [isOnlyUser, setIsOnlyUser] = useState(false);
  const [isSameApprover, setIsSameApprover] = useState(false);
  useEffect(() => {
    if (
      loggedInData &&
      loggedInData?.roles.some((item) => "user" === item) &&
      loggedInData?.roles.some((item) => "Viewer" === item)
    ) {
      setIsViewer(true);
    } else if (
      loggedInData &&
      loggedInData?.roles.some((item) => "user" === item) &&
      loggedInData?.roles.length === 1
    ) {
      setIsOnlyUser(true);
    } else if (loggedInData?.roles.some((item) => "Approver" === item)) {
      setIsApprover(true);
    } else if (loggedInData?.roles.some((item) => "Authorizer" === item)) {
      setIsAuthorizer(true);
    }

    setSelectedValue(data?.status);
    setRejectionReason(data?.reasonForRejection);
  }, [data?.status]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    if (
      data &&
      loggedInData?._id == data?.raisedBy?.[0]?._id &&
      loggedInData?.roles.some((item) => item === "Approver")
    ) {
      setIsSameApprover(true);
    } else {
      setIsSameApprover(false);
    }
  };

  const handleConfirm = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      onStatusChange(selectedValue, rejectionReason);
      toggleModal();
    }, 2000);
  };


  return (
    <Dialog open={isModalOpen} fullWidth maxWidth="md" onClose={toggleModal}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Leave Request {data?.leaveId}:</Typography>
          <IconButton onClick={toggleModal} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            {isOnlyUser || isViewer
              ? "Your Leave Summary:"
              : `${data?.raisedBy?.[0]?.username}'s Leave Summary:`}
          </Typography>

          <LeaveDash leaveData={employeeLeaveData} showLeavePolicy={false} />
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6">This Leave request data:</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={2}
              sx={{
                p: 2,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <DateRangeIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="subtitle2">Leave Period</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Chip
                    label={`From: ${data?.startDate}`}
                    variant="outlined"
                    size="small"
                  />
                  <Chip
                    label={`To: ${data?.endDate}`}
                    variant="outlined"
                    size="small"
                  />
                </Box>
              </Box>
              <Box mt={2}>
                <Box display="flex" alignItems="center" mb={1}>
                  <AccessTimeIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="subtitle2">Duration</Typography>
                </Box>
                <Chip
                  label={`${data?.noOfDays || "N/A"} days`}
                  color="primary"
                  size="small"
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={2}
              sx={{
                p: 2,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <SubjectIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="subtitle2">Leave Details</Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  Type
                </Typography>
                <Chip
                  label={data?.selectedLeaveType}
                  color="secondary"
                  size="small"
                  sx={{ mt: 0.5 }}
                />
              </Box>
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary">
                  Reason
                </Typography>
                <Typography variant="body1" sx={{ mt: 0.5 }}>
                  {data?.reasonForLeave}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Box mt={3}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              {isOnlyUser ? "Request Status" : "Update Status"}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={selectedValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="Pending"
                  control={<Radio />}
                  label="Pending"
                  disabled
                />
                <FormControlLabel
                  value="Approved"
                  control={<Radio />}
                  label="Approved"
                />
                <FormControlLabel
                  value="Rejected"
                  control={<Radio />}
                  label="Rejected"
                />
              </RadioGroup>
            </FormControl>
            {selectedValue === "Rejected" && (
              <TextField
                label="Reason for Rejection"
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: isOnlyUser || isViewer,
                }}
                multiline
                minRows={2}
              />
            )}
          </Paper>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            onClick={toggleModal}
            variant="outlined"
            color="primary"
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            disabled={
              isSameApprover ||
              isViewer ||
              isOnlyUser ||
              (selectedValue == data?.status &&
                rejectionReason?.trim() == data?.reasonForRejection) ||
              (selectedValue === "Rejected" && !rejectionReason?.trim()) ||
              data?.status == "Rejected" ||
              loader
            }
          >
            {loader ? <CircularProgress size={24} /> : "Confirm"}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LeaveActionModal;
